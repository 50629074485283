import { Menu, Row, Text } from "app/components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AdminForm from "./AdminForm";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useRecoilValue } from "recoil";

const UserGroupPermissions = ({
  anchorElement,
  onClose,
  onChange,
  values,
  noGroupsText,
  isUserPage = false,
  allowedValues = [],
}) => {
  const activeApp = useRecoilValue(rApp);

  const location = useLocation();
  const path = get(location, "pathname");

  const [localValues, setLocalValues] = useState([]);
  const userGroups = get(activeApp, "user_groups", []) || [];
  const userGroupIds = userGroups.map((g) => g.id);

  const navigate = useNavigate();

  useEffect(() => {
    if (!values) {
      // Values are not yet defined, set all permissions to ON
      const defaults = userGroupIds.filter((g) => allowedValues.includes(g));
      setLocalValues(defaults);
    } else {
      const filtered = values.filter((v) => userGroupIds.includes(v));
      setLocalValues(filtered);
    }
  }, []);

  const change = (key, value) => {
    const filtered = localValues.filter((v) => userGroupIds.includes(v));

    if (filtered.includes(key)) {
      // REMOVE
      onChange(filtered.filter((g) => g !== key));
      setLocalValues(filtered.filter((g) => g !== key));
    } else {
      // ADD
      onChange([...filtered, key]);
      setLocalValues([...filtered, key]);
    }
  };

  const reset = () => {
    onChange([]);
    setLocalValues([]);
    successNotification("User groups reset");
  };

  return (
    <Menu
      anchorElement={anchorElement}
      hide={onClose}
      label="Visibility Permissions"
      hint="Create customized access to parts of your app with User Groups"
      hintLink={"https://help.frontly.ai/en/articles/7982162-users-permissions"}
    >
      {localValues.length === 0 && (
        <WarningBox>
          Warning! All permissions are turned off, which means this setting will
          be skipped.{" "}
          {isUserPage
            ? "This user will have full access to this app's pages."
            : "This content will be visible to everyone."}
        </WarningBox>
      )}
      <AdminForm
        sectionPadding={"0px"}
        onChange={change}
        fields={userGroups.map((g) => {
          const disabled =
            allowedValues.length > 0 && !allowedValues.includes(g.id);

          return {
            id: g.id,
            label: g.name,
            componentId: "Switch",
            orientation: "horizontal",
            value: disabled ? false : localValues.includes(g.id),
            disabled,
          };
        })}
      />
      {allowedValues.length > 0 && (
        <Text
          data={{
            text: "Permissions disabled on the page level are disabled in this menu",
            margin: "20px 0 0 0",
            fontStyle: "bodySm",
            color: "var(--grey7)",
          }}
        />
      )}
      {userGroups.length === 0 && (
        <Text
          data={{
            text: noGroupsText,
            fontStyle: "bodyMd",
            color: "var(--grey7)",
          }}
        />
      )}

      <Row justifyContent="space-between">
        {path !== "/users" && (
          <Text
            data={{
              text: "Edit User Groups",
              margin: "10px 0 0 0",
              fontStyle: "headingSm",
              color: "var(--primary-admin)",
              onClick: () => navigate("/users"),
            }}
          />
        )}

        <Text
          data={{
            text: "Reset Values",
            margin: "10px 0 0 0",
            fontStyle: "headingSm",
            color: "var(--primary-admin)",
            onClick: reset,
          }}
        />
      </Row>
    </Menu>
  );
};

export default UserGroupPermissions;

const WarningBox = styled.div`
  border-radius: 8px;
  background: ${colors.clickUpBackground};
  color: var(--grey8);
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
  max-width: 300px;
  margin-bottom: 15px;
`;
