import { rActiveBlockId, rPageBlocks } from "./recoil";

import { useRecoilValue } from "recoil";

const useActiveBlock = () => {
  const blocks = useRecoilValue(rPageBlocks);
  const activeBlockId = useRecoilValue(rActiveBlockId);
  return blocks.find((b) => b.id === activeBlockId);
};

export default useActiveBlock;
