import { colors } from "app/utils/theme";
import { getColor } from "./utils";
import { rDarkMode } from "app/utils/recoil";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const Legend = ({
  position,
  values,
  colorScheme,
  customColorScheme,
  legendWidth,
  characterLimit,
}) => {
  const darkMode = useRecoilValue(rDarkMode);
  const barThickness = values.length < 6 ? "20px" : "15px";

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Container position={position}>
      {values
        .filter((v, i) => (!showMore ? i < 7 : true))
        .map((item, index) => {
          return (
            <ItemContainer>
              <ValueBar
                thickness={barThickness}
                color={getColor(index, colorScheme, customColorScheme)}
              >
                &nbsp;
              </ValueBar>
              <ItemLabel darkMode={darkMode} legendWidth={legendWidth}>
                {truncateText(item.label, parseInt(characterLimit))}
              </ItemLabel>
            </ItemContainer>
          );
        })}
      {values.length > 7 && (
        <ShowMoreLabel onClick={handleShowMore}>{`${
          showMore ? "Show Less" : `+ ${values.length - 8} more`
        }`}</ShowMoreLabel>
      )}
    </Container>
  );
};

export default Legend;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) =>
    ["above", "below"].includes(p.position) ? "row" : "column"};
  flex-wrap: wrap;
  gap: 10px;
  height: fit-content;
`;

const ItemContainer = styled.div`
  display: flex;
  width: 135px;
  align-items: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  gap: 15px;
`;

const ItemLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: ${(p) => (p.legendWidth ? `${p.legendWidth}px` : "100px")};
  color: ${(p) => (p.darkMode ? "white" : "var(--grey8)")};
`;

const ShowMoreLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 135px;
  width: ${(p) => (p.legendWidth ? `${p.legendWidth}px` : "100px")};
  color: ${(p) => (p.darkMode ? "white" : "var(--grey8)")};
  cursor: pointer;
`;

const ValueBar = styled.div`
  width: 20px;
  background: ${(p) => p.color || "blue"};
  border-radius: 4px;
  height: ${(p) => p.thickness};
`;
