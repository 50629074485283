import {
  addFetchingBlocks,
  rApp,
  rFetchingBlockIds,
  rPageBlocks,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import {
  getCurrentDomain,
  getGoogleSheetsEndpoint,
  getRelatedSheets,
  isFrontlyAdmin,
  safeArray,
} from "app/utils/utils";
import { useEffect, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import { apiRequest } from "app/utils/apiRequests";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";

export const useAdminData = () => {
  const blocks = useRecoilValue(rPageBlocks);

  const [savedDataSources, setSavedDataSources] =
    useRecoilState(rSavedSpreadsheets);

  const app = useRecoilValue(rApp);

  const dataRelations = get(app, "data_relations", []);

  // Check if the data policy is set to "live"
  const dataPolicy = get(app, "data_policy", "live");

  const addToFetchingBlocks = useSetRecoilState(addFetchingBlocks);

  const resetFetchingBlockIds = useResetRecoilState(rFetchingBlockIds);

  const [isReady, setIsReady] = useState(false);

  const [fetchedSources, setFetchedSources] = useState([]);

  const dataSourceIds = [
    ...new Set(blocks.map((b) => b.spreadsheet).filter(Boolean)),
  ];

  // Create service map
  let serviceTypeMap = {};
  savedDataSources.forEach((s) => {
    serviceTypeMap[s.id] = get(s, "service", "google");
  });

  const unfetched = dataSourceIds.filter((id) => !fetchedSources.includes(id));

  const hasUnFetchedSheets = unfetched.length > 0;

  const dataPolicyIsLiveOrCache =
    dataPolicy === "live" || dataPolicy === "cache";

  // Find all the sources that have already been loaded
  useEffect(() => {
    if (dataPolicyIsLiveOrCache) {
      let alreadyLoaded = [];
      Object.keys(savedDataSources).forEach((key) => {
        const source = get(savedDataSources, key, null);
        const sourceData = safeArray(source, "data");

        if (sourceData.length > 0) {
          alreadyLoaded.push(source.id);
        }

        setFetchedSources(alreadyLoaded);
        setIsReady(true);
      });
    }
  }, []);

  useEffect(() => {
    if (
      isReady &&
      dataPolicyIsLiveOrCache &&
      isFrontlyAdmin &&
      unfetched.length > 0
    ) {
      fetchSpreadsheets();
    }
  }, [hasUnFetchedSheets, isReady]);

  // Fetch spreadsheets from API based on blocks
  const fetchSpreadsheets = () => {
    setFetchedSources([...fetchedSources, ...unfetched]);

    let matchingBlockIds = [];

    blocks.forEach((block) => {
      if (unfetched.includes(block.spreadsheet)) {
        matchingBlockIds.push(block.id);
      }
    });

    addToFetchingBlocks(matchingBlockIds);

    const sheetRequests = unfetched.map((sheetId) => {
      const relatedSheetObjects = getRelatedSheets(
        sheetId,
        app,
        savedDataSources
      ).map((r) => {
        const relatedSheet = get(r, "relatedSheet");
        const relationColumns = get(r, "relationColumns");
        const matchingRel = dataRelations.find((rel) => rel.id === r.id);

        return {
          relation_id: get(r, "id"),
          id: get(relatedSheet, "id"),
          current_column: get(relationColumns, "current"),
          inner_join: get(matchingRel, "inner_join", false),
          related_column: get(relationColumns, "other"),
          display_column: get(r, "display_column"),
          relation_key: get(r, "relationKey"),
        };
      });

      return {
        id: sheetId,
        block_id: sheetId, // Pretending that the block ID is the sheet ID
        service: get(serviceTypeMap, sheetId),
        related_sheets: relatedSheetObjects,
      };
    });

    apiRequest
      .post(getGoogleSheetsEndpoint(), {
        endpoint_type: "multi",
        spreadsheets: sheetRequests,
        domain: getCurrentDomain(),
      })
      .then((response) => {
        const error = get(response, ["data", "error"]);

        if (error) {
          errorNotification(error);
        }

        const newData = get(response, ["data"], []);

        setSavedDataSources(
          savedDataSources.map((s) => {
            const matchingData = get(newData, s.id, null);
            if (matchingData) {
              return {
                ...s,
                data: matchingData,
              };
            }
            return s;
          })
        );

        resetFetchingBlockIds();
      });
  };
};
