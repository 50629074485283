import { Hint, Icon, Row, Text } from "app/components";

import styled from "styled-components";

const NavTabs = styled.div`
  display: flex;
  padding: ${(p) => p.padding || "0px"};
  margin: ${(p) => p.margin || "0px"};
  gap: ${(p) => (p.tabStyle === "buttons" ? "10px" : "20px")};
`;

const NavTab = styled.div`
  ${(p) =>
    p.tabStyle === "default" &&
    `
    padding-bottom: 10px;
    border-bottom: 3px solid ${
      p.active ? "var(--primary-admin)" : "transparent"
    };
    opacity: ${p.disabled ? "0.5" : p.active ? "1.0" : "0.6"};
    cursor: ${p.disabled ? "not-allowed" : "pointer"};
  `}

  ${(p) =>
    p.tabStyle === "buttons" &&
    `
    padding: 10px;
    border-radius: 10px;
    background: ${p.active ? "#e3e3e3" : "transparent"};
    opacity: ${p.disabled ? "0.5" : p.active ? "1.0" : "0.6"};
    cursor: ${p.disabled ? "not-allowed" : "pointer"};
  `}
`;

const Tabs = ({ data }) => {
  const { fontSize = null, padding, margin, tabs, tabStyle = "default" } = data;

  let tabTextColor = data.color || "#646464";

  return (
    <NavTabs padding={padding} margin={margin} tabStyle={tabStyle}>
      {tabs
        .filter((tab) => !tab.hidden)
        .map((tab, index) => {
          if (tabStyle === "buttons" && tab.active) {
            tabTextColor = "#09090b";
          }

          return (
            <NavTab
              tabStyle={tabStyle}
              key={index}
              active={tab.active}
              disabled={tab.disabled}
              onClick={tab.disabled ? null : tab.onClick}
            >
              <Row alignItems="center">
                {tab.icon && (
                  <Icon
                    data={{
                      size: "20px",
                      icon: tab.icon,
                      hover: true,
                      margin: "0 10px 0 0",
                      color: data.color || "var(--primary)",
                    }}
                  />
                )}

                <Text
                  data={{
                    text: tab.label,
                    fontSize: fontSize
                      ? fontSize
                      : tabStyle === "buttons"
                      ? 14
                      : 16,
                    fontWeight: 600,
                    color: tabTextColor,
                    cursor: "pointer",
                  }}
                />

                {tab.hint && (
                  <Hint data={{ ...tab.hint, margin: "5px 0 0 2px" }} />
                )}
              </Row>
            </NavTab>
          );
        })}
    </NavTabs>
  );
};

export default Tabs;
