import { cardFieldKeys, cardKeys, listBlockResources } from "./shared";

export const Kanban = {
  componentId: "Kanban",
  resources: [
    ...listBlockResources,
    {
      id: "fields",
      label: "Kanban Card Fields",
      componentId: "DynamicFields",
      keys: cardFieldKeys,
      requiresSheet: true,
      section: "card",
      hint: "Add multiple text fields to display on the kanban item card",
    },
    {
      id: "image",
      label: "Image",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "card",
      hint: "The spreadsheet column to use for the card image (optional)",
    },
    {
      id: "columnKey",
      label: "Column Field",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      refreshColumns: true,
      section: "setup",
      wizard: true,
      hint: "The spreadsheet column to use to split up your data into the Kanban board columns",
    },
    {
      id: "columns",
      label: "Columns",
      hint: "Define the columns in your board to group data by",
      componentId: "DynamicFields",
      emptyLabel: "No Columns",
      customLabel: "Edit Column",
      getLabel: (field) => field.label,
      keys: [
        {
          id: "label",
          key: "label",
          label: "Label",
          componentId: "Input",
          hint: "The visual label for the column",
        },
        {
          id: "value",
          key: "value",
          label: "Value",
          componentId: "Input",
          hint: "The value to match for a card to appear in this column",
        },
        {
          id: "color",
          key: "color",
          label: "Accent Color",
          componentId: "ColorPicker",
        },
      ],
      orientation: "vertical",
      requiresSheet: true,
      section: "setup",
    },
    {
      id: "imageHeight",
      label: "Image Height",
      section: "image",
      isStyle: true,
      requiresSheet: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "150px",
      options: [
        { label: "50px", value: "50px" },
        { label: "100px", value: "100px" },
        { label: "150px", value: "150px" },
        { label: "200px", value: "200px" },
        { label: "250px", value: "250px" },
        { label: "300px", value: "300px" },
      ],
    },
    ...cardKeys,
    {
      id: "customBadgeColors",
      label: "Custom Colors",
      componentId: "MultiForm",
      labelSingular: "Custom Color",
      passIdToChild: true,
      section: "badge",
      width: "300px",
      fields: [
        {
          key: "label",
          label: "Label",
          placeholder: "Label",
          componentId: "Input",
          width: "100%",
        },
        {
          key: "color",
          label: "Color",
          componentId: "ColorPicker",
          width: "100%",
        },
        {
          id: "displayConditions",
          label: "Display Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
        },
      ],
    },
    {
      label: "Item Move Action",
      id: "itemMoveAction",
      section: "actions",
      dynamicSources: ["record"],
      hint: "This action runs in addition to the default update action in your Google Sheet. Only use this for additional custom actions",
      isAction: true,
      componentId: "Action",
      requiresSheet: true,
      advanced: true,
    },
  ],
};
