import {
  filterBasedOnUserGroups,
  fixCustomBlocks,
  getValidActions,
  isFrontlyAdmin,
  safeArray,
} from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rCustomBlocks,
  rDarkMode,
  rPageActions,
  rSavedSpreadsheets,
  rUser,
} from "app/utils/recoil";

import { CustomBlockContainer } from "./CustomBlockContainer";
import { PaginationWrapper } from "app/components";
import RenderBlockRouter from "../RenderBlock/RenderBlockRouter";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { get } from "lodash";
import { sortRecords } from "./Table";
import useActionResolver from "../useActionResolver";
import useBlockVersion from "app/utils/useBlockVersion";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useUtils from "../useUtils";

const Custom = ({ page, block }) => {
  const { actionResolver } = useActionResolver(page);

  const { processObjects } = useProcessObjects();

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);

  const { recordClick, processDynamicText, passesDisplayConditions } =
    useUtils();

  const user = useRecoilValue(rUser);

  const app = useRecoilValue(rApp);

  const customBlocks = useRecoilValue(rCustomBlocks);

  const matchingBlockOriginal = safeArray(customBlocks).find(
    (b) => b.original_id === block.customBlock
  );

  const matchingBlockNew = safeArray(customBlocks).find(
    (b) => b.id === block.customBlock
  );

  // If there's a match with original_id, use that - otherwise try the new one.
  const matchingBlock = matchingBlockOriginal || matchingBlockNew;

  const pageActions = useRecoilValue(rPageActions);

  const subBlocks = get(matchingBlock, "blocks", []);

  // Filter for actions with valid steps. Which means they have at least one step with a type
  const subActions = getValidActions(get(matchingBlock, "actions", []));

  const inputs = get(block, "inputs", []);

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const { getBlockWithVersion } = useBlockVersion();

  const blocksWithVersions = subBlocks.map((b) => {
    const { blockWithVersion: blockVersion } = getBlockWithVersion(b);
    return blockVersion;
  });

  const b = blocksWithVersions.map((b) => {
    let newBlock = { ...b };

    let actionMap = {};

    get(blockConfigs, [b.componentId, "resources"], [])
      .filter((r) => r.isAction)
      .forEach((r) => {
        // The resource / field

        let action = null;

        // Check if an action has been defined for this specific field
        const actionId = get(b, r.id);

        if (actionId) {
          // Find the action in the subActions array
          const foundAction = subActions.find((a) => a.id === actionId);

          if (foundAction) {
            // Find matching action defined at Custom root block level
            action = foundAction;

            // Handle if the user has selected 'editable By User'
            const isEditableByUser = get(action, "editableByUser", false);
            if (isEditableByUser) {
              // Check for 'editable by user' actions
              const concatActionId = `action-${b.id}-${r.id}`;
              const matchingBlockField = get(block, concatActionId);
              const matchingPageAction = pageActions.find(
                (a) => a.id === matchingBlockField
              );
              if (matchingPageAction) {
                action = matchingPageAction;
              }
            }
            // Add the final raw action to the resource ID key (clickAction, etc)
            actionMap[r.id] = action;
          }
        }
      });

    return {
      ...newBlock,
      actionMap,
      inputMap: inputs,
    };
  });

  const blocks = fixCustomBlocks(
    filterBasedOnUserGroups({
      items: b,
      app,
      user,
      passesDisplayConditions,
    })
  );

  const recordClickAction = get(block, "recordClickAction");

  const mockPage = {
    requireLogin: get(page, "requireLogin", false),
    newLayoutSystem: true,
    blocks: blocks,
    actions: subActions,
  };

  const rootBlock = blocks.find((b) => !b.layoutParent);

  // Handle repeating block
  if (get(matchingBlock, "repeating")) {
    const data = getListData(block);
    const resultsPerPage = get(block, "resultsPerPage", 10);
    let items = processObjects(page, block, [], data, processDynamicText);

    // In admin mode, limit to 3 items (rendering speed)
    if (isFrontlyAdmin) {
      items = items.filter((i, index) => index < 3);
    }

    const sortingColumn = get(block, "sortingColumn");
    const sortingDirection = get(block, "sortingDirection", "asc");

    if (sortingColumn) {
      const spreadsheet = savedSpreadsheets.find(
        (s) => s.id === block.spreadsheet
      );

      const fieldData = get(
        spreadsheet,
        ["field_data", "config", sortingColumn],
        {}
      );

      let columnType = "text";

      if (get(fieldData, "componentId") === "DateTimePicker") {
        columnType = "date";
      }

      // Sort rows
      items = sortRecords(
        items,
        sortingColumn,
        sortingDirection,
        columnType,
        get(fieldData, "dateFormat", {}),
        appDateFormat
      );
    }

    return (
      <PaginationWrapper
        itemsPerPage={resultsPerPage}
        items={items}
        hideBorder={true}
        padding="16px 0 0 0"
        noResultsPadding="0 0 16px 0"
        darkMode={darkMode}
        isFetching={block.isFetching}
        block={block}
      >
        {(paginatedItems) => {
          return (
            <CustomBlockContainer
              itemCount={paginatedItems.length}
              {...matchingBlock}
              containerType={get(matchingBlock, "containerType", "grid")}
            >
              {paginatedItems.map((item, i) => {
                const customBlockOnClick =
                  recordClickAction === "none"
                    ? null
                    : () => recordClick(block, item, actionResolver);

                return (
                  <RenderBlockRouter
                    key={i}
                    data={{
                      page: mockPage,
                      customBlockOnClick,
                      block: {
                        ...rootBlock,
                        reusableBlockId: block.id,
                        id: block.id,
                        originalBlockId: get(rootBlock, "id"),
                        parentComponentId: block.parentComponentId,
                        repeatingRecord: item,
                      },
                    }}
                  />
                );
              })}
            </CustomBlockContainer>
          );
        }}
      </PaginationWrapper>
    );
  }

  return (
    <RenderBlockRouter
      data={{
        page: mockPage,
        block: {
          ...rootBlock,
          reusableBlockId: block.id,
          id: block.id,
          originalBlockId: get(rootBlock, "id"),
          parentComponentId: block.parentComponentId,
        },
      }}
    />
  );
};

export default Custom;
