import {
  rActiveBlockId,
  rActiveEditField,
  rActiveVersionMap,
  rConfirmationModalData,
  rCopiedBlockId,
  rPageActions,
} from "app/utils/recoil";
import { useEffect, useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { fixBlocks } from "app/utils/utils";
import { get } from "lodash";
import { getBlockBundle } from "../getBlockBundle";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import useAdminAppUtils from "../useAdminAppUtils";
import useBlocks from "app/utils/useBlocks";

// This hook handles the detection of the 'delete' key being pressed in the admin and deleting the active block
const useDeleteBlock = () => {
  const activeBlock = useActiveBlock();

  const { getCleanedBlocksAndActions } = useAdminAppUtils();

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const [activeVersionMap, setActiveVersionMap] =
    useRecoilState(rActiveVersionMap);
  const setActiveEditField = useSetRecoilState(rActiveEditField);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);
  const [copiedBlockId, setCopiedBlockId] = useRecoilState(rCopiedBlockId);

  const { blocks, setBlocks } = useBlocks();
  const [pageActions, setPageActions] = useRecoilState(rPageActions);

  const activeBlockId = get(activeBlock, "id");

  // Blocks Ref
  const blocksRef = useRef(blocks);
  useEffect(() => {
    blocksRef.current = blocks;
  }, [blocks]);

  // Actions Ref
  const actionsRef = useRef(pageActions);
  useEffect(() => {
    actionsRef.current = pageActions;
  }, [pageActions]);

  // copiedBlockId Ref
  const copiedBlockIdRef = useRef(copiedBlockId);
  useEffect(() => {
    copiedBlockIdRef.current = copiedBlockId;
  }, [copiedBlockId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Active Block Ref
  const activeBlockRef = useRef(activeBlock);
  useEffect(() => {
    if (activeBlockId !== activeBlockRef.current) {
      activeBlockRef.current = activeBlock;
      setActiveEditField(null);
    }
  }, [activeBlockId]);

  const addBlockToClipboard = () => {
    const currentBlockId = get(activeBlockRef, ["current", "id"]);
    if (currentBlockId) {
      setCopiedBlockId(currentBlockId);
    }
  };

  const pasteBlockFromClipboard = () => {
    if (copiedBlockIdRef.current) {
      // THIS IS COPIED FROM THE DUPLICATE FUNCTION AND SHOULD BE MADE REUSABLE
      const actions = actionsRef.current;
      const blocks = fixBlocks(blocksRef.current);

      const blockToPaste = blocks.find(
        (block) => block.id === copiedBlockIdRef.current
      );

      const bundle = getBlockBundle({
        block: blockToPaste,
        actions,
        blocks,
        blocksToDuplicate: blocks,
      });

      const finalActions = [...actions, ...get(bundle, "actions", [])];
      const finalBlocks = [...blocks, ...get(bundle, "blocks", [])];

      // Set actions
      setPageActions(finalActions);

      // Set blocks
      setBlocks(finalBlocks);

      setActiveBlockId(get(bundle, "blockId")); // Set the newly duplicated block as active
      successNotification("Block Duplicated");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const targetId = get(event, "target.id", "none");

      // Check if the event target is an input, textarea, or a contenteditable element
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "TEXTAREA" ||
        event.target.isContentEditable
      ) {
        // Do nothing if the event is from an input, textarea, or contenteditable element

        if (!targetId.includes("formInput__")) {
          return;
        }
      }

      // Handle copy command (Command + C or Ctrl + C)
      if ((event.metaKey || event.ctrlKey) && event.key === "c") {
        addBlockToClipboard();
        return;
      }

      // Handle paste command (Command + V or Ctrl + V)
      if ((event.metaKey || event.ctrlKey) && event.key === "v") {
        pasteBlockFromClipboard();
        return;
      }

      // Check if the 'Delete' or 'Backspace' key was pressed
      if (event.key === "Delete" || event.key === "Backspace") {
        deleteBlock();
        return;
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const deleteBlock = () => {
    const currentBlock = get(activeBlockRef, "current");
    const currentBlockComponentId = get(currentBlock, "componentId");

    if (currentBlock) {
      setConfirmationModalData({
        title: `Delete ${currentBlockComponentId} Block`,
        text: "Are you sure you want to delete this block? This is irreversible.",
        confirm: () => {
          const { finalActions, finalBlocks } = getCleanedBlocksAndActions(
            get(activeBlockRef, ["current", "id"]),
            blocksRef.current,
            actionsRef.current
          );

          // Set actions
          setPageActions(finalActions);

          // Set blocks
          setBlocks(finalBlocks);

          setActiveBlockId(null);
          setActiveVersionMap({
            ...activeVersionMap,
            [get(activeBlockRef, ["current", "id"])]: null,
          });
          setActiveEditField(null);
          successNotification("Block Deleted");
        },
      });
    }
  };

  return { deleteBlock };
};

export default useDeleteBlock;
