import {
  filterBasedOnUserGroups,
  getPixels,
  isFrontlyAdmin,
  isObjectEqualFromKeys,
} from "app/utils/utils";
import { rApp, rPageBlocks, rUser } from "app/utils/recoil";

import RenderBlockRouter from "../RenderBlock/RenderBlockRouter";
import { get } from "lodash";
import { memo } from "react";
import styled from "styled-components";
import useDynamicText from "../useDynamicText";
import { useRecoilValue } from "recoil";
import useUtils from "../useUtils";

// ================================
// 1. Memoized Inner (Presentational) Component
// ================================
const RowInner = ({
  block,
  page,
  isColumn,
  user,
  app,
  processDynamicText,
  passesDisplayConditions,
  pageBlocks,
}) => {
  // Compute the final set of blocks to render
  const reusableBlockId = get(block, "reusableBlockId", null);
  const finalBlocks = reusableBlockId ? get(page, "blocks", []) : pageBlocks;
  const correctedBlockId = block.originalBlockId || block.id;
  const childBlocks = finalBlocks.filter(
    (b) => b.layoutParent === correctedBlockId
  );

  const blocks = filterBasedOnUserGroups({
    items: childBlocks,
    app,
    user,
    passesDisplayConditions,
  }).filter((b) =>
    passesDisplayConditions({
      conditions: get(b, "displayConditions", []),
      reusableBlockId: block.reusableBlockId,
      context: { repeatingRecord: get(block, "repeatingRecord") },
    })
  );

  // If not admin and no blocks, render nothing
  if (!isFrontlyAdmin && blocks.length === 0) {
    return <></>;
  }

  // Compute layout-related props
  const gap = get(block, "gap") || 20;
  const flexWrap = get(block, "flexWrap") ? "wrap" : "nowrap";
  const alignItems = get(block, "verticalAlignment", "flex-start");
  const justifyContent = get(block, "horizontalAlignment", "flex-start");
  const customCss = get(block, "customCss", {});

  // Process custom CSS if needed
  const processedCustomCss = processDynamicText({
    text: get(customCss, "container"),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });

  return (
    <Container
      backgroundImage={get(block, "backgroundImage", null)}
      hasAction={!!get(block, "clickAction", null)}
      isColumn={isColumn}
      gap={gap}
      flexWrap={flexWrap}
      alignItems={alignItems}
      justifyContent={justifyContent}
      customCss={processedCustomCss}
    >
      {isFrontlyAdmin && blocks.length === 0 && (
        <div style={{ padding: "20px" }}>
          Empty {isColumn ? "Column" : "Row"} - Add blocks here{" "}
        </div>
      )}
      {blocks.map((b, bi) => {
        // Determine layout width
        const layoutFullWidth = [
          "Row",
          "Map",
          "Iframe",
          "Image",
          "Video",
        ].includes(b.componentId);

        const layoutWidth = b.layoutWidth
          ? getPixels(b.layoutWidth)
          : layoutFullWidth
          ? "100%"
          : "fit-content";

        // Merge extra block props
        const finalBlock = {
          ...b,
          layoutWidth,
          reusableBlockId: block.reusableBlockId,
          parentComponentId: block.componentId,
          repeatingRecord: block.repeatingRecord,
          index: bi,
        };

        return (
          <RenderBlockRouter
            key={b.id}
            data={{
              inLayout: true,
              page,
              block: finalBlock,
              setupMode: isFrontlyAdmin,
              parentType: isColumn ? "Column" : "Row",
              index: bi,
            }}
          />
        );
      })}
    </Container>
  );
};

// ------------------------------
// 2. Custom Comparator for Memoization
// ------------------------------
// Compare only the keys that matter. Functions (e.g. processDynamicText)
// are assumed stable. Adjust the keys list as needed.
// const areEqualRow = (prevProps, nextProps) => {
//   const keysToCompare = [
//     "block",
//     "page",
//     "isColumn",
//     "user",
//     "app",
//     "pageBlocks",
//   ];
//   return isObjectEqualFromKeys(prevProps, nextProps, keysToCompare);
// };

// const MemoizedRowInner = memo(RowInner, areEqualRow);

// ================================
// 3. Container Component
// ================================
const Row = ({ block, page, isColumn }) => {
  // Extract values via hooks
  const user = useRecoilValue(rUser);
  const app = useRecoilValue(rApp);
  const { processDynamicText } = useDynamicText();
  const { passesDisplayConditions } = useUtils();
  const pageBlocks = useRecoilValue(rPageBlocks);

  return (
    <RowInner
      block={block}
      page={page}
      isColumn={isColumn}
      user={user}
      app={app}
      processDynamicText={processDynamicText}
      passesDisplayConditions={passesDisplayConditions}
      pageBlocks={pageBlocks}
    />
  );
};

export default Row;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.isColumn ? "column" : "row")};
  flex-wrap: ${(p) => p.flexWrap};
  gap: ${(p) => getPixels(p.gap)};
  align-items: ${(p) => p.alignItems};
  justify-content: ${(p) => p.justifyContent};
  cursor: ${(p) => (p.hasAction || isFrontlyAdmin ? "pointer" : "default")};
  height: 100%;
  ${(p) => p.customCss}
`;
