import {
  Button,
  FormInputWrapper,
  FullScreenLoader,
  Input,
  Row,
  Spinner,
  Text,
} from "app/components";
import {
  FormContainer,
  Logo,
  PublicWrapper,
  RightContainer,
} from "./AdminLogin";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import Cookies from "js-cookie";
import { apiRequest } from "app/utils/apiRequests";
import { errorNotification } from "app/utils/Notification";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const AppSumoSignup = () => {
  // Use "code" query parameter from the OAuth redirect
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [error, setError] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [formState, setFormState] = useState({
    password: "",
  });
  const [email, setEmail] = useState("");
  const [licenseKey, setLicenseKey] = useState("");
  const [errors, setErrors] = useState({});
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const navigate = useNavigate();

  const errorMap = {
    DUPLICATE_EMAIL:
      "It looks like you already have an account with that email.",
  };

  const validate = () => {
    let errors = {};

    if (formState.password.length < 6) {
      errors["password"] = "Please choose a more secure password";
      errorNotification("Please choose a more secure password");
    }

    if (!privacyPolicyAccepted) {
      errors["privacy"] = "You must accept the Privacy Policy and Terms of Use";
      errorNotification("You must accept the Privacy Policy and Terms of Use");
    }

    setErrors(errors);
    return isEmpty(errors);
  };

  useEffect(() => {
    if (code) {
      setIsVerifying(true);
      apiRequest
        .get("/appsumo/v2/verify/", { params: { code } })
        .then((r) => {
          const response = get(r, "data");
          console.log("response", response);
          const err = get(response, "error");
          const transactionData = get(response, "transaction_data");

          if (err) {
            setError(err);
          } else {
            setEmail(get(transactionData, "activation_email"));
            setLicenseKey(get(transactionData, "license_key"));
          }
          setIsVerifying(false);
        })
        .catch(() => {
          setError(
            "We are unable to process your verification code at the moment. Please contact support for resolution."
          );
          setIsVerifying(false);
        });
    } else {
      setError(
        "We are unable to process your verification code at the moment. Please contact support for resolution."
      );
    }
  }, [code]);

  const createAccount = () => {
    if (validate()) {
      setIsCreatingAccount(true);
      apiRequest
        .post("/appsumo/v2/signup/", {
          ...formState,
          email,
          license_key: licenseKey,
        })
        .then((response) => {
          const token = get(response, ["data", "token"]);
          Cookies.set("accessToken", token, { expires: 30 });
          setIsCreatingAccount(false);

          // Track signup in mixpanel
          mixpanel.identify(email);
          mixpanel.track("Create Account", {
            source: "AppSumo",
          });
          navigate("/onboarding");
        })
        .catch(() => {
          setIsCreatingAccount(false);
          errorNotification("Failed to create account. Please try again.");
        });
    }
  };

  const inputPadding = window.innerWidth < 800 ? "9px" : "13px";

  const errorText =
    error &&
    get(
      errorMap,
      error,
      "We are unable to process your verification code at the moment. Please contact support for resolution."
    );

  const headline = "Complete Registration";
  const subtitle =
    errorText ||
    "Complete your registration to access your exclusive AppSumo plan";

  if (isVerifying) {
    return <FullScreenLoader />;
  }

  return (
    <PublicWrapper>
      <Container>
        <LeftContainer>
          <Headline style={{ fontSize: "45px" }}>{headline}</Headline>
          <Subtitle>{subtitle}</Subtitle>
        </LeftContainer>
        <RightContainer>
          {isVerifying && <Spinner size={40} />}
          {error && (
            <FormContainer>
              <Logo src="https://res.cloudinary.com/frontly/image/upload/v1737557733/frontly_black_abjsh6_hn3daa.svg" />
              {error === "DUPLICATE_EMAIL" ? (
                <Button
                  data={{
                    text: "Login Now",
                    onClick: () => navigate("/login"),
                    width: "100%",
                    padding: inputPadding,
                    borderRadius: "12px",
                    size: "large",
                  }}
                />
              ) : (
                <Text
                  data={{
                    text: "Contact support@frontlyapp.com for assistance",
                    color: "var(--grey7)",
                    fontSize: 18,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                />
              )}
            </FormContainer>
          )}
          {licenseKey && !error && (
            <FormContainer>
              <Logo src="https://res.cloudinary.com/frontly/image/upload/v1737557733/frontly_black_abjsh6_hn3daa.svg" />
              <FormInputWrapper label={"Email"} labelStyle="bodyMdMedium">
                <Input
                  data={{
                    placeholder: "Email",
                    value: email,
                    padding: inputPadding,
                    borderRadius: "12px",
                    onChange: (v) => setEmail(v),
                  }}
                />
              </FormInputWrapper>
              <FormInputWrapper
                label={"Password"}
                error={errors.password}
                labelStyle="bodyMdMedium"
              >
                <Input
                  data={{
                    placeholder: "Password",
                    type: "password",
                    border: errors.password && "1px solid red",
                    padding: inputPadding,
                    borderRadius: "12px",
                    onChange: (v) =>
                      setFormState((s) => ({ ...s, password: v })),
                  }}
                />
              </FormInputWrapper>
              <Row alignItems="center" gap="10px" margin="5px 0 0 0">
                <Checkbox
                  color="primary"
                  checked={privacyPolicyAccepted}
                  onChange={() =>
                    setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                  }
                  value={privacyPolicyAccepted}
                  style={{ padding: "0px" }}
                  size="small"
                />
                <div style={{ fontSize: 14 }}>
                  I agree to Frontly's{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.frontly.ai/en/articles/8049500-frontly-privacy-policy"
                      )
                    }
                    style={{
                      color: "var(--primary-admin)",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.frontly.ai/en/articles/9892485-frontly-terms-of-use"
                      )
                    }
                    style={{
                      color: "var(--primary-admin)",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Terms of Use
                  </span>
                </div>
              </Row>
              <Button
                data={{
                  text: "Complete Registration",
                  onClick: createAccount,
                  width: "100%",
                  padding: inputPadding,
                  borderRadius: "12px",
                  size: "large",
                  margin: "15px 0 0 0",
                  isFetching: isCreatingAccount,
                }}
              />
              <Row justifyContent="center" gap="10px" margin="10px 0 0 0">
                <Text
                  data={{
                    text: "Already have an account?",
                    color: "var(--grey7)",
                    fontStyle: "bodyMd",
                  }}
                />
                <Text
                  data={{
                    text: "Login",
                    color: "var(--primary-admin)",
                    fontStyle: "headingSm",
                    onClick: () => navigate("/login"),
                  }}
                />
              </Row>
            </FormContainer>
          )}
        </RightContainer>
      </Container>
    </PublicWrapper>
  );
};

export default AppSumoSignup;

const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 150px;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
  }
`;

const Headline = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
  color: var(--text);
  @media (max-width: 1000px) {
    font-size: 42px;
    text-align: center;
  }
`;

const Subtitle = styled.div`
  font-size: 30px;
  font-weight: 400;
  color: var(--grey6);
  margin: 0 0 30px 0;
  @media (max-width: 1000px) {
    text-align: center;
    margin: 0 0 20px;
  }
`;
