import { Input, Modal, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rNewDataSourceModalData, rSavedSpreadsheets } from "app/utils/recoil";

import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useState } from "react";

const FrontlyDatabase = () => {
  const [data, setData] = useRecoilState(rNewDataSourceModalData);
  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const [isImporting, setIsImporting] = useState(false);
  const navigate = useNavigate();

  const importDatabase = () => {
    setIsImporting(true);

    apiRequest
      .post("/add_frontly_db/", {
        name: get(data, "name"),
      })
      .then((response) => {
        const newDatabase = get(response, "data", {});
        const error = get(newDatabase, "error");

        if (error) {
          errorNotification(error);
          setIsImporting(false);
          return;
        }

        setSavedSpreadsheets([...savedSpreadsheets, newDatabase]);

        successNotification("Database setup successful");
        setIsImporting(false);
        mixpanel.track("Setup Frontly Database");
        setData(null);
        navigate(`/spreadsheet/${newDatabase.id}`);
      });
  };

  return (
    <Modal
      minWidth="400px"
      header={{ title: "Setup Frontly Database" }}
      buttons={[
        {
          text: "Setup Database",
          onClick: importDatabase,
          isFetching: isImporting,
        },
      ]}
      hide={() => setData(null)}
    >
      <Text
        data={{
          text: "Give a name to your new Frontly database",
          margin: "0 0 20px 0",
        }}
      />
      <Input
        data={{
          label: "Database Name",
          placeholder: "Orders, etc",
          required: true,
          width: "100%",
          value: get(data, "name"),
          onChange: (value) => setData({ ...data, name: value }),
        }}
      />
    </Modal>
  );
};

export default FrontlyDatabase;
