import { allBlocks, getBlocksWithPlaceholder, getHighest } from "./utils";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rCustomBlockChanges,
  rPageBlocks,
  rPageChanges,
} from "./recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { blockConfigs } from "app/adminApp/blockConfigs";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import useActiveBlock from "./useActiveBlock";
import { useCallback } from "react";
import useIsCustomBlock from "./useIsCustomBlock";

const useAddBlock = () => {
  const app = useRecoilValue(rApp);
  const styling = get(app, "styling", {});
  const darkMode = get(styling, "theme", {}) === "dark";

  const [pageBlocks, setPageBlocks] = useRecoilState(rPageBlocks);

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);
  const activeBlockId = useRecoilValue(rActiveBlockId);
  const activeBlock = useActiveBlock();
  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const setActiveEditField = useSetRecoilState(rActiveEditField);
  const setPageChanges = useSetRecoilState(rPageChanges);
  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);
  const isCustomBlock = useIsCustomBlock();

  const addBlock = useCallback(
    ({
      type,
      layoutPosition = null,
      layoutParent = null,
      returnInsteadOfAdd = false,
      callback = null,
      placeholderId = null,
      latestBlocks = null,
    }) => {
      const currentBlocks = latestBlocks || pageBlocks;
      const newId = getHighest(currentBlocks, "id") + 1;

      const defaults = getDefaultBlockData(
        type,
        darkMode,
        get(styling, "blockBackgroundColor", "#FFFFFF")
      );

      let newBlock = {
        id: newId,
        componentId: type,
        label: type, // This should be inferred, but not a big deal
        ...defaults,
      };

      if (layoutParent) {
        newBlock.layoutParent = layoutParent;
      }

      if (layoutPosition) {
        newBlock.layoutPosition = layoutPosition;
      }

      if (activeDetailViewId) {
        newBlock.parent = activeDetailViewId;
      }

      const blockConfig = allBlocks.find((b) => b.type === type);
      const inLayout = ["Layout", "Row", "Column"].includes(
        get(activeBlock, "componentId")
      );

      const gridWidth = get(blockConfig, "gridWidth", 2);
      newBlock["columnSpan"] = gridWidth;
      newBlock["rowSpan"] = 1;

      // If there's an active container block, adjust the new block’s properties
      if (activeBlockId && inLayout) {
        if (!["Button", "Text"].includes(type)) {
          newBlock["fillRemainingSpace"] = true;
        }
        newBlock["layoutWidth"] = blockConfig.defaultLayoutWidth;
        newBlock["layoutParent"] = activeBlockId;
        delete newBlock["parent"];
        setActiveEditField(null);
      } else {
        setActiveBlockId(newId);
        setActiveEditField(null);
      }

      let newBlocks = [...currentBlocks, newBlock];

      if (placeholderId) {
        // Replace placeholder block with new block data
        newBlocks = getBlocksWithPlaceholder(
          newBlocks,
          placeholderId,
          newBlock.id
        );
      }

      mixpanel.track("Add Block", { type });

      if (returnInsteadOfAdd) {
        return newBlock;
      }

      setPageBlocks(newBlocks);

      if (isCustomBlock) {
        setCustomBlockChanges(true);
      } else {
        setPageChanges(true);
      }

      if (callback) {
        callback();
      }
    },
    [pageBlocks, activeDetailViewId, activeBlockId]
  );

  return addBlock;
};

// GET DEFAULT BLOCK DATA
const getDefaultBlockData = (type, darkMode, blockBgColor) => {
  const config = get(blockConfigs, type);
  const blockConfig = allBlocks.find((b) => b.type === type);

  let defaultObj = {};

  const defaultBgColor = darkMode
    ? "white" // default to white because we override in dark mode
    : blockBgColor;

  // Add background color if card block
  if (get(blockConfig, "card") !== "disabled") {
    defaultObj["backgroundColor"] = defaultBgColor;
  }

  get(config, "resources", [])
    .filter((r) => r.defaultValue)
    .forEach((r) => {
      defaultObj[r.id] = r.defaultValue;
    });

  if (get(blockConfig, "padding")) {
    defaultObj["padding"] = get(blockConfig, "padding");
  }

  // Apply default resource values - only used in Chart block right now
  get(blockConfig, "defaults", []).forEach((c) => {
    defaultObj[c.id] = c.value;
  });

  return defaultObj;
};

export default useAddBlock;
