import {
  Button,
  Column,
  FormInputWrapper,
  Input,
  Modal,
  Row,
  Text,
} from "app/components";
import { get, isEmpty } from "lodash";

import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import mixpanel from "mixpanel-browser";
import { rPages } from "app/utils/recoil";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useState } from "react";

const NewPageModal = ({ hide }) => {
  const [pages, setPages] = useRecoilState(rPages);
  const [pageTitle, setPageTitle] = useState("");
  const [route, setRoute] = useState("new-page");
  const [isCreating, setIsCreating] = useState(false);
  const [customizeUrl, setCustomizeUrl] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    let errors = {};

    if (!pageTitle) {
      errors["pageTitle"] = "Please enter a valid page title";
    }

    if (route.length < 3) {
      errors["route"] = "Please enter a valid page URL";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const createPage = () => {
    // Create page
    if (validate()) {
      setIsCreating(true);
      apiRequest
        .post("/create_page/", { name: pageTitle, route })
        .then((response) => {
          const data = get(response, "data");
          setPages([...pages, data]);
          mixpanel.track("Page Created");
          navigate(`/page/${data.id}`);
          setIsCreating(false);
        });
    }
  };

  function stripNonASCII(inputStr) {
    // Replace all non-ASCII characters with an empty string
    return inputStr.replace(/[^\x00-\x7F]/g, "").replace(" ", "");
  }

  return (
    <Modal
      minWidth="400px"
      hide={hide}
      header={{
        title: "Create A New Page",
      }}
    >
      <Column gap="15px">
        <FormInputWrapper
          label="Page Name"
          hint="The internal name for this page. This is not visible to end-users."
        >
          <Input
            data={{
              value: pageTitle,
              placeholder: "New Page",
              error: errors.pageTitle,
              onChange: (v) => {
                setPageTitle(v);
                if (!customizeUrl) {
                  setRoute(v.toLowerCase().replaceAll(" ", "-"));
                }
              },
            }}
          />
        </FormInputWrapper>

        <FormInputWrapper
          label="Page URL"
          hint="The location of your page within your app, used in the browser's address bar to navigate to the page."
        >
          {customizeUrl ? (
            <Input
              data={{
                value: route,
                placeholder: "new-page",
                error: errors.route,
                onChange: (v) => {
                  let finalVal = v;
                  finalVal = finalVal.toLowerCase();
                  finalVal = stripNonASCII(finalVal);
                  setRoute(finalVal);
                },
              }}
            />
          ) : (
            <Text
              data={{
                text: route,
                onClick: () => setCustomizeUrl(true),
                link: true,
                margin: "10px 0 0 0",
              }}
            />
          )}

          {!customizeUrl && (
            <Text
              data={{
                text: "Customize URL",
                onClick: () => setCustomizeUrl(true),
                color: "var(--primary-admin)",
                link: true,
                margin: "10px 0 0 0",
              }}
            />
          )}
        </FormInputWrapper>

        <Row gap="10px">
          <Button
            data={{
              text: "Create Page",
              onClick: createPage,
              isFetching: isCreating,
            }}
          />

          <Button
            data={{
              text: "Browse Pages Marketplace",
              onClick: () => navigate("/marketplace?type=pages"),
              type: "basic",
              icon: "FiExternalLink",
            }}
          />
        </Row>
      </Column>
    </Modal>
  );
};

export default NewPageModal;
