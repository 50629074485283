import { Button, Row, SearchBar, Spinner } from "app/components";
import { get, startCase } from "lodash";
import { useEffect, useState } from "react";

import CardGrid from "app/adminApp/components/CardGrid";
import CreateListingModal from "./CreateListingModal";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import { Label } from "app/adminApp/home/Pages";
import { apiRequest } from "app/utils/apiRequests";
import { rConfirmationModalData } from "app/utils/recoil";
import { statusColorMap } from "./ListingDetails";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

const Listings = () => {
  const [listings, setListings] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [showNewListing, setShowNewListing] = useState(false);
  const [search, setSearch] = useState("");

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const navigate = useNavigate();

  // Fetch listings
  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/marketplace_listings/").then((r) => {
      const newListings = get(r, "data", []);
      setListings(newListings);
      setIsFetching(false);
    });
  }, []);

  const deleteListing = (id) => {
    setConfirmationModalData({
      title: "Delete Listing",
      text: "Are you sure you want to delete this listing? This is irreversible. Your content will be removed from the marketplace.",
      confirm: () => {
        apiRequest.delete(`/marketplace_listings/${id}/`);
        setListings(listings.filter((l) => l.id !== id));
        successNotification("Listing deleted");
      },
    });
  };

  const filteredListings = listings.filter((l) => {
    return l.name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div>
      {showNewListing && (
        <CreateListingModal hide={() => setShowNewListing(false)} />
      )}
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Listings</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search listings",
              onChange: (v) => setSearch(v),
            }}
          />
          <Button
            data={{
              text: "New Listing",
              icon: "FiPlus",
              onClick: () => setShowNewListing(true),
            }}
          />
        </Row>
      </Row>

      {isFetching && <Spinner />}

      {!isFetching && listings.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any listings"
          subtitle="Listings allow you to share pages and actions you've created with other users in the Frontly community. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716244997/Create_ljrvzs.svg"
          }
          buttonText="New Listing"
          onClick={() => setShowNewListing(true)}
        />
      )}

      {!isFetching && listings.length > 0 && (
        <CardGrid
          noResultsName={"listings"}
          items={filteredListings.map((l) => ({
            title: l.name,
            description: startCase(l.type),
            onClick: () => navigate(`/marketplace_admin/listing/${l.id}`),
            badges: [
              {
                color: statusColorMap[get(l, "status")],
                text: startCase(get(l, "status")),
              },
            ],
            options: [
              {
                label: "View Public Listing",
                icon: "FiExternalLink",
                onClick: () => {
                  navigate(`/marketplace/listing/${l.id}`);
                },
              },
              {
                label: "Delete Listing",
                icon: "FiTrash",
                onClick: () => deleteListing(l.id),
              },
            ],
          }))}
          itemsPerPage={16}
        />
      )}
    </div>
  );
};

export default Listings;
