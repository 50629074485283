import styled from "styled-components";

const FullScreenPreviewContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${(p) => p.bannerHeight}px);
  overflow: auto;
`;

const GridLayout = styled.div`
  ${(p) =>
    !p.newGridSystem
      ? `
  display: flex;
  flex-direction: column;
  `
      : `
  display: grid;
  background: #f0f0f0;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }
  `}
`;

export { FullScreenPreviewContainer, GridLayout };
