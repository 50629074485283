import { errorNotification, successNotification } from "app/utils/Notification";

import { AdminForm } from "..";
import { Modal } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { isEmpty } from "lodash";
import { rActions } from "app/utils/recoil";
import { useSetRecoilState } from "recoil";
import { useState } from "react";

const CreateActionModal = ({ hide, action, config }) => {
  const setActions = useSetRecoilState(rActions);
  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: action?.name || "",
    description: action?.description || "",
  });

  const { name, description } = formData;

  const isFormValid = () => {
    let errors = {};

    if (!name) {
      errors["name"] = "Please enter a valid name.";
    }

    if (!description) {
      errors["description"] = "Please enter a valid description.";
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const handleSubmit = () => {
    if (!isFormValid()) return;

    setIsFetching(true);
    const request = action?.id
      ? apiRequest.patch(`/actions/${action.id}/`, {
          name,
          description,
          config: action.config,
        })
      : apiRequest.post("/actions/", {
          name,
          description,
          config,
        });

    request
      .then((response) => {
        const newAction = response.data;
        setActions((prevActions) => {
          if (action?.id) {
            return prevActions.map((act) =>
              act.id === action.id ? newAction : act
            );
          }
          return [...prevActions, newAction];
        });
        successNotification(action?.id ? "Action Updated" : "Action Saved");
        hide();
      })
      .catch(() => {
        errorNotification("Something went wrong.");
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const fields = [
    {
      id: "name",
      componentId: "Input",
      label: "Name",
      value: name,
    },
    {
      id: "description",
      componentId: "TextArea",
      label: "Description",
      value: description,
    },
  ];

  return (
    <Modal
      minWidth="400px"
      header={{
        title: action?.id ? "Edit Action" : "Save Action",
      }}
      hide={hide}
    >
      <AdminForm
        isFetching={isFetching}
        labelStyle="headingSm"
        submit={handleSubmit}
        errors={errors}
        submitText={action?.id ? "Update Action" : "Create Action"}
        onChange={(k, v) =>
          setFormData({
            ...formData,
            [k]: v,
          })
        }
        sectionPadding="0px"
        fields={fields}
      />
    </Modal>
  );
};

export default CreateActionModal;
