import { layoutOptions, layouts } from "app/renderingApp/layouts";

import { Menu } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const LayoutBrowser = ({ data }) => {
  const [showBrowser, setShowBrowser] = useState(false);

  const selectedLayout = get(data, "value");

  const selected = get(layouts, selectedLayout);

  const [anchorElement, setAnchorElement] = useState(null);

  return (
    <>
      <MiniLayoutContainer onClick={(e) => setAnchorElement(e.currentTarget)}>
        <MiniLayout layout={selected} small />
      </MiniLayoutContainer>
      <Menu
        anchorElement={anchorElement}
        hide={() => setAnchorElement(null)}
        label="Select Layout"
      >
        <LayoutsGrid>
          {layoutOptions.map((layout, i) => {
            const matchingLayout = get(layouts, layout.value);
            return (
              <LayoutContainer
                active={selectedLayout === layout.value}
                onClick={() => data.onChange(layout.value)}
              >
                <MiniLayout layout={matchingLayout} />
              </LayoutContainer>
            );
          })}
        </LayoutsGrid>
      </Menu>
    </>
  );
};

export default LayoutBrowser;

export const MiniLayout = ({ width, layout = null, small = false }) => {
  // RENDER LAYOUT
  const items = get(layout, "items", []);
  return (
    <GridContainer {...layout} width={width} small={small}>
      {items.map((item, itemIndex) => {
        // SHOW SLOT NUMBER
        return (
          <Layout small={small} {...item}>
            {small ? null : itemIndex + 1}
          </Layout>
        );
      })}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  ${(p) => p.cols12 && `grid-template-columns: ${p.cols12};`}
  ${(p) => p.row12 && `grid-row: ${p.row12};`}
  ${(p) => p.col12 && `grid-column: ${p.col12};`}
  width: ${(p) => p.width || "120px"};
  height: 80px;
  ${(p) => p.small && "height: 20px; width: 25px; grid-gap: 3px;"}
`;

const Layout = styled.div`
  ${(p) => p.cols12 && `grid-template-columns: ${p.cols12};`}
  ${(p) => p.row12 && `grid-row: ${p.row12};`}
  ${(p) => p.col12 && `grid-column: ${p.col12};`}
  background: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey7);
`;

const MiniLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 3px;
  cursor: pointer;
  background: var(--grey7);
`;

const LayoutContainer = styled.div`
  border: 2px solid
    ${(p) => (p.active ? "var(--primary-admin)" : "transparent")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 12px;
  gap: 10px;
  cursor: pointer;
  background: ${colors.clickUpBackground};
`;

const LayoutsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  gap: 20px;
  width: 640px;
`;
