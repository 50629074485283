import {
  PaginationWrapper,
  Row,
  SimpleDropdown,
  Spinner,
} from "app/components";

import { get } from "lodash";
import styled from "styled-components";

const CardGrid = ({ items, itemsPerPage = 8, noResultsName }) => {
  return (
    <PaginationWrapper
      itemsPerPage={itemsPerPage}
      items={items}
      isFetching={false}
      noResultsName={noResultsName}
      padding="16px 0 0 0"
      noResultsPadding="0px"
      hideBorder
    >
      {(paginatedItems) => {
        return (
          <div>
            <Grid count={paginatedItems.length}>
              {paginatedItems.map((p) => (
                <Card onClick={p.onClick}>
                  {p.isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      <Row justifyContent="space-between">
                        <div>
                          <CardLabel>{p.title}</CardLabel>
                          <CardText>{p.description}</CardText>
                        </div>
                        <SimpleDropdown
                          data={{
                            options: get(p, "options", []),
                            icon: {
                              icon: "FiMoreHorizontal",
                              size: 25,
                              hover: true,
                              color: "var(--grey7)",
                            },
                          }}
                        />
                      </Row>
                      <Row justifyContent="space-between">
                        {get(p, "icon") && <Icon src={get(p, "icon")} />}
                        <Row gap="10px">
                          {get(p, "badges", []).map((b) => (
                            <Badge color={b.color}>{b.text}</Badge>
                          ))}
                        </Row>
                      </Row>
                    </>
                  )}
                </Card>
              ))}
            </Grid>
          </div>
        );
      }}
    </PaginationWrapper>
  );
};

export default CardGrid;

const Icon = styled.img`
  height: 25px;
`;

const Badge = styled.div`
  background: ${(p) => p.color};
  padding: 5px 7px 5px 7px;
  border-radius: 16px;
  font-size: 11px;
  font-weight: 400;
  color: #202223;
  width: fit-content;
`;

const CardLabel = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CardText = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: var(--grey7);
  margin-top: 4px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 15px;
  cursor: pointer;
  gap: 20px;
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  &:hover {
    filter: brightness(0.98);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 25px;
  ${(p) =>
    p.count && p.count < 4 && "grid-template-columns: 280px 280px 280px;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`;
