import { rApp, rConfirmationModalData } from "app/utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import AdminForm from "../components/AdminForm";
import SettingsCard from "./components/SettingsCard";
import { Text } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const DataPolicy = () => {
  const [app, setApp] = useRecoilState(rApp);
  const [changes, setChanges] = useState({});
  const dataPolicy = get(app, "data_policy", "live");

  const confirmSave = () => {
    const sectionChanges = get(changes, "dataPolicy");
    apiRequest.post("/app_settings/", sectionChanges);
    setChanges({
      ...changes,
      dataPolicy: null,
    });
    successNotification("Saved");
  };

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const save = () => {
    if (dataPolicy === "cache") {
      // Show confirmation
      setConfirmationModalData({
        title: "Confirm Caching Data In Frontly Database",
        text: "Your settings dictate that Frontly will cache a copy of your actual data source records in our database. Are you sure you want to proceed?",
        confirm: confirmSave,
      });
    } else {
      // Save immediately
      confirmSave();
    }
  };

  const dataPolicyFields = [
    {
      id: "data_policy",
      label: "Admin Data Policy",
      hint: "Note: This setting is only for the Frontly admin and does not affect your live app. 'Load Live Data' means we won't store any of your live data source records, and instead we'll load them in real-time in admin mode. 'Cache Data' means we will save a copy of your actual live data source records in our database (not recommended if this data is sensitive). 'Mock Data' will show generic mock data instead of loading your real data in the admin.",
      componentId: "Select",
      value: dataPolicy,
      hideEmptyItem: true,
      options: [
        {
          label: "Load Live Data",
          value: "live",
        },
        {
          label: "Show Mock Data",
          value: "mock",
        },
        {
          label: "Cache Data",
          value: "cache",
        },
      ],
    },
  ];

  const policyMap = {
    live: "Frontly will not store any of your live data source records, and instead we'll load them in real-time in admin mode.",
    mock: "Frontly will show generic mock data instead of loading your real data in the admin.",
    cache:
      "Frontly will save a copy of your actual live data source records in our database for use in the admin.",
  };

  return (
    <SettingsCard
      label="Data Policy"
      description="Configure how data is stored in your app."
      articleLink="https://help.frontly.ai/en/articles/9889286-admin-data-policy-setting"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="0px"
        fields={dataPolicyFields}
        submitText={"Save Changes"}
        submit={changes.dataPolicy ? save : null}
        onChange={(k, v) => {
          setChanges({
            ...changes,
            dataPolicy: {
              ...get(changes, "dataPolicy"),
              [k]: v,
            },
          });
          setApp({
            [k]: v,
          });
        }}
      />

      <Text
        data={{
          text: `Based on your current data policy, ${policyMap[dataPolicy]}`,
          fontStyle: "bodyLg",
          margin: "10px 0 0 0",
          color: "var(--grey8)",
        }}
      />
      <Text
        data={{
          text: "Click here to learn more about the Data Policy setting",
          fontStyle: "bodyLg",
          fontWeight: 600,
          margin: "10px 0 0 0",
          color: "var(--primary-admin)",
          onClick: () =>
            window.open(
              "https://help.frontly.ai/en/articles/9889286-admin-data-policy-setting"
            ),
        }}
      />
    </SettingsCard>
  );
};

export default DataPolicy;
