import { Button, Modal, SearchBar } from "app/components";
import SettingsSection, { CardSection } from "./SettingsSection";
import { errorNotification, successNotification } from "app/utils/Notification";

import AdminForm from "../components/AdminForm";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { rContentLibrary } from "app/utils/recoil";
import { useRecoilState } from "recoil";
import { useState } from "react";

const ContentLibrary = () => {
  const [contentList, setContentList] = useRecoilState(rContentLibrary);

  const [search, setSearch] = useState("");
  const [editContent, setEditContent] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const filteredContent = contentList.filter((content) => {
    const searchMatch = content.name
      .toLowerCase()
      .includes(search.toLowerCase());
    const dataMatch = content.data.toLowerCase().includes(search.toLowerCase());
    return searchMatch || dataMatch;
  });

  const openCreateModal = () => {
    setEditContent({ name: "", key: "", data: "" });
  };

  const openEditModal = (content) => {
    setEditContent(content);
  };

  const closeModal = () => {
    setEditContent(null);
  };

  const saveContent = () => {
    const payload = { ...editContent };
    setIsFetching(true);
    apiRequest
      .post("/content/", payload)
      .then((response) => {
        successNotification("Content saved successfully.");

        const newItem = get(response, "data");

        const updatedContentList = [...contentList];
        const index = updatedContentList.findIndex((c) => c.id === newItem.id);
        if (index >= 0) {
          updatedContentList[index] = newItem;
        } else {
          updatedContentList.push(newItem);
        }

        setContentList(updatedContentList);

        closeModal();
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const deleteContent = (contentId) => {
    setIsFetching(true);
    apiRequest
      .delete("/content/", { data: { id: contentId } })
      .then((response) => {
        successNotification("Content deleted successfully.");
        setContentList(contentList.filter((c) => c.id !== contentId));
      })
      .catch((error) => {
        errorNotification("Error deleting content.");
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Content Library"
      description="A text-based content library for storing large prompts and pieces of text to use as context throughout your app and AI actions."
      settingsCategory={"App Settings"}
    >
      <CardSection>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <SearchBar
            data={{
              value: search,
              placeholder: "Search content",
              onChange: (v) => setSearch(v),
            }}
          />
          <Button
            data={{
              text: "Create Content",
              onClick: openCreateModal,
            }}
          />
        </div>
        <TableComponent
          data={{
            rowActions: [
              {
                label: "Edit",
                onClick: (row) => openEditModal(row),
              },
              {
                label: "Delete",
                onClick: (row) => deleteContent(row.id),
              },
            ],
            preventDarkMode: true,
            rows: filteredContent.map((content) => ({
              ...content,
              onClick: () => openEditModal(content),
              actions: [
                {
                  label: "Edit",
                  onClick: () => openEditModal(content),
                },
                {
                  label: "Delete",
                  onClick: () => deleteContent(content.id),
                },
              ],
            })),
            columns: [
              {
                label: "Name",
                key: "name",
              },
              {
                label: "Key",
                key: "key",
              },
              {
                label: "Data",
                key: "data",
              },
            ],
          }}
        />
      </CardSection>

      {editContent && (
        <Modal
          minWidth="800px"
          header={{
            title: editContent.id ? "Edit Content" : "Create Content",
          }}
          hide={closeModal}
        >
          <AdminForm
            borderBottom={true}
            labelStyle="headingSm"
            sectionPadding="0px"
            isFetching={isFetching}
            fields={[
              {
                id: "name",
                label: "Name",
                value: editContent.name,
                hint: "A visual reference for you only.",
              },
              {
                id: "key",
                label: "Key",
                hint: "This is how you reference the content in a variable",
                value: editContent.key,
              },
              {
                id: "data",
                label: "Content",
                componentId: "TextArea",
                value: editContent.data,
                minHeight: "200px",
                hint: "The actual text content. This can include variables.",
              },
            ]}
            submitText={"Save"}
            submit={saveContent}
            width="100%"
            onChange={(k, v) => setEditContent({ ...editContent, [k]: v })}
            orientation={"vertical"}
          />
        </Modal>
      )}
    </SettingsSection>
  );
};

export default ContentLibrary;
