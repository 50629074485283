import { fontOptions } from "app/utils/theme";
import { get } from "lodash";

export const inputBlockResources = [
  {
    id: "key",
    label: "Field ID",
    componentId: "Input",
    hint: "This ID is used to reference the field in the submit action form variable",
  },
  {
    id: "fontSize",
    label: "Font Size",
    section: "font",
    componentId: "Input",
    type: "number",
    orientation: "horizontal",
    width: "120px",
    defaultValue: 14,
    isStyle: true,
  },
  {
    id: "fontWeight",
    label: "Font Weight",
    section: "font",
    componentId: "Select",
    defaultValue: 300,
    hideEmptyItem: true,
    orientation: "horizontal",
    width: "120px",
    isStyle: true,
    options: [
      {
        value: "200",
        label: "Extra Light",
      },
      {
        value: "300",
        label: "Light",
      },
      {
        value: "400",
        label: "Normal",
      },
      {
        value: "500",
        label: "Medium",
      },
      {
        value: "600",
        label: "Semi Bold",
      },
      {
        value: "700",
        label: "Bold",
      },
    ],
  },
  {
    id: "fontColor",
    label: "Font Color",
    section: "font",
    isStyle: true,
    componentId: "ColorPicker",
    orientation: "horizontal",
  },
  {
    id: "padding",
    label: "Padding",
    section: "spacing",
    isStyle: true,
    hint: "The spacing inside the input field",
    componentId: "PaddingConfig",
  },
];

export const sortingColumn = {
  id: "sortingColumn",
  label: "Column",
  componentId: "SpreadsheetColumnSelect",
  section: "sorting",
  requiresSheet: true,
  orientation: "horizontal",
  width: "150px",
  advanced: true,
};

export const sortingDirection = {
  id: "sortingDirection",
  label: "Sort Direction",
  section: "sorting",
  requiresSheet: true,
  componentId: "Select",
  orientation: "horizontal",
  width: "150px",
  hideEmptyItem: true,
  defaultValue: "asc",
  options: [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ],
  advanced: true,
};

export const fetchIfHidden = {
  id: "fetchIfHidden",
  label: "Fetch Data If Hidden",
  hint: "Force this block to fetch it's data from the Google Sheet even if it's hidden. This is useful if you want to use the data in other blocks or if you want to make sure the data is loaded in advance in case you plan to reveal the block conditionally.",
  componentId: "Switch",
  section: "visibility",
  defaultValue: false,
  requiresSheet: true,
  advanced: true,
  orientation: "horizontal",
};

export const customCss = {
  id: "customCss",
  componentId: "CustomCSS",
  section: "Custom CSS",
  sectionLabel: "Custom CSS",
  isStyle: true,
};

export const label = {
  id: "label",
  componentId: "DynamicString",
  section: "label",
  sectionHint:
    "A visual label displayed at the top of most Blocks. Also used for your internal reference.",
  requiresSheet: true,
  advanced: true,
  isStyle: true,
};

export const removeFromLayout = {
  id: "removeFromLayout",
  componentId: "RemoveFromLayout",
  section: "removeFromLayout",
  advanced: true,
};

export const saveCustomBlockButton = {
  id: "saveCustomBlockButton",
  componentId: "CustomBlock",
  section: "saveAsCustomBlock",
  advanced: true,
};

export const spreadsheetSelect = {
  id: "spreadsheet",
  // label: "Spreadsheet",
  wizardLabel: "Data Source",
  sectionLabel: "Data Source",
  componentId: "SpreadsheetSelect",
  section: "dataSource",
  sectionHint: "Select the spreadsheet used to populate this Block with data",
  wizard: true,
  link: "https://help.frontly.ai/en/articles/7971110-spreadsheet-block-setting",
  // hint: "Select the spreadsheet used to populate this Block with data",
};

export const resultsPerPage = {
  id: "resultsPerPage",
  label: "Results Per Page",
  componentId: "Input",
  isStyle: true,
  section: "data",
  width: "80px",
  required: true,
  defaultValue: 25,
  type: "number",
  max: 100,
  // options: [
  //   { label: 5, value: 5 },
  //   { label: 10, value: 10 },
  //   { label: 25, value: 25 },
  //   { label: 50, value: 50 },
  //   { label: 100, value: 100 },
  // ],
  requiresSheet: true,
  orientation: "horizontal",
  hint: "Determine how many results show per-page before displaying buttons to navigate between pages of results",
  advanced: true,
};

export const recordClickAction = {
  id: "recordClickAction",
  label: "Record Click Action",
  componentId: "Select",
  section: "actions",
  defaultValue: "default",
  requiresSheet: true,
  hideEmptyItem: true,
  options: [
    { label: "Default View", value: "default" },
    { label: "Custom View", value: "custom" },
    { label: "Custom Action", value: "action" },
    { label: "None", value: "none" },
  ],
  hint: "Determine what to do when a user clicks on a record in a list of data",
  link: "https://help.frontly.ai/en/articles/7971235-record-click-action",
};

export const customRecordClickAction = {
  id: "customRecordClickAction",
  label: "Custom Click Action",
  componentId: "Action",
  section: "actions",
  dynamicSources: ["record"],
  isAction: true,
  displayCondition: (block) => block.recordClickAction === "action",
  link: "https://help.frontly.ai/en/articles/7971243-custom-action",
};

export const detailViewSettings = {
  id: "detailViewSettings",
  label: "Edit Detail View",
  componentId: "DetailViewConfig",
  section: "actions",
  requiresSheet: true,
  hint: "Edit the default view displayed when a record is clicked",
  displayCondition: (block) =>
    ["custom", "default"].includes(block.recordClickAction),
  link: "https://help.frontly.ai/en/articles/7971674-detail-view",
};

export const listActionsResources = [
  recordClickAction,
  customRecordClickAction,
  detailViewSettings,
  {
    id: "editConditions",
    label: "Allow Editing (Custom Conditions)",
    componentId: "DisplayConditions",
    orientation: "vertical",
    advanced: true,
    section: "permissions",
    hint: "Define custom conditions that must be met in order for the user to be able to edit records",
  },
  {
    id: "deleteConditions",
    label: "Allow Deleting (Custom Conditions)",
    componentId: "DisplayConditions",
    orientation: "vertical",
    section: "permissions",
    hint: "Define custom conditions that must be met in order for the user to be able to delete records",
    advanced: true,
  },
  {
    id: "createConditions",
    label: "Allow Creating (Custom Conditions)",
    componentId: "DisplayConditions",
    section: "permissions",
    hint: "Define custom conditions that must be met in order for the user to be able to create new records",
    orientation: "vertical",
    advanced: true,
  },
];

export const backgroundImageStyles = [
  {
    id: "backgroundImage",
    label: "Background Image",
    componentId: "DynamicString",
    section: "background",
    isStyle: true,
  },
  {
    id: "backgroundSize",
    label: "Background Size",
    componentId: "Select",
    section: "background",
    defaultValue: "cover",
    isStyle: true,
    options: [
      { label: "Cover", value: "cover" },
      { label: "Contain", value: "contain" },
    ],
    displayCondition: (block) => block.showBackground,
  },
  {
    id: "backgroundPosition",
    label: "Background Position",
    componentId: "Select",
    section: "background",
    isStyle: true,
    defaultValue: "center",
    options: [
      { label: "Center", value: "center" },
      { label: "Top", value: "top" },
      { label: "Bottom", value: "bottom" },
      { label: "Left", value: "left" },
      { label: "Right", value: "right" },
    ],
    displayCondition: (block) => block.showBackground,
  },
  {
    id: "showOverlay",
    label: "Show Overlay",
    componentId: "Switch",
    section: "background",
    defaultValue: false,
    orientation: "horizontal",
    requiresSheet: true,
    advanced: true,
    isStyle: true,
    displayCondition: (block) => block.showBackground,
  },
  {
    id: "overlayColor",
    label: "Overlay Color",
    componentId: "ColorPicker",
    section: "background",
    isStyle: true,
    advanced: true,
    orientation: "horizontal",
    displayCondition: (block) => block.showBackground && block.showOverlay,
  },
];

export const hiddenFilters = {
  id: "hiddenFilters",
  componentId: "HiddenFiltersConfig",
  section: "hiddenFilters",
  requiresSheet: true,
  sectionHint: "Filter the initial data that users can access",
  link: "https://help.frontly.ai/en/articles/7971258-hidden-filters",
};

export const visibleFilters = {
  id: "visibleFilters",
  componentId: "VisibleFiltersConfig",
  section: "visibleFilters",
  requiresSheet: true,
  sectionHint:
    "Allow users to filter the visible data with Input fields and Select dropdowns",
  link: "https://help.frontly.ai/en/articles/7971668-visible-filters",
};

export const listFilterResources = [
  visibleFilters,
  {
    id: "visibleFiltersTheme",
    label: "Color Theme",
    componentId: "Select",
    section: "visibleFilters",
    hideEmptyItem: true,
    defaultValue: "white",
    requiresSheet: true,
    options: [
      { label: "White", value: "white" },
      { label: "Grey", value: "grey" },
    ],
  },
  hiddenFilters,
  {
    id: "showSearch",
    componentId: "Switch",
    section: "showSearchFilter",
    defaultValue: true,
    orientation: "horizontal",
    requiresSheet: true,
    sectionHint: "Display a search bar allowing users to easily filter results",
  },
  {
    id: "hideHeader",
    componentId: "Switch",
    section: "hideHeader",
    orientation: "horizontal",
    requiresSheet: true,
    advanced: true,
    sectionHint:
      "Hide the block header entirely so it doesn't take up any space",
  },
];

export const labelColor = {
  id: "labelColor",
  section: "label",
  label: "Label Color",
  componentId: "ColorPicker",
  advanced: true,
  orientation: "horizontal",
  isStyle: true,
};

export const listBlockResources = [
  label,
  labelColor,
  spreadsheetSelect,
  ...listActionsResources,
  ...listFilterResources,
  {
    id: "showCreate",
    label: "Allow Creating Records",
    componentId: "Switch",
    section: "actions",
    defaultValue: true,
    requiresSheet: true,
    orientation: "horizontal",
    hint: "Display a 'Create' button that lets the user create new rows in the selected spreadsheet",
  },
  {
    id: "labelSingular",
    label: "Item Label",
    componentId: "Input",
    section: "content",
    requiresSheet: true,
    hint: "A label to replace the default 'Record' text in create and edit views.",
    advanced: true,
  },
  {
    id: "editModeLabel",
    label: "Edit Label",
    componentId: "Input",
    section: "display",
    requiresSheet: true,
    advanced: true,
    width: "130px",
    orientation: "horizontal",
    hint: "A label to replace the default 'Record' text in edit views.",
  },
  {
    id: "createModeLabel",
    label: "Create Label",
    componentId: "Input",
    section: "display",
    advanced: true,
    width: "130px",
    orientation: "horizontal",
    requiresSheet: true,
    hint: "A label to replace the default 'Record' text in create views.",
  },
  fetchIfHidden,
];

export const badgeColors = {
  id: "badgeColors",
  label: "Badge Colors",
  hint: "Define custom colors for your badges",
  componentId: "DataGrid",
  orientation: "vertical",
  requiresSheet: true,
  hideEmptyItem: true,
  columns: [
    {
      key: "value",
      componentId: "Input",
      width: "93px",
    },
    {
      key: "color",
      componentId: "Select",
      options: [
        { label: "Grey", value: "grey" },
        { label: "Red", value: "red" },
        { label: "Orange", value: "orange" },
        { label: "Yellow", value: "yellow" },
        { label: "Green", value: "green" },
        { label: "Blue", value: "blue" },
        { label: "Purple", value: "purple" },
      ],
      width: "93px",
    },
  ],
};

export const cardKeys = [
  {
    id: "badge",
    label: "Badge",
    componentId: "SpreadsheetColumnSelect",
    requiresSheet: true,
    section: "badge",
    hint: "The spreadsheet column to use for badges on the card (optional)",
  },
  {
    id: "splitBadge",
    label: "Split Badges",
    componentId: "Switch",
    section: "badge",
    orientation: "horizontal",
    defaultValue: false,
    requiresSheet: true,
    hint: "Should split the badges by `,`?",
  },
  {
    id: "backgroundColor",
    componentId: "ColorPicker",
    section: "cardBackgroundColor",
    isStyle: true,
    sectionHint: "Add background color on the kanban item card",
  },
  {
    displayCondition: (f) => !!get(f, "badge"),
    ...badgeColors,
    section: "badge",
  },
  {
    id: "gridColumns",
    label: "Card Grid Columns",
    componentId: "Input",
    section: "card",
    isStyle: true,
    defaultValue: 2,
    requiresSheet: true,
    hint: "The number of columns to display on the card grid",
  },
];

export const cardFieldKeys = [
  {
    id: "key",
    label: "Spreadsheet Column",
    componentId: "SpreadsheetColumnSelect",
    required: true,
    hint: "The field from your spreadsheet to display in this text",
  },
  {
    id: "fontStyle",
    label: "Font Style",
    componentId: "Select",
    required: true,
    defaultValue: "bodyMd",
    options: fontOptions,
  },
  {
    id: "fontColor",
    key: "fontColor",
    label: "Font Color",
    componentId: "ColorPicker",
  },
  {
    id: "truncate",
    key: "truncate",
    label: "Character Limit",
    componentId: "Input",
    type: "number",
    defaultValue: 35,
    advanced: true,
  },
  {
    id: "label",
    key: "label",
    label: "Label",
    componentId: "DynamicString",
    defaultValue: "",
    hint: "A visual label displayed above the value",
  },
  {
    id: "valuePrefix",
    key: "valuePrefix",
    label: "Value Prefix",
    componentId: "DynamicString",
    defaultValue: "",
    hint: "Text to display before the value",
    advanced: true,
  },
  {
    id: "valueSuffix",
    key: "valueSuffix",
    label: "Value Suffix",
    componentId: "DynamicString",
    defaultValue: "",
    hint: "Text to display before the value",
    advanced: true,
  },
  // {
  //   id: "columnSpan",
  //   label: "Column Span",
  //   componentId: "Input",
  //   requiresSheet: true,
  //   hint: "The number of columns to span for this item",
  // },
  // {
  //   id: "rowSpan",
  //   label: "Row Span",
  //   componentId: "Input",
  //   defaultValue: 1,
  //   requiresSheet: true,
  //   hint: "The number of rows to span for this item",
  // },
];
