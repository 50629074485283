import { Icon, Modal, Row, Text } from "app/components";

import { badges } from "app/utils/theme";
import { get } from "lodash";
import { rOrganization } from "app/utils/recoil";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const IntroModal = ({ hide }) => {
  const organization = useRecoilValue(rOrganization);
  const aiPrompt = get(organization, "ai_prompt");

  const navigate = useNavigate();

  // New AI prompt popup
  if (aiPrompt) {
    return (
      <Modal
        header={{
          title: "Welcome to Frontly!",
        }}
        hide={hide}
        buttons={[
          {
            text: "Start Browsing Frontly",
            type: "primary",
            icon: "FiArrowRight",
            size: "large",
            flippedIcon: true,
            onClick: () => {
              hide();
            },
          },
        ]}
      >
        <Text
          data={{
            fontSize: 18,
            fontWeight: 400,
            color: "var(--grey9)",
            text: "Our new AI Copilot launches March 31st. In the meantime, you can:",
            margin: "0 0 15px 0",
          }}
        />
        <Row alignItems="center" gap="10px">
          <Icon
            data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }}
          />
          <Text
            data={{
              fontStyle: "headingMd",
              text: "Create custom apps manually",
            }}
          />
        </Row>
        <Row alignItems="center" gap="5px" margin="10px 0 0 0">
          <Icon
            data={{
              icon: "FiCheckCircle",
              color: badges.green,
              size: 20,
              margin: "0 5px 0 0",
            }}
          />
          <Text
            data={{
              fontStyle: "headingMd",
              text: "Browse our App Marketplace",
            }}
          />
        </Row>
        <Row alignItems="center" gap="10px" margin="10px 0 0 0">
          <Icon
            data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }}
          />
          <Text
            data={{
              fontStyle: "headingMd",
              text: "Join our Discord community to learn more",
            }}
          />
        </Row>
      </Modal>
    );
  }

  return (
    <Modal
      header={{
        title: "You're on Frontly's free plan!",
      }}
      hide={hide}
      buttons={[
        {
          text: "Continue For Free",
          type: "secondary",
          icon: "FiCheck",
          size: "large",
          onClick: () => {
            hide();
          },
        },
        {
          text: "View Upgrade Options",
          type: "primary",
          icon: "FiUploadCloud",
          size: "large",
          onClick: () => {
            hide();
            navigate("/settings/billing");
          },
        },
      ]}
    >
      <Text
        data={{
          fontSize: 18,
          fontWeight: 400,
          color: "var(--grey9)",
          text: "The free plan includes:",
          margin: "0 0 15px 0",
        }}
      />
      <Row alignItems="center" gap="10px">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text data={{ fontStyle: "headingMd", text: "Unlimited Users" }} />
      </Row>
      <Row alignItems="center" gap="10px" margin="10px 0 0 0">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "Unlimited Apps",
          }}
        />
      </Row>
      <Row alignItems="center" gap="5px" margin="10px 0 0 0">
        <Icon
          data={{
            icon: "FiCheckCircle",
            color: badges.green,
            size: 20,
            margin: "0 5px 0 0",
          }}
        />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "1,000 Monthly Operations",
          }}
        />
        <Text
          data={{
            fontStyle: "bodyLg",
            color: "var(--grey6)",
            text: "(for database and AI usage)",
          }}
        />
      </Row>
      <Text
        data={{
          fontSize: 18,
          fontWeight: 400,
          color: "var(--grey9)",
          text: "Other features available on paid plans:",
          margin: "30px 0 15px 0",
        }}
      />
      <Row alignItems="center" gap="5px">
        <Icon
          data={{
            icon: "FiUploadCloud",
            color: badges.orange,
            size: 20,
            margin: "0 5px 0 0",
          }}
        />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "Custom Branding",
          }}
        />
        <Text
          data={{
            fontStyle: "bodyLg",
            color: "var(--grey6)",
            text: "(logo and custom domain)",
          }}
        />
      </Row>
      <Row alignItems="center" gap="5px" margin="10px 0 0 0">
        <Icon
          data={{
            icon: "FiUploadCloud",
            color: badges.orange,
            size: 20,
            margin: "0 5px 0 0",
          }}
        />
        <Text
          data={{ fontStyle: "headingMd", text: "Increased Usage Limits" }}
        />
      </Row>
      <Row alignItems="center" gap="5px" margin="10px 0 0 0">
        <Icon
          data={{
            icon: "FiUploadCloud",
            color: badges.orange,
            size: 20,
            margin: "0 5px 0 0",
          }}
        />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "Frontly Team Support",
          }}
        />
        <Text
          data={{
            fontStyle: "bodyLg",
            color: "var(--grey6)",
            text: "(live chat and email)",
          }}
        />
      </Row>
    </Modal>
  );
};

export default IntroModal;
