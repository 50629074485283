import Badge, { getUniqueBadgeColors } from "./Badge";
import {
  Button,
  Icon,
  Row,
  SkeletonLoader,
  Text,
  Thumbnail,
} from "app/components";
import { get, isEmpty, startCase } from "lodash";
import {
  getLinkOnClick,
  getUniqueValues,
  isFrontlyAdmin,
  parseBoolean,
  parseDateWithFormatObject,
  safeArray,
  truncateText,
} from "app/utils/utils";

import { colors } from "app/utils/theme";
import { rTranslations } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import useUtils from "app/renderingApp/useUtils";

export const getBadgeColorMap = (definedColors) => {
  const badges = {
    grey: "var(--grey21)",
    red: "hsl(347, 95%, 88%)",
    orange: "hsl(30, 95%, 88%)",
    yellow: "hsl(50, 95%, 88%)",
    green: "hsl(139, 95%, 88%)",
    blue: "hsl(208, 95%, 88%)",
    purple: "hsl(253, 95%, 88%)",
  };

  // User Defined Color Map
  let colorMap = {};
  safeArray(definedColors).forEach((c) => {
    colorMap[c.value] = get(badges, c.color);
  });

  return colorMap;
};

const ViewOnlyList = ({
  appDateFormat,
  displayFields,
  formState,
  processDynamicText,
  gridLayout,
  maxWidth,
  handleFieldClick,
  darkMode,
  isFetching,
  deleteButton,
}) => {
  return (
    <div>
      <InfoListContainer gridLayout={gridLayout} maxWidth={maxWidth}>
        {displayFields.map((item) => {
          const k = get(item, "key");
          const v = get(formState, k);

          let badgeColors = null;

          if (item.type === "badge") {
            const isMulti = item.splitBadge ? "MultiSelect" : null;
            const colors = getUniqueValues(item.options, "label", isMulti);
            badgeColors = getUniqueBadgeColors(colors);
          }

          return (
            <ValueContainer
              key={k}
              style={{ gridColumn: `span ${get(item, "columnSpan", 1)}` }}
              onClick={(e) => {
                if (isFrontlyAdmin && handleFieldClick) {
                  handleFieldClick({ id: k, target: e.currentTarget });
                }
              }}
              handleFieldClick={isFrontlyAdmin && handleFieldClick}
            >
              {isFetching ? (
                <>
                  <SkeletonLoader
                    height="15px"
                    margin="0 0 10px 0"
                    widthRange={[50, 150]}
                  />
                  <SkeletonLoader height="20px" widthRange={[150, 250]} />
                </>
              ) : (
                <>
                  <Text
                    data={{
                      text: processDynamicText({
                        text: item.label || startCase(k),
                      }),
                      fontStyle: "headingMd",
                      margin: "0 0 5px 0",
                      color: darkMode ? "white" : colors.default,
                    }}
                  />
                  <RenderValue
                    item={item}
                    value={processDynamicText({ text: v })}
                    darkMode={darkMode}
                    badgeColors={badgeColors}
                    appDateFormat={appDateFormat}
                  />
                </>
              )}
            </ValueContainer>
          );
        })}
      </InfoListContainer>
      {deleteButton && (
        <Button data={{ ...deleteButton, margin: "20px 0 0 0" }} />
      )}
    </div>
  );
};

export default ViewOnlyList;

const RenderValue = ({ value, item, darkMode, badgeColors, appDateFormat }) => {
  const { type, componentId, truncate = 200 } = item;

  const translations = useRecoilValue(rTranslations);
  const { passesDisplayConditions } = useUtils();

  const [expanded, setExpanded] = useState(false);

  if (type === "image" || componentId === "ImageUpload") {
    return <Thumbnail src={value} size="large" />;
  } else if (type === "badge") {
    const badgeColorMap = getBadgeColorMap(get(item, "badgeColors", []));
    const customColorMap = get(item, "customColors", []);

    const splitBadge = get(item, "splitBadge", false);

    if (value) {
      value = value.toString();
    }

    const badgeArray =
      splitBadge && value
        ? value.split(",").map((v) => v.trim())
        : value
        ? [value.trim()]
        : [];

    const grey = get(badgeColorMap, "grey");

    // Handles multiple condition. The first condition that matches the badge's value will use the set color.
    const handleCustomColor = (v) => {
      const colorMap = customColorMap?.find((item) => {
        const conditions = item?.displayConditions?.map((d) => ({
          ...d,
          value1: v,
        }));
        const isPass = passesDisplayConditions({
          conditions,
          showInAdmin: true,
        });
        return isPass;
      });
      if (colorMap) return colorMap?.color;
      return grey;
    };

    return (
      <Row gap="5px" flexWrap="wrap">
        {badgeArray.map((v) => {
          const color =
            !isEmpty(customColorMap) && customColorMap
              ? handleCustomColor(v)
              : !isEmpty(badgeColorMap)
              ? get(badgeColorMap, v, grey)
              : get(badgeColors, v, grey);
          return <Badge value={v || "No Value"} color={color} />;
        })}
      </Row>
    );
  } else if (type === "boolean") {
    const parsedValue = parseBoolean(value);

    return (
      <BooleanWrapper value={parsedValue}>
        <Icon
          data={{
            icon: parsedValue ? "FiCheck" : "FiX",
            size: 14,
            hover: true,
            color: parsedValue ? "#2F7D61" : "#CB401B",
          }}
        />
      </BooleanWrapper>
    );
  } else if (type === "date") {
    const df = { ...appDateFormat, ...get(item, "dateFormat", {}) };
    return parseDateWithFormatObject({
      value,
      formatObject: df,
    });
  }

  const linkClick = item.ignoreLinks ? null : getLinkOnClick(value);

  const truncateAmount = truncate || 35;

  const finalValue = get(item, "linkText") || value;

  if (linkClick) {
    return (
      <Text
        data={{
          text: truncateText(finalValue, truncateAmount),
          onClick: linkClick,
          color: "var(--primary)",
          fontStyle: "bodyLg",
          cursor: "pointer",
          overflowWrap: "anywhere",
          whiteSpace: true,
          allowSelect: true,
        }}
      />
    );
  }

  return (
    <>
      <Text
        data={{
          text: truncate && !expanded ? truncateText(value, truncate) : value,
          fontStyle: "bodyLg",
          overflowWrap: "anywhere",
          allowSelect: true,
          whiteSpace: "pre-wrap",
          color: darkMode ? "white" : colors.default,
        }}
      />
      {truncate && get(value, "length") > truncate && !expanded && (
        <Text
          data={{
            text: get(translations, "showAll", "Show All"),
            fontStyle: "headingSm",
            color: "var(--primary)",
            margin: "3px 0 0 0",
            onClick: () => setExpanded(true),
            cursor: "pointer",
          }}
        />
      )}
    </>
  );
};

const ValueContainer = styled.div`
  ${(p) =>
    p.handleFieldClick &&
    `
  &:hover{
    outline: 2px solid ${colors.frontlyBlue};
    outline-offset: -2px;
    cursor: pointer;
    border-radius: 4px;
  }
`};
`;

const InfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(p) => p.maxWidth || "auto"};
  gap: 30px;
  ${(p) =>
    p.gridLayout &&
    `
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    `}
`;

const BooleanWrapper = styled.div`
  display: flex;
  background: ${(p) => (p.value ? "#B8E7D2" : "#F9D5D2")};
  border-radius: 20px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
