import { DragPreviewImage, useDrag } from "react-dnd";
import React, { memo, useCallback, useRef } from "react";

import { Icon } from "app/components";
import { Tooltip } from "react-tooltip";
import dragIcon from "app/assets/images/move-icon-2.png";
import { spacing } from "app/utils/theme";
import styled from "styled-components";

// Wrap BlockOption with memo so that it only re-renders when its props change.
const BlockOption = memo(({ blockType, blockIcon, addBlock }) => {
  // The dependency array now includes blockType so that the drag spec is re-created only when needed.
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "element",
      item: { type: "element", blockType },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [blockType]
  );

  const containerRef = useRef(null);

  // Optionally memoize the click handler (provided addBlock is stable)
  const handleClick = useCallback(() => {
    addBlock({ type: blockType });
  }, [addBlock, blockType]);

  return (
    <>
      <Tooltip
        anchorSelect={`.${blockType}`}
        place="bottom"
        style={{ zIndex: 9999 }}
      >
        {blockType}
      </Tooltip>
      <DragPreviewImage connect={preview} src={dragIcon} />
      <BlockIcon
        // Pass the container ref into drag to attach drag behavior.
        ref={drag(containerRef)}
        className={blockType}
        onClick={handleClick}
      >
        <Icon
          data={{
            icon: blockIcon,
            size: 23,
            color: "var(--primary-admin)",
            hover: true,
          }}
        />
      </BlockIcon>
    </>
  );
});

export default BlockOption;

export const BlockIcon = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.7;
  gap: ${spacing.s1};
  cursor: pointer;
  margin: ${(p) => p.margin};
  &:hover {
    background: var(--grey1);
  }
`;
