import { layoutOptions, layouts } from "app/renderingApp/layouts";

import { Select } from "app/components";
import { get } from "lodash";
import useBlocks from "app/utils/useBlocks";
import useSetPage from "app/utils/useSetPage";

const LayoutSelect = ({ data }) => {
  const { setPage } = useSetPage();

  const { blocks, setBlocks } = useBlocks();

  const resetLayout = (layoutId) => {
    const layout = get(layouts, layoutId);
    const layoutItems = get(layout, "items", []).map((i) => i.i);
    const newBlocks = blocks.map((b, i) => ({
      ...b,
      layoutPosition: get(layoutItems, i),
    }));

    setPage({ layout: layoutId });
    setBlocks(newBlocks);
  };

  return (
    <>
      <Select
        data={{
          ...data,
          options: layoutOptions,
          onChange: resetLayout,
        }}
      />
    </>
  );
};

export default LayoutSelect;
