import { cardFieldKeys, cardKeys, listBlockResources } from "./shared";

export const Calendar = {
  componentId: "Calendar",
  resources: [
    ...listBlockResources,
    {
      id: "fields",
      // label: "Event Card Fields",
      componentId: "DynamicFields",
      keys: cardFieldKeys,
      requiresSheet: true,
      section: "eventCardFields",
      sectionHint:
        "Add multiple text fields to display on the calendar event card",
    },
    {
      id: "eventColorColumn",
      label: "Event Color Column",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "eventColors",
      isStyle: true,
      hint: "The spreadsheet column to use for the event color matching",
    },
    {
      id: "eventColors",
      label: "Event Colors",
      componentId: "MultiColorPicker",
      requiresSheet: true,
      isStyle: true,
      section: "eventColors",
      hint: "Define custom colors for your event types",
    },
    {
      id: "eventCardColor",
      label: "Restricted Date Color",
      componentId: "ColorPicker",
      requiresSheet: true,
      isStyle: true,
      section: "eventColors",
      hint: "Set the color for restricted date",
      displayCondition: (field) => field.isPreventPastEvents,
    },
    {
      id: "eventBadgeStyle",
      label: "Event Badge Style",
      componentId: "Select",
      requiresSheet: true,
      isStyle: true,
      defaultValue: "minimal",
      section: "eventColors",
      hideEmptyItem: true,
      options: [
        {
          label: "Minimal",
          value: "minimal",
        },
        {
          label: "Badge",
          value: "badge",
        },
      ],
      hint: "Adjust the style of your event badge",
    },
    {
      id: "eventLabel",
      label: "Event Label",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "dateSettings",
      hint: "The spreadsheet column to use for the calendar event label",
    },
    {
      id: "startDate",
      label: "Start Date",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "dateSettings",
      wizard: true,
      hint: "The spreadsheet column containing your event start date",
    },
    {
      id: "endDate",
      label: "End Date",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      section: "dateSettings",
      hint: "The spreadsheet column containing your event end date",
    },
    {
      id: "weekStart",
      label: "Week Starts",
      componentId: "Select",
      requiresSheet: true,
      defaultValue: "sunday",
      section: "dateSettings",
      hideEmptyItem: true,
      options: [
        {
          label: "Sunday",
          value: "sunday",
        },
        {
          label: "Monday",
          value: "monday",
        },
      ],
      hint: "Select the day to define the start of the week in your calendar",
    },
    {
      id: "isPreventPastEvents",
      label: "Prevent Past Events",
      componentId: "Switch",
      requiresSheet: true,
      section: "dateSettings",
      orientation: "horizontal",
      defaultValue: true,
      hint: "When enabled, users can't create events for past dates. When disabled, appointments can be scheduled for past dates",
    },
    {
      id: "showDateOnCard",
      label: "Show Date On Card",
      componentId: "Switch",
      requiresSheet: true,
      section: "dateSettings",
      orientation: "horizontal",
      defaultValue: true,
      hint: "Show the start date and end date (if set) on the event card",
    },
    ...cardKeys,
  ],
};
