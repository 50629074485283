import BlockOption, { BlockIcon } from "./BlockOption";
import { Icon, Row } from "app/components";
import { allBlocks, allowedInsideCustomReusable } from "app/utils/utils";
import { memo, useState } from "react";

import { NewBlock } from "app/adminApp/components/BlockManager/NewBlock";
import { Tooltip } from "react-tooltip";

const BlocksSection = memo(({ addBlock, isRepeatingCustomBlock }) => {
  const [showNewBlock, setShowNewBlock] = useState(false);

  let highlighted = [
    "Table",
    "Grid",
    "Form",
    "Kanban",
    "Calendar",
    "Chart",
    "Stat",
    "Text",
    "Button",
    "Row",
    "Column",
  ];

  // If repeating reusable block, filter options
  if (isRepeatingCustomBlock) {
    highlighted = allowedInsideCustomReusable.filter((b) => b !== "RichText");
  }

  return (
    <Row
      alignItems="center"
      justifyContent="center"
      gap="10px"
      style={{ flex: 1 }}
    >
      {showNewBlock && <NewBlock hide={() => setShowNewBlock(false)} />}
      {allBlocks
        .filter((b) => highlighted.includes(b.type))
        .map((b) => (
          <BlockOption
            key={b.type}
            blockType={b.type}
            blockIcon={b.icon}
            addBlock={addBlock}
          />
        ))}
      <Tooltip
        anchorSelect={".moreBlocks"}
        place="bottom"
        style={{ zIndex: 9999 }}
      >
        More
      </Tooltip>
      <BlockIcon className={"moreBlocks"} onClick={() => setShowNewBlock(true)}>
        <Icon
          data={{
            icon: "FiMoreHorizontal",
            size: 23,
            color: "var(--primary-admin)",
            hover: true,
          }}
        />
      </BlockIcon>
    </Row>
  );
});

export default BlocksSection;
