import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { rApp, rTranslations, rUser } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Cookies from "js-cookie";
import { isFrontlyAdmin } from "app/utils/utils";
import { useNavigate } from "react-router-dom";

const useDropdownLinks = () => {
  const translations = useRecoilValue(rTranslations);
  const [user, setUser] = useRecoilState(rUser);
  const app = useRecoilValue(rApp);

  const logoutText = get(translations, "logout", "Logout");
  const loggedOutText = get(translations, "loggedOut", "Logged out");
  const editProfileText = get(translations, "editProfile", "Edit Profile");

  const navigate = useNavigate();

  // -- DETERMINE USER / LOGIN STATUS -- //
  const userIsNullOrEmpty = !user || isEmpty(user);

  let dropdownLinks = userIsNullOrEmpty
    ? []
    : [
        {
          label: logoutText,
          icon: "FiLogOut",
          onClick: () => {
            if (isFrontlyAdmin) {
              errorNotification("Links are disabled in admin preview");
              return;
            }

            setUser(null);
            Cookies.remove("accessToken");
            localStorage.removeItem("appId");
            successNotification(loggedOutText);
            navigate("/login");
          },
        },
      ];

  if (!userIsNullOrEmpty && get(app, "enable_edit_profile", false)) {
    dropdownLinks.unshift({
      label: editProfileText,
      icon: "FiUser",
      onClick: () => {
        if (isFrontlyAdmin) {
          errorNotification("Links are disabled in admin preview");
          return;
        }

        navigate("/edit-profile");
      },
    });
  }

  return dropdownLinks;
};

export default useDropdownLinks;
