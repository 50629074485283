import { Column, Modal, StackBreadcrumbs } from "app/components";
import {
  fixBlocks,
  getAllBlocksBeneath,
  getDetailViewMode,
  getPixels,
} from "app/utils/utils";
import {
  rApp,
  rConfirmationModalData,
  rDarkMode,
  rPageBlocks,
  rTranslations,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import DetailView from "../renderPage/DetailView";
import RenderBlockRouter from "./RenderBlock/RenderBlockRouter";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getViewLabel } from "app/renderPage/utils";
import useBlockVersion from "app/utils/useBlockVersion";
import { useFetchSpreadsheets } from "./fetchSpreadsheets";
import useModalStateData from "app/useModalStateData";
import useUtils from "./useUtils";

const RenderDetailView = ({ page, activeBlock }) => {
  const { processDynamicText, passesDisplayConditions } = useUtils();

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const activeApp = useRecoilValue(rApp);

  const styling = get(activeApp, "styling", {});
  const blockBorderRadius = get(styling, "blockBorderRadius") || 8;

  const { getBlockWithVersion } = useBlockVersion();

  const pageBlocks = useRecoilValue(rPageBlocks);

  const blocks = fixBlocks(pageBlocks).map((b) => {
    const { blockWithVersion: blockVersion } = getBlockWithVersion(b);
    return blockVersion;
  });

  const displayBlocks = blocks
    .filter((b) => b.parent === get(activeBlock, "id") && !b.layoutParent)
    .filter((b) =>
      passesDisplayConditions({ conditions: get(b, "displayConditions", []) })
    );

  const detailBlockIds = getAllBlocksBeneath(get(activeBlock, "id"), blocks);

  const customViewBlocksAndLayouts = blocks.filter((b) =>
    detailBlockIds.includes(b.id)
  );

  const recordClickAction = get(activeBlock, "recordClickAction");

  const currentDetailViewMode = getDetailViewMode(activeBlock, blocks);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const { modalStack, setModalStack, hideTopStackItem, lastItem } =
    useModalStateData();

  const isCreate = get(lastItem, "type") === "create";

  const isCustomView = recordClickAction === "custom";
  const isDefaultView =
    isCreate || !recordClickAction || recordClickAction === "default";

  const viewLabel = processDynamicText({
    text: getViewLabel(activeBlock),
    context: { repeatingRecord: get(activeBlock, "repeatingRecord") },
  });

  const detailViewWidth = get(activeBlock, "detailViewWidth", "600px");

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { fetchSpreadsheets } = useFetchSpreadsheets();

  // Force fetching spreadsheets again when detail view appears
  useEffect(() => {
    if (isCustomView) {
      fetchSpreadsheets(customViewBlocksAndLayouts);
    }
  }, [get(lastItem, "id")]);

  const blocksContent = (
    <Column gap={"25px"}>
      {displayBlocks.map((block) => (
        <RenderBlockRouter
          key={block.id}
          data={{
            page,
            block,
          }}
        />
      ))}
    </Column>
  );

  // RETURN MODAL VIEW
  if (currentDetailViewMode === "modal") {
    if (isDefaultView) {
      const crumbs = modalStack.map((stackItem, i) => ({
        text: stackItem.label,
        onClick: () =>
          setModalStack(modalStack.filter((x) => x.id <= stackItem.id)),
      }));

      return (
        <Modal
          darkMode={darkMode}
          background={darkMode ? colors.darkModeLightBackground : "white"}
          borderRadius={blockBorderRadius ? getPixels(blockBorderRadius) : null}
          header={{ title: viewLabel, breadcrumbs: crumbs }}
          minWidth={detailViewWidth}
          hide={() => {
            if (hasUnsavedChanges) {
              setConfirmationModalData({
                title: get(
                  translations,
                  "unsavedChangesWarningHeader",
                  "Close With Unsaved Changes?"
                ),
                text: get(
                  translations,
                  "unsavedChangesWarning",
                  "There are unsaved changes. Are you sure you want to close?"
                ),
                confirm: () => {
                  hideTopStackItem();
                },
                hide: () => null,
              });
            } else {
              hideTopStackItem();
            }
          }}
        >
          <DetailView
            page={page}
            hideCard
            setHasUnsavedChanges={setHasUnsavedChanges}
            setupBlock={activeBlock}
            width="100%"
          />
        </Modal>
      );
    }

    return (
      <Modal
        darkMode={darkMode}
        background={darkMode ? colors.darkModeLightBackground : "white"}
        borderRadius={blockBorderRadius ? getPixels(blockBorderRadius) : null}
        header={{ title: viewLabel }}
        minWidth={detailViewWidth}
      >
        {blocksContent}
      </Modal>
    );
  }

  // FULL SCREEN DETAIL VIEW
  return (
    <div>
      <StackBreadcrumbs
        label={get(activeBlock, "label")}
        margin={"0 0 25px 0"}
        darkMode={darkMode}
      />
      {isDefaultView && (
        <DetailView
          page={page}
          setupBlock={activeBlock}
          margin="30px 0 0 0"
          width={detailViewWidth}
        />
      )}
      {!isDefaultView && blocksContent}
    </div>
  );
};

export default RenderDetailView;
