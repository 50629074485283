import { useEffect, useRef, useState } from "react";

import { Icon } from "app/components";
import { colors } from "app/utils/theme";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";

const SmartString = ({
  segments,
  setSegments,
  value,
  onChange,
  handleClick,
  inputRef,
}) => {
  // Run on component mount
  useEffect(() => {
    const regex = /(\{\{.*?\}\})/g;
    const parts = value.split(regex).filter(Boolean);
    const segments = parts.map((part) => {
      if (part.startsWith("{{") && part.endsWith("}}")) {
        return { type: "variable", value: part };
      }
      return { type: "text", value: part };
    });
    setSegments(segments);
  }, []);

  const updateInput = (index, value) => {
    onChange(
      segments
        .map((s, i) => {
          if (i === index) {
            return value;
          }
          return s.value;
        })
        .join("")
    );
  };

  const addVariable = (direction, index) => {
    const newIndex = direction === "left" ? index : index + 1;
    handleClick(newIndex);
  };

  return (
    <Container ref={inputRef}>
      {segments.map((segment, index) => {
        if (segment.type === "text") {
          return (
            <AutoResizeInput
              key={index}
              defaultValue={segment.value}
              onChange={(v) => updateInput(index, v)}
              addVariable={(direction) => addVariable(direction, index)}
            />
          );
        } else if (segment.type === "variable") {
          return (
            <VariableComponent
              key={index}
              value={segment.value}
              addVariable={(direction) => addVariable(direction, index)}
            />
          );
        }
      })}
    </Container>
  );
};

export default SmartString;

const VariableComponent = ({ value, addVariable }) => {
  const [hover, setHover] = useState(false);

  return (
    <VariableContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FloatButton
        hover={hover}
        onClick={() => addVariable("left")}
        direction="left"
      />
      <Variable>{value.replace("{{", "").replace("}}", "")}</Variable>
      <FloatButton
        hover={hover}
        onClick={() => addVariable("right")}
        direction="right"
      />
    </VariableContainer>
  );
};

const AutoResizeInput = ({ defaultValue, onChange, addVariable }) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const spanRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    // Update input width based on the span width
    if (spanRef.current && inputRef.current) {
      const spanWidth = spanRef.current.offsetWidth + 4;
      inputRef.current.style.width = `${spanWidth}px`;
    }
  }, [inputValue]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  const [hover, setHover] = useState(false);

  return (
    <InputContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FloatButton
        hover={hover}
        onClick={() => addVariable("left")}
        direction="left"
      />
      <HiddenSpan ref={spanRef}>{inputValue || " "}</HiddenSpan>
      <StyledInput ref={inputRef} value={inputValue} onChange={handleChange} />
      <FloatButton
        hover={hover}
        onClick={() => addVariable("right")}
        direction="right"
      />
    </InputContainer>
  );
};

const FloatButton = ({ hover, onClick, direction }) => {
  return (
    <FloatingButton hover={hover} onClick={onClick} direction={direction}>
      <Icon
        data={{
          icon: "FiPlus",
          color: "var(--grey8)",
          size: 10,
          hover: true,
        }}
      />
    </FloatingButton>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  padding: 6px;
  border: ${(p) => p.border || `1px solid ${colors.pitchBorder}`};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
  overflow-x: wrap;
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  padding: 5px 8px 5px 8px;
  background: var(--grey21);
  border-radius: 5px;
  font-size: 14px;
  text-align: left;
  font-color: var(--grey8);
  border: none;
  width: auto; /* Initially allow dynamic width */
  &:focus {
    outline: none;
  }
`;

const FloatingButton = styled.div`
  display: none;
  position: absolute;
  background: white;
  border: 1px solid var(--grey7);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 10px;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${(p) => (p.hover ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  ${(p) =>
    p.direction === "left"
      ? `
          left: -10px;
        `
      : `
          right: -10px;
        `}
`;

const Variable = styled.div`
  background: var(--primary-admin);
  padding: 5px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  display: inline-block;
  &:hover {
    filter: brightness(90%);
  }
`;

// Hidden span to calculate the input width
const HiddenSpan = styled.span`
  visibility: hidden;
  position: absolute;
  white-space: pre; /* Ensure the text doesn't wrap */
  font-size: 14px;
  font-family: inherit;
  padding: 5px 6px 5px 6px; /* Match padding with input */
  border-radius: 5px;
`;

const VariableContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
