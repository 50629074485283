import { rCustomBlockChanges, rPageActions } from "./recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import useIsCustomBlock from "./useIsCustomBlock";

const usePageActions = () => {
  const [actions, setActions] = useRecoilState(rPageActions);
  const isCustomBlock = useIsCustomBlock();

  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);

  const customSetActions = (newActions) => {
    if (isCustomBlock) {
      setCustomBlockChanges(true);
    }
    setActions(newActions);
  };

  return {
    actions,
    setActions: customSetActions,
  };
};

export default usePageActions;
