import { AdminForm, BackButton } from "../components";
import { Button, FullScreenLoader, Row, Tabs } from "app/components";
import { rActiveActionObject, rApp } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Cookies from "js-cookie";
import EditActionStepModal from "../components/Action/EditActionStepModal";
import FullScreenForm from "app/renderingApp/blocks/FullScreenForm";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { openPageInNewTab } from "app/utils/utils";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useRecoilValue } from "recoil";

const FormEditor = () => {
  const [config, setConfig] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [changes, setChanges] = useState(false);

  const activeActionObject = useRecoilValue(rActiveActionObject);

  const [tab, setTab] = useState("basics");

  const [forceItemIndex, setForceItemIndex] = useState(0);

  const app = useRecoilValue(rApp);

  const fields = get(config, "fields", []);

  const { id } = useParams();

  useEffect(() => {
    mixpanel.track("Form Editor View");
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get(`/forms/?id=${id}`).then((res) => {
      setConfig(res.data);
      setIsFetching(false);
    });
  }, []);

  const saveForm = () => {
    setIsSaving(true);
    apiRequest.post("/forms/", config).then((res) => {
      setIsSaving(false);
      successNotification("Saved");
      setChanges(false);
    });
  };

  if (isFetching) {
    return <FullScreenLoader />;
  }

  let activeFields = [];

  if (tab === "basics") {
    activeFields = [
      {
        id: "name",
        label: "Name",
        componentId: "Input",
        value: config.name,
        section: "Basics",
      },
      {
        id: "route",
        label: "URL",
        componentId: "Input",
        value: config.route,
        section: "Basics",
      },
      {
        id: "fields",
        // label: "Fields",
        componentId: "MultiForm",
        labelSingular: "Field",
        section: "Fields",
        width: "400px",
        value: fields,
        itemClick: (i) => setForceItemIndex(i),
        newObject: {
          componentId: "Input",
          description: "A field description can go here",
          placeholder: "A placeholder can go here",
        },
        fields: [
          {
            key: "key",
            label: "Field ID",
            componentId: "Input",
            hint: "This ID is used to reference the field in the submit action form variable",
          },
          {
            key: "label",
            label: "Label",
            componentId: "Input",
          },
          {
            key: "description",
            label: "Description",
            componentId: "Input",
          },
          {
            key: "placeholder",
            label: "Placeholder",
            componentId: "Input",
          },
          {
            id: "componentId",
            label: "Field Type",
            hint: "Define how the column should be displayed visually in forms and other blocks",
            componentId: "Select",
            required: true,
            hideEmptyItem: true,
            defaultValue: "Input",
            options: [
              { label: "Input", value: "Input" },
              { label: "Options", value: "Options" },
              { label: "Text Area", value: "TextArea" },
              { label: "Date", value: "DateTimePicker" },
              { label: "Image", value: "ImageUpload" },
              { label: "File Upload", value: "FileUpload" },
              // { label: "Markdown", value: "MarkdownEditor" },
              // { label: "Select", value: "Select" },
              // { label: "MultiSelect", value: "MultiSelect" },
              // { label: "Switch", value: "Switch" },
              // { label: "Checkbox", value: "Checkbox" },
              // { label: "Time", value: "TimePicker" },
              // { label: "Hidden", value: "Hidden" },
            ],
          },
          {
            id: "isMulti",
            label: "Allow Multiple Selections",
            componentId: "Switch",
            displayCondition: (f) =>
              ["Options"].includes(get(f, "componentId")),
          },
          {
            id: "options",
            label: "Options",
            componentId: "DataGrid",
            newObject: { label: "New Option", value: "New Option" },
            columns: [
              {
                key: "label",
                componentId: "Input",
              },
              {
                key: "value",
                componentId: "Input",
              },
            ],
            displayCondition: (f) =>
              ["Options"].includes(get(f, "componentId")),
          },
          {
            id: "validation",
            label: "Validation",
            componentId: "FormValidation",
            customOnChange: true,
          },
          {
            id: "displayConditions",
            label: "Display Conditions",
            componentId: "DisplayConditions",
            orientation: "vertical",
          },
        ],
      },
      {
        id: "makeDefaultPage",
        label: "Make Default Page",
        componentId: "Switch",
        hint: "Make this form the default page for your app. This will override the 'default page' setting.",
        value: config.makeDefaultPage,
        orientation: "horizontal",
        section: "Access",
      },
      {
        id: "requireLogin",
        label: "Require Login",
        componentId: "Switch",
        value: config.requireLogin,
        orientation: "horizontal",
        section: "Access",
      },
      {
        id: "published",
        label: "Published",
        componentId: "Switch",
        value: config.published,
        orientation: "horizontal",
        section: "Access",
      },
      {
        id: "displayConditions",
        label: "Display Conditions",
        componentId: "DisplayConditions",
        value: config.displayConditions,
        orientation: "vertical",
        section: "Access",
      },
      {
        id: "submitAction",
        label: "Submit Action",
        componentId: "Action",
        hint: "An action triggered when the user reaches the completion page",
        isAction: true,
        customAction: get(config, "submitAction"),
        section: "Actions",
        customOnChange: (newAction) => {
          setConfig({
            ...config,
            submitAction: {
              ...get(config, "submitAction"),
              steps: newAction,
            },
          });
          setChanges(true);
        },
      },
      {
        id: "completeButtonAction",
        label: "Complete Button Action",
        componentId: "Action",
        hint: "An action triggered when the user clicks the button on the Completion page. Often used to navigate to another page or URL.",
        isAction: true,
        customAction: get(config, "completeButtonAction"),
        section: "Actions",
        customOnChange: (newAction) => {
          setConfig({
            ...config,
            completeButtonAction: {
              ...get(config, "completeButtonAction"),
              steps: newAction,
            },
          });
          setChanges(true);
        },
      },
    ];
  }

  if (tab === "text") {
    activeFields = [
      {
        id: "completeMessage",
        label: "Complete Message",
        componentId: "Input",
        section: "Completion Page",
        value: config.completeMessage,
      },
      {
        id: "completeDescription",
        label: "Complete Description",
        componentId: "Input",
        section: "Completion Page",
        value: config.completeDescription,
      },
      {
        id: "completeButtonText",
        label: "Complete Button Text",
        componentId: "Input",
        section: "Completion Page",
        sectionHint:
          "The final page after a user has completed all the form fields",
        value: config.completeButtonText,
      },
      {
        id: "pressEnterText",
        label: "Press Enter Text",
        componentId: "Input",
        section: "Other",
        hint: "Overwrite the 'Press enter' text in the form",
        value: config.pressEnterText,
      },
      {
        id: "nextButtonText",
        label: "Next Button Text",
        componentId: "Input",
        section: "Other",
        hint: "Overwrite the 'OK' text on the next button in the form",
        value: config.nextButtonText,
      },
    ];
  }

  if (tab === "styles") {
    activeFields = [
      {
        id: "showLogo",
        label: "Show Logo",
        hint: "Display your 'Navigation Logo' from your app settings in the top left of your form",
        componentId: "Switch",
        value: config.showLogo,
        orientation: "horizontal",
      },
      {
        id: "logo",
        label: "Logo",
        componentId: "ImageUpload",
        value: config.logo,
        orientation: "horizontal",
      },
      {
        id: "backgroundColor",
        label: "Background Color",
        sectionHint: "Change the background color of your form",
        componentId: "ColorPicker",
        value: config.backgroundColor,
        orientation: "horizontal",
      },
      {
        id: "textColor",
        label: "Text Color",
        componentId: "Select",
        defaultValue: "light",
        hideEmptyItem: true,
        options: [
          { label: "Light", value: "light" },
          { label: "Dark", value: "dark" },
        ],
        value: config.textColor,
        orientation: "horizontal",
        width: "120px",
      },
      {
        id: "buttonTextColor",
        label: "Button Text Color",
        componentId: "ColorPicker",
        value: config.buttonTextColor,
        orientation: "horizontal",
      },
      {
        id: "buttonColor",
        label: "Button Color",
        componentId: "ColorPicker",
        value: config.buttonColor,
        orientation: "horizontal",
      },
    ];
  }

  return (
    <div>
      {activeActionObject && <EditActionStepModal obj={activeActionObject} />}
      <Sidebar>
        <Row
          alignItems="center"
          justifyContent="space-between"
          margin="0 0 0 0"
        >
          <BackButton
            width="auto"
            text="Edit Form"
            onClick={() => navigate("/forms")}
            margin="0px"
          />
          {changes ? (
            <Button
              data={{
                text: "Save",
                onClick: saveForm,
                isFetching: isSaving,
              }}
            />
          ) : (
            <Button
              data={{
                text: "Preview",
                type: "basic",
                onClick: () => {
                  mixpanel.track("Preview Form");
                  openPageInNewTab({
                    page: config,
                    app,
                    token: Cookies.get("accessToken"),
                  });
                },
              }}
            />
          )}
        </Row>

        <Tabs
          data={{
            margin: "15px 0 0 0",
            tabs: [
              {
                label: "Basics",
                active: tab === "basics",
                onClick: () => setTab("basics"),
              },
              {
                label: "Styles",
                active: tab === "styles",
                onClick: () => setTab("styles"),
              },
              {
                label: "Text",
                active: tab === "text",
                onClick: () => setTab("text"),
              },
            ],
          }}
        />

        <ScrollContainer>
          <AdminForm
            sectionPadding={"20px 0px 20px 0px"}
            borderBottom
            onChange={(k, v) => {
              setConfig({ ...config, [k]: v });
              setChanges(true);
            }}
            fields={activeFields}
          />
        </ScrollContainer>
      </Sidebar>
      <FullScreenForm
        data={config}
        editMode
        backgroundColor={config.backgroundColor}
        forceStep={forceItemIndex}
        setForceStep={setForceItemIndex}
      />
    </div>
  );
};

export default FormEditor;

const ScrollContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 60px;
`;

const Sidebar = styled.div`
  min-width: 300px;
  max-width: 300px;
  background: white;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 20px;
  z-index: 999;
  border-right: 1px solid #e5e5e5;
`;
