import { getFieldStyles, safeParseFloatOrInt } from "app/utils/utils";

import { fontObject } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const Input = ({ data }) => {
  const { onChange } = data;

  const fontStyle = get(data, "fontStyle", "bodyMd");
  const styleObject = get(fontObject, fontStyle);
  const validTypes = ["text", "number", "password", "email"];

  let backgroundColor = data.backgroundColor || data.background;
  return (
    <StyledInput
      onKeyDown={(e) => e.stopPropagation()}
      {...styleObject}
      {...data}
      type={validTypes.includes(data.type) ? data.type : "text"}
      background={backgroundColor}
      width={data.width || "100%"}
      autoComplete="new-password"
      onChange={(e) => {
        let newValue = e.target.value;
        // If the input is a number and has a max value, make sure it doesn't exceed the max value
        if (data.type === "number" && data.max) {
          if (safeParseFloatOrInt(newValue) > data.max) {
            newValue = data.max;
          }
        }

        onChange(newValue);
      }}
    />
  );
};

export default Input;

const StyledInput = styled.input`
  ${(p) => getFieldStyles(p)};
  text-align: left;
  min-width: 50px;
  &:focus {
    outline: none;
  }

  ${(p) =>
    p.placeholderColor &&
    `
  ::placeholder {
    color: ${p.placeholderColor};
  }
  
  ::-ms-input-placeholder { /* Edge 12 -18 */
  color: ${p.placeholderColor};
  }
  `}
`;
