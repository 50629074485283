import {
  getHighest,
  isFrontlyAdmin,
  migrateConditionFormat,
  passesCondition,
} from "app/utils/utils";

import { get } from "lodash";
import { getViewLabel } from "app/renderPage/utils";
import memoize from "lodash/memoize";
import { rAppDateFormat } from "app/utils/recoil";
import stableStringify from "json-stable-stringify";
import useDynamicText from "./useDynamicText";
import useModalStateData from "app/useModalStateData";
import { useRecoilValue } from "recoil";

const useUtils = () => {
  const { processDynamicText } = useDynamicText();

  const { modalStack, setModalStack } = useModalStateData();

  const appDateFormat = useRecoilValue(rAppDateFormat);

  const addModalToStack = (context) => {
    const newStackItemId = getHighest(modalStack, "id") + 1;
    const newItem = { id: newStackItemId, ...context };
    const existingStack = modalStack.map((item) => {
      return {
        ...item,
        label: processDynamicText({ text: get(item, "label") }),
      };
    });

    setModalStack([...existingStack, newItem]);
    return newStackItemId;
  };

  const recordClick = (block, item, actionResolver) => {
    // Skip in setup mode
    if (isFrontlyAdmin) {
      return null;
    }

    let clickAction = get(block, "recordClickAction");
    const validClickActionTypes = ["default", "custom", "action", "none"];
    if (!validClickActionTypes.includes(clickAction)) {
      clickAction = "default";
    }

    if (["default", "custom"].includes(clickAction)) {
      const viewLabel = getViewLabel(block);
      addModalToStack({
        label: viewLabel,
        blockId: block.id,
        itemId: item.frontly_id,
        type: "edit",
        detailViewMode: block.detailViewMode,
        recordClickAction: clickAction,
      });
    } else if (clickAction === "action") {
      actionResolver({
        actionId: get(block, "customRecordClickAction"),
        context: { record: item },
        reusableBlockId: block.reusableBlockId,
        blockId: block.id,
      });
    } else {
      // NO ACTION
    }
  };

  // Create a memoized version of your function.
  // The resolver function generates a cache key based on the properties that affect the output.
  const memoizedPassesDisplayConditions = memoize(
    (data) => _passesDisplayConditions(data, processDynamicText, appDateFormat),
    (data) => {
      const { conditions, context, reusableBlockId, showInAdmin } = data;
      return stableStringify({
        conditions,
        context,
        reusableBlockId,
        showInAdmin,
        appDateFormat,
      });
    }
  );

  return {
    processDynamicText,
    addModalToStack,
    recordClick,
    passesDisplayConditions: memoizedPassesDisplayConditions,
  };
};

export default useUtils;

// Define your function that computes the result.
// Make sure this function is defined outside of any component so that its cache is shared.
const _passesDisplayConditions = (data, processDynamicText, appDateFormat) => {
  const { conditions, context, reusableBlockId, showInAdmin } = data;

  // Early exit if there's nothing to check.
  if (
    (isFrontlyAdmin && !showInAdmin) ||
    !conditions ||
    conditions.length === 0
  ) {
    return true;
  }

  // Migrate each condition to the expected format.
  const migratedConditions = conditions.map((c) => migrateConditionFormat(c));

  // Determine if there is at least one "or" condition.
  const orConditions =
    migratedConditions.filter((c) => c.conditionType === "or").length > 0;

  // Filter out incomplete conditions and compute whether each condition passes.
  const results = migratedConditions
    .filter((condition) => {
      // Skip if condition is incomplete.
      return !!condition.value1;
    })
    .map((condition) => {
      const { value1, value2 } = condition;

      const resolvedValue1 = processDynamicText({
        text: value1,
        context,
        reusableBlockId,
      });

      const resolvedValue2 = processDynamicText({
        text: value2,
        context,
        reusableBlockId,
      });

      // Evaluate the condition.
      return passesCondition({
        value1: resolvedValue1,
        value2: resolvedValue2,
        operator: get(condition, "operator", "equals"),
        value1DateFormat: appDateFormat,
      });
    });

  // If any of the conditions are "or", return true if any result is true.
  if (orConditions) {
    return results.includes(true);
  }

  // Otherwise, return true only if all conditions passed.
  return !results.includes(false);
};
