import {
  Button,
  Column,
  Hint,
  Icon,
  Input,
  Row,
  Select,
  Switch,
  Text,
} from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isNil, startCase } from "lodash";
import { getHighest, safeString } from "app/utils/utils";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { SpreadsheetSelect } from "../components";
import { apiRequest } from "app/utils/apiRequests";
import styled from "styled-components";
import { useState } from "react";

const RelationalData = () => {
  const [app, setApp] = useRecoilState(rApp);

  const relations = get(app, "data_relations", []) || [];

  const [changes, setChanges] = useState(false);

  const saveRelations = () => {
    const emptyRelationIds = relations.filter((r) => {
      let error = false;
      ["column1", "column2", "sheet1", "sheet2"].forEach((k) => {
        if (isNil(r[k]) || r[k] === "") {
          error = true;
        }
      });

      return error;
    });

    if (emptyRelationIds.length > 0) {
      errorNotification("Please fill out all fields for each data relation");
      return;
    } else {
      apiRequest.post("/app_settings/", {
        data_relations: relations,
      });
      successNotification("Saved");
    }
  };

  const setRelations = (v) => {
    setChanges(true);
    setApp({ data_relations: v });
  };

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const deleteRelation = (id) => {
    setRelations(relations.filter((r) => r.id !== id));
  };

  const updateRelation = (id, field, value) => {
    setRelations(
      relations.map((r) => {
        if (r.id === id) {
          return { ...r, [field]: value };
        }
        return r;
      })
    );
  };

  return (
    <>
      <Row
        gap="5px"
        margin="0 0 20px 0"
        alignItems="center"
        justifyContent="space-between"
      >
        <Row gap="5px">
          <Text
            data={{
              text: "Create conceptual links between different data sources (advanced).",
            }}
          />
          <Text
            data={{
              text: "Read the article on Relational Data.",
              color: "var(--primary-admin)",
              onClick: () =>
                window.open(
                  "https://help.frontly.ai/en/articles/8022597-using-relational-data"
                ),
            }}
          />
        </Row>
        {changes && (
          <Button data={{ text: "Save Changes", onClick: saveRelations }} />
        )}
      </Row>

      <Column gap="25px">
        {relations.map((r, i) => (
          <Relationship>
            <Header>
              <Text
                data={{
                  text: `Relationship ${i + 1}`,
                  fontStyle: "headingMd",
                }}
              />
              <Icon
                data={{
                  icon: "FiTrash",
                  onClick: () => deleteRelation(r.id),
                  size: 20,
                  color: "var(--grey7)",
                  hover: true,
                }}
              />
            </Header>
            <Section>
              <Row alignItems="center" gap="10px">
                <Text data={{ text: "", width: "90px" }} />
                <Row style={{ width: "200px" }} alignItems="center" gap="3px">
                  <Text
                    data={{
                      text: "Parent",
                      fontStyle: "headingMd",
                    }}
                  />
                  <Hint
                    margin={"4px 0 0 0"}
                    hint={
                      "The parent table is the one that conceptually 'owns' the child table. For example, if you have a table of 'Users' and a table of 'Orders', the 'Users' table would be the parent table, because a User may have multiple orders."
                    }
                  />
                </Row>
                <Row style={{ width: "200px" }} alignItems="center" gap="3px">
                  <Text
                    data={{
                      text: "Child",
                      fontStyle: "headingMd",
                    }}
                  />
                  <Hint
                    margin={"4px 0 0 0"}
                    hint={
                      "The child table is the one that conceptually 'belongs' to the parent table. For example, if you have a table of 'Users' and a table of 'Orders', the 'Orders' table would be the child table, because a User may have multiple Orders."
                    }
                  />
                </Row>
              </Row>
              <Row alignItems="center" gap="10px">
                <Text data={{ text: "Data Sources", width: "90px" }} />
                <SpreadsheetSelect
                  data={{
                    value: r.sheet1,
                    hideSheetId: r.sheet2,
                    preventExtras: true,
                    hideLinks: true,
                    wizard: false,
                    width: "200px",
                    onChange: (v) => updateRelation(r.id, "sheet1", v),
                  }}
                />
                <SpreadsheetSelect
                  data={{
                    value: r.sheet2,
                    hideSheetId: r.sheet1,
                    hideLinks: true,
                    width: "200px",
                    onChange: (v) => updateRelation(r.id, "sheet2", v),
                  }}
                />
              </Row>
              <Row alignItems="center" gap="10px">
                <Text data={{ text: "Columns", width: "90px" }} />
                <Select
                  data={{
                    label: "Column 1",
                    value: r.column1,
                    width: "200px",
                    noneText: "Select a column",
                    options: get(
                      savedSpreadsheets.find((s) => s.id === r.sheet1),
                      "headers",
                      []
                    ).map((h) => ({
                      label: startCase(h),
                      value: h,
                    })),
                    onChange: (v) => updateRelation(r.id, "column1", v),
                  }}
                />
                <Select
                  data={{
                    label: "Column 2",
                    value: r.column2,
                    width: "200px",
                    noneText: "Select a column",
                    options: get(
                      savedSpreadsheets.find((s) => s.id === r.sheet2),
                      "headers",
                      []
                    ).map((h) => ({
                      label: startCase(h),
                      value: h,
                    })),
                    onChange: (v) => updateRelation(r.id, "column2", v),
                  }}
                />
              </Row>
              {r.relationId && (
                <Row alignItems="center" gap="10px">
                  <Text data={{ text: "Custom ID", width: "90px" }} />
                  <Input
                    data={{
                      label: "Relation ID",
                      value: r.relationId,
                      width: "150px",
                      onChange: (v) => updateRelation(r.id, "relationId", v),
                    }}
                  />
                </Row>
              )}
              <Row alignItems="center" gap="10px">
                <Text data={{ text: "Display Name", width: "90px" }} />
                <Select
                  data={{
                    label: "Display Name",
                    value: r.display_column,
                    width: "200px",
                    hideEmptyItem: true,
                    options: get(
                      savedSpreadsheets.find((s) => s.id === r.sheet1),
                      "headers",
                      []
                    ).map((h) => ({
                      label: startCase(h),
                      value: h,
                    })),
                    onChange: (v) => updateRelation(r.id, "display_column", v),
                  }}
                />
              </Row>
              {safeString(get(r, "sheet1", "")).includes("db__") && (
                <Row alignItems="center" gap="10px">
                  <Text
                    data={{ text: "Inner Join (Advanced)", width: "150px" }}
                  />
                  <Switch
                    data={{
                      value: r.inner_join,
                      onChange: (v) => updateRelation(r.id, "inner_join", v),
                    }}
                  />
                </Row>
              )}
            </Section>
          </Relationship>
        ))}
      </Column>
      <Button
        data={{
          text: "Add Relation",
          icon: "FiPlus",
          type: "basic",
          margin: "15px 0 0 0",
          onClick: () =>
            setRelations([
              ...relations,
              { id: getHighest(relations, "id") + 1 },
            ]),
        }}
      />
    </>
  );
};

export default RelationalData;

const Relationship = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey3);
  border-radius: 12px;
  overflow: hidden;
  background: white;
  max-width: 600px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 10px;
  border-bottom: 1px solid var(--grey21);
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
`;
