import { Button, Modal, Row, Text } from "app/components";
import { get, startCase } from "lodash";
import { rApp, rUser } from "app/utils/recoil";

import { dataSourceIconMap } from "../spreadsheets/utils";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const AppCreatedEmailModal = ({ hide, action = "created" }) => {
  const user = useRecoilValue(rUser);
  return (
    <Modal
      header={{ title: `Your App Is Being ${startCase(action)}` }}
      hide={hide}
    >
      <Text
        data={{
          text: `This process can take up to a few minutes.`,
          fontSize: 20,
          fontWeight: 300,
        }}
      />

      <Text
        data={{
          text: `You will receive an email at ${user.email} with the app link once it is ready.`,
          fontSize: 20,
          fontWeight: 300,
          margin: "20px 0 0 0",
        }}
      />

      <Text
        data={{
          text: "You can safely close this window now.",
          fontSize: 20,
          fontWeight: 300,
          margin: "20px 0 0 0",
        }}
      />

      <Button
        data={{
          text: "Close Window",
          onClick: hide,
          margin: "20px 0 0 0",
        }}
      />
    </Modal>
  );
};

export default AppCreatedEmailModal;

export const CreateNewAppModal = ({ hide, onClick, title }) => {
  const [dataService, setDataService] = useState(null);

  const app = useRecoilValue(rApp);
  const googleConnected = get(app, "google_sheets_connected");

  const labelMap = {
    frontly_db: "Frontly Database",
    google: "Google Sheets",
  };

  return (
    <Modal
      hide={hide}
      minWidth="550px"
      header={{
        title,
      }}
    >
      {!dataService && (
        <div>
          <MessageText style={{ marginBottom: "20px" }}>
            Select your preferred data source:
          </MessageText>

          <Row gap={"15px"} margin="10px 0 0 0">
            <ServiceOption onClick={() => setDataService("frontly_db")}>
              <ServiceIcon src={get(dataSourceIconMap, "frontly_db")} />
              <ServiceText>Frontly Database</ServiceText>
            </ServiceOption>

            {googleConnected && (
              <ServiceOption onClick={() => setDataService("google")}>
                <ServiceIcon src={get(dataSourceIconMap, "google")} />
                <ServiceText>Google Sheets</ServiceText>
              </ServiceOption>
            )}
          </Row>
        </div>
      )}
      {dataService && (
        <div>
          <MessageText style={{ marginBottom: "20px" }}>
            Using <strong>{labelMap[dataService]}</strong> as your data source.
          </MessageText>

          <MessageText style={{ marginBottom: "20px" }}>
            Do you want to create a new app, or copy the content into your
            current app?
          </MessageText>

          <Row gap={"15px"} margin="10px 0 0 0">
            <Button
              data={{
                text: "Create New App",
                onClick: () => onClick("new", dataService),
                size: "large",
                icon: "FiPlus",
              }}
            />
            <Button
              data={{
                text: "Copy Content Into Current App",
                onClick: () => onClick("current", dataService),
                size: "large",
                icon: "FiCopy",
                type: "secondary",
              }}
            />
          </Row>
        </div>
      )}
    </Modal>
  );
};

const ServiceOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--grey3);
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: var(--primary);
  }
`;

const ServiceIcon = styled.img`
  height: 25px;
`;

const ServiceText = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const MessageText = styled.div`
  font-size: 18px;
  font-weight: 300;
`;
