import { Button, Icon, Row, SearchBar, Text } from "app/components";
import { rApp, rSubscription } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AdminWrapper from "../home/AdminWrapper";
import Advanced from "./Advanced";
import ApiSettings from "./ApiSettings";
import Billing from "../billing";
import ContentLibrary from "./ContentLibrary";
import CustomBlocks from "./CustomBlocks";
import CustomFields from "../users/CustomFields";
import CustomVariables from "./CustomVariables";
import Domains from "./Domains";
import EditProfileSettings from "../users/EditProfileSettings";
import ErrorLog from "./ErrorLog";
import General from "./General";
import Integrations from "./Integrations";
import Navigation from "./Navigation";
import Password from "./Password";
import SignupSettings from "../users/SignupSettings";
import Styling from "./Styling";
import Translations from "./Translations";
import UserGroups from "../users/UserGroups";
import { get } from "lodash";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const Settings = () => {
  const params = useParams();

  const { id } = params;

  const app = useRecoilValue(rApp);

  const subscription = useRecoilValue(rSubscription);
  const isPaid = get(subscription, "plan");

  const sections = [
    {
      label: "App Settings",
      description: `Manage basic settings that apply to the current app (${get(
        app,
        "name"
      )})`,
      color: "#c3e2f7",
      pages: [
        {
          id: "general",
          label: "General",
          description: "Manage basic app settings like name and subdomain",
          component: General,
          icon: "FiSettings",
        },
        {
          id: "navigation",
          label: "Navigation",
          description: "Configure your app's header or sidebar navigation menu",
          component: Navigation,
          icon: "FiCompass",
        },
        {
          id: "styling",
          label: "Styling",
          description:
            "Customize your app's visual appearance, colors and default spacing, etc.",
          component: Styling,
          icon: "FiEdit",
        },
        {
          id: "integrations",
          label: "Integrations",
          description:
            "Manage your integrations with third-party services like OpenAI, Mailgun, etc",
          component: Integrations,
          icon: "FiGitPullRequest",
        },
        {
          id: "translations",
          label: "Translations",
          description:
            "Translate public-facing text in your app to another language or wording",
          component: Translations,
          icon: "FiType",
        },
        {
          id: "domains",
          label: "Custom Domain",
          description:
            "Add a custom website domain to your app, like yourwebsite.com",
          component: Domains,
          icon: "FiGlobe",
          upgradeRequired: !isPaid,
        },
      ],
    },
    {
      label: "User Settings",
      description: "Manage your own user account settings",
      color: "#c1f2cd",
      pages: [
        {
          id: "signupSettings",
          label: "Login & Signup",
          description:
            "Manage login and signup settings, including custom actions and default user group",
          component: SignupSettings,
          icon: "FiSettings",
        },
        {
          id: "userGroups",
          label: "User Groups",
          description:
            "Manage user groups to control access to your app pages and data",
          component: UserGroups,
          icon: "FiGrid",
        },
        {
          id: "customFields",
          label: "Custom Fields",
          description:
            "Create custom fields to store additional information about your users",
          component: CustomFields,
          icon: "FiList",
        },
        // MERGE into user settings somewhere
        {
          id: "editProfile",
          label: "Profile Editing",
          description:
            "Manage profile editing settings, including custom fields and user group access",
          component: EditProfileSettings,
          icon: "FiEdit",
        },
        {
          id: "password",
          label: "Reset Password",
          description:
            "Reset your own user profile password for logging into Frontly",
          component: Password,
          icon: "FiKey",
        },
      ],
    },
    {
      label: "Advanced App Settings",
      description: `Manage advanced settings that apply to the current app (${get(
        app,
        "name"
      )})`,
      color: "#eecdfa",
      pages: [
        {
          id: "advanced",
          label: "Advanced",
          component: Advanced,
          description: "Manage advanced settings that apply to the current app",
          icon: "FiSliders",
        },
        {
          id: "customVariables",
          label: "Custom Variables",
          description:
            "Create custom variables that fetch data from your data sources in sophisticated ways",
          component: CustomVariables,
          icon: "FiBox",
        },
        {
          id: "customBlocks",
          label: "Custom Blocks",
          description:
            "Create custom blocks to use throughout your app for common configurations",
          component: CustomBlocks,
          icon: "FiTool",
          upgradeRequired: !isPaid,
        },
        {
          id: "contentLibrary",
          label: "Content Library",
          description:
            "Create text snippets to use throughout your app as variables. Generally used in AI prompts.",
          component: ContentLibrary,
          icon: "FiBook",
        },
        {
          id: "errors",
          label: "Error Log",
          description:
            "View and manage errors that occur in your app to help you debug and fix issues",
          component: ErrorLog,
          icon: "FiAlertTriangle",
        },
      ],
    },
    {
      label: "Organization Settings",
      description:
        "Manage settings that apply to all apps in your organization",
      color: "#fad0df",
      pages: [
        {
          id: "api",
          label: "API Settings",
          description:
            "Manage your app's API settings to allow third-party services to interact with your app",
          component: ApiSettings,
          icon: "FiCode",
          upgradeRequired: !isPaid,
        },
        {
          id: "billing",
          label: "Billing",
          description:
            "Manage your organization's billing settings and subscription plan",
          component: Billing,
          icon: "FiCreditCard",
        },
      ],
    },
  ];

  // Unused colors
  // "#c0f0f0", "#ffc5b2"

  const allPages = sections.flatMap((s) => s.pages);

  const currentPage = allPages.find((p) => p.id === id);

  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch("");
  }, [id]);

  const filteredSections = sections
    .map((section) => {
      const filteredPages = section.pages.filter(
        (page) =>
          page.label.toLowerCase().includes(search.toLowerCase()) ||
          page.description.toLowerCase().includes(search.toLowerCase())
      );
      return { ...section, pages: filteredPages };
    })
    .filter((section) => section.pages.length > 0);

  if (currentPage) {
    const Component = get(currentPage, "component");
    return (
      <AdminWrapper>
        <Component />
      </AdminWrapper>
    );
  }

  return (
    <AdminWrapper>
      <FloatingSearch>
        <SearchBar
          data={{
            value: search,
            placeholder: "Search settings",
            onChange: (v) => {
              setSearch(v);
            },
          }}
        />
      </FloatingSearch>
      <SettingsOptions>
        {filteredSections.map((s) => (
          <SectionContainer key={s.label}>
            <Text data={{ text: s.label, fontSize: 24, fontWeight: 600 }} />
            <Text
              data={{
                text: s.description,
                fontSize: 18,
                fontWeight: 400,
                color: "var(--grey6)",
                margin: "10px 0 25px 0",
              }}
            />

            <PagesGrid pageCount={get(s, "pages", []).length}>
              {get(s, "pages", []).map((p, index) => (
                <RenderOption key={index} option={p} sectionColor={s.color} />
              ))}
            </PagesGrid>
          </SectionContainer>
        ))}
      </SettingsOptions>
      <Text
        data={{
          text: "Frontly Version 1.0.79",
          fontSize: 18,
          fontWeight: 300,
          color: "var(--grey6)",
          margin: "30px 0 0 0",
        }}
      />
    </AdminWrapper>
  );
};

export default Settings;

const RenderOption = ({ option, sectionColor }) => {
  const navigate = useNavigate();

  return (
    <SettingsPage onClick={() => navigate(`/settings/${option.id}`)}>
      <Row gap="15px">
        <IconWrapper color={sectionColor}>
          <Icon data={{ icon: option.icon, size: 20, hover: true }} />
        </IconWrapper>
        <div>
          <Text
            data={{
              text: option.label,
              fontSize: 16,
              fontWeight: 600,
              cursor: "pointer",
              margin: "0 0 5px 0",
            }}
          />
          <Text
            data={{
              text: option.description,
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
              color: "var(--grey6)",
            }}
          />
        </div>
      </Row>
      {option.upgradeRequired && (
        <Button
          data={{
            text: "Paid Feature",
            // icon: "FiUploadCloud",
            size: "small",
          }}
        />
      )}
    </SettingsPage>
  );
};

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SettingsOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const PagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  ${({ pageCount }) =>
    pageCount < 3 &&
    `
      grid-template-columns: calc(100% / 3 - 10px) calc(100% / 3 - 10px);
    `}

  @media (max-width: 768px) {
    grid-template-columns: 300px;
  }
`;

const SettingsPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: var(--card-shadow);
  &:hover {
    box-shadow: var(--card-shadow-hover);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color};
  padding: 10px;
  border-radius: 10px;
`;

const FloatingSearch = styled.div`
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 1000;
`;
