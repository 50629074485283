import { Button, Column, Menu, Row } from "app/components";
import { debounce, get, startCase } from "lodash";
import { getUniqueValues, safeArray } from "app/utils/utils";
import {
  rApp,
  rDarkMode,
  rFilters,
  rLiveSpreadsheets,
  rSavedSpreadsheets,
  rTranslations,
  refreshBlockIdsSelector,
} from "app/utils/recoil";
import { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import NewFilter from "app/components/NewFilter";
import { colors } from "app/utils/theme";
import { getRelationColumnsFromBlock } from "../blocks/Form/relationUtils";
import { sortRecords } from "../blocks/Table";
import useDynamicText from "../useDynamicText";
import useListData from "app/useListData";

const VisibleFilters = ({ block, filters, collapseSearch }) => {
  const setRefreshBlockIds = useSetRecoilState(refreshBlockIdsSelector);

  const debouncedSearch = useCallback(
    debounce(async (v) => {
      // if (v.length > 1) {
      setRefreshBlockIds([block.id]);
      // }
    }, 300), // Adjust the delay time as needed
    []
  );

  const [filterState, setFilterState] = useRecoilState(rFilters);

  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const { processDynamicText } = useDynamicText();

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const app = useRecoilValue(rApp);

  const dataRelations = safeArray(app, "data_relations");

  const styling = get(app, "styling", {});
  const formInputBorderRadius = get(styling, "formInputRadius");
  const formInputBorderColor = get(styling, "formInputBorderColor");

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const matchingSheet = savedSpreadsheets.find(
    (s) => s.id === get(block, "spreadsheet")
  );
  const service = get(matchingSheet, "service");

  const isDataBase = ["supabase", "airtable", "frontly_db"].includes(service);

  const { relationColumns } = getRelationColumnsFromBlock({
    block,
    app,
    spreadsheets,
  });

  const rows = getListData(block);

  const getDynamicOptions = (f) => {
    // Use Data Source Options
    const useDataSourceOptions = get(f, "useDataSourceOptions", false);
    if (useDataSourceOptions) {
      // Current Sheet
      let dsOptions = get(
        matchingSheet,
        ["field_data", "config", f.key, "options"],
        []
      );

      try {
        // If related field, get options from related sheet
        if (f.key.includes("__")) {
          const keySplit = f.key.split("__")[1];

          dataRelations.forEach((r) => {
            if (r.sheet2 === matchingSheet.id) {
              const relatedSheet = savedSpreadsheets.find(
                (s) => s.id === r.sheet1
              );
              const relatedHeaders = get(relatedSheet, "headers", []);

              if (relatedHeaders.includes(keySplit)) {
                dsOptions = get(
                  relatedSheet,
                  ["field_data", "config", keySplit, "options"],
                  []
                );
                return dsOptions;
              }
            }
          });
        }
      } catch (e) {
        console.log("ERROR GETTING DYNAMIC OPTIONS", e);
      }

      return dsOptions;
    }

    // HANDLE RELATIONAL DATA HERE
    const matchingRelationColumn = get(relationColumns, f.key);
    if (matchingRelationColumn) {
      return matchingRelationColumn;
    }

    // User-Defined Options
    const userDefinedOptions = safeArray(f, "options");

    // Unique Options from Data
    const uniqueOptionsFromData = get(f, "preventAutoGeneratedOptions", false)
      ? []
      : [
          ...getUniqueValues(rows, f.key, f.componentId)
            .filter((v) => v && v)
            .filter((v) => !userDefinedOptions.find((o) => o.value === v))
            .map((d) => ({
              label: d,
              value: d,
            })),
        ];

    // HANDLE NORMAL CASE
    return [...userDefinedOptions, ...uniqueOptionsFromData];
  };

  const [filterMenuAnchor, setFilterMenuAnchor] = useState(false);

  let filterCount = filters.length;

  // No Visible Filters
  if (filterCount === 0 && !get(block, "showSearch")) {
    // This just acts as a spacer
    return <div style={{ flex: 1 }}></div>;
  }

  const blockFilters = get(filterState, block.id);

  let collapsedFilters = [];
  let displayedFilters = [];

  filters.forEach((f) => {
    let selectOptions = ["Select", "MultiSelect"].includes(f.componentId)
      ? getDynamicOptions(f)
      : [];

    const selectSorting = get(f, "selectSorting");
    if (selectSorting) {
      selectOptions = sortRecords(selectOptions, "value", selectSorting);
    }

    const comp = (
      <NewFilter
        data={{
          ...f,
          label: get(f, "label", startCase(f.key)),
          options: selectOptions,
          theme: get(block, "visibleFiltersTheme", "white"),
          value: processDynamicText({
            text: get(blockFilters, f.key),
          }),
          borderRadius: formInputBorderRadius,
          blockSpreadsheetId: block.spreadsheet,
          border: `1px solid ${formInputBorderColor || colors.pitchBorder}`,
          color: darkMode && "white",
          background: darkMode && colors.darkModeInputBackground,
          onChange: (v) => {
            setFilterState({
              ...filterState,
              [block.id]: {
                ...blockFilters,
                [f.key]: v,
              },
            });

            // Only if this is a supabase block, run the query to the back-end
            if (isDataBase) {
              debouncedSearch(v);
            }
          },
        }}
      />
    );

    if (f.inFilterDropdown) {
      collapsedFilters.push(comp);
    } else {
      displayedFilters.push(comp);
    }
  });

  // Configure Search Bar
  let searchBar = null;
  if (block.spreadsheet && block.showSearch && !isDataBase) {
    searchBar = (
      <NewFilter
        data={{
          // STYLE STUFF
          width: "140px",
          showLabel: false,
          theme: get(block, "visibleFiltersTheme", "white"),
          border: `1px solid ${formInputBorderColor || colors.pitchBorder}`,
          color: darkMode && "white",
          background: darkMode && colors.darkModeInputBackground,

          // OTHER
          icon: "FiSearch",
          componentId: "Input",
          displayValue: null,
          label: null,
          placeholder: get(translations, "searchText", "Search"),
          operator: "equals",
          value: get(blockFilters, "search"),
          blockSpreadsheetId: block.spreadsheet,

          onChange: (v) =>
            setFilterState({
              ...filterState,
              [block.id]: {
                ...blockFilters,
                search: v,
              },
            }),
        }}
      />
    );
  }

  // Render Visible Filters
  return (
    <Row
      gap="15px"
      style={{
        width: "fit-content",
        flex: 1,
        justifyContent: "flex-end",
      }}
    >
      {filterMenuAnchor && (
        <Menu
          label={get(translations, "filtersText", "Filters")}
          anchorElement={filterMenuAnchor}
          hide={() => setFilterMenuAnchor(null)}
          darkMode={darkMode}
        >
          <Column gap="10px">
            {collapseSearch && searchBar ? searchBar : null}
            {collapsedFilters}
          </Column>
        </Menu>
      )}

      {!collapseSearch && searchBar !== null ? searchBar : null}

      {displayedFilters}

      {(collapseSearch || collapsedFilters.length > 0) && (
        <Button
          data={{
            icon: "FiFilter",
            type: "basic",
            height: "38px",
            backgroundColor: darkMode
              ? colors.darkModeInputBackground
              : "white",
            border: darkMode ? `1px solid ${colors.darkModeLightBorder}` : null,
            onClick: (e) => setFilterMenuAnchor(e.currentTarget),
          }}
        />
      )}
    </Row>
  );
};

export default VisibleFilters;
