import { Icon, Modal, Row, SearchBar, Text } from "app/components";
import { rApp, rRefreshSheetData, rSavedSpreadsheets } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { dataSourceIconMap } from "../spreadsheets/utils";
import { get } from "lodash";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import { useState } from "react";

const SpreadsheetSelect = ({ data }) => {
  const activeBlock = useActiveBlock();

  const activeApp = useRecoilValue(rApp);

  const customBlock = get(data, "customBlock");

  const block = customBlock || activeBlock;

  const setRefreshSheetData = useSetRecoilState(rRefreshSheetData);

  const [showOptions, setShowOptions] = useState(false);

  const {
    handleTable,
    handleGrid,
    handleForm,
    handleInfoList,
    handleKanban,
    handleCalendar,
    handleChart,
    handleStat,
    handleCustom,
  } = useSpreadsheetRequests();

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const selectedSheet = savedSpreadsheets.find((s) => s.id === data.value);

  let finalDataSources = savedSpreadsheets;
  if (data.hideSheetId) {
    finalDataSources = finalDataSources.filter(
      (s) => s.id !== data.hideSheetId
    );
  }

  const type = get(block, "componentId");

  const changeSheet = (sheetId) => {
    data.onChange(sheetId);
    setShowOptions(false);

    if (!data.preventExtras) {
      // Handle settings
      if (type === "Table") {
        handleTable(sheetId);
      } else if (type === "InfoList") {
        handleInfoList(sheetId);
      } else if (type === "Form") {
        handleForm(sheetId);
      } else if (type === "Kanban") {
        handleKanban(sheetId);
      } else if (type === "Grid") {
        handleGrid(sheetId);
      } else if (type === "Calendar") {
        handleCalendar(sheetId);
      } else if (type === "Chart") {
        handleChart(sheetId);
      } else if (type === "Stat") {
        handleStat(sheetId);
      } else if (type === "Custom") {
        handleCustom(sheetId);
      }
    }
  };

  const dataSourceMap = {
    supabase: {
      text: "Open In Supabase",
      onClick: () =>
        window.open(
          `https://supabase.com/dashboard/project/${get(
            activeApp,
            "supabase_project_id"
          )}`
        ),
    },
    google: {
      text: "Open In Google",
      onClick: () =>
        window.open(
          `https://docs.google.com/spreadsheets/d/${selectedSheet.spreadsheet_id}/edit#gid=${selectedSheet.tab_id}`
        ),
    },
    airtable: {
      text: "Open In Airtable",
      onClick: () =>
        window.open(
          `https://airtable.com/${get(selectedSheet, [
            "config",
            "base_id",
          ])}/${get(selectedSheet, ["config", "table_id"])}`
        ),
    },
  };

  const selectedService = get(selectedSheet, "service", "google");

  return (
    <>
      {showOptions && (
        <DataSourceModal
          dataSources={finalDataSources}
          changeSheet={changeSheet}
          setShowOptions={setShowOptions}
        />
      )}

      <DisplayRow onClick={() => setShowOptions(true)} width={data.width}>
        {selectedSheet ? (
          <>
            <DataSourceIcon src={get(dataSourceIconMap, selectedService)} />
            <DataSourceName>{get(selectedSheet, "title")}</DataSourceName>
          </>
        ) : (
          <>
            <Icon data={{ icon: "FiPlus", size: 20, color: "var(--grey6)" }} />
            <DataSourceName>Select Data Source</DataSourceName>
          </>
        )}
      </DisplayRow>

      {selectedService !== "frontly_db" && !data.hideLinks && (
        <Row justifyContent="space-between" gap="30px">
          {selectedSheet && (
            <Text
              data={{
                ...get(
                  dataSourceMap,
                  get(selectedSheet, "service", "google"),
                  {}
                ),
                margin: "5px 0 0 0",
                color: "var(--primary-admin)",
                fontStyle: "headingXs",
                cursor: "pointer",
              }}
            />
          )}
          {selectedSheet && (
            <Text
              data={{
                margin: "5px 0 0 0",
                text: "Refresh Data",
                color: "var(--primary-admin)",
                fontStyle: "headingXs",
                onClick: () =>
                  setRefreshSheetData({
                    sheetId: selectedSheet.id,
                    blockId: get(activeBlock, "id"),
                  }),
              }}
            />
          )}
        </Row>
      )}
    </>
  );
};

export default SpreadsheetSelect;

const DisplayRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--grey3);
  padding: 10px 12px 10px 13px;
  border-radius: 8px;
  cursor: pointer;
  width: ${({ width }) => width || "100%"};
  margin-bottom: 5px;
  background: white;
  &:hover {
    background: var(--grey2);
  }
`;

const DataSourcesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
`;

const DataSourceIcon = styled.img`
  height: 20px;
  margin-right: 3px;
`;

const DataSourceName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const DataSourceDescription = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: var(--grey5);
  margin-top: 2px;
`;

const DataSourceCard = styled.div`
  border: 1px solid var(--grey3);
  padding: 10px 13px 10px 13px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  &:hover {
    background: var(--grey1);
  }
`;

const DataSourceModal = ({ dataSources, changeSheet, setShowOptions }) => {
  const [search, setSearch] = useState("");

  const filteredDataSources = dataSources.filter((ds) =>
    get(ds, "title").toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Modal
      minWidth="800px"
      header={{ title: "Select Data Source" }}
      hide={() => setShowOptions(false)}
    >
      <SearchBar
        data={{
          value: search,
          placeholder: "Search data sources",
          onChange: (v) => setSearch(v),
        }}
      />
      <DataSourcesGrid>
        {filteredDataSources.map((ds) => {
          const service = get(ds, "service", "google");

          return (
            <DataSourceCard onClick={() => changeSheet(ds.id)}>
              <DataSourceIcon
                style={{
                  height: service === "airtable" ? "25px" : "28px",
                }}
                src={get(dataSourceIconMap, service)}
              />
              <div>
                <DataSourceName>{get(ds, "title")}</DataSourceName>
                <DataSourceDescription>
                  {get(ds, ["headers", "length"])} columns
                </DataSourceDescription>
              </div>
            </DataSourceCard>
          );
        })}
      </DataSourcesGrid>
    </Modal>
  );
};
