import { Icon, Row, Text } from "app/components";

import { get } from "lodash";
import styled from "styled-components";

const UserSection = ({
  user = false,
  text = false,
  showMenu = false,
  menuPreference = "dynamic",
}) => {
  return (
    <Row alignItems="center" gap="10px">
      <UserCircle
        user={user}
        showMenu={showMenu}
        menuPreference={menuPreference}
      />
      {text && <UserText user={user} />}
    </Row>
  );
};

export default UserSection;

export const UserText = ({ user }) => {
  const fullName = [get(user, "first_name", ""), get(user, "last_name", "")]
    .join(" ")
    .trim();

  return (
    <div>
      <Text
        data={{
          text: fullName,
          fontSize: 14,
          fontWeight: 500,
          color: "var(--nav-font-color)",
        }}
      />
      <Text
        data={{
          text: get(user, "email", ""),
          fontSize: 12,
          fontWeight: 300,
          color: "var(--nav-chevron-color)",
        }}
      />
    </div>
  );
};

const UserCircle = ({ user, showMenu, menuPreference }) => {
  const firstCharacter = get(user, "first_name", "").slice(0, 1).toUpperCase();

  if (menuPreference === "menu" || showMenu) {
    return (
      <CircleContainer>
        <Icon
          data={{ icon: "FiMenu", color: "var(--nav-font-color)", hover: true }}
        />
      </CircleContainer>
    );
  }

  return <CircleContainer>{firstCharacter}</CircleContainer>;
};

const CircleContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--nav-item-accent);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--nav-font-color);
  font-size: 17px;
  cursor: pointer;
  font-weight: 400;
  &:hover {
    opacity: 0.8;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--nav-font-color);
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  border-bottom: 1px solid var(--grey2);
  padding: 10px;
`;
