import {
  ActiveIndicator,
  BlockContainer,
  BlockHeader,
  BlockLabel,
  BlockLabelInput,
  FiltersRow,
  HoverIndicator,
  Overlay,
} from "./RenderBlockStyles";
import { Button, Icon, Spinner } from "app/components";
import {
  allBlocks,
  getPixels,
  isFrontlyAdmin,
  safeArray,
} from "app/utils/utils";
import { boxShadow, colors } from "app/utils/theme";
import { get, isEmpty, startCase } from "lodash";
import { getCalc, getShowBackground } from "./renderblockUtils";
import {
  rActiveBlockId,
  rApp,
  rCustomBlocks,
  rDarkMode,
  rFetchingBlockIds,
  rFetchingVariables,
  rFilters,
  rSpinner,
  rTranslations,
  rUser,
} from "app/utils/recoil";

import { DragPreviewImage } from "react-dnd";
import { InfoBox } from "app/adminApp/settings/InfoBox";
import { PublicAccessWarning } from "./PublicAccessWarning";
import VisibleFilters from "./VisibleFilters";
import blockMap from "../blocks";
import dragIcon from "app/assets/images/move-icon-2.png";
import { getCustomVariablesFromValue } from "../fetchSpreadsheets";
import { getDisplayValue } from "app/components/CustomFilterButton";
import { paddingObjToString } from "../blocks/Text";
import styled from "styled-components";
import useActionResolver from "../useActionResolver";
import useContainerDimensions from "app/utils/useContainerDimensions";
import useDynamicText from "../useDynamicText";
import { useRecoilValue } from "recoil";
import { useRef } from "react";
import useSetBlock from "app/utils/useSetBlock";
import useUtils from "../useUtils";

const RenderBlock = ({ data }) => {
  const {
    page,
    inLayout,
    isPreview = false,
    availableWidth,
    isActiveBlock,
    showPublicAccessError,
    dragParams,
    mouseEvents,
    showSheetSetup,
    position,
    hasValidAction,
    block,
    adminBorderOverride = null,
  } = data;

  const blockId = get(block, "id");

  const { processDynamicText } = useDynamicText();

  const { actionResolver } = useActionResolver(page);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const setBlock = useSetBlock();

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const activeApp = useRecoilValue(rApp);

  const customBlocks = useRecoilValue(rCustomBlocks);

  const user = useRecoilValue(rUser);

  const activeUserGroups = get(user, ["user_groups", activeApp.id], []);
  const activeAppUserGroups = get(activeApp, "user_groups", []);

  const appStyling = get(activeApp, "styling", {});

  const matchingCustomBlock = safeArray(customBlocks).find(
    (b) => b.id === get(block, "customBlock")
  );

  const newGridSystem =
    (get(page, "newGridSystem", false) ||
      get(activeApp, "new_grid_system", false)) &&
    !matchingCustomBlock;

  // CUSTOM VARIABLES
  const blockCustomVariables = getCustomVariablesFromValue(
    JSON.stringify(block),
    safeArray(activeApp, "custom_variables"),
    processDynamicText
  );

  const styling = get(activeApp, "styling");

  const primaryColor = "var(--primary)";

  const componentId = get(block, "componentId");

  // POSSIBLE LIVE MODE? ----------

  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const isFetchingVariables = useRecoilValue(rFetchingVariables);

  const waitForCustomVariables =
    blockCustomVariables.length > 0 && isFetchingVariables;

  const spinner = useRecoilValue(rSpinner);

  const isFetching =
    !spinner &&
    ((fetchingBlockIds.includes(blockId) &&
      get(block, "componentId") !== "Button") ||
      waitForCustomVariables);

  const filters = useRecoilValue(rFilters);

  // POSSIBLE LIVE MODE? ----------

  const label = processDynamicText({
    text: get(block, "label"),
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });

  const blockConfig = allBlocks.find(
    (b) => b.type === get(block, "componentId")
  );

  const { addModalToStack, passesDisplayConditions } = useUtils();

  const Component = get(blockMap, componentId);

  const recordLabel = get(block, "labelSingular") || "Record";

  const createModeLabel = get(block, "createModeLabel") || `New ${recordLabel}`;

  const showCreateView = () =>
    !isFrontlyAdmin &&
    addModalToStack({
      label: createModeLabel,
      blockId: blockId,
      type: "create",
    });

  const visFiltersRef = useRef();
  const headerRef = useRef();

  const { height: headerHeight, width: headerWidth } = useContainerDimensions(
    headerRef,
    availableWidth
  );

  const createButtonWidth = get(block, "showCreate")
    ? get(block, "labelSingular", "Record").length * 7 + 65
    : 0;

  const blockLabelWidth = label ? label.length * 9.5 : 0;

  const containerRef = get(dragParams, "ref");

  const { width } = useContainerDimensions(containerRef, availableWidth);

  const blockFilters = get(filters, blockId);

  let visibleFilters = get(block, "visibleFilters", [])
    .filter((f) => f.key)
    .map((f) => {
      const displayValue = getDisplayValue({
        processDynamicText,
        label: get(f, "label", startCase(f.key)),
        value: get(blockFilters, f.key),
        componentId: get(f, "componentId", "Input"),
        operator: get(f, "operator", "equals"),
        translations,
        options: get(f, "options", []),
      });

      return { ...f, displayValue };
    });

  const hideLabel = get(blockConfig, "hideLabel");

  const { firstHiddenIndex, finalDisplayWidth } = getCalc(
    visibleFilters,
    block,
    headerWidth,
    createButtonWidth,
    blockLabelWidth
  );

  const createConditions = passesDisplayConditions({
    conditions: get(block, "createConditions", []),
  });

  const showCreate = get(block, "showCreate") && createConditions;

  const blockBoxShadow = get(styling, "blockBoxShadow");

  const showBackground = getShowBackground(block);

  let finalBoxShadow = "none";
  if (showBackground && blockBoxShadow !== false) {
    finalBoxShadow = boxShadow.card;
  }

  const buttonBorderRadius = get(styling, "buttonBorderRadius");

  // PADDING
  const defaultBlockPadding = get(styling, "blockPadding") || 20;
  const blockPadding = paddingObjToString(
    block.padding,
    getPixels(defaultBlockPadding)
  );

  // MARGIN
  const margin = paddingObjToString(block.margin, 0);

  // BORDER RADIUS
  const borderRadius = paddingObjToString(
    block.borderRadius,
    getPixels(get(styling, "blockBorderRadius") || 8)
  );

  // BORDER
  let blockBorder = adminBorderOverride || {
    borderWidth: "0px",
    borderRadius,
  };

  const showBorder = get(block, "showBorder") || get(styling, "blockBorder");

  if (!adminBorderOverride && showBorder && showBackground) {
    blockBorder["borderWidth"] = paddingObjToString(block.borderWidth, 0);
    blockBorder["borderColor"] =
      get(block, "borderColor") || get(styling, "blockBorderColor");
  }

  const blockContentHeight = parseInt(get(block, "height")) - headerHeight - 20; // minus 20 for margin

  const finalFilters = visibleFilters.map((f, i) => ({
    ...f,
    inFilterDropdown: firstHiddenIndex !== null && i >= firstHiddenIndex,
  }));

  const remainingSpaceForSearch =
    headerWidth - blockLabelWidth - createButtonWidth - finalDisplayWidth;

  const collapseSearch =
    get(block, "showSearch") && remainingSpaceForSearch < 200;

  const labelInputWidth = (get(block, ["label", "length"]) || 0) * 11;

  const labelColor = get(block, "labelColor");

  const customCss = get(block, "customCss", {});

  const blockHeight = block.height ? getPixels(block.height) : "auto";

  const blockPaddingWidth = block.padding
    ? parseInt(get(block, ["padding", "right"], 0)) +
      parseInt(get(block, ["padding", "left"], 0))
    : parseInt(defaultBlockPadding) * 2;

  const parentComponentId = get(block, "parentComponentId");

  const newLayoutSystem = get(page, "newLayoutSystem");

  const fillRemainingSpace = get(block, "fillRemainingSpace");

  const background = darkMode
    ? colors.darkModeLightBackground
    : get(block, "backgroundColor", "#ffffff");

  const customNotRepeating =
    matchingCustomBlock && !get(matchingCustomBlock, "repeating");

  const hasCard = get(blockConfig, "card");

  const showBlockHeader =
    !customNotRepeating &&
    hideLabel !== true &&
    !get(block, "hideHeader") &&
    ["off", "on"].includes(hasCard);

  const showOverlay = get(block, "showOverlay", false);
  const overlayColor = get(block, "overlayColor", "rgba(0, 0, 0, 0.5)");

  // Prevent breakage if no component ID
  if (!block.componentId) {
    return <></>;
  }

  const blockContent = (
    <>
      {showOverlay && <Overlay color={overlayColor} />}
      {isFrontlyAdmin && showPublicAccessError && <PublicAccessWarning />}
      {showBlockHeader && (
        <BlockHeader ref={headerRef}>
          {/* MOVE TO SETUP MODE */}

          {isActiveBlock && (
            <BlockLabelInput
              color={labelColor}
              darkMode={darkMode}
              width={labelInputWidth}
              value={get(block, "label")}
              placeholder="Label"
              customCss={processDynamicText({
                text: get(customCss, "blockLabel"),
                reusableBlockId: block.reusableBlockId,
                context: { repeatingRecord: get(block, "repeatingRecord") },
              })}
              onChange={(e) =>
                setBlock({
                  data: {
                    label: e.target.value,
                  },
                })
              }
            />
          )}

          {!isActiveBlock && label && (
            <BlockLabel
              color={labelColor}
              darkMode={darkMode}
              customCss={processDynamicText({
                text: get(customCss, "blockLabel"),
                reusableBlockId: block.reusableBlockId,
                context: { repeatingRecord: get(block, "repeatingRecord") },
              })}
            >
              {label}
            </BlockLabel>
          )}

          {/* DESKTOP FILTERS */}
          <FiltersRow ref={visFiltersRef}>
            <VisibleFilters
              block={block}
              filters={finalFilters}
              collapseSearch={collapseSearch}
            />
            {!showSheetSetup && showCreate && (
              <Button
                data={{
                  text: createModeLabel,
                  text: headerWidth > 300 && createModeLabel,
                  onClick: showCreateView,
                  icon: "FiPlus",
                  height: headerWidth < 300 && "38px",
                  borderRadius: buttonBorderRadius
                    ? getPixels(buttonBorderRadius)
                    : null,
                  backgroundColor: "var(--primary)",
                }}
              />
            )}
          </FiltersRow>
        </BlockHeader>
      )}

      {/* SHOW REGULAR SPINNER FOR BLOCKS THAT DONT HAVE SKELETON LOADER YET */}
      {isFetching &&
      [
        "Custom",
        "Calendar",
        "RichText",
        "Stat",
        "Map",
        "Timeline",
        "Image",
        "Video",
        "Iframe",
        "QuoteCalculator",
      ].includes(componentId) ? (
        <Spinner color={colors.grey4} size={40} padding="20px 0px 20px 0px" />
      ) : (
        <>
          {/* FIGURE OUT HOW TO MOVE THIS INTO THE SETUP MODE FILE */}
          {showSheetSetup && isFrontlyAdmin ? (
            <InfoBox
              darkMode={darkMode}
              warning
              margin="15px 5px 5px 5px"
              helpLink="https://help.frontly.ai/en/articles/7971110-spreadsheet-block-setting"
            >
              To complete Block setup, please select a spreadsheet
            </InfoBox>
          ) : (
            <Component
              page={page}
              block={{
                ...block,
                inLayout,
                gridHeight: blockContentHeight,
                maxWidth: width - blockPaddingWidth,
                allowColumnClick: true,
                isPreview,
                isFetching,
              }}
              setupMode={isFrontlyAdmin}
              filters={filters}
              showCreate={showCreate}
              // MEMO STUFF
              {...{
                app: activeApp,
                setBlock,
                activeBlockId,
                processDynamicText,
                passesDisplayConditions,
                actionResolver,
                appStyling,
                primaryColor,
                activeUserGroups,
                activeAppUserGroups,
              }}
            />
          )}
        </>
      )}
    </>
  );

  const layoutWidth = get(block, "layoutWidth");

  const resolvedBgImage = get(block, "backgroundImage", null)
    ? processDynamicText({
        text: get(block, "backgroundImage", null),
        reusableBlockId: block.reusableBlockId,
        context: { repeatingRecord: get(block, "repeatingRecord") },
      })
    : null;

  const addVisiblePadding =
    isFrontlyAdmin &&
    ["Row", "Column"].includes(componentId) &&
    get(page, "addVisiblePadding");

  const isHovering = get(mouseEvents, "hovering");

  const showActiveIndicator =
    get(mouseEvents, "active") || isHovering || get(dragParams, "isDropTarget");

  const dragPreviewFunction = get(data, "dragPreviewFunction", null);

  const windowInnerWidth = window.innerWidth;

  const exceedsScreenWidth = exceedsWindowWidth(layoutWidth, windowInnerWidth);

  const trueColumnSpan = parseInt(get(block, "columnSpan", 4));
  const trueRowSpan = parseInt(get(block, "rowSpan", 1));

  let columnSpan = trueColumnSpan;
  let rowSpan = trueRowSpan;

  // Handle screen sizes gracefully
  if (windowInnerWidth < 480) {
    columnSpan = 1;
    rowSpan = 1;
  } else if (windowInnerWidth < 800) {
    if (columnSpan > 2) {
      columnSpan = 2;
    }
    if (rowSpan > 2) {
      rowSpan = 2;
    }
  }

  const isNewGridSystem = get(page, "newGridSystem", false);

  return (
    <BlockContainer
      // SETUP MODE PARAMS
      {...dragParams} // The container ref is being passed in here in both modes
      id={`block-${blockId}`}
      newGridSystem={isNewGridSystem}
      addVisiblePadding={addVisiblePadding}
      stopPointerEvents={
        !hasValidAction && !isFrontlyAdmin && componentId === "Icon"
      }
      // SETUP MODE PARAMS
      {...mouseEvents}
      columnSpan={columnSpan}
      rowSpan={rowSpan}
      backgroundImage={resolvedBgImage}
      backgroundSize={get(block, "backgroundSize", "cover")}
      backgroundPosition={get(block, "backgroundPosition", "center")}
      componentId={componentId}
      newLayoutSystem={newLayoutSystem}
      parentComponentId={parentComponentId}
      fillRemainingSpace={fillRemainingSpace}
      width={exceedsScreenWidth ? "100%" : layoutWidth}
      className={`${componentId}-${blockId}`}
      margin={margin}
      padding={blockPadding}
      {...blockBorder}
      boxShadow={finalBoxShadow}
      inLayout={inLayout}
      background={background}
      height={blockHeight}
      showBackground={showBackground}
      customCss={processDynamicText({
        text: get(customCss, "blockContainer"),
        reusableBlockId: block.reusableBlockId,
        context: { repeatingRecord: get(block, "repeatingRecord") },
      })}
    >
      {isFrontlyAdmin && (
        <DragPreviewImage connect={dragPreviewFunction} src={dragIcon} />
      )}

      {isFrontlyAdmin && (
        <ActiveIndicator
          active={showActiveIndicator}
          borderRadius={borderRadius}
        />
      )}
      {isFrontlyAdmin &&
        !isEmpty(position) &&
        !get(dragParams, "isDragging") && <HoverIndicator style={position} />}
      {blockContent}

      {newGridSystem && !inLayout && isHovering && (
        <>
          <FloatingButtons
            field="columnSpan"
            position={"right"}
            value={trueColumnSpan}
            blockId={blockId}
          />
          <FloatingButtons
            field="rowSpan"
            position={"bottom"}
            value={trueRowSpan}
            blockId={blockId}
          />
        </>
      )}
    </BlockContainer>
  );
};

export default RenderBlock;

const FloatingButtons = ({ position, field, value, blockId }) => {
  const setBlock = useSetBlock();

  const canIncrement =
    (field === "columnSpan" && value < 4) || (field === "rowSpan" && value < 4);

  const canDecrement =
    (field === "columnSpan" && value > 1) || (field === "rowSpan" && value > 1);

  const handleIncrement = (e) => {
    e.stopPropagation();
    if (canIncrement) {
      setBlock({
        data: {
          [field]: value + 1,
        },
        customBlockId: blockId,
      });
    }
  };

  const handleDecrement = (e) => {
    e.stopPropagation();
    if (canDecrement) {
      setBlock({
        data: {
          [field]: value - 1,
        },
        customBlockId: blockId,
      });
    }
  };

  return (
    <RightContainer position={position}>
      <FloatingButton onClick={handleIncrement}>
        <Icon
          data={{
            icon: position === "right" ? "FiArrowRight" : "FiArrowDown",
            size: 14,
            hover: true,
          }}
        />
      </FloatingButton>
      <FloatingButton onClick={handleDecrement}>
        <Icon
          data={{
            icon: position === "right" ? "FiArrowLeft" : "FiArrowUp",
            size: 14,
            hover: true,
          }}
        />
      </FloatingButton>
    </RightContainer>
  );
};

const RightContainer = styled.div`
  position: absolute;
  ${(p) =>
    p.position === "right"
      ? `
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    flex-direction: column;
  `
      : `
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  `};
  z-index: 1000;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const FloatingButton = styled.div`
  background: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  border: 1px solid var(--grey21);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function exceedsWindowWidth(width, windowWidth) {
  if (!width) {
    return false;
  }

  if (typeof width === "string") {
    if (width.endsWith("%")) {
      // If the width is a percentage, return false
      return false;
    } else if (width.endsWith("px")) {
      // If the width is in pixels, convert it to a number
      width = parseInt(width, 10);
    } else {
      // If the width is a string without units, assume it's a number
      width = parseInt(width, 10);
    }
  }

  // Ensure width is a number
  width = Number(width);

  // Compare the width with the windowWidth
  return width > windowWidth;
}
