import { createRef, useRef } from "react";
import { getDetailViewMode, getPixels } from "app/utils/utils";
import {
  rActiveDetailViewId,
  rApp,
  rDarkMode,
  rPageBlocks,
  rScreenPreviewSize,
  rShowSettingsPanel,
  rShowStylesPanel,
} from "app/utils/recoil";

import SetupModeNew from "../../setupmode";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useAdminData } from "app/adminApp/useAdminData";
import useContainerDimensions from "app/utils/useContainerDimensions";
import { useEffect } from "react";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";

const Body = () => {
  const darkMode = useRecoilValue(rDarkMode);

  const page = usePage();

  const blocks = useRecoilValue(rPageBlocks);

  useAdminData();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const activeApp = useRecoilValue(rApp);

  const showStylesPanel = useRecoilValue(rShowStylesPanel);
  const showSettingsPanel = useRecoilValue(rShowSettingsPanel);

  const defaultPageBackgroundColor = get(activeApp, [
    "styling",
    "pageBackgroundColor",
  ]);

  const screenPreviewSize = useRecoilValue(rScreenPreviewSize);

  const activeDetailBlock = blocks.find((b) => b.id === activeDetailViewId);

  const ref = createRef(null);

  useEffect(() => {
    mixpanel.track("Page Builder View");
  }, []);

  const previewRef = useRef();
  const { width } = useContainerDimensions(previewRef, screenPreviewSize);

  // Preview Width
  const contentWidth = get(page, "contentWidth") || "100%";

  const previewWidth = getPreviewWidth(contentWidth, width, screenPreviewSize);

  const detailViewMode = getDetailViewMode(activeDetailBlock, blocks);

  const modalPreview = blocks.length === 0 || detailViewMode;

  const primaryColor = get(activeApp, "primary_color");

  const pageBackgroundColor =
    get(page, "backgroundColor") || defaultPageBackgroundColor;

  return (
    <BodyContainer
      ref={previewRef}
      showStylesPanel={showStylesPanel}
      showSettingsPanel={showSettingsPanel}
      darkMode={darkMode}
      backgroundColor={getBackgroundColor(darkMode, pageBackgroundColor)}
    >
      <PreviewContent
        style={{
          "--primary": primaryColor,
        }}
        width={previewWidth}
        ref={ref}
        modalPreview={modalPreview}
      >
        <SetupModeNew
          availableWidth={previewWidth}
          screenPreviewSize={screenPreviewSize}
        />
      </PreviewContent>
    </BodyContainer>
  );
};

export default Body;

// Get the background color for the body
const getBackgroundColor = (darkMode, pageBackgroundColor) => {
  // Dark mode, override BG color
  if (darkMode) {
    return colors.darkModeBackground;
  }

  // User-defined background color OR fallback
  return pageBackgroundColor;
};

const getPreviewWidth = (contentWidth, width, screenPreviewSize) => {
  let previewWidth = contentWidth === "100%" ? width : contentWidth;

  if (screenPreviewSize === "tablet") {
    previewWidth = 800;
  }

  if (screenPreviewSize === "mobile") {
    previewWidth = 400;
  }

  return previewWidth;
};

const BodyContainer = styled.div`
  display: flex;
  position: fixed;
  top: 60px;
  left: ${(p) => (p.showSettingsPanel ? "290px" : "0px")};
  right: ${(p) => (p.showStylesPanel ? "290px" : "0px")};
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.backgroundColor};
`;

const PreviewContent = styled.div`
  width: ${(p) => getPixels(p.width)};
  overflow-y: auto;
  height: 100%;
  margin: 0 auto;
  ${(p) =>
    p.modalPreview &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;
