import { Icon, PopupMenu, Row, SimpleDropdown, Text } from "app/components";
import {
  allBlocks,
  getPixels,
  isFrontlyAdmin,
  lightenColor,
} from "app/utils/utils";
import { get, isEmpty } from "lodash";
import { rApp, rTranslations, rUser } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Cookies from "js-cookie";
import { Tooltip } from "react-tooltip";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Navigation = ({
  header,
  sections = [],
  dropdownLinks = [],
  isPreview = false,
  primaryColor,
  width,
}) => {
  const [user, setUser] = useRecoilState(rUser);
  const userIsNullOrEmpty = !user || isEmpty(user);

  const translations = useRecoilValue(rTranslations);

  const activeApp = useRecoilValue(rApp);

  const navigate = useNavigate();

  let finalDropdownLinks = [...dropdownLinks];

  const logoutText = get(translations, "logout", "Logout");
  const loggedOutText = get(translations, "loggedOut", "Logged out");

  if (!userIsNullOrEmpty && get(activeApp, "enable_edit_profile", false)) {
    finalDropdownLinks.push({
      text: get(translations, "editProfile", "Edit Profile"),
      onClick: () => {
        navigate("/edit-profile");
      },
    });
  }

  if (width < 800) {
    const allItems = sections
      .flatMap((section) => get(section, "items", []).map((item) => item))
      .map((i) => ({
        text: i.label,
        onClick: i.onClick,
      }));
    finalDropdownLinks = [...finalDropdownLinks, ...allItems];
  }

  const isAdmin = isFrontlyAdmin && !isPreview;

  const navigation = get(activeApp, "navigation", {});
  const navigationType = isAdmin
    ? "sidebar"
    : get(navigation, "type", "sidebar");

  const lightMode = !isAdmin && get(navigation, "textColor") === "light";

  const backgroundColor = isAdmin
    ? "white"
    : get(navigation, "backgroundColor");

  const border = get(navigation, "showBorder")
    ? `1px solid ${get(navigation, "borderColor")}`
    : null;

  const Container =
    navigationType === "header" ? HeaderContainer : SidebarContainer;

  const minimalSidebar =
    width > 800 &&
    navigationType === "sidebar" &&
    get(navigation, "sidebarStyle") === "minimal";

  const centerVertical = get(navigation, "sidebarItemPositioning") === "center";
  // const showMinimalLabels = get(navigation, "showMinimalLabels");
  const showMinimalLabels = false;

  const showMenu = finalDropdownLinks.length > 0 || !userIsNullOrEmpty;

  return (
    <Container
      background={backgroundColor}
      border={border}
      minimalSidebar={minimalSidebar}
    >
      <HeaderItems
        navigationType={navigationType}
        minimalSidebar={minimalSidebar}
        centerVertical={centerVertical}
      >
        {header && <Header minimalSidebar={minimalSidebar}>{header}</Header>}
        <SectionsContainer
          navigationType={navigationType}
          minimalSidebar={minimalSidebar}
          centerVertical={centerVertical}
        >
          {width > 800 &&
            sections.map((section, si) => (
              <Section
                key={si}
                sectionIndex={si}
                expandable={section.expandable}
                defaultExpanded={section.defaultExpanded}
                icon={section.icon}
                label={section.label}
                items={section.items}
                lightMode={lightMode}
                isAdmin={isAdmin}
                primaryColor={primaryColor}
                minimalSidebar={minimalSidebar}
                showMinimalLabels={showMinimalLabels}
              />
            ))}
        </SectionsContainer>
      </HeaderItems>
      {showMenu && !isPreview && width < 800 && (
        <div style={{ margin: "0 15px 0 20px" }}>
          <PopupMenu
            data={{
              lightMode,
              minimalSidebar: false,
              activeUser: user,
              isMobile: true,
              links: userIsNullOrEmpty
                ? [...finalDropdownLinks]
                : [
                    ...finalDropdownLinks,
                    {
                      text: logoutText,
                      onClick: () => {
                        setUser(null);
                        Cookies.remove("accessToken");
                        localStorage.removeItem("appId");
                        successNotification("Logged out.");
                        navigate("/login");
                      },
                    },
                  ],
            }}
          />
        </div>
      )}
      {showMenu && !isPreview && width >= 800 && user && (
        <div style={{ margin: minimalSidebar ? "0px" : "0 15px 0 20px" }}>
          <PopupMenu
            data={{
              minimalSidebar,
              lightMode,
              activeUser: user,
              links: userIsNullOrEmpty
                ? [...finalDropdownLinks]
                : [
                    ...finalDropdownLinks,
                    {
                      text: logoutText,
                      onClick: () => {
                        setUser(null);
                        Cookies.remove("accessToken");
                        localStorage.removeItem("appId");
                        successNotification(loggedOutText);
                        navigate("/login");
                      },
                    },
                  ],
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default Navigation;

export const Section = ({
  label,
  items,
  lightMode,
  isAdmin,
  primaryColor,
  expandable,
  defaultExpanded,
  icon,
  minimalSidebar,
  sectionIndex,
  showMinimalLabels,
}) => {
  const activeApp = useRecoilValue(rApp);
  const navigation = get(activeApp, "navigation", {});
  const navigationType = isAdmin
    ? "sidebar"
    : get(navigation, "type", "sidebar");

  const [expanded, setExpanded] = useState(defaultExpanded);
  const showItems = expanded || !expandable || !label;

  const ExpandableSection = ({ header = false }) => (
    <Row
      onClick={() => setExpanded(!expanded)}
      alignItems="center"
      margin={header ? "0px" : "0 5px 10px 5px"}
      justifyContent="space-between"
      style={{ cursor: "pointer" }}
      gap={header && "10px"}
    >
      <Row alignItems="center" gap="10px">
        {icon && (
          <Icon
            data={{
              icon,
              size: 18,
              hover: true,
              color: lightMode ? "white" : colors.clickUpIconGrey,
            }}
          />
        )}
        <Text
          data={{
            text: label,
            cursor: "pointer",
            color: lightMode ? "white" : colors.default,
            fontStyle: "bodyMdMedium",
          }}
        />
      </Row>
      {(expandable || header) && (
        <Icon
          data={{
            icon: header
              ? "FiChevronDown"
              : expanded
              ? "FiChevronUp"
              : "FiChevronDown",
            size: 16,
            hover: true,
            color: lightMode ? "white" : colors.clickUpMedGrey,
          }}
        />
      )}
    </Row>
  );

  if (items.length === 0) {
    return <></>;
  }

  return (
    <SectionContainer
      navigationType={navigationType}
      minimalSidebar={minimalSidebar}
      sidebarSectionTopMargin={get(navigation, "sidebarSectionTopMargin", 20)}
      sectionGap={get(navigation, "sectionGap", 8)}
    >
      {navigationType === "sidebar" && label && <ExpandableSection />}

      {navigationType === "header" && label && (
        <SimpleDropdown
          data={{
            options: items.map((i) => ({
              label: i.label,
              onClick: i.onClick,
              icon: i.icon,
            })),
            optionFontStyle: "bodyMdMedium",
            component: <ExpandableSection header />,
          }}
        />
      )}

      {((navigationType === "header" && !label) ||
        navigationType === "sidebar") &&
        showItems &&
        items.map((item, ik) => (
          <Item
            key={ik}
            {...item}
            lightMode={lightMode}
            navigationType={navigationType}
            primaryColor={primaryColor}
            isAdmin={isAdmin}
            sectionIndex={sectionIndex}
            itemIndex={ik}
            minimalSidebar={minimalSidebar}
            showMinimalLabels={showMinimalLabels}
          />
        ))}
    </SectionContainer>
  );
};

const getColor = (active, lightMode, obj) => {
  if (lightMode) {
    // LIGHT MDOE
    const o = get(obj, "lightMode");
    if (active) {
      return get(o, "active");
    }
    return get(o, "inactive");
  } else {
    const o = get(obj, "darkMode");
    // DARK MODE
    if (active) {
      return get(o, "active");
    }
    return get(o, "inactive");
  }
};

const Item = ({
  label,
  onClick,
  active,
  icon,
  lightMode,
  navigationType,
  type,
  primaryColor,
  isAdmin,
  sectionIndex,
  itemIndex,
  minimalSidebar,
  showMinimalLabels,
}) => {
  const textColor = getColor(active, lightMode, {
    lightMode: {
      active: "white",
      inactive: "white",
    },
    darkMode: {
      active: isFrontlyAdmin ? "var(--primary-admin)" : primaryColor,
      inactive: colors.default,
    },
  });

  return (
    <ItemContainer
      onClick={onClick}
      active={active}
      darkModehighlightColor={lightenColor(primaryColor, 0.05)}
      lightMode={lightMode}
      navigationType={navigationType}
      minimalSidebar={minimalSidebar}
    >
      {minimalSidebar && (
        <Tooltip
          anchorSelect={`.item-${sectionIndex}-${itemIndex}`}
          place="left"
          style={{
            zIndex: 9999,
            background: "white",
            color: "var(--grey8)",
            opacity: 1.0,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          {label}
        </Tooltip>
      )}
      <ItemInnerContainer
        // onClick={onClick} // This was here and causing double onclicks
        active={active}
        className={`item-${sectionIndex}-${itemIndex}`}
        minimalSidebar={minimalSidebar}
        showMinimalLabels={showMinimalLabels}
      >
        <IconWrapper
          active={active}
          isAdmin={isAdmin}
          minimalSidebar={minimalSidebar}
        >
          <Icon
            data={{
              icon,
              defaultIcon: get(
                allBlocks.find((b) => b.type === type),
                "icon"
              ),
              color: getColor(active, lightMode, {
                lightMode: {
                  active: "white",
                  inactive: "white",
                },
                darkMode: {
                  active: isFrontlyAdmin
                    ? active
                      ? "white"
                      : "var(--primary-admin)"
                    : primaryColor,
                  inactive: colors.clickUpIconGrey,
                },
              }),
              size: minimalSidebar ? 24 : active ? 16 : 17,
              hover: true,
            }}
          />
        </IconWrapper>
        {(!minimalSidebar || showMinimalLabels) && (
          <Text
            data={{
              text: label,
              color: textColor,
              fontStyle: showMinimalLabels
                ? "bodyXs"
                : active
                ? "headingSm"
                : "bodyMdMedium",
              cursor: "pointer",
              textAlign: showMinimalLabels ? "center" : "left",
              margin: navigationType === "header" ? "0 5px 0 0" : "0px",
            }}
          />
        )}
      </ItemInnerContainer>
    </ItemContainer>
  );
};

const HeaderItems = styled.div`
  width: 100%;
  ${(p) =>
    p.navigationType == "header" &&
    `
    display: flex;
    gap: 20px;
  `}
  ${(p) =>
    p.minimalSidebar &&
    p.centerVertical &&
    `flex: 1;
      display: flex;
      flex-direction: column;
      `}
`;

const SectionsContainer = styled.div`
  ${(p) =>
    p.navigationType == "header" &&
    `
    display: flex;
    align-items: center;
  `}
  ${(p) =>
    p.minimalSidebar &&
    `
    flex: 1;
    display: flex;
    flex-direction: column;
    ${!p.centerVertical && "padding-top: 50px;"}
    `}
`;

const Header = styled.div`
  padding: 0 16px 0 16px;
  ${(p) =>
    p.minimalSidebar &&
    `
    display: flex;
    justify-content: center;
  `}
`;

const SidebarContainer = styled.div`
  background: ${(p) => p.background || "white"};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  min-width: 240px;
  max-width: 240px;
  padding: 20px 0 20px 0;
  overflow: auto;
  border-right: ${(p) => p.border};
  @media screen and (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    padding: 10px 0 10px 0;
  }

  ${(p) =>
    p.minimalSidebar &&
    `
    padding: 30px 0 30px 0;
    min-width: 100px;
    max-width: 100px;
    align-items: center;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  padding: 10px 0 10px 0;
  height: fit-content;
  background: ${(p) => p.background || "white"};
  border-bottom: ${(p) => p.border};
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => getPixels(p.sidebarSectionTopMargin)};
  padding: 0 13px 0 13px;
  gap: ${(p) => (isFrontlyAdmin ? "0px" : getPixels(p.sectionGap))};
  ${(p) =>
    p.navigationType === "header" && "flex-direction: row; margin-top: 0px;"}
  ${(p) =>
    p.minimalSidebar &&
    `
    justify-content: center;
    flex: 1;
    align-items: center;
  `}
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  ${(p) =>
    p.minimalSidebar &&
    `
    justify-content: center;
    height: 66px;
    width: 66px;
    border-radius: 12px;
  `}

  background: ${(p) =>
    getColor(p.active, p.lightMode, {
      lightMode: {
        active: "rgba(255,255,255,0.10)",
        inactive: "transparent",
      },
      darkMode: {
        active: isFrontlyAdmin ? "#F0F0F3" : p.darkModehighlightColor,
        inactive: "transparent",
      },
    })};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  background: ${(p) =>
    p.active && p.isAdmin ? "var(--primary-admin)" : "transparent"};

  ${(p) =>
    p.minimalSidebar &&
    `
    justify-content: center;
  `}
`;

const ItemInnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px 5px 10px;
  width: 100%;
  border-radius: 4px;
  ${(p) =>
    p.minimalSidebar &&
    `
    justify-content: center;
  `}
  ${(p) => p.showMinimalLabels && "flex-direction: column;"}
`;
