import { Icon } from ".";
import { parseBoolean } from "app/utils/utils";
import styled from "styled-components";

const Boolean = ({ value }) => {
  const parsedValue = parseBoolean(value);

  return (
    <BooleanWrapper value={parsedValue}>
      <Icon
        data={{
          icon: parsedValue ? "FiCheck" : "FiX",
          size: 14,
          hover: true,
          color: parsedValue ? "#2F7D61" : "#CB401B",
        }}
      />
    </BooleanWrapper>
  );
};

export default Boolean;

const BooleanWrapper = styled.div`
  display: flex;
  background: ${(p) => (p.value ? "#B8E7D2" : "#F9D5D2")};
  border-radius: 20px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
