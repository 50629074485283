import { getCurrentDomain } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const MWFContainer = styled.div`
  background: black;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  @media screen and (max-width: 800px) {
    bottom: 10px;
    right: 10px;
    left: 10px;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
  }
`;

const MWFLogo = styled.img`
  height: 30px;
  margin-left: 1px;
  @media screen and (max-width: 800px) {
    height: 25px;
  }
`;

const MadeWithFrontly = () => {
  return (
    <MWFContainer
      onClick={() => {
        window.open("https://www.frontly.ai?r=madewithbadge");
        mixpanel.track("Made With Frontly Clicked", {
          domain: getCurrentDomain(),
        });
      }}
    >
      <MadeWithText>Made with</MadeWithText>
      <MWFLogo src="https://res.cloudinary.com/frontly/image/upload/v1687922385/frontly_white_xmcwyt.svg" />
    </MWFContainer>
  );
};

export default MadeWithFrontly;

const MadeWithText = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 300;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 17px;
  }
`;
