import { Icon, Text } from "app/components";
import { getPixels, isFrontlyAdmin } from "app/utils/utils";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import useUtils from "../useUtils";

const getTransformedUrl = (url) => {
  if (!url) {
    return null;
  }

  if (!url.includes("youtu")) {
    return url;
  }

  // Check for short YouTube URL
  const shortUrlPattern = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]+)/;
  const longUrlPattern =
    /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;

  let videoId;

  const shortMatch = url.match(shortUrlPattern);
  const longMatch = url.match(longUrlPattern);

  if (shortMatch) {
    videoId = shortMatch[1]; // Extract video ID from short URL
  } else if (longMatch) {
    videoId = longMatch[1]; // Extract video ID from long URL
  } else {
    return null; // Invalid URL format
  }

  // Return the transformed embed URL
  return `https://www.youtube.com/embed/${videoId}`;
};

const VideoPlayer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Video = ({ block }) => {
  const { processDynamicText } = useUtils();
  const url = processDynamicText({
    text: get(block, "url", ""),
    reusableBlockId: block.reusableBlockId,
    context: { repeatingRecord: get(block, "repeatingRecord") },
  });

  return (
    <div
      style={{
        height: getPixels(block.height) || "400px",
        width: "100%",
      }}
    >
      {isFrontlyAdmin && !block.forceShowContent && (
        <Container>
          <Icon data={{ icon: "FiYoutube", color: "var(--grey7)" }} />
          <Text
            data={{
              text: "Video content will render in live mode",
              fontStyle: "bodyXl",
            }}
          />
        </Container>
      )}
      {(!isFrontlyAdmin || block.forceShowContent) && url && (
        <VideoPlayer
          src={getTransformedUrl(url)}
          title="YouTube video player"
          frameBorder="0"
          allowfullscreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
        />
      )}
    </div>
  );
};

export default Video;

const Container = styled.div`
  background: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
