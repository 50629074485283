import { Button, Progress, Row, Text } from "app/components";
import {
  rOrganization,
  rSetOrganization,
  rSubscription,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import PlansGrid from "./PlansGrid";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useIntercom } from "react-use-intercom";
import { useLocation } from "react-router-dom";

const Billing = () => {
  // const organization = useRecoilValue(rOrganization);

  // const navigate = useNavigate();

  const setOrganization = useSetRecoilState(rSetOrganization);

  const [isCancelling, setIsCancelling] = useState(false);

  // const [isFetchingPlan, setIsFetchingPlan] = useState(null);

  // const [newPlan, setNewPlan] = useState(null);

  // const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const { showNewMessage } = useIntercom();

  const location = useLocation();

  // const action = getUrlParameter("action", location);
  // const result = getUrlParameter("result", location);
  // const sessionId = getUrlParameter("session_id", location);
  const planType = getUrlParameter("plan_type", location);

  // const cardSaved = get(organization, "stripe_id");

  // const recoilSubscription = useRecoilValue(rSubscription);

  // const subscription = recoilSubscription || starter;

  // const currentPlan = get(subscription, "plan");

  useEffect(() => {
    mixpanel.track("Billing Page View");
  }, []);

  const cancelSubscription = (isSavingSet = null) => {
    mixpanel.track("Cancel Subscription", { type: planType });
    setIsCancelling(true);
    isSavingSet?.(true);
    apiRequest
      .post("/stripe/cancel/")
      .then(() => {
        setOrganization({
          subscription: null,
        });

        successNotification("Plan Cancelled");
        setIsCancelling(false);
      })
      .catch((error) => {
        isSavingSet?.(false);
      });
  };

  // const createSub = (planType) => {
  //   if (cardSaved) {
  //     console.log("planType", planType);
  //     console.log("cardSaved", cardSaved);
  //     // createSubscription(planType);
  //   } else {
  //     console.log("no cardSaved");
  //     // createCardSession(planType);
  //   }
  // };

  // const createCardSession = (planType) => {
  //   setIsFetchingPlan(planType);
  //   apiRequest
  //     .get(`/stripe/session/?plan_type=${planType}`)
  //     .then((response) => {
  //       const sessionUrl = get(response, ["data", "session", "url"]);
  //       window.location.href = sessionUrl;
  //       setIsFetchingPlan(null);
  //     });
  // };

  // const createSubscription = (planType, isSavingSet = null) => {
  //   mixpanel.track("Create Subscription", { type: planType });
  //   setIsFetchingPlan(planType);
  //   isSavingSet?.(true);
  //   apiRequest
  //     .post(`/stripe/subscription/`, {
  //       plan_type: planType,
  //     })
  //     .then((response) => {
  //       const sub = get(response, ["data", "subscription"]);
  //       setOrganization({
  //         subscription: sub,
  //       });
  //       successNotification("Plan Changed Successfully");
  //       setIsFetchingPlan(null);
  //       setNewPlan(null);

  //       navigate("/thank-you-sub");
  //     })
  //     .catch((error) => {
  //       isSavingSet?.(false);
  //     });
  // };

  // useEffect(() => {
  //   if (action === "payment" && result === "success" && sessionId && planType) {
  //     setIsFetchingPlan(planType);
  //     apiRequest
  //       .post("/stripe/auth/", {
  //         session_id: sessionId,
  //         plan_type: planType,
  //       })
  //       .then((response) => {
  //         const cardSaved = get(response, ["data", "card_saved"]);
  //         const sub = get(response, ["data", "subscription"]);
  //         if (cardSaved) {
  //           // UPDATE STATE TO SHOW CARD IS SAVED
  //           setOrganization({
  //             stripe_id: true,
  //             subscription: sub,
  //           });
  //           successNotification("Plan Changed Successfully");
  //         }
  //         setIsFetchingPlan(null);
  //         setNewPlan(null);
  //       });
  //   }
  // }, []);

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Billing"
      description="Manage your subscription and billing information"
      settingsCategory={"Organization Settings"}
    >
      {/* {newPlan && (
        <PlanChangeModal
          currentPlan={currentPlan}
          newPlan={newPlan}
          hide={() => setNewPlan(null)}
          createSub={createSub}
          isFetchingPlan={isFetchingPlan}
        />
      )} */}

      <PlansGrid
        showNewMessage={showNewMessage}
        //  setNewPlan={setNewPlan}
      />

      <Text
        data={{
          text: "Looking to cancel your subscription? Click here to chat with our team",
          fontStyle: "bodyMd",
          color: "var(--grey8)",
          margin: "30px 0 0 0",
          onClick: () =>
            showNewMessage(
              "Hey Frontly team, I'm looking to cancel my subscription."
            ),
        }}
      />

      <UsageSection />

      {/* Remove cancel subscription, for now */}
      {/* {get(currentPlan, "frequency" === "monthly") && (
        <Button
          data={{
            text: "Cancel Subscription",
            margin: "30px 0 0 0",
            size: "large",
            type: "basic",
            isFetching: isCancelling,
            onClick: () =>
              setConfirmationModalData({
                title: "Cancel Subscription?",
                text: "Are you sure you want to cancel your subscription? This will unpublish your live app and prevent any users from accessing it.",
                confirm: cancelSubscription,
              }),
          }}
        />
      )} */}
    </SettingsSection>
  );
};

export default Billing;

const UsageSection = () => {
  const organization = useRecoilValue(rOrganization);
  const subscription = useRecoilValue(rSubscription);

  const aiUsage = get(organization, "ai_usage", 0);

  const aiRequestsLimit = get(subscription, "ai_requests_limit", 50);

  if (!subscription) {
    return <></>;
  }

  return (
    <UsageCard>
      <Row>
        <div style={{ width: "100%" }}>
          <Text
            data={{
              text: "AI Usage (Last 30 days)",
              fontSize: 24,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "AI usage is based on the past 30 days and is updated daily.",
              fontSize: 16,
              margin: "0 0 10px 0",
            }}
          />
        </div>
        <Button
          data={{
            text: "Learn About AI Usage",
            type: "basic",
            icon: "FiExternalLink",
            onClick: () =>
              window.open(
                "https://help.frontly.ai/en/articles/8130024-ai-token-usage-and-api-keys"
              ),
          }}
        />
      </Row>
      <Progress percent={(aiUsage / aiRequestsLimit) * 100} />
      <Row alignItems="center" margin="10px 0 0 0" gap="10px">
        <Text
          data={{
            text: "Monthly AI Token Limit",
            fontSize: 16,
            fontWeight: 600,
          }}
        />
        <Text data={{ text: aiRequestsLimit, fontSize: 16 }} />
      </Row>
      <Row alignItems="center" margin="10px 0 0 0" gap="10px">
        <Text
          data={{
            text: "Current Monthly Usage",
            fontSize: 16,
            fontWeight: 600,
          }}
        />
        <Text data={{ text: aiUsage, fontSize: 16 }} />
      </Row>
    </UsageCard>
  );
};

export const renderValue = (value) => {
  if (value) {
    return value.toLocaleString("en-US");
  }
  return "Unlimited";
};

// const PlanChangeModal = ({
//   currentPlan,
//   newPlan,
//   hide,
//   createSub,
//   isFetchingPlan,
// }) => {
//   const planMap = {
//     basic,
//   };

//   const planInfo = get(planMap, newPlan);

//   const title = currentPlan
//     ? `Confirm changing your plan to the Frontly ${startCase(newPlan)} plan?`
//     : `Confirm subscribing to the Frontly ${startCase(newPlan)} plan?`;

//   return (
//     <Modal
//       hide={hide}
//       header={{
//         title,
//       }}
//     >
//       <Text
//         data={{
//           text: `The ${startCase(newPlan)} plan includes:`,
//           textAlign: "center",
//           fontStyle: "headingSm",
//         }}
//       />
//       <ul>
//         {planInfo.features.map((f, i) => (
//           <li key={i}>
//             <Text
//               data={{
//                 text: f.text,
//                 fontStyle: "bodyMd",
//                 color: "var(--grey8)",
//               }}
//             />
//           </li>
//         ))}
//       </ul>
//       <Row gap="15px" margin="20px 0 0 0">
//         <Button
//           data={{
//             isFetching: isFetchingPlan,
//             text: "Confirm Plan Change",
//             onClick: (e) => {
//               e.stopPropagation();
//               createSub(newPlan);
//             },
//           }}
//         />
//         <Button
//           data={{
//             text: "Cancel",
//             type: "basic",
//             onClick: hide,
//           }}
//         />
//       </Row>
//     </Modal>
//   );
// };

const UsageCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  margin: 20px 0;
`;
