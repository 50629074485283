import {
  rActiveBlockId,
  rActiveVersionMap,
  rCustomBlockChanges,
  rPageBlocks,
  rPageChanges,
} from "./recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { get } from "lodash";
import useIsCustomBlock from "./useIsCustomBlock";

const useSetBlock = () => {
  const activeVersionMap = useRecoilValue(rActiveVersionMap);
  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);
  const setPageChanges = useSetRecoilState(rPageChanges);
  const isCustomBlock = useIsCustomBlock();

  // Not using useBlocks because we do not want to trigger the 'changed' state since it's already being handled here
  const [pageBlocks, setPageBlocks] = useRecoilState(rPageBlocks);

  // The active block ID in the editor
  const activeBlockId = useRecoilValue(rActiveBlockId);

  const setBlock = ({ data, customBlockId = null, isVersion = false }) => {
    // Get the new list of updated blocks before setting to recoil state
    const newBlocks = pageBlocks.map((b) => {
      // The block we're updating, either the active block or a custom block ID
      const bId = customBlockId || activeBlockId;

      // If block ID matches, update the block
      if (b.id === bId) {
        // Check for active version and if found, return block with versions updated
        const activeVersionMatch = get(activeVersionMap, bId);

        // If isVersion is true, that means we're actually updating the high-level
        // version info like name or conditions rather than editing the data WITHIN that version
        if (activeVersionMatch && !isVersion) {
          return returnUpdatedVersion(b, activeVersionMatch, data);
        }

        // SET ROOT BLOCK DATA
        return {
          ...b,
          ...data,
        };
      }
      return b;
    });

    // Update recoil state with updated blocks
    setPageBlocks(newBlocks);

    // Update the custom block changes state
    if (isCustomBlock) {
      setCustomBlockChanges(true);
    } else {
      setPageChanges(true);
    }
  };

  return setBlock;
};

export default useSetBlock;

// Handle updating the active version
const returnUpdatedVersion = (block, activeVersionId, obj) => {
  // SET ACTIVE VERSION
  const newVersions = get(block, "versions", []).map((v) => {
    if (get(v, "id") === activeVersionId) {
      return {
        ...v,
        data: {
          ...get(v, "data", {}),
          ...obj,
        },
      };
    }
    return v;
  });

  return {
    ...block,
    versions: newVersions,
  };
};
