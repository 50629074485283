import { Icon, Text } from "app/components";

import { colors } from "app/utils/theme";
import styled from "styled-components";

const InfoBoxContainer = styled.div`
  border-radius: 8px;
  background: ${(p) =>
    p.darkMode ? colors.darkModeLightBackground : colors.clickUpBackground};
  color: ${(p) => (p.darkMode ? "white" : "var(--grey8)")};
  border: ${(p) =>
    p.darkMode ? `1px solid ${colors.darkModeLightBorder}` : "none"};
  padding: ${(p) => p.padding};
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  margin: ${(p) => p.margin};
`;

export const InfoBox = ({
  children,
  margin,
  helpLink,
  warning = false,
  padding = "15px",
  darkMode = false,
}) => {
  return (
    <InfoBoxContainer margin={margin} padding={padding} darkMode={darkMode}>
      <Icon
        data={{
          icon: warning ? "FiAlertTriangle" : "FiInfo",
          color: darkMode && colors.grey2,
        }}
      />
      <div>
        {children}
        {helpLink && (
          <Text
            data={{
              text: "Click here to learn more",
              color: colors.grey3,
              margin: "5px 0 0 0",
              fontStyle: "headingXs",
              onClick: () => window.open(helpLink),
            }}
          />
        )}
      </div>
    </InfoBoxContainer>
  );
};
