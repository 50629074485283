import { Icon } from ".";
import React from "react";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const Options = ({ data }) => {
  const { onChange } = data;

  const textColor = get(data, "textColor", "light");

  const value = get(data, "value", null);
  const options = get(data, "options", []);

  // HANDLE MULTI SELECT
  const isMulti = get(data, "isMulti", false);
  const splitValues = value ? value.split(",").map((req) => req.trim()) : [];

  return (
    <Container count={options.length}>
      {options.map((option) => {
        const active = isMulti
          ? splitValues.includes(option.value)
          : value === option.value;

        return (
          <Option
            textColor={textColor}
            active={active}
            onClick={() => {
              if (isMulti) {
                // MULTI
                const newValues = active
                  ? splitValues.filter((v) => v !== option.value)
                  : [...splitValues, option.value];
                onChange(newValues.join(", "));
              } else {
                // SINGLE
                onChange(option.value);
              }
            }}
          >
            {option.label}
            <IconContainer>
              {active && (
                <Icon
                  data={{
                    icon: "FiCheck",
                    color:
                      textColor === "light"
                        ? "rgba(255, 255, 255, 0.6)"
                        : "rgba(0, 0, 0, 0.6)",
                    margin: "3px 0 0 0",
                  }}
                />
              )}
            </IconContainer>
          </Option>
        );
      })}
    </Container>
  );
};

export default Options;

const IconContainer = styled.div`
  min-width: 30px;
  min-height: 30px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content; // sets the column size to the size of the largest child
  gap: 12px;
  width: fit-content;
  ${(p) =>
    p.count > 4 &&
    "grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));"}

  overflow-y: auto;
  width: 100%;
  max-height: 400px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%; // ensures each child takes 100% width of the container
  box-sizing: border-box;
  background: ${(p) =>
    p.textColor === "light"
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(0, 0, 0, 0.07)"};
  border: 1px solid
    ${(p) =>
      p.textColor === "light"
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.3)"};
  color: ${(p) => (p.textColor === "light" ? colors.grey1 : "var(--grey8)")};
  cursor: pointer;
  min-width: 200px;
  transition: 0.2s ease-in-out;
  &:hover {
    background: ${(p) =>
      p.textColor === "light"
        ? "rgba(255, 255, 255, 0.15)"
        : "rgba(0, 0, 0, 0.15)"};
  }

  padding: 10px;
  border-radius: 10px;

  @media screen and (max-width: 800px) {
    padding: 5px 10px 5px 10px;
    min-width: auto;
  }

  ${(p) =>
    p.active &&
    `
      border: 2px solid
        ${
          p.textColor === "light"
            ? "rgba(255, 255, 255, 0.6)"
            : "rgba(0, 0, 0, 0.6)"
        };
    `}
`;
