import {
  FullScreenLoader,
  Icon,
  Row,
  SearchBar,
  Select,
  Text,
} from "app/components";
import { boxShadow, colors } from "app/utils/theme";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

import { apiRequest } from "app/utils/apiRequests";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";
import { useNavigate } from "react-router-dom";

const InternalAdminExperts = () => {
  // Filters
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [experts, setExperts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/internal_admin/experts/").then((res) => {
      const expertsList = get(res, "data", []).map((e) => ({
        id: get(e, "id"),
        name: get(e, "name"),
        ...get(e, "data", {}),
      }));
      setExperts(expertsList);
      setIsFetching(false);
    });
  }, []);

  const [withSamples, setWidthSamples] = useState("withSamples");

  if (isFetching) {
    return <FullScreenLoader />;
  }

  const filteredExperts = experts.filter((e) => {
    const demoApps = get(e, "demo_apps", []).filter((da) => !isEmpty(da));
    if (withSamples === "withSamples" && demoApps.length === 0) return false;
    if (statusFilter && e.status !== statusFilter) return false;
    if (search !== "" && !e.name.toLowerCase().includes(search.toLowerCase()))
      return false;
    return true;
  });

  return (
    <Body>
      <Text
        data={{
          text: "Expert Management Dashboard",
          fontStyle: "headingLg",
          margin: "0 0 30px 0",
        }}
      />
      <Row gap="30px" margin="0 0 30px 0" style={{ width: "100%" }}>
        <SearchBar
          data={{
            width: "100%",
            value: search,
            placeholder: "Search by name or services",
            onChange: (v) => setSearch(v),
          }}
        />
        <Select
          data={{
            width: "200px",
            label: "Status",
            value: statusFilter,
            onChange: (v) => setStatusFilter(v),
            options: [
              { value: "in_progress", label: "In Progress" },
              { value: "pending", label: "Pending" },
              { value: "approved", label: "Approved" },
              { value: "published", label: "Published" },
              { value: "changes_requested", label: "Changes Requested" },
              { value: "denied", label: "Denied" },
            ],
          }}
        />
        <Select
          data={{
            width: "200px",
            label: "With Samples",
            value: withSamples,
            onChange: (v) => setWidthSamples(v),
            hideEmptyItem: true,
            defaultValue: "withSamples",
            options: [
              { value: "withSamples", label: "With Samples" },
              { value: "all", label: "All" },
            ],
          }}
        />
      </Row>

      <Grid count={filteredExperts.length}>
        {filteredExperts.map((expert) => (
          <ExpertCard
            onClick={() => navigate(`/expert/${expert.id}?admin=true`)}
          >
            <ExpertImage src={expert.image} />
            <ExpertName>{expert.name}</ExpertName>
            <ExpertDescription>
              {truncateText(expert.short_description, 80)}
            </ExpertDescription>
            <Row alignItems="center">
              <RatingStars rating={expert.rating || 5} />
              <RatingText>{expert.rating || 0.0}</RatingText>
              <RatingCount>({expert.ratingCount || 0})</RatingCount>
            </Row>
          </ExpertCard>
        ))}
      </Grid>
    </Body>
  );
};

export default InternalAdminExperts;

const Body = styled.div`
  padding: 30px;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  ${(p) => p.count && p.count < 4 && "grid-template-columns: 1fr 1fr 1fr;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const ExpertCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: ${boxShadow.card};
  cursor: pointer;
`;

const ExpertName = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 0 0;
`;

const ExpertDescription = styled.div`
  font-size: 16px;
  margin: 10px 0;
`;

export const RatingText = styled.div`
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
`;

export const RatingCount = styled.div`
  font-size: 16px;
  margin-left: 5px;
`;

export const RatingStars = ({ rating }) => {
  return (
    <Row>
      {[...Array(5)].map((_, i) => (
        <Icon
          data={{
            size: 20,
            icon: "BsFillStarFill",
            color: parseInt(rating) >= i ? "#FFD700" : "var(--grey21)",
          }}
        />
      ))}
    </Row>
  );
};

const NoImageWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
  height: 200px;
  border: 1px solid var(--grey21);
  background: var(--grey1);
  border-radius: 8px;
`;

const Thumb = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border: 1px solid var(--grey21);
  border-radius: 8px;
  object-fit: cover;
`;

const ExpertImage = ({ src }) => {
  const [error, setError] = useState(false);

  if (!src || error) {
    return (
      <NoImageWrapper src={src}>
        <Icon data={{ icon: "FiImage", color: "var(--grey21)", size: 40 }} />
      </NoImageWrapper>
    );
  }
  return <Thumb src={src} onError={() => setError(true)} />;
};
