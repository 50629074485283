import { Button } from "app/components";
import { get } from "lodash";
import useSetBlock from "app/utils/useSetBlock";

const SwitchComponent = ({ data }) => {
  const setBlock = useSetBlock();

  const newComponentId = get(data, "type");

  const switchComponent = () => {
    setBlock({
      data: {
        componentId: newComponentId,
        horizontalAlignment: undefined,
        verticalAlignment: undefined,
        flexWrap: undefined,
      },
    });
  };

  return (
    <Button
      data={{
        onClick: () => switchComponent(),
        type: "basic",
        text: "Switch To " + newComponentId,
      }}
    />
  );
};

export default SwitchComponent;
