import { Icon } from "app/components";
import { Tooltip } from "react-tooltip";
import { getPixels } from "app/utils/utils";
import styled from "styled-components";

const Item = ({
  label,
  icon,
  active,
  onClick,
  expanded,
  isDropdown,
  sectionIndex,
  itemIndex,
  accentType = "badge",
  isNarrow = false,
  accentBorderRadius = 24,
}) => {
  let displayIcon = icon;
  if (isNarrow && !icon) {
    displayIcon = "FiFile";
  }

  return (
    <div>
      {isNarrow && (
        <Tooltip
          anchorSelect={`.item-${sectionIndex}-${itemIndex}`}
          place="right"
          style={{
            zIndex: 9999,
            background: "white",
            color: "black",
            opacity: 1.0,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          {label}
        </Tooltip>
      )}

      <ItemContainer
        onClick={onClick}
        active={active}
        borderRadius={accentBorderRadius}
        isNarrow={isNarrow}
        accentType={accentType}
        className={`item-${sectionIndex}-${itemIndex}`}
      >
        {displayIcon && (
          <div style={{ minWidth: "16px" }}>
            <Icon
              data={{
                icon: displayIcon,
                color: "var(--nav-font-color)",
                size: isNarrow ? 24 : 16,
                hover: true,
                margin: "3px 0 0 0",
              }}
            />
          </div>
        )}
        <Label isNarrow={isNarrow} accentType={accentType}>
          {label}
        </Label>
        {isDropdown && !isNarrow && (
          <Icon
            data={{
              icon: expanded ? "FiChevronUp" : "FiChevronDown",
              size: 16,
              hover: true,
              color: "var(--nav-chevron-color)",
            }}
          />
        )}
      </ItemContainer>
    </div>
  );
};

export default Item;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--nav-font-color);
  display: ${(p) => (p.isNarrow ? "none" : "block")};
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(p) => (p.isNarrow ? "column" : "row")};
  gap: 10px;
  cursor: pointer;
  padding: 10px 15px;

  ${(p) =>
    p.accentType === "badge" &&
    `
      border-radius: ${getPixels(p.borderRadius)};
      background-color: ${p.active ? "var(--nav-item-accent)" : "none"};
      &:hover {
        background-color: var(--nav-item-accent);
      }
  `}

  ${(p) =>
    p.accentType !== "badge" &&
    `
    opacity: ${p.active ? 1.0 : 0.8};
    &:hover {
      opacity: 1.0;
    }
  `}

  ${(p) =>
    p.isNarrow &&
    `
    justify-content: center;
    height: 66px;
    width: 66px;
    border-radius: 50px;
  `}
`;
