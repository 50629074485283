import { Icon, Modal, Row, Text } from "app/components";
import { get, startCase } from "lodash";

import { badges } from "app/utils/theme";
import { rSubscription } from "app/utils/recoil";
import { useRecoilValue } from "recoil";

const AppSumoModal = ({ hide }) => {
  const subscription = useRecoilValue(rSubscription);

  const frequency = startCase(get(subscription, "frequency", "1_year"));
  const planName = startCase(
    get(subscription, "plan", "frontly_x_appsumo_tier1").replace(
      "frontly_x_appsumo",
      ""
    )
  );

  const operations = get(subscription, "operations_limit", 0);

  const brandedApps = get(subscription, "app_limit", 0);

  const apiAccess = get(subscription, "api_access", false);

  return (
    <Modal
      header={{
        title: "Welcome to Frontly!",
      }}
      hide={hide}
      buttons={[
        {
          text: "View Upgrade Options",
          type: "basic",
          size: "large",
          onClick: () => {
            window.open(
              "https://appsumo.com/products/frontly/#overview",
              "_blank"
            );
          },
        },
        {
          text: "Build my first app",
          type: "primary",
          icon: "FiArrowRight",
          size: "large",
          flippedIcon: true,
          onClick: () => {
            hide();
          },
        },
      ]}
    >
      <Text
        data={{
          fontSize: 18,
          fontWeight: 400,
          color: "var(--grey9)",
          text: `Your ${planName} plan includes ${frequency} of the following features:`,
          margin: "0 0 15px 0",
        }}
      />
      <Row alignItems="center" gap="10px">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text data={{ fontStyle: "headingMd", text: "Unlimited Users" }} />
      </Row>
      <Row alignItems="center" gap="10px" margin="10px 0 0 0">
        <Icon data={{ icon: "FiCheckCircle", color: badges.green, size: 20 }} />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "Unlimited Apps",
          }}
        />
      </Row>

      <Row alignItems="center" gap="5px" margin="10px 0 0 0">
        <Icon
          data={{
            icon: "FiCheckCircle",
            color: badges.green,
            size: 20,
            margin: "0 5px 0 0",
          }}
        />
        <Text
          data={{
            fontStyle: "headingMd",
            text: `${brandedApps} Branded App${brandedApps === 1 ? "" : "s"}`,
          }}
        />
        <Text
          data={{
            fontStyle: "bodyLg",
            color: "var(--grey6)",
            text: "(Logo, Custom Domain, Remove Frontly branding)",
          }}
        />
      </Row>

      <Row alignItems="center" gap="5px" margin="10px 0 0 0">
        <Icon
          data={{
            icon: "FiCheckCircle",
            color: badges.green,
            size: 20,
            margin: "0 5px 0 0",
          }}
        />
        <Text
          data={{
            fontStyle: "headingMd",
            text: `${operations.toLocaleString()} Monthly Operations`,
          }}
        />
        <Text
          data={{
            fontStyle: "bodyLg",
            color: "var(--grey6)",
            text: "(for database requests and AI usage)",
          }}
        />
      </Row>

      {apiAccess && (
        <Row alignItems="center" gap="5px" margin="10px 0 0 0">
          <Icon
            data={{
              icon: "FiCheckCircle",
              color: badges.green,
              size: 20,
              margin: "0 5px 0 0",
            }}
          />
          <Text
            data={{
              fontStyle: "headingMd",
              text: `API Access`,
            }}
          />
          <Text
            data={{
              fontStyle: "bodyLg",
              color: "var(--grey6)",
              text: "(for more advanced integrations)",
            }}
          />
        </Row>
      )}

      <Text
        data={{
          fontSize: 18,
          fontWeight: 400,
          color: "var(--grey9)",
          text: "Other features available on paid plans:",
          margin: "30px 0 15px 0",
        }}
      />
      <Row alignItems="center" gap="10px" margin="10px 0 0 0">
        <Icon
          data={{ icon: "FiUploadCloud", color: badges.orange, size: 20 }}
        />
        <Text
          data={{
            fontStyle: "headingMd",
            text: "More Branded Apps and Operations",
          }}
        />
      </Row>
    </Modal>
  );
};

export default AppSumoModal;
