import { useEffect, useState } from "react";

import { Column } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import { rUser } from "app/utils/recoil";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

const EmailVerification = () => {
  const user = useRecoilValue(rUser);

  const location = useLocation();

  const token = getUrlParameter("token", location);

  const [isVerified, setIsVerified] = useState(false);

  const userRole = get(user, "role");

  useEffect(() => {
    if (token && !isVerified) {
      setIsVerified(true);

      apiRequest
        .post("/verify_email/", {
          token,
        })
        .then((res) => {
          const link = get(res, ["data", "link"]);
          if (link) {
            window.location.href = link;
          } else {
            errorNotification("Something went wrong. Please contact support.");
          }
        });
    }
  }, [token]);

  return (
    <Loader>
      <Column gap="30px" alignItems="center" justifyContent="center">
        {userRole !== "admin" && (
          <Logo
            src={
              "https://res.cloudinary.com/frontly/image/upload/v1737557733/frontly_black_abjsh6_hn3daa.svg"
            }
          />
        )}
        <VerifyText>Please verify your email to continue.</VerifyText>
        <VerifySubText>
          You should see an email in your inbox ({get(user, "email")}). If you
          don't, please check your spam folder.
        </VerifySubText>
      </Column>
    </Loader>
  );
};

export default EmailVerification;

const Logo = styled.img`
  height: 60px;
`;

const VerifyText = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-align: center;
`;

const VerifySubText = styled.div`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

const Loader = styled.div`
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
