import { Breadcrumb, Button, Icon, Row } from "app/components";
import { get, startCase } from "lodash";
import { getUrlParameter, resizeImage, safeArray } from "app/utils/utils";
import { rActions, rPages, rSavedSpreadsheets } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ActionImage } from "./Home";
import AdminWrapper from "app/adminApp/home/AdminWrapper";
import { InstallListingModal } from "./InstallListingModal";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useSetRecoilState } from "recoil";

const Listing = () => {
  const [listing, setListing] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const setPages = useSetRecoilState(rPages);
  const setActions = useSetRecoilState(rActions);

  const setDataSources = useSetRecoilState(rSavedSpreadsheets);

  const params = useParams();

  const { id } = params;

  const location = useLocation();

  const status = getUrlParameter("status", location);

  // Fetch listing
  useEffect(() => {
    setIsFetching(true);
    apiRequest.get(`/marketplace/${id}/?status=${status}`).then((r) => {
      const listingData = get(r, "data");
      setIsFetching(false);
      setListing(listingData);
    });
  }, []);

  const listingDataSources = safeArray(listing, "data_sources");
  const hasDataSources = listingDataSources.length > 0;

  const listingType = get(listing, "type");

  const navigate = useNavigate();

  const [isInstalling, setIsInstalling] = useState(false);

  const installListing = (dataService) => {
    if (listingType === "page") {
      installPage(dataService);
    } else {
      installAction(dataService);
    }
  };

  // Install the page after the install confirmation modal
  const installPage = (dataService) => {
    setShowInstallModal(false);
    setIsInstalling(true);
    apiRequest
      .post("/marketplace/install_listing/", {
        marketplace_listing_id: id,
        service: dataService,
      })
      .then((r) => {
        const newPage = get(r, ["data", "page"], {});
        const newDataSources = get(r, ["data", "data_sources"], []);

        setPages((prev) => [...prev, newPage]);
        setDataSources((prev) => [...prev, ...newDataSources]);
        successNotification(`Listing ${get(listing, "name")} installed`);
        navigate(`/page/${newPage.id}`);
        setIsInstalling(false);
      });
  };

  // Install the action after the install confirmation modal
  const installAction = (dataService) => {
    setShowInstallModal(false);
    // Just add to favorites
    if (listing.saved) {
      successNotification("Removed from favorites");
      apiRequest.post(`/marketplace/favorite/${id}/`);
      // Remove from favorites
      setListing({
        ...listing,
        saved: !listing.saved,
      });
    } else {
      // Handle installing, check if it has datasources or not
      if (hasDataSources) {
        setIsInstalling(true);
        // Run the full install action process with copying data sources
        apiRequest
          .post("/marketplace/install_listing/", {
            marketplace_listing_id: id,
            service: dataService,
          })
          .then((r) => {
            const newAction = get(r, ["data", "action"], {});
            const newDataSources = get(r, ["data", "data_sources"], []);
            setActions((prev) => [...prev, newAction]);
            setDataSources((prev) => [...prev, ...newDataSources]);
            successNotification(
              "Action installed. You can find it in your 'Saved Actions' within the page builder"
            );
            setIsInstalling(false);
          });
      } else {
        // Just add to favorites
        successNotification("Added to favorites");
        setListing({
          ...listing,
          saved: !listing.saved,
        });
        // Add to saved listings
        apiRequest.post(`/marketplace/favorite/${id}/`);
      }
    }
  };

  const builderProfile = get(listing, "builder_profile");

  const [hover, setHover] = useState(false);

  const hasMultiple =
    [
      get(listing, "primary_image"),
      get(listing, "secondary_image"),
      get(listing, "tertiary_image"),
    ].filter(Boolean).length > 1;

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const getActiveImage = () => {
    const images = [
      get(listing, "primary_image"),
      get(listing, "secondary_image"),
      get(listing, "tertiary_image"),
    ].filter(Boolean);

    return images[activeImageIndex % images.length];
  };

  const getButtonText = () => {
    if (listingType === "action") {
      if (listing.saved) {
        return "Remove from Favorites";
      } else {
        return "Add to Favorites";
      }
    } else if (listingType === "page") {
      return `Install This ${startCase(listingType)}`;
    }
  };

  const [showInstallModal, setShowInstallModal] = useState(false);

  return (
    <AdminWrapper contentWidth="100%" isFetching={isFetching}>
      {showInstallModal && (
        <InstallListingModal
          hide={() => setShowInstallModal(false)}
          listing={listing}
          confirm={installListing}
        />
      )}

      <Breadcrumb
        fontStyle="headingXl"
        margin="0 0 30px 0"
        items={[
          {
            text: "Marketplace",
            onClick: () => navigate("/marketplace"),
          },
          { text: get(listing, "name"), onClick: null },
        ]}
      />

      <ContentRow>
        <LeftSection>
          <Name>{get(listing, "name")}</Name>
          <Description>
            {get(listing, "short_description") || "No description available"}
          </Description>

          {builderProfile && (
            <Row alignItems="center" gap="10px" margin="0 0 30px 0">
              <ProfileImage
                src={resizeImage({
                  url: get(builderProfile, "image"),
                  width: 50,
                  height: 50,
                })}
              />
              <ProfileName
                onClick={() =>
                  navigate(`/marketplace/profile/${get(builderProfile, "id")}`)
                }
              >
                By {get(builderProfile, "name")}
              </ProfileName>
            </Row>
          )}

          <ButtonRow>
            <Button
              data={{
                borderRadius: "50px",
                fontStyle: "headingMd",
                text: getButtonText(),
                size: "large",
                padding: "20px",
                fontWeight: 600,
                isFetching: isInstalling,
                onClick: () => setShowInstallModal(true),
              }}
            />
            {/* TODO - Figure out how 'view example' would work */}
            {builderProfile && (
              <Button
                data={{
                  borderRadius: "50px",
                  fontStyle: "headingLg",
                  size: "large",
                  type: "secondary",
                  text: "View Builder Profile",
                  padding: "20px",
                  icon: "FiExternalLink",
                  fontWeight: 600,
                  onClick: () =>
                    navigate(
                      `/marketplace/profile/${get(builderProfile, "id")}`
                    ),
                }}
              />
            )}
          </ButtonRow>

          <SectionHeader>About This {startCase(listingType)}</SectionHeader>
          <SectionDescription>
            {get(listing, "long_description") || "No description available"}
          </SectionDescription>
          {get(listing, "features", []).length > 0 && (
            <>
              <SectionHeader>Features</SectionHeader>
              <FeaturesGrid>
                {get(listing, "features", []).map((feature, index) => (
                  <Feature key={index}>
                    <FeatureTitle>{feature.name}</FeatureTitle>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </Feature>
                ))}
              </FeaturesGrid>
            </>
          )}

          {/* <CategoriesLabel>Categories</CategoriesLabel>
              <CategoriesGrid>
                {get(listing, "categories", []).map((category, index) => (
                  <Category key={index}>{category}</Category>
                ))}
              </CategoriesGrid> */}
        </LeftSection>
        <RightSection>
          {listingType === "action" ? (
            <ActionImage
              steps={get(listing, "config.steps", [])}
              width="100%"
              height="400px"
              showLabels={true}
            />
          ) : (
            <CarouselContainer
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {hover && hasMultiple && (
                <>
                  <IconWrapper
                    onClick={() =>
                      setActiveImageIndex((activeImageIndex - 1 + 3) % 3)
                    }
                  >
                    <Icon
                      data={{
                        icon: "FiChevronLeft",
                        size: 24,
                        hover: true,
                        color: "white",
                      }}
                    />
                  </IconWrapper>
                  <IconWrapper
                    onClick={() =>
                      setActiveImageIndex((activeImageIndex + 1) % 3)
                    }
                    style={{ right: "10px", left: "auto" }}
                  >
                    <Icon
                      data={{
                        icon: "FiChevronRight",
                        size: 24,
                        hover: true,
                        color: "white",
                      }}
                    />
                  </IconWrapper>
                </>
              )}
              <Screenshot src={getActiveImage()} />
            </CarouselContainer>
          )}
        </RightSection>
      </ContentRow>
    </AdminWrapper>
  );
};

export default Listing;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--card-shadow);
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

const ProfileName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.frontlyBlue};
  margin-bottom: 2px;
  cursor: pointer;
`;

const ContentRow = styled.div`
  justify-content: space-between;
  gap: 80px;
  align-items: flex-start;
  display: flex;

  width: 100%;
  background: white;
  padding: 40px;
  box-shadow: var(--card-shadow);
  border-radius: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
  }
`;

const Screenshot = styled.img`
  height: 400px;
  width: 100%;
  object-fit: contain;
  border-radius: 15px;
  background: #f0f0f3;
  border: 1px solid var(--grey3);
  padding: 20px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
`;

const Feature = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

const FeatureTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.div`
  font-size: 16px;
  color: #666;
`;

const Name = styled.div`
  font-size: 35px;
  font-weight: 600;
`;

const Description = styled.div`
  font-size: 20px;
  color: #666;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 400px;
`;

const SectionHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-top: 40px;
`;

const SectionDescription = styled.div`
  font-size: 20px;
  color: #666;
  margin-top: 25px;
  margin-bottom: 30px;
  white-space: pre-line;
`;

const CategoriesLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
`;

const CategoriesGrid = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Category = styled.div`
  background: var(--grey2);
  color: var(--text);
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;
