import { rPage, rPageChanges } from "./recoil";

import { useSetRecoilState } from "recoil";

const useSetPage = () => {
  const setPage = useSetRecoilState(rPage);
  const setPageChanges = useSetRecoilState(rPageChanges);

  const handleSetPage = (newPageData) => {
    setPage((prevPage) => ({ ...prevPage, ...newPageData }));
    setPageChanges(true);
  };

  return { setPage: handleSetPage };
};

export default useSetPage;
