import BlockHeader from "./BlockHeader";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const Analytics = ({ page, data, hue, large }) => {
  const { stat_label, stat_value, chart_label } = get(page, "block_data", {});

  const chartData = [16, 40, 26];

  return (
    <>
      <BlockHeader name={page.name} hue={hue} hideButton large={large} />
      <AnalyticsContainer>
        <StatContainer>
          <StatLabel>{stat_label}</StatLabel>
          <StatValue>{stat_value}</StatValue>
        </StatContainer>
        <ChartContainer>
          <ChartLabel>{chart_label}</ChartLabel>
          <BarChart>
            {chartData.map((value, index) => (
              <Bar key={index} height={value} hue={hue} />
            ))}
          </BarChart>
        </ChartContainer>
      </AnalyticsContainer>
    </>
  );
};

export default Analytics;

const AnalyticsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid ${colors.grey2};
  width: 100px;
  flex: 1;
`;

const StatLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: hsl(${(p) => p.hue}, 93%, 25%);
  margin-top: 15px;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid ${colors.grey2};
  width: 100px;
`;

const ChartLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`;

const BarChart = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`;

const Bar = styled.div`
  width: 12px;
  height: ${(p) => p.height}px;
  background: hsla(${(p) => p.hue}, 63%, 60%, 0.8);
  border-radius: 5px;
`;
