import {
  Button,
  Modal,
  Pagination,
  Row,
  Spinner,
  Tabs,
  Text,
} from "app/components";
import { get, isEmpty, startCase } from "lodash";
import { useEffect, useState } from "react";

import { AdminForm } from "../components";
import { TableComponent } from "app/renderingApp/blocks/Table";
import { rConfirmationModalData } from "app/utils/recoil";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useSetRecoilState } from "recoil";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";

const DataSourceAdmin = ({
  dataSource,
  rows,
  setRows,
  pagination,
  setPagination,
  setTab,
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [activeRowChanges, setActiveRowChanges] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const page = get(pagination, "page", 1);
  const pageCount = get(pagination, "total_pages", 1);

  const headers = get(dataSource, "headers", []);
  const fieldConfig = get(dataSource, ["field_data", "config"], {});

  const isFrontlyDb = get(dataSource, "service") === "frontly_db";
  const recordCount = get(dataSource, "record_count", 0);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const { getRecords, createRecord, updateRecord, deleteRecord } =
    useSpreadsheetRequests();

  // Fetch data
  const fetchData = (p = 1) => {
    setIsFetching(true);

    getRecords({
      sheetId: dataSource.id,
      pagination: { page_size: 20, page: p },
    }).then((res) => {
      const records = get(res, "data", []);
      const pag = get(res, "pagination", {});
      setPagination(pag);
      setRows(records);
      setIsFetching(false);
    });
  };

  // Fetch data on mount
  useEffect(() => {
    if (rows.length === 0) {
      fetchData();
    }
  }, []);

  const fields = headers.map((h) => {
    const match = get(fieldConfig, h, null);
    const component = get(match, "componentId", "Input");
    const value = get(activeRow, h, null);
    return {
      key: h,
      ...match,
      label: get(match, "label", startCase(h)),
      value: value,
      disabled: h === "frontly_id",
      componentId: component,
      columnType: component === "ImageUpload" ? "image" : null,
      //   columnSpan: ["TextArea", "ImageUpload"].includes(component) ? 2 : 1,
    };
  });

  const handleSave = () => {
    const formattedValues = Object.keys(activeRowChanges).map((key) => ({
      key,
      value: activeRowChanges[key],
    }));

    if (activeRow.frontly_id) {
      setIsSaving(true);
      updateRecord({
        sheetId: dataSource.id,
        recordId: activeRow.frontly_id,
        values: formattedValues,
        rowIdColumn: "frontly_id",
      });

      setRows((prevRows) =>
        prevRows.map((row) =>
          row.frontly_id === activeRow.frontly_id
            ? { ...row, ...activeRowChanges }
            : row
        )
      );
      setActiveRow(null);
      setActiveRowChanges({});
      setIsSaving(false);
      successNotification("Record updated");
    } else {
      setIsSaving(true);
      createRecord({
        sheetId: dataSource.id,
        values: formattedValues,
      }).then((newRecord) => {
        setRows((prevRows) => [...prevRows, newRecord]);
        setActiveRow(null);
        setActiveRowChanges({});
        setIsSaving(false);
        successNotification("Record created");
      });
    }
  };

  const handleDelete = () => {
    if (activeRow.frontly_id) {
      setConfirmationModalData({
        title: "Delete Record?",
        text: "Are you sure you want to delete this record? This action cannot be undone.",
        confirm: () => {
          deleteRecord({
            sheetId: dataSource.id,
            recordId: activeRow.frontly_id,
            rowIdColumn: "frontly_id",
          });

          setRows((prevRows) =>
            prevRows.filter((row) => row.frontly_id !== activeRow.frontly_id)
          );
          setActiveRow(null);
          setActiveRowChanges({});
          successNotification("Record deleted");
        },
      });
    }
  };

  let buttons = [
    {
      text: "Save Record",
      disabled: isEmpty(activeRowChanges),
      onClick: handleSave,
      isFetching: isSaving,
    },
  ];

  if (!isEmpty(activeRowChanges)) {
    buttons = [
      {
        text: "Discard Changes",
        type: "basic",
        onClick: () => {
          setActiveRow(null);
          setActiveRowChanges({});
        },
      },
      ...buttons,
    ];
  }

  if (activeRow && activeRow.frontly_id) {
    buttons = [
      {
        text: "Delete Record",
        type: "basic",
        onClick: handleDelete,
      },
      ...buttons,
    ];
  }

  return (
    <>
      <Row
        alignItems="center"
        justifyContent="space-between"
        gap="30px"
        margin="0 0 30px 0"
      >
        <Text
          data={{
            text: `${get(dataSource, "title")}${
              isFrontlyDb ? ` (${recordCount} records)` : ""
            }`,
            fontSize: 24,
            fontWeight: 600,
            margin: "0 0 10px 0",
          }}
        />

        <Button
          data={{
            text: "New Record",
            icon: "FiPlus",
            onClick: () => {
              setActiveRow({});
              setActiveRowChanges({});
            },
          }}
        />
      </Row>

      {isFrontlyDb && (
        <Tabs
          data={{
            margin: "20px 0 20px 0",
            tabs: [
              {
                label: "Settings",
                active: false,
                onClick: () => setTab("settings"),
              },
              {
                label: "Manage Data",
                active: true,
                onClick: () => setTab("data"),
              },
            ],
          }}
        />
      )}

      {activeRow && (
        <Modal
          hide={() => {
            setActiveRow(null);
            setActiveRowChanges({});
          }}
          header={{
            title: activeRow.frontly_id ? "Edit Record" : "New Record",
          }}
          buttons={buttons}
        >
          <AdminForm
            sectionPadding={"0px"}
            gridLayout
            fields={fields.filter((f) => {
              if (!activeRow.frontly_id) {
                return f.key !== "frontly_id";
              }
              return true;
            })}
            labelStyle={"headingSm"}
            onChange={(k, v) => {
              setActiveRow({ ...activeRow, [k]: v });
              setActiveRowChanges({ ...activeRowChanges, [k]: v });
            }}
          />
        </Modal>
      )}
      {isFetching ? (
        <Spinner />
      ) : (
        <Container>
          <TableComponent
            data={{
              preventDarkMode: true,
              isFetching,
              rows: rows.map((u) => ({
                ...u,
                onClick: () => setActiveRow(u),
              })),
              columns: fields,
            }}
          />
          <Pagination
            margin={"10px 15px 15px 15px"}
            currentPage={page}
            pageCount={pageCount}
            onChange={(p) => fetchData(p)}
          />
        </Container>
      )}
    </>
  );
};

export default DataSourceAdmin;

const Container = styled.div`
  width: 100%;
  padding: 0 0 10px 0;
  background: white;
  border-radius: 10px;
  box-shadow: var(--card-shadow);
`;
