import { Button, Row, SearchBar } from "app/components";
import { get, startCase, truncate } from "lodash";
import {
  rConfirmationModalData,
  rCustomBlocks,
  rSubscription,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import CardGrid from "../components/CardGrid";
import { CustomBlock } from "../components";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import { Label } from "../home/Pages";
import SettingsCard from "./components/SettingsCard";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { safeArray } from "app/utils/utils";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CustomBlocks = () => {
  const navigate = useNavigate();

  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  const [search, setSearch] = useState("");

  const subscription = useRecoilValue(rSubscription);

  const blocks = safeArray(customBlocks);

  const filteredBlocks = blocks.filter(
    (b) =>
      get(b, "name", "").toLowerCase().includes(search.toLowerCase()) ||
      get(b, "description", "").toLowerCase().includes(search.toLowerCase())
  );

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const [duplicatingId, setDuplicatingId] = useState(null);

  const deleteBlock = async (id) => {
    setConfirmationModalData({
      title: "Delete Custom Block",
      text: "Are you sure you want to delete this custom block? This is irreversible.",
      confirm: () => {
        apiRequest.delete(`/custom_blocks/${id}/`);
        setCustomBlocks((prev) => safeArray(prev).filter((b) => b.id !== id));
        successNotification("Custom Block Deleted");
      },
    });
  };

  const duplicateBlock = async (id) => {
    setDuplicatingId(id);

    const newBlockRes = await apiRequest.post(
      `/custom_blocks/duplicate/${id}/`
    );
    const newBlockObj = get(newBlockRes, "data", {});

    successNotification("Custom Block Duplicated");

    setDuplicatingId(null);

    setCustomBlocks((prev) => [...safeArray(prev), newBlockObj]);

    navigate(`/custom-block/${get(newBlockObj, "id", "")}`);
  };

  const getDropdownOptions = (b) => {
    return [
      {
        label: "Duplicate Block",
        icon: "FiCopy",
        onClick: () => duplicateBlock(b.id),
      },
      {
        label: "Delete Block",
        icon: "FiTrash",
        onClick: () => deleteBlock(b.id),
      },
    ];
  };

  // Show upgrade if user is not on a paid plan
  if (!subscription) {
    return (
      <SettingsSection
        hideCard
        width="100%"
        title="Custom Blocks"
        description="Create and manage custom blocks for more advanced styling customization"
        settingsCategory={"App Settings"}
      >
        <SettingsCard
          label="Custom Blocks"
          description="Create and manage custom reusable blocks for more advanced styling customization"
        >
          <Button
            data={{
              text: "Upgrade to access this feature",
              icon: "FiArrowUpCircle",
              onClick: () => navigate("/settings/billing"),
            }}
          />
        </SettingsCard>
      </SettingsSection>
    );
  }

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Custom Blocks"
      description="Create and manage custom blocks for more advanced styling customization"
      settingsCategory={"App Settings"}
    >
      <Row
        justifyContent="space-between"
        alignItems="center"
        margin="0 0 30px 0"
      >
        <Label>Custom Blocks</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search custom blocks",
              onChange: (v) => setSearch(v),
            }}
          />
          <CustomBlock isNew />
        </Row>
      </Row>

      {blocks.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any custom blocks"
          subtitle="Custom blocks are reusable components that you can use in your app. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716244997/Create_ljrvzs.svg"
          }
        />
      )}

      {filteredBlocks.length > 0 && (
        <CardGrid
          noResultsName={"custom blocks"}
          items={filteredBlocks.map((b) => {
            let blockType = "standard";

            if (b.reusable) {
              blockType = "reusable";
            }

            if (b.repeating) {
              blockType = "repeating";
            }

            const blockTypeColorMap = {
              standard: "#b8e7d2",
              reusable: "#AFE6F1",
              repeating: "#f9d29d",
            };

            const dropdownOptions = getDropdownOptions(b);

            return {
              title: b.name,
              description: truncate(b.description || "No description", {
                length: 60,
              }),
              onClick: () => navigate(`/custom-block/${b.id}`),
              isFetching: duplicatingId === b.id,
              badges: [
                {
                  color: blockTypeColorMap[blockType],
                  text: startCase(blockType),
                },
              ],
              options: dropdownOptions,
            };
          })}
          itemsPerPage={25}
        />
      )}
    </SettingsSection>
  );
};

export default CustomBlocks;
