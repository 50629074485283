import { Icon, Modal, Row, Text } from "app/components";
import { boxShadow, colors } from "app/utils/theme";

import { Template } from "../appManagement/Template";
import TemplateModal from "./TemplateModal";
import { get } from "lodash";
import { rOrganization } from "app/utils/recoil";
import styled from "styled-components";
import { templates } from "../new_templates/templates";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const CreateModal = ({ hide, showNewApp }) => {
  const [activeTemplateId, setActiveTemplateId] = useState(null);

  const navigate = useNavigate();

  const organization = useRecoilValue(rOrganization);

  if (activeTemplateId) {
    return (
      <TemplateModal
        activeTemplateId={activeTemplateId}
        setActiveTemplateId={setActiveTemplateId}
      />
    );
  }

  const newAiSystem = get(organization, "new_ai_system", false);

  return (
    <Modal
      minWidth="830px"
      header={{
        title: "How do you want to create your app?",
      }}
      hide={hide}
    >
      {!activeTemplateId && (
        <Row gap="15px">
          <CreateOption
            title="Create With AI"
            icon="HiSparkles"
            description={
              newAiSystem
                ? "Generate a starter app based on a prompt"
                : "This feature is undergoing a revamp and is temporarily disabled"
            }
            onClick={() => (newAiSystem ? navigate("/aibuilder") : null)}
            disabled={!newAiSystem}
          />
          <CreateOption
            title="Create From Scratch"
            description="Start with an empty app, then add pages manually"
            icon="FiFile"
            onClick={() => showNewApp("manual")}
          />
        </Row>
      )}
      <Text
        data={{
          text: "Or Select A Template:",
          fontStyle: "headingLg",
          margin: "5px 0 15px 0",
        }}
      />
      <Templates>
        {templates.map((t) => (
          <Template
            small
            isDashboard
            data={t}
            onClick={() => setActiveTemplateId(t.id)}
          />
        ))}
      </Templates>
    </Modal>
  );
};

export default CreateModal;

export const CreateOption = ({
  title,
  description,
  icon,
  onClick,
  disabled = false,
}) => {
  return (
    <CreateOptionWrapper
      disabled={disabled}
      onClick={disabled ? null : onClick}
      style={{ margin: "0 0 15px 0", opacity: disabled ? "0.6" : 1.0 }}
    >
      <Icon data={{ icon, color: "var(--primary-admin)", disabled }} />
      <Text
        data={{
          text: title,
          disabled,
          fontStyle: "headingLg",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
      <Text
        data={{
          text: description,
          fontStyle: "bodyMd",
          cursor: disabled ? "not-allowed" : "pointer",
          color: "var(--grey7)",
          disabled,
          textAlign: "center",
        }}
      />
    </CreateOptionWrapper>
  );
};

const CreateOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  align-self: stretch;
  border: 1px solid ${colors.pitchBorder};
  background: white;
  padding: 20px;
  cursor: pointer;
  ${(p) => p.disabled && "cursor: not-allowed;"}
  &:hover {
    box-shadow: ${boxShadow.card};
  }
`;

const Templates = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
