import { Button, FullScreenLoader, Text } from "app/components";
import { useEffect, useState } from "react";

import { AdminForm } from "../../components";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import styled from "styled-components";

const EditBuilderProfile = () => {
  const [profile, setProfile] = useState(null);
  const [changes, setChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const fields = [
    {
      id: "image",
      label: "Image",
      placeholder: "Image URL",
      hint: "The image to display on your profile. This can be your company logo or your own photo.",
      componentId: "ImageUpload",
      value: get(profile, "image"),
      imageHeight: 150,
      imageWidth: 150,
    },
    {
      id: "name",
      label: "Name",
      placeholder: "Company or Expert Name",
      hint: "The public name to display on your profile. This can be your company name or your own name.",
      componentId: "Input",
      value: get(profile, "name"),
    },
    {
      id: "location",
      label: "Location",
      placeholder: "Location",
      hint: "Where you are located in the world.",
      componentId: "Input",
      value: get(profile, "location"),
    },
    {
      id: "description",
      label: "Description",
      placeholder: "Description",
      hint: "The full description to display on your detailed profile.",
      componentId: "TextArea",
      minHeight: "150px",
      value: get(profile, "description"),
    },
  ];

  const save = () => {
    setIsSaving(true);
    apiRequest.patch("/builder_profile/", profile).then(() => {
      setChanges(false);
      setIsSaving(false);
    });
  };

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/builder_profile/").then((r) => {
      const res = get(r, "data", {});
      setIsFetching(false);
      setProfile(res);
    });
  }, []);

  const createProfile = () => {
    setIsSaving(true);
    apiRequest.post("/builder_profile/").then((r) => {
      const res = get(r, "data", {});
      setChanges(false);
      setIsSaving(false);
      setProfile(res);
    });
  };

  if (isFetching) {
    return <FullScreenLoader />;
  }

  return (
    <Card>
      <Text
        data={{
          text: "Manage Your Builder Profile",
          fontSize: 24,
          fontWeight: 600,
          margin: "0 0 20px 0",
        }}
      />

      {!profile && (
        <div>
          <Text
            data={{
              text: "You haven't created a Builder Profile. Create one to get started.",
            }}
          />
          <Button
            data={{
              text: "Create Builder Profile",
              onClick: createProfile,
              isFetching: isSaving,
              margin: "15px 0 0 0",
            }}
          />
        </div>
      )}

      {profile && (
        <AdminForm
          labelStyle="headingSm"
          sectionPadding="0px"
          fields={fields}
          submitText={"Save Changes"}
          submit={save}
          isFetching={isSaving}
          disableSubmit={!changes}
          onChange={(k, v) => {
            setChanges(true);
            setProfile({
              ...profile,
              [k]: v,
            });
          }}
        />
      )}
    </Card>
  );
};

export default EditBuilderProfile;

const Card = styled.div`
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  background: white;
  overflow: hidden;
  padding: 25px;
  width: 600px;
`;
