import { CardSection, Label } from "../home/Pages";
import { Row, SearchBar, Text } from "app/components";

import { Template } from "./Template";
import TemplateModal from "../components/TemplateModal";
import { templates } from "../new_templates/templates";
import { useState } from "react";

export const TemplatesSection = () => {
  const [activeTemplateId, setActiveTemplateId] = useState(null);

  const [search, setSearch] = useState("");

  const filteredTemplates = templates.filter((t) =>
    t.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      {activeTemplateId && (
        <TemplateModal
          activeTemplateId={activeTemplateId}
          setActiveTemplateId={setActiveTemplateId}
        />
      )}

      <Row justifyContent="space-between" margin="0 0 30px 0">
        <Label>Templates</Label>
        <SearchBar
          data={{
            value: search,
            placeholder: "Search templates",
            onChange: (v) => setSearch(v),
          }}
        />
      </Row>

      <CardSection style={{ margin: "25px 0 0 0" }}>
        {filteredTemplates.map((t) => (
          <Template
            small
            isDashboard
            data={t}
            onClick={() => setActiveTemplateId(t.id)}
          />
        ))}
        {filteredTemplates.length === 0 && (
          <Text data={{ text: "No templates found" }} />
        )}
      </CardSection>
    </div>
  );
};
