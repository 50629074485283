import { BlockTile, BlocksContainer } from "../BlockManager/NewBlock";
import { Modal, SearchBar, Tabs } from "app/components";
import { rActiveActionObject, rConfirmationModalData } from "app/utils/recoil";

import { AdminForm } from "..";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import useActions from "app/utils/useActions";
import { useSetRecoilState } from "recoil";
import { useState } from "react";

const EditActionStepModal = ({ obj }) => {
  const {
    actionId,
    currentStep, // The current data object for the step, containing its properties and values.
    disabledTypes, // An array of disabled step types, preventing certain step types from being selected.
    childCount, // The number of child steps that depend on this step, used to warn about dependencies when deleting.
    deleteStep, // A function to delete the current step, with confirmation if there are dependencies.
    onChange, // A function to handle changes to the step data, updating the state accordingly.
    previousSteps, // An array of steps that precede the current step, used for setting conditions or dependencies.
    dynamicSources, // An array of dynamic data sources available for the step, used in step configuration.
    excludedSources, // An array of data sources that should be excluded from the step's configuration.
  } = obj;

  const { getStepTypes } = useActions();

  // Save changes to the step
  const saveChanges = () => {
    onChange(localData);
    setActiveActionObject(null);
  };

  const [localData, setLocalData] = useState(currentStep);

  const stepId = get(localData, "id");

  const stepTypes = getStepTypes({
    step: localData,
    onChange,
    disabledTypes,
    actionId,
    stepId,
  });

  const stepType = get(localData, "type");

  const config = stepTypes.find((st) => st.type === stepType);

  const [tab, setTab] = useState("all");
  const title = get(config, "label", "Select Step Type");

  const setActiveActionObject = useSetRecoilState(rActiveActionObject);

  const [search, setSearch] = useState("");
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const editStepType = () =>
    setConfirmationModalData({
      title: "Reset Step Data?",
      text: "Changing the type will erase any current data for this step. Are you sure you want to continue?",
      confirm: () => {
        successNotification("Step Reset");
        setLocalData({ id: stepId, type: null });
      },
    });

  const confirmDelete = () => {
    if (childCount > 0) {
      setConfirmationModalData({
        title: "Delete Action Step?",
        text: "This step has depdendencies. Any references to this step in subsequent steps will need to be manually updated.",
        confirm: () => {
          successNotification("Step Deleted");
          deleteStep(stepId);
        },
      });
    } else {
      setConfirmationModalData({
        title: "Delete Action Step?",
        text: "This is irreversible. Are you sure you want to continue?",
        confirm: () => {
          successNotification("Step Deleted");
          deleteStep(stepId);
        },
      });
    }
  };

  const editType = stepType
    ? [
        {
          text: "Edit Type",
          type: "basic",
          icon: "FiEdit",
          onClick: editStepType,
        },
      ]
    : [];

  const displayedBlockTypes = stepTypes.filter((t) => {
    const matchesTab = tab === "all" || t.category === tab;
    const matchesSearch =
      search.trim() === "" ||
      t.label.toLowerCase().includes(search.toLowerCase()) ||
      (t.description &&
        t.description.toLowerCase().includes(search.toLowerCase()));
    return matchesTab && matchesSearch;
  });

  return (
    <Modal
      minWidth={stepType ? "550px" : "1000px"}
      background="var(--grey1)"
      header={{
        title,
        customComponent: title === "Select Step Type" && (
          <SearchBar
            data={{
              value: search,
              placeholder: "Search actions",
              onChange: (v) => setSearch(v),
            }}
          />
        ),
      }}
      buttons={[
        {
          text: "Delete",
          type: "basic",
          icon: "FiTrash",
          onClick: confirmDelete,
        },
        ...editType,
      ]}
      hide={saveChanges}
    >
      <>
        {/* STEP TYPE SELECTION */}
        {!stepType && (
          <StepTypeSelect
            tab={tab}
            setTab={setTab}
            blockTypes={displayedBlockTypes}
            onChange={(k, v) => setLocalData({ ...localData, [k]: v })}
          />
        )}

        {/* STEP FIELDS */}
        {stepType && (
          <EditStepFields
            config={config}
            previousSteps={previousSteps}
            dynamicSources={dynamicSources}
            excludedSources={excludedSources}
            onChange={(k, v) => setLocalData({ ...localData, [k]: v })}
            data={localData}
          />
        )}
      </>
    </Modal>
  );
};

export default EditActionStepModal;

const StepTypeSelect = ({ tab, setTab, blockTypes, onChange }) => {
  return (
    <>
      <Tabs
        data={{
          margin: "0 0 15px 0",
          tabs: [
            {
              label: "All",
              active: tab === "all",
              onClick: () => setTab("all"),
            },
            {
              label: "Data",
              active: tab === "data",
              onClick: () => setTab("data"),
            },
            {
              label: "Generative AI",
              active: tab === "ai",
              onClick: () => setTab("ai"),
            },
            {
              label: "Other",
              active: tab === "other",
              onClick: () => setTab("other"),
            },
            {
              label: "Advanced",
              active: tab === "advanced",
              onClick: () => setTab("advanced"),
            },
          ],
        }}
      />
      <BlocksContainer count={blockTypes.length}>
        {blockTypes.map((t) => (
          <BlockTile
            disabledMessage={t.disabledMessage}
            onClick={() => onChange("type", t.type)}
            title={t.label}
            description={t.description}
            icon={t.icon || "FiGrid"}
          />
        ))}
      </BlocksContainer>
    </>
  );
};

const EditStepFields = ({
  config,
  previousSteps,
  dynamicSources,
  excludedSources,
  data,
  onChange,
}) => {
  const fields = [
    ...get(config, "fields", []),
    {
      id: "conditions",
      sectionHint:
        "Set conditions to determine whether this step and any of it's children will be run or not.",
      componentId: "DisplayConditions",
      orientation: "vertical",
      section: "Run Conditions",
    },
  ];

  const otherContent = get(config, "otherContent");

  return (
    <AdminForm
      otherContent={otherContent}
      sectionPadding={"0px 0px 15px 0px"}
      previousSteps={previousSteps}
      dynamicSources={dynamicSources}
      excludedSources={excludedSources}
      gridLayout
      onChange={(k, v) => onChange(k, v)}
      fields={fields
        .filter(
          (f) =>
            !f.displayCondition || (f.displayCondition && f.displayCondition(f))
        )
        .map((f) => ({
          ...f,
          value: get(data, f.id),
        }))}
    />
  );
};
