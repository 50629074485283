import { Button } from "app/components";
import { spacing } from "app/utils/theme";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Logo = styled.img`
  height: 40px;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 15px 25px;
  width: 100%;
  background: white;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
`;

const HeaderLink = styled.div`
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: var(--grey8);

  ${(p) =>
    p.hideMobile &&
    `
    @media screen and (max-width: 800px) {
      display: none;
    }
  `}
`;

const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.s8};
`;

export const Header = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <Logo
        alt="Frontly Logo"
        src={
          "https://res.cloudinary.com/frontly/image/upload/v1737557733/frontly_black_abjsh6_hn3daa.svg"
        }
        onClick={() => navigate("/")}
      />
      <HeaderCenter>
        <HeaderLink hideMobile onClick={() => navigate("/templates")}>
          Templates
        </HeaderLink>
        <HeaderLink
          hideMobile
          onClick={() => window.open("https://www.frontly.ai/pricing")}
        >
          Pricing
        </HeaderLink>
        <HeaderLink
          onClick={() => window.open("https://discord.gg/8wnQndpBGU")}
        >
          Community
        </HeaderLink>
        <HeaderLink
          onClick={() => window.open("https://www.youtube.com/@frontlyapp")}
        >
          YouTube
        </HeaderLink>
        <HeaderLink
          hideMobile
          onClick={() => window.open("https://help.frontly.ai")}
        >
          Help Center
        </HeaderLink>
        <HeaderLink hideMobile onClick={() => navigate("/login")}>
          Login
        </HeaderLink>
      </HeaderCenter>
      <Button
        data={{
          text: "Start for free",
          onClick: () => navigate("/signup"),
        }}
      />
    </HeaderContainer>
  );
};
