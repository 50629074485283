import { asyncActionTypes, isFrontlyAdmin } from "./utils/utils";
import { get, isObject } from "lodash";
import {
  rAiBlockData,
  rAiView,
  rApp,
  rFetchingBlockIds,
  rFormIsFetching,
  rForms,
  rPageActions,
  rSpinner,
  rUser,
  rWebsocketRequests,
} from "./utils/recoil";
import { useCallback, useEffect, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import { successNotification } from "./utils/Notification";
import useActionResolver from "./renderingApp/useActionResolver";
import useDynamicText from "./renderingApp/useDynamicText";
import usePage from "./utils/usePage";
import useSpreadsheetRequests from "./useSpreadsheetRequests";

const hasAsyncActions = (page, pageActions) => {
  let hasAsyncActions = false;
  if (page) {
    let actions = [];

    if (page.isForm) {
      const completeButtonAction = get(page, "completeButtonAction", {});
      const submitAction = get(page, "submitAction", {});

      if (completeButtonAction) {
        actions = [...actions, completeButtonAction];
      }

      if (submitAction) {
        actions = [...actions, submitAction];
      }
    } else {
      actions = pageActions;
    }

    actions.forEach((a) => {
      get(a, "steps", []).forEach((s) => {
        if (asyncActionTypes.includes(s.type)) {
          if (["OPEN_AI", "AI_GENERATE_RECORDS"].includes(s.type)) {
            hasAsyncActions = true;
          } else {
            if (s.waitForResponse) {
              hasAsyncActions = true;
            }
          }
        }
      });
    });
  }

  return hasAsyncActions;
};

const useWebsocket = () => {
  const user = useRecoilValue(rUser);

  const page = usePage();

  const app = useRecoilValue(rApp);

  const setSpinner = useSetRecoilState(rSpinner);

  const appId = get(app, "id");
  const userId = get(user, "id");

  const forms = useRecoilValue(rForms);

  const webhookTestMode = false;

  const { createMultipleRecords } = useSpreadsheetRequests();

  const [websocket, setWebsocket] = useState(null);
  const [history, setHistory] = useState([]);
  const [responses, setResponses] = useState([]);

  const [aiView, setAiView] = useRecoilState(rAiView);
  const [aiBlockData, setAiBlockData] = useRecoilState(rAiBlockData);

  const [formIsFetching, setFormIsFetching] = useRecoilState(rFormIsFetching);

  const websocketRequests = useRecoilValue(rWebsocketRequests);
  const requestCount = websocketRequests.length;
  const { actionResolver } = useActionResolver();

  // console.log("WEBSOCKET REQUESTS", websocketRequests);

  const clearFetchingBlocks = useResetRecoilState(rFetchingBlockIds);

  const pageActions = useRecoilValue(rPageActions);

  const hasAsync = hasAsyncActions(page, pageActions);

  const shouldConnect = requestCount > 0 || hasAsync;

  const { processDynamicText } = useDynamicText();

  useEffect(() => {
    const toProcess = responses.filter((r) => !history.includes(r.instance_id));
    toProcess.forEach((r) => processResponse(r));
  }, [responses]);

  const processResponse = async (response) => {
    const responseType = get(response, "type");
    const instanceId = get(response, "instance_id");

    const matchingInstance = websocketRequests.find(
      (r) => r.instanceId === instanceId
    );

    const matchingStep = get(matchingInstance, "step", {});

    if (matchingInstance && !history.includes(instanceId)) {
      let rawAction = null;

      if (matchingInstance.rawAction) {
        rawAction = matchingInstance.rawAction;
      }

      if (matchingInstance.formId) {
        const form = forms.find((f) => f.id === matchingInstance.formId);
        const formAction = get(form, matchingInstance.formActionType, []);
        rawAction = formAction;
      }
      let contextObject = matchingInstance.context;

      const stepType = get(matchingStep, "type", null);

      // MULTIPLE RECORDS
      if (
        stepType === "AI_GENERATE_RECORDS" &&
        responseType === "openai_action" &&
        get(matchingStep, "mode", "multiple_records") === "multiple_records"
      ) {
        const fieldData = get(matchingStep, "fieldData", {});
        const config = get(fieldData, "config", {});

        let staticValues = {};
        Object.keys(config).forEach((key) => {
          const value = processDynamicText({
            text: get(config, [key, "value"], null),
            context: contextObject,
          });
          if (value) {
            staticValues[key] = value;
          }
        });

        const aiResponse = get(response, "response");
        const records = get(aiResponse, "records", []).map((r, i) => ({
          ...r,
          ...staticValues,
          frontly_id: i,
        }));

        const aiResponseView = get(matchingStep, "aiResponseView", false);
        clearFetchingBlocks();
        if (aiResponseView) {
          // Show ai response view
          setAiView({
            type: "multiple_records",
            dataSource: matchingStep.dataSource,
            records,
          });
          setSpinner(null);
        } else {
          // Create records immediately

          await createMultipleRecords({
            sheetId: matchingStep.dataSource,
            rows: records,
          });
          setSpinner(null);
          setAiView(null);
        }
      } else if (responseType === "openai_action") {
        // If responseType is text, this will just be text. if responseType is object, this will be an object
        const aiResponse = get(response, "response");

        const step = get(contextObject, "step", {});
        const actionSteps = get(contextObject, "actionSteps", {});

        const aiResponseType = get(step, "responseType", "text");

        // This removes the need to do response.field for objects
        let actionStepsResponseValue = { response: aiResponse };
        if (isObject(aiResponse) || aiResponseType === "object") {
          actionStepsResponseValue = { ...aiResponse, response: aiResponse };
        }

        contextObject = {
          ...contextObject,
          step: {
            ...step,
            [matchingInstance.currentStepCount]: aiResponse,
          },
          actionSteps: {
            ...actionSteps,
            [matchingInstance.currentStepCount]: actionStepsResponseValue,
          },
        };
      } else if (responseType === "inbound_webhook") {
        const responseData = get(response, "data", {});

        const actionSteps = get(contextObject, "actionSteps", {});

        if (webhookTestMode) {
          successNotification(JSON.stringify(responseData));
        }

        contextObject = {
          ...contextObject,
          step: {
            ...get(contextObject, "step", {}),
            [matchingInstance.currentStepCount]: responseData,
          },
          actionSteps: {
            ...actionSteps,
            [matchingInstance.currentStepCount]: {
              ...response,
              ...responseData,
            },
          },
        };
      }

      const formId = get(matchingInstance, "formId");
      const formActionType = get(matchingInstance, "formActionType");

      if (formId && formActionType) {
        setFormIsFetching(null);
      }

      // If ai block don't run action resolver
      if (responseType === "ai_block") {
        const responseData = get(response, "response", {});

        const answer = get(responseData, "answer", "Sorry, please try again.");

        setAiBlockData((prevData) => ({
          ...prevData,
          [get(matchingInstance, "blockId")]: {
            isFetching: false,
            data: answer,
          },
        }));

        // Add instanceId to history to prevent duplicate runs
        setHistory((prevHistory) => [...prevHistory, instanceId]);

        return;
      }

      const resolvedNextStep =
        matchingInstance.getNextStepWithContext(contextObject);

      if (resolvedNextStep) {
        // Ensure a triggered action is only resolved once
        actionResolver({
          rawAction,
          responseType,
          actionId: matchingInstance.actionId,
          context: contextObject,
          startFromStepId: resolvedNextStep.id,
          startFromStepCount: matchingInstance.nextStepCount,
          blockId: matchingInstance.blockId,
          hasStartedFetchingInit: true, // make this true because it's already fetching from before
        });
      }

      // Add instanceId to history to prevent duplicate runs
      setHistory((prevHistory) => [...prevHistory, instanceId]);
    }
  };

  // Function to initialize WebSocket connection
  const connect = useCallback(() => {
    if (!isFrontlyAdmin && app && shouldConnect && !websocket) {
      const wsURL = `wss://f8lzd569pg.execute-api.ca-central-1.amazonaws.com/production?app_id=${appId}&function=automation_webhook_connection`;

      const ws = new WebSocket(wsURL);

      ws.onopen = () => {
        console.log("WebSocket Connected");
        setWebsocket(ws);
      };

      ws.onmessage = (event) => {
        const wsResponse = JSON.parse(event.data);
        setResponses((r) => [...r, wsResponse]);
      };

      ws.onclose = () => {
        console.log("WebSocket Disconnected");
        setWebsocket(null);
      };

      return () => ws.close();
    }
  }, [appId, userId, shouldConnect, websocket]);

  // Function to close WebSocket connection
  const disconnect = useCallback(() => {
    if (websocket) {
      websocket.close();
    }
  }, [websocket]);

  useEffect(() => {
    connect();
    return () => disconnect();
  }, [connect, disconnect]);

  return { connect, disconnect };
};

export default useWebsocket;
