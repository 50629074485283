import { Column, Icon, Row } from "app/components";

import { colors } from "app/utils/theme";
import { resizeImage } from "app/utils/utils";
import styled from "styled-components";
import { truncate } from "lodash";
import { useState } from "react";

const BuilderProfile = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  let description = data.description;
  if (!expanded) {
    description = truncate(description, { length: 450 });
  }

  return (
    <Container>
      <Image
        src={resizeImage({
          url: data.image,
          width: 200,
          height: 200,
        })}
      />
      <Column gap="10px">
        <Name>{data.name}</Name>
        <Row alignItems="center" gap="5px">
          <Icon data={{ icon: "FiMapPin", color: "var(--grey7)", size: 18 }} />
          <ProfileLocation>{data?.location || "No Location"}</ProfileLocation>
        </Row>
        <Description>{description}</Description>
        <ShowAll onClick={() => setExpanded(!expanded)}>
          {expanded ? "Show Less" : "Show More"}
        </ShowAll>
      </Column>
    </Container>
  );
};

export default BuilderProfile;

const Container = styled.div`
  display: flex;
  gap: 25px;
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  margin-bottom: 30px;
`;

const Image = styled.img`
  min-width: 130px;
  max-width: 130px;
  min-height: 130px;
  max-height: 130px;
  object-fit: cover;
  border-radius: 20px;
`;

const Name = styled.div`
  font-size: 26px;
  font-weight: 600;
`;

const ProfileLocation = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: var(--grey7);
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: var(--grey9);
  margin-top: 10px;
  white-space: pre-line;
`;

const ShowAll = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.frontlyBlue};
  cursor: pointer;
`;
