import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "../settings/components/SettingsCard";
import { rActiveActionObject, rApp } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { AdminForm } from "../components";
import Authentication from "../settings/Authentication";
import EditActionStepModal from "../components/Action/EditActionStepModal";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const SignupSettings = () => {
  const [app, setApp] = useRecoilState(rApp);

  const activeActionObject = useRecoilValue(rActiveActionObject);

  // USER GROUPS
  const defaultUserGroup = get(app, "default_user_group");
  const userGroups = get(app, "user_groups", []) || [];
  const [changes, setChanges] = useState(false);

  const userSignupAction = get(app, "user_signup_action", {});
  const userLoginAction = get(app, "user_login_action", {});

  const signupActionHasNavigateStep = get(userSignupAction, "steps", []).find(
    (s) => s.type === "NAVIGATE"
  );

  const loginActionHasNavigateStep = get(userLoginAction, "steps", []).find(
    (s) => s.type === "NAVIGATE"
  );

  const saveAuthChanges = () => {
    setChanges(false);
    successNotification("User Groups Saved");
    apiRequest.post("/app_settings/", {
      default_user_group: defaultUserGroup,
      user_signup_action: userSignupAction,
      user_login_action: userLoginAction,
      disable_login_default_page: get(app, "disable_login_default_page"),
      disable_signup_default_page: get(app, "disable_signup_default_page"),
    });
  };

  const authFields = [
    {
      id: "default_user_group",
      label: "Default User Group",
      componentId: "Select",
      hint: "Set the default user group for new users who sign up to your app",
      value: defaultUserGroup,
      options: userGroups.map((g) => ({
        label: g.name,
        value: g.id,
      })),
    },
    {
      id: "user_login_action",
      label: "Login Action",
      componentId: "Action",
      hint: "Trigger a custom action when a user logs in to your app",
      disabledTypes: ["REFRESH_BLOCKS"],
      customAction: userLoginAction,
      customOnChange: (v) => {
        setChanges(true);
        setApp({
          user_login_action: {
            ...userLoginAction,
            steps: v,
          },
        });
      },
    },
    {
      label: "Signup Action",
      componentId: "Action",
      id: "user_signup_action",
      hint: "Trigger a custom action when a new user registers for your app",
      disabledTypes: ["REFRESH_BLOCKS"],
      customAction: userSignupAction,
      customOnChange: (v) => {
        setChanges(true);
        setApp({
          user_signup_action: {
            ...userSignupAction,
            steps: v,
          },
        });
      },
    },
    {
      id: "disable_signup_default_page",
      label: "Disable 'default' page setting for signup",
      componentId: "Switch",
      hint: "Used when your signup action includes a 'navigate' step",
      value: get(app, "disable_signup_default_page"),
      customOnChange: (v) => {
        setChanges(true);
        setApp({
          disable_signup_default_page: v,
        });
      },
      displayCondition: () => signupActionHasNavigateStep,
    },
    {
      id: "disable_login_default_page",
      label: "Disable 'default' page setting for login",
      hint: "Used when your login action includes a 'navigate' step",
      componentId: "Switch",
      value: get(app, "disable_login_default_page"),
      customOnChange: (v) => {
        setChanges(true);
        setApp({
          disable_login_default_page: v,
        });
      },
      displayCondition: () => loginActionHasNavigateStep,
    },
  ].filter((f) => !get(f, "displayCondition") || f.displayCondition());

  return (
    <>
      {activeActionObject && <EditActionStepModal obj={activeActionObject} />}

      <SettingsSection
        title="User Signup & Login"
        description="Configure settings for login, signup"
        settingsCategory={"App Settings"}
        hideCard
        width="100%"
      >
        <ColumnsRow>
          <CardsColumn>
            <SettingsCard
              label="Default User Group & Login/Signup Actions"
              description="Configure settings for login, signup"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={authFields}
                submitText={"Save Changes"}
                submit={changes && saveAuthChanges}
                orientation={"horizontal"}
                width="220px"
                onChange={(k, v) => {
                  setChanges(true);
                  setApp({
                    [k]: v,
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>
          <CardsColumn>
            <Authentication />
          </CardsColumn>
        </ColumnsRow>
      </SettingsSection>
    </>
  );
};

export default SignupSettings;
