import { Button, Column, Modal, Row, Text } from "app/components";
import { get, startCase } from "lodash";
import {
  rAppDateFormat,
  rDateFieldsModalData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { DateFormatConfig } from "../components";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const DateFieldsModal = () => {
  const appDateFormat = useRecoilValue(rAppDateFormat);
  const [dateFields, setDateFields] = useRecoilState(rDateFieldsModalData);

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  if (!dateFields) return null;

  return (
    <Modal
      hide={() => setDateFields(null)}
      header={{ title: "Date Columns Detected" }}
    >
      <Text
        data={{
          fontStyle: "bodyLg",
          text: "Confirm the correct date format for each date column to continue:",
        }}
      />

      <Column gap="15px" margin="20px 0 0 0">
        {dateFields.map((df) => {
          const m = savedSpreadsheets.find((s) => s.id === df.id);
          const firstThreeDates = get(m, "data", [])
            .slice(0, 3)
            .map((d) => get(d, df.field))
            .join(", ");
          const fd = get(m, "field_data");
          const fdc = get(fd, "config", {});
          const fdcv = get(fdc, df.field);

          const dateFormat = get(
            fdcv,
            "dateFormat",
            get(appDateFormat, "inputDate")
          );

          return (
            <DateContainer>
              <Text
                data={{ text: startCase(df.field), fontStyle: "headingMd" }}
              />
              <Row gap="10px">
                <Text
                  data={{
                    text: "Samples From Your Sheet:",
                    fontStyle: "headingSm",
                  }}
                />
                <Text
                  data={{
                    text: firstThreeDates,
                    fontStyle: "bodyMd",
                  }}
                />
              </Row>

              <Row gap="10px">
                <Text
                  data={{
                    text: "Current Format Setting:",
                    fontStyle: "headingSm",
                  }}
                />
                <Text
                  data={{
                    text: get(dateFormat, "inputDate"),
                    fontStyle: "bodyMd",
                  }}
                />
              </Row>

              <DateFormatConfig
                data={{
                  value: dateFormat,
                  enabledFields: ["inputDate", "inputTime"],
                  onChange: (newDateFormatObj) => {
                    setSavedSpreadsheets(
                      savedSpreadsheets.map((s) => {
                        if (s.id === df.id) {
                          let newObj = {
                            ...fd,
                            config: {
                              ...fdc,
                              [df.field]: {
                                ...fdcv,
                                dateFormat: newDateFormatObj,
                              },
                            },
                          };

                          return {
                            ...s,
                            field_data: newObj,
                          };
                        }
                        return s;
                      })
                    );
                  },
                }}
              />
            </DateContainer>
          );
        })}
      </Column>
      <Button
        data={{
          margin: "15px 0 0 0",
          text: "Save Date Formats",
          onClick: () => {
            setDateFields(null);
            successNotification("Date Formats Saved");

            // Bulk save edited sheets
            dateFields.forEach((df) => {
              const m = savedSpreadsheets.find((s) => s.id === df.id);
              apiRequest.post("/sheet_management/", m);
            });
          },
        }}
      />
    </Modal>
  );
};

export default DateFieldsModal;

const DateContainer = styled.div`
  border: 1px solid var(--grey3);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
