import { Button, Modal, Text } from "app/components";
import {
  rActions,
  rApp,
  rApps,
  rPages,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { AdminForm } from "app/adminApp/components";
import DataSourcesDisplay from "./DataSourcesDisplay";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getCustomVariablesFromValue } from "app/renderingApp/fetchSpreadsheets";
import { safeArray } from "app/utils/utils";
import styled from "styled-components";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useNavigate } from "react-router-dom";

const CreateListingModal = ({ hide }) => {
  const [actions, setActions] = useRecoilState(rActions);
  const dataSources = useRecoilValue(rSavedSpreadsheets);

  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { processDynamicText } = useDynamicText();

  // Fetch actions if not already fetched
  useEffect(() => {
    if (actions.length === 0) {
      setIsFetching(true);
      apiRequest.get("/actions/").then((r) => {
        const newActions = get(r, "data", []);
        setActions(newActions);
        setIsFetching(false);
      });
    }
  }, []);

  const [data, setData] = useState({
    type: "page",
    instance_id: null,
  });

  // const apps = useRecoilValue(rApps);
  const pages = useRecoilValue(rPages);

  const navigate = useNavigate();

  const app = useRecoilValue(rApp);

  const vars = safeArray(app, "custom_variables");

  let activeDataSources = [];

  let instanceField = {
    id: "instance_id",
    componentId: "Select",
    hideEmptyItem: true,
    showSearch: true,
    value: data.instance_id,
  };

  let activeVariableKeys = [];
  let customVarDataSourceIds = [];

  // App
  // if (data.type === "app") {
  //   instanceField = {
  //     ...instanceField,
  //     label: "App",
  //     options: apps.map((a) => ({ label: a.name, value: a.id })),
  //   };
  // }

  const instanceId = get(data, "instance_id");

  const matchingPage = instanceId && pages.find((p) => p.id === instanceId);

  let hasCustomBlocks = false;

  // Page
  if (data.type === "page") {
    if (matchingPage) {
      // Custom Var DS IDS and keys ------------------------------------
      // Handle grabbing data source IDs and variable keys from the page and action data
      const customVarObj = getCustomVariablesAndDataSources({
        vars,
        pages: [matchingPage],
        processDynamicText,
      });

      customVarDataSourceIds = get(customVarObj, "dataSourceIds", []);
      activeVariableKeys = get(customVarObj, "variableKeys", []);
      // Custom Var DS IDS and keys ------------------------------------

      const pageActions = get(matchingPage, "actions", []);

      const blocks = get(matchingPage, "blocks", []);

      const customBlocks = blocks.filter(
        (b) => get(b, "componentId") === "Custom"
      );

      hasCustomBlocks = customBlocks.length > 0;

      const blockDataSourceIds = blocks
        .filter((b) => get(b, "spreadsheet"))
        .map((b) => get(b, "spreadsheet"));

      let actionDataSourceIds = [];

      // Find actions that reference data sources
      pageActions.forEach((action) => {
        actionDataSourceIds = [
          ...actionDataSourceIds,
          ...getDataSourceIdsFromAction(action),
        ];
      });

      // Find active data sources
      activeDataSources = dataSources.filter((ds) =>
        [
          ...customVarDataSourceIds,
          ...blockDataSourceIds,
          ...actionDataSourceIds,
        ].includes(ds.id)
      );
    }

    instanceField = {
      ...instanceField,
      label: "Page",
      options: pages.map((a) => ({ label: a.name, value: a.id })),
    };
  }

  // Action
  if (data.type === "action") {
    const matchingAction =
      instanceId && actions.find((a) => a.id === instanceId);

    if (matchingAction) {
      // Custom Var DS IDS and keys ------------------------------------
      // Handle grabbing data source IDs and variable keys from the page and action data
      const customVarObj = getCustomVariablesAndDataSources({
        vars,
        // Spoofing 'pages' with actions to keep the same format
        pages: [
          {
            actions: matchingAction,
          },
        ],
        actions,
        processDynamicText,
      });

      customVarDataSourceIds = get(customVarObj, "dataSourceIds", []);
      activeVariableKeys = get(customVarObj, "variableKeys", []);
      // Custom Var DS IDS and keys ------------------------------------

      // Find active data sources
      activeDataSources = dataSources.filter((ds) =>
        [
          ...customVarDataSourceIds,
          ...getDataSourceIdsFromAction(matchingAction),
        ].includes(ds.id)
      );
    }

    instanceField = {
      ...instanceField,
      label: "Action",
      options: actions.map((a) => ({ label: a.name, value: a.id })),
    };
  }

  let fields = [
    {
      id: "type",
      label: "Listing Type",
      componentId: "SelectToggle",
      value: data.type,
      tabs: [
        // {
        //   label: "App",
        //   value: "app",
        // },
        {
          label: "Page",
          value: "page",
        },
        {
          label: "Action",
          value: "action",
        },
      ],
    },
    instanceField,
  ];

  const hasDataSources = activeDataSources.length > 0;

  const createListing = () => {
    setIsCreating(true);
    apiRequest
      .post("/marketplace_listings/", {
        ...data,
        data_source_ids: activeDataSources.map((ds) => ds.id),
        custom_variables: vars.filter((v) =>
          activeVariableKeys.includes(v.key)
        ),
      })
      .then((r) => {
        navigate(`/marketplace_admin/listing/${get(r, "data.id")}`);
        setIsCreating(false);
      });
  };

  // Add DS copying warning
  if (hasDataSources && !hasCustomBlocks) {
    fields = [
      ...fields,
      {
        id: "copyDataSources",
        label: "I agree to copying app data",
        description: `I certify that there is no sensitive data in this ${data.type} or it's associated data sources and custom variables, and I understand that the data sources and custom variables used will be copied in their entirety when installed into other user's accounts.`,
        componentId: "Switch",
        value: data.copyDataSources,
      },
    ];
  }

  return (
    <Modal
      hide={hide}
      minWidth={"450px"}
      header={{ title: "Create Listing" }}
      isFetching={isFetching}
    >
      <AdminForm
        sectionPadding={"0px"}
        labelStyle="headingSm"
        onChange={(k, v) => setData({ ...data, [k]: v })}
        fields={fields}
      />

      {!hasCustomBlocks && hasDataSources && (
        <DataSourcesDisplay
          dataSources={activeDataSources}
          type={data.type}
          margin="20px 0 0 0"
        />
      )}

      {hasCustomBlocks && (
        <div>
          <Text
            data={{
              text: "Custom blocks detected",
              fontSize: 18,
              fontWeight: 600,
              margin: "20px 0 0 0",
            }}
          />
          <Text
            data={{
              text: "Sorry, but Custom blocks are not currently supported in Marketplace listings. Please select a page without Custom blocks.",
              fontSize: 16,
              margin: "5px 0 0 0",
              fontWeight: 300,
            }}
          />
        </div>
      )}

      {!hasCustomBlocks && (
        <Button
          data={{
            text: "Create Listing",
            onClick: createListing,
            margin: "20px 0 0 0",
            disabled:
              get(data, "instance_id") === null ||
              (hasDataSources && !get(data, "copyDataSources")),
            isFetching: isCreating,
          }}
        />
      )}
    </Modal>
  );
};

export default CreateListingModal;

const getDataSourceIdsFromAction = (action) => {
  let actionDataSourceIds = [];

  const actionSteps = safeArray(
    get(action, ["config", "steps"]) || get(action, "steps")
  );

  actionSteps
    .filter((s) => ["GOOGLE", "AI_GENERATE_RECORDS"].includes(s.type))
    .forEach((step) => {
      if (step.type === "GOOGLE" && step.spreadsheet) {
        actionDataSourceIds.push(step.spreadsheet);
      } else if (step.type === "AI_GENERATE_RECORDS" && step.dataSource) {
        actionDataSourceIds.push(step.dataSource);
      }
    });

  return actionDataSourceIds;
};

const getCustomVariablesAndDataSources = ({
  vars,
  pages,
  processDynamicText,
}) => {
  let dataSourceIds = [];
  let variableKeys = [];

  pages.forEach((p) => {
    // CUSTOM VARIABLES FROM PAGE ACTIONS
    getCustomVariablesFromValue(
      JSON.stringify(p),
      vars,
      processDynamicText
    ).forEach((v) => {
      if (!dataSourceIds.includes(v.spreadsheet)) {
        dataSourceIds.push(v.spreadsheet);
      }
      if (!variableKeys.includes(v.key)) {
        variableKeys.push(v.key);
      }
    });
  });

  return {
    dataSourceIds,
    variableKeys,
  };
};
