import {
  Button,
  Card,
  CardContent,
  Drawer,
  Icon,
  Row,
  Text,
} from "app/components";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isEqual,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { colors, spacing } from "app/utils/theme";
import {
  defaultTrue,
  getPixels,
  isFrontlyAdmin,
  parseDateWithFormatObject,
  truncateText,
} from "app/utils/utils";
import {
  rApp,
  rAppDateFormat,
  rDarkMode,
  rSavedSpreadsheets,
  rTranslations,
} from "app/utils/recoil";
import styled, { css } from "styled-components";
import { useRef, useState } from "react";

import { get } from "lodash";
import { getBadgeColorMap } from "../Table";
import moment from "moment";
import useActionResolver from "app/renderingApp/useActionResolver";
import useContainerDimensions from "app/utils/useContainerDimensions";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useUtils from "app/renderingApp/useUtils";

// main
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s10};
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

// left side
const CalendarWrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.s2};
  border: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightText : "var(--grey21)")};
  border-radius: ${(p) => getPixels(p.borderRadius || 8)};
`;

const Month = styled.div`
  color: ${(p) => (p.darkMode ? colors.darkModeLightText : "#111827")};
  font-weight: 600;
`;

const DaysOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-top: 0.5rem;
  color: ${(p) => (p.darkMode ? colors.darkModeLightText : "#6b7280")};
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
`;

const DaysOfMonth = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: 100%;

  border-left: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightText : "var(--grey21)")};
  border-top: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightText : "var(--grey21)")};
`;

const Day = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid var(--grey21);
  cursor: pointer;
  overflow: hidden;
  border-radius: 0px;
  width: 100%;
  border: 0px;
  min-height: 50px;
  background-color: ${(p) =>
    p.disabled ? p.eventCardColor || colors.grey1 : ""};
  border-right: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightText : "var(--grey21)")};
  border-bottom: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightText : "var(--grey21)")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: ${(p) => getPixels(p.height)};
  padding: 12px;
  ${(p) => p.height < 100 && "padding: 8px;"}
  ${(p) => p.height < 50 && "padding: 5px;"}

  ${({ day, selectedDay, firstDayCurrentMonth }) => {
    if (
      !isEqual(day, selectedDay) &&
      !isToday(day) &&
      isSameMonth(day, firstDayCurrentMonth) &&
      isToday(day)
    )
      return css`
        color: #111827;
      `;
  }};

  ${({ day, selectedDay, firstDayCurrentMonth }) => {
    if (
      !isEqual(day, selectedDay) &&
      !isToday(day) &&
      !isSameMonth(day, firstDayCurrentMonth)
    )
      return css`
        color: #9ca3af;
      `;
  }};

  ${({ day, selectedDay, darkMode }) => {
    return css`
        &:hover {
          background-color: ${
            darkMode ? colors.darkModeInputBackground : "var(--grey1)"
          }};
        }
      `;
  }};

  ${({ day, selectedDay }) => {
    if (isEqual(day, selectedDay) || isToday(day))
      return css`
        font-weight: 600;
      `;
  }};
`;

const Bullet = styled.div`
  min-height: 8px;
  min-width: 8px;
  max-height: 8px;
  max-width: 8px;
  border-radius: 50%;
  background: ${(p) => p.color};
`;

const DateDetails = styled.div`
  width: 350px;
  min-width: 350px;
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }
`;

DateDetails.displayName = "DateDetails";

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.s4};
  margin-top: 15px;
`;

const eventOverlapsWithDay = (e, day, dateFormat) => {
  // Because we are just doing a basic string matching for the date, we don't want any time stuff here
  const df = {
    ...dateFormat,
    // outputDate: "YYYY-MM-DD",
    inputTime: null,
    outputTime: null,
    showAmPm: false,
  };

  const start = parseDateWithFormatObject({
    value: e.startDate,
    formatObject: df,
    returnMoment: true,
  });

  const endDate = parseDateWithFormatObject({
    value: e.endDate,
    formatObject: df,
    returnMoment: true,
  });

  const thisDay = parseDateWithFormatObject({
    value: day,
    formatObject: df,
    returnMoment: true,
  });

  if (e.endDate) {
    // COMPARE RANGE
    if (start && endDate && thisDay) {
      const isAfterStart = start.isSameOrBefore(thisDay);
      const isBeforeEnd = endDate.isSameOrAfter(thisDay);
      if (isAfterStart && isBeforeEnd) {
        return true;
      }
    }
  } else {
    // COMPARE SINGLE DATE
    if (start && thisDay && start.isSame(thisDay)) {
      return true;
    }
  }
};

const Calendar = ({ block, page, showCreate }) => {
  const { processObjects } = useProcessObjects();
  const appDateFormat = useRecoilValue(rAppDateFormat);

  const { getListData } = useListData();

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const app = useRecoilValue(rApp);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const eventColors = get(block, "eventColors", []);
  const eventColorColumn = get(block, "eventColorColumn");
  const eventColorMap = eventColors.reduce((acc, c) => {
    acc[c.name] = c.color;
    return acc;
  }, {});

  const eventBadgeStyle = get(block, "eventBadgeStyle", "minimal");

  const { recordClick, processDynamicText, addModalToStack } = useUtils();

  const primaryColor = "var(--primary)";

  const blockBorderRadius = get(app, ["styling", "blockBorderRadius"]);

  const matchingSpreadsheet = savedSpreadsheets.find(
    (s) => s.id === block.spreadsheet
  );

  const daysOfWeek = [
    { key: "calendarSundayLetter", value: "S" },
    { key: "calendarMondayLetter", value: "M" },
    { key: "calendarTuesdayLetter", value: "T" },
    { key: "calendarWednesdayLetter", value: "W" },
    { key: "calendarThursdayLetter", value: "T" },
    { key: "calendarFridayLetter", value: "F" },
    { key: "calendarSaturdayLetter", value: "S" },
  ];

  const data = getListData(block);

  const fields = get(block, "fields", []);

  const isPreventPastEvents = get(block, "isPreventPastEvents", true);
  const eventCardColor = get(block, "eventCardColor");

  const finalFields = [
    {
      mapKey: "image", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "image"),
    },
    {
      mapKey: "startDate", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "startDate"),
    },
    {
      mapKey: "endDate", // This tells processObjects to convert the mapped key to 'startDate'
      key: get(block, "endDate"),
    },
    ...fields,
  ];

  let events = processObjects(
    page,
    block,
    finalFields,
    data,
    processDynamicText
  );

  const { actionResolver } = useActionResolver(page);

  const today = startOfToday();

  // built-in hooks
  const [selectedDay, selectedDaySet] = useState(today);
  const [currentMonth, currentMonthSet] = useState(format(today, "MMM-yyyy"));
  const [showDateDetails, setShowDateDetails] = useState(false);

  const firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());

  const weekStart = get(block, "weekStart");

  const days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth, {
      weekStartsOn: weekStart === "sunday" ? 0 : 1,
    }),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth), {
      weekStartsOn: weekStart === "sunday" ? 0 : 1,
    }),
  });

  const isPreviousDay = (day) => {
    return day < today;
  };

  const getOrderedWeek = (weekStart) => {
    const startIndex = weekStart === "sunday" ? 0 : 1; // Sunday index is 6, Monday index is 0
    return [
      ...daysOfWeek.slice(startIndex),
      ...daysOfWeek.slice(0, startIndex),
    ];
  };

  const orderedWeek = getOrderedWeek(weekStart);

  const previousMonth = () => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    currentMonthSet(format(firstDayNextMonth, "MMM-yyyy"));
  };

  const nextMonth = () => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    currentMonthSet(format(firstDayNextMonth, "MMM-yyyy"));
  };

  // DATE FORMAT STUFF -----------

  const fieldData = get(matchingSpreadsheet, "field_data", {});
  const config = get(fieldData, "config", {});
  const dateFieldData = get(config, get(block, "startDate"));
  const spreadsheetDateFormat = get(dateFieldData, "dateFormat", {});

  const dateFormat = {
    ...appDateFormat,
    ...spreadsheetDateFormat,
    ...get(block, "dateFormat", {}),
  };

  const selectedEvents = events.filter((e) => {
    if (e.startDate) return eventOverlapsWithDay(e, selectedDay, dateFormat);
    return false;
  });

  const elementRef = useRef();
  const { width } = useContainerDimensions(elementRef, null);

  const showDateOnCard = defaultTrue(get(block, "showDateOnCard"));

  const dayHeight = parseInt((width / 7) * 0.7);
  const truncateAmount = parseInt(width / 7 / 10);
  const createModeLabel = get(block, "createModeLabel") || "New Event";

  const badgeColorMap = getBadgeColorMap(get(block, "badgeColors", []));

  const gridColumns = get(block, "gridColumns", 2);

  const dateDetailsContent = (wrapper) => {
    const showCreate = () =>
      !isFrontlyAdmin &&
      block.showCreate &&
      addModalToStack({
        label: createModeLabel,
        blockId: block.id,
        type: "create",
        defaultValues: {
          [get(block, "startDate")]: parseDateWithFormatObject({
            value: moment(selectedDay),
            formatObject: dateFormat,
          }),
        },
      });

    return (
      <DateDetailsWrapper wrapper={wrapper}>
        {block.showCreate && !isPreviousDay(selectedDay) && (
          <Button
            data={{
              text: createModeLabel,
              icon: "FiPlus",
              onClick: showCreate,
              backgroundColor: "var(--primary)",
            }}
          />
        )}

        <EventsWrapper>
          {selectedEvents.map((e) => (
            <Card
              onClick={() => recordClick(block, e, actionResolver)}
              width="100%"
              darkMode={darkMode}
            >
              <CardContent
                fields={fields}
                item={{ ...e, badgeColorMap }}
                blockId={block.id}
                gridColumns={gridColumns}
              />
              {showDateOnCard && (
                <EventTime
                  start={e.startDate}
                  end={e.endDate}
                  showTime={true}
                  inputFormat={e.dateFormat}
                />
              )}
            </Card>
          ))}
          {!selectedEvents.length && (
            <Text
              data={{
                text: get(
                  translations,
                  "noEventsText",
                  "No events on this date."
                ),
                color: darkMode && "white",
              }}
            />
          )}
        </EventsWrapper>
      </DateDetailsWrapper>
    );
  };

  const eventLabel = get(block, "eventLabel");

  // Display Month & Year, translated if defined
  let displayMonth = format(firstDayCurrentMonth, "MMMM");
  const translatedMonth = get(translations, `calendarMonth${displayMonth}`);
  if (translatedMonth) {
    displayMonth = translatedMonth;
  }

  const displayYear = format(firstDayCurrentMonth, "yyyy");
  const displayMonthYear = `${displayMonth} ${displayYear}`;

  let drawerMonth = moment(selectedDay).format("MMMM");
  const translatedDrawerMonth = get(
    translations,
    `calendarMonth${drawerMonth}`
  );
  if (translatedDrawerMonth) {
    drawerMonth = translatedDrawerMonth;
  }
  const drawerDate = moment(selectedDay).format("Do, YYYY");
  const drawerDateLabel = `${drawerMonth} ${drawerDate}`;

  const getEventColors = (e) => {
    const eventColor = get(
      eventColorMap,
      get(e, eventColorColumn),
      "var(--primary)"
    );

    const badgeBackground =
      eventBadgeStyle === "badge" ? eventColor : "transparent";

    const eventTextColor = eventBadgeStyle === "badge" ? "white" : eventColor;

    return {
      badgeBackground,
      eventTextColor,
    };
  };

  return (
    <Container>
      {/* modal */}
      {showDateDetails && (
        <Drawer
          data={{
            background: darkMode
              ? colors.darkModeLightBackground
              : "var(--grey1)",
            open: true,
            width: "400px",
            hide: () => setShowDateDetails(false),
            label: drawerDateLabel,
            darkMode,
          }}
        >
          <DateDetails withinModal>{dateDetailsContent(false)}</DateDetails>
        </Drawer>
      )}

      <CalendarWrapper>
        <Header borderRadius={blockBorderRadius} darkMode={darkMode}>
          <Icon
            data={{
              hover: true,
              color: "var(--grey7)",
              size: 20,
              onClick: previousMonth,
              icon: "FiChevronLeft",
            }}
          />

          <Month darkMode={darkMode}>{displayMonthYear}</Month>

          <Icon
            data={{
              hover: true,
              color: "var(--grey7)",
              size: 20,
              onClick: nextMonth,
              icon: "FiChevronRight",
            }}
          />
        </Header>

        <DaysOfWeek darkMode={darkMode}>
          {orderedWeek.map((item) => (
            <div>{get(translations, item.key, item.value)}</div>
          ))}
        </DaysOfWeek>

        <DaysOfMonth ref={elementRef} darkMode={darkMode}>
          {days.map((day, index) => {
            const dayEvents = events.filter((e) =>
              eventOverlapsWithDay(e, day, dateFormat)
            );

            const isToday = isEqual(day, today);

            const maxEvents = width > 900 ? 2 : 1;

            const hasEvents = dayEvents.length > 0;
            const remainingEvents = dayEvents.length - maxEvents;

            let dayColor = "var(--primary)";

            if (dayEvents.length > 0) {
              if (eventBadgeStyle === "minimal") {
                dayColor = get(getEventColors(dayEvents[0]), "eventTextColor");
              } else {
                const field =
                  width > 745 ? "eventTextColor" : "badgeBackground";
                dayColor = get(getEventColors(dayEvents[0]), field);
              }
            }

            let eventBubbleFontSize = "headingSm";
            if (width < 600) {
              eventBubbleFontSize = "heading2Xs";
            } else if (width < 1200) {
              eventBubbleFontSize = "headingXs";
            }

            let bubbleTextFontSize = "13px";
            if (width < 600) {
              bubbleTextFontSize = "10px";
            } else if (width < 1200) {
              bubbleTextFontSize = "12px";
            }

            let dayNumberContainerSize = "30px";
            if (width < 600) {
              dayNumberContainerSize = "18px";
            } else if (width < 1200) {
              dayNumberContainerSize = "25px";
            }

            return (
              <Day
                disabled={isPreventPastEvents && isPreviousDay(day)}
                eventCardColor={eventCardColor}
                darkMode={darkMode}
                height={dayHeight < 110 ? dayHeight : 110}
                day={day}
                key={index}
                selectedDay={selectedDay}
                onClick={(e) => {
                  if (!isFrontlyAdmin) {
                    // Wide View
                    if (dayEvents.length > 1 || showCreate) {
                      selectedDaySet(day);
                      setShowDateDetails(true);
                    }

                    // Narrow View
                    if (width <= 745 && dayEvents.length < 2) {
                      if (showCreate) {
                        selectedDaySet(day);
                        setShowDateDetails(true);
                      } else if (dayEvents.length === 1) {
                        e.stopPropagation();
                        const firstEvent = get(dayEvents, 0);
                        recordClick(block, firstEvent, actionResolver);
                      }
                    }
                  }
                }}
                firstDayCurrentMonth={firstDayCurrentMonth}
              >
                <DayNumberContainer
                  color={primaryColor}
                  active={isToday}
                  margin={width < 500 ? "0px" : "0 0 5px 0"}
                  size={dayNumberContainerSize}
                >
                  <Text
                    data={{
                      text: format(day, "d"),
                      fontStyle: eventBubbleFontSize,
                      color: isToday
                        ? "white"
                        : darkMode
                        ? colors.darkModeLightText
                        : null,
                    }}
                  />
                </DayNumberContainer>

                {hasEvents && (
                  <>
                    {width > 745 && (
                      <EventBubbles>
                        {dayEvents
                          .filter((e, i) => i < maxEvents)
                          .map((e, i) => {
                            let labelText = get(e, eventLabel);

                            const { badgeBackground, eventTextColor } =
                              getEventColors(e);

                            return (
                              <EventBubble
                                darkMode={darkMode}
                                background={badgeBackground}
                                key={i}
                                onClick={(clickEvent) => {
                                  clickEvent.stopPropagation();
                                  recordClick(block, e, actionResolver);
                                }}
                              >
                                {eventBadgeStyle === "minimal" && (
                                  <Bullet color={eventTextColor}>&nbsp;</Bullet>
                                )}
                                <BubbleText
                                  fontSize={bubbleTextFontSize}
                                  color={eventTextColor}
                                >
                                  {truncateText(
                                    labelText || "Event",
                                    truncateAmount
                                  )}
                                </BubbleText>
                              </EventBubble>
                            );
                          })}
                        {width > 745 && remainingEvents > 0 && (
                          <EventBubble darkMode={darkMode}>
                            <BubbleText
                              fontSize={bubbleTextFontSize}
                              color={
                                darkMode
                                  ? colors.darkModeLightText
                                  : "var(--grey8)"
                              }
                            >
                              {remainingEvents} more event
                              {remainingEvents > 1 ? "s" : ""}
                            </BubbleText>
                          </EventBubble>
                        )}
                      </EventBubbles>
                    )}

                    {width <= 745 && width > 565 && (
                      <Row alignItems="center">
                        <Bullet color={dayColor} style={{ marginRight: "5px" }}>
                          &nbsp;
                        </Bullet>
                        <MoreText color={dayColor}>
                          {dayEvents.length} event
                          {dayEvents.length > 1 ? "s" : ""}
                        </MoreText>
                      </Row>
                    )}

                    {width <= 565 && (
                      <Bullet color={dayColor} style={{ marginTop: "8px" }}>
                        &nbsp;
                      </Bullet>
                    )}
                  </>
                )}
              </Day>
            );
          })}
        </DaysOfMonth>
      </CalendarWrapper>
    </Container>
  );
};

export default Calendar;

const EventTime = ({ start, end, showTime, inputFormat }) => {
  const format = showTime ? `MMM Do h:mm a` : "MMMM Do";

  const startDate = parseDateWithFormatObject({
    value: start,
    formatObject: inputFormat,
    returnMoment: true,
  });

  let timeString = "";

  const formattedStartDate = startDate && startDate.format(format);

  timeString = formattedStartDate;

  if (end) {
    const endDate = parseDateWithFormatObject({
      value: end,
      formatObject: inputFormat,
      returnMoment: true,
    });

    const formattedEndDate = endDate && endDate.format(format);

    if (formattedEndDate !== formattedEndDate) {
      timeString = `${formattedStartDate} - ${formattedEndDate}`;
    }
  } else {
    timeString = formattedStartDate;
  }

  return (
    <Text
      data={{
        text: timeString,
        margin: "10px 0 0 0",
        cursor: "pointer",
        fontStyle: "headingXs",
        color: "var(--grey8)",
      }}
    />
  );
};

const BubbleText = styled.div`
  font-weight: 600;
  color: ${(p) => p.color};
  font-size: ${(p) => p.fontSize};
`;

const MoreText = styled.div`
  font-weight: 600;
  color: ${(p) => p.color};
  font-size: 12px;
`;

const EventBubbles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const EventBubble = styled.div`
  border-radius: 6px;
  padding: 2px 2px 2px 4px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  background: ${(p) => p.background};
  &:hover {
    filter: brightness(0.9);
  }
`;

const DateDetailsWrapper = styled.div`
  ${(p) =>
    p.wrapper &&
    `
      border-radius: 6px;
      border: 1px solid var(--grey21);
      padding: ${spacing.s5};
  `}
`;

const DayNumberContainer = styled.div`
  border-radius: 50%;
  height: ${(p) => p.size};
  width: ${(p) => p.size};
  background: ${(p) => (p.active ? p.color : "transparent")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
