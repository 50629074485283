import { Breadcrumb, Button, Row } from "app/components";
import { useNavigate, useParams } from "react-router-dom";

import { Header } from "../landing/Header";
import { TemplateContent } from "./NewTemplates";
import { get } from "lodash";
import styled from "styled-components";
import { templates } from "./templates";

const TemplateDetails = ({
  templateId,
  installTemplate = null,
  internal = false,
}) => {
  const params = useParams();

  const { id } = params;

  const finalId = templateId || id;

  const template = templates.find((t) => t.id == finalId);

  const navigate = useNavigate();

  if (!template) {
    return <div>Template not found</div>;
  }

  return (
    <Background internal={internal}>
      {!internal && <Header />}
      <Content padding={internal ? "50px 0px 50px 0px" : "50px"}>
        {!internal && (
          <Breadcrumb
            fontStyle="headingMd"
            margin="0 0 30px 0"
            items={[
              {
                text: "Templates",
                onClick: () => navigate("/templates"),
              },
              { text: template.name, onClick: null },
            ]}
          />
        )}

        <Row justifyContent="space-between" alignItems="center" gap="100px">
          <div>
            <Name>{template.name}</Name>
            <Description>{template.description}</Description>
            <ButtonRow>
              <Button
                data={{
                  borderRadius: "50px",
                  fontStyle: "headingMd",
                  text: "Use Template",
                  size: "large",
                  padding: "20px",
                  fontWeight: 600,
                  onClick: () =>
                    installTemplate
                      ? installTemplate()
                      : navigate(`/signup?template=${template.key}`),
                }}
              />
              <Button
                data={{
                  borderRadius: "50px",
                  fontStyle: "headingLg",
                  size: "large",
                  type: "secondary",
                  text: "View Live App",
                  padding: "20px",
                  icon: "FiExternalLink",
                  fontWeight: 600,
                  onClick: () =>
                    window.open(`https://${template.key}.frontly.ai`, "_blank"),
                }}
              />
            </ButtonRow>

            <CategoriesLabel>Categories</CategoriesLabel>
            <CategoriesGrid>
              {get(template, "categories", []).map((category, index) => (
                <Category key={index}>{category}</Category>
              ))}
            </CategoriesGrid>
          </div>
          <TemplateContent data={template} size="large" hover={false} />
        </Row>
        <SectionHeader>About This Template</SectionHeader>
        <SectionDescription>{template.long_description}</SectionDescription>
        <SectionHeader>Features</SectionHeader>
        <FeaturesGrid>
          {get(template, "features", []).map((feature, index) => (
            <Feature key={index}>
              <FeatureTitle>{feature.name}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </Feature>
          ))}
        </FeaturesGrid>
      </Content>
    </Background>
  );
};

export default TemplateDetails;

const Background = styled.div`
  ${(p) => !p.internal && "background: white;"}
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 15px;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
`;

const Feature = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

const FeatureTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.div`
  font-size: 16px;
  color: #666;
`;

const Content = styled.div`
  padding: ${({ padding }) => padding || "50px"};
  max-width: 1200px;
  margin: 0 auto;
`;

const Name = styled.div`
  font-size: 35px;
  font-weight: 600;
`;

const Description = styled.div`
  font-size: 20px;
  color: #666;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 400px;
`;

const SectionHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  margin-top: 20px;
`;

const SectionDescription = styled.div`
  font-size: 20px;
  color: #666;
  margin-top: 25px;
  margin-bottom: 30px;
`;

const CategoriesLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
`;

const CategoriesGrid = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Category = styled.div`
  background: var(--grey2);
  color: var(--text);
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
`;
