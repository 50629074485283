import styled from "styled-components";

export const ConfigContainer = styled.div`
  min-width: ${(p) => (p.expanded ? "290px" : "46px")};
  max-width: ${(p) => (p.expanded ? "290px" : "46px")};
  overflow-y: auto;
  background: white;
  position: fixed;
  top: 60px;
  bottom: 0;
  transition: 0.3s;
  ${(p) =>
    p.rightSide
      ? `
    border-left: 1px solid var(--grey21);
    right: 0;
  `
      : `
    border-right: 1px solid var(--grey21);
    left: 0;
  `}

  ${(p) =>
    !p.expanded &&
    `
    height: 46px;
    width: 46px;
    border-radius: 46px;
    border: 1px solid #e0e1e6;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 65px;
    ${p.rightSide ? "right: 5px;" : "left: 5px;"}
    cursor: pointer;
    z-index: 9999;
    background: white;
    &:hover{
      box-shadow: var(--card-shadow);
    }
  `};
`;

export const PanelContent = styled.div`
  opacity: ${(p) => (p.expanded ? 1 : 0)};
  overflow-y: auto;
  height: calc(100% - 0px);
  transition: 0.3s;
  overflow-x: hidden;
  width: 100%;
`;
