import BlockHeader from "./BlockHeader";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";

const Kanban = ({ page, data, hue, large }) => {
  const blockData = get(page, "block_data", {});
  const labelField = get(blockData, "label_field");

  const cards = data.map((item) => get(item, labelField));

  const columns = [
    { name: "In Progress", tasks: [get(cards, 0), get(cards, 1)] },
    { name: "Complete", tasks: [get(cards, 2)] },
  ];

  return (
    <>
      <BlockHeader name={page.name} hue={hue} large={large} />
      <KanbanContainer>
        {columns.map((column) => (
          <KanbanColumn key={column.name}>
            <ColumnHeader hue={hue}>{column.name}</ColumnHeader>
            {column.tasks.map((task, index) => (
              <TaskCard key={index}>{truncateText(task, 12)}</TaskCard>
            ))}
          </KanbanColumn>
        ))}
      </KanbanContainer>
    </>
  );
};

export default Kanban;

const KanbanContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const KanbanColumn = styled.div`
  background: white;
  border-radius: 5px;
  width: 100px;
`;

const ColumnHeader = styled.div`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 5px;
  color: hsl(${(p) => p.hue}, 93%, 25%);
`;

const TaskCard = styled.div`
  background: #f9f9f9;
  border: 1px solid ${colors.grey2};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 600;
`;
