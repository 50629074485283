import { Hint, Row, Text } from "app/components";

import Menu from "@mui/material/Popover"; // Switched over to popover Feb 12, 2025
import { colors } from "app/utils/theme";
import styled from "styled-components";

const Container = styled.div`
  width: ${(p) => p.width || "fit-content"};
  min-width: ${(p) => p.minWidth || "fit-content"};
  background: ${(p) => p.background};
  height: 100%;
  padding: ${(p) => p.padding || "20px"};
`;

const HeaderRow = styled.div`
  margin-bottom: 15px;
`;

const Body = styled.div`
  width: 100%;
`;

const MenuComponent = ({
  label,
  description,
  hint,
  hintLink,
  hide,
  width,
  minWidth,
  children,
  anchorElement,
  anchorOrigin,
  padding,
  background,
  darkMode,
  onBlur,
  onClick,
}) => {
  return (
    <Menu
      anchorPosition={{ right: 10 }}
      anchorOrigin={anchorOrigin || { vertical: "bottom", horizontal: "left" }}
      open={Boolean(anchorElement)}
      onClose={hide}
      anchorEl={anchorElement}
      onBlur={onBlur}
      onClick={onClick}
      transitionDuration={0}
      slotProps={{
        paper: {
          style: {
            padding: "0px",
            margin: "0px",
            border: "0px",
            borderRadius: "10px",
            backgroundColor: darkMode
              ? colors.darkModeLightBackground
              : background || "#fff",
          },
        },
      }}
      MenuListProps={{
        sx: {
          py: 0, // Ensure no vertical padding
          px: 0, // Ensure no horizontal padding
          m: 0, // Ensure no margin
        },
      }}
    >
      <Container
        width={width}
        minWidth={minWidth}
        padding={padding}
        background={darkMode ? colors.darkModeBackground : "white"}
      >
        {label && (
          <HeaderRow>
            <Row alignItems="center" gap="5px">
              {label && (
                <Text
                  data={{
                    color: darkMode ? "white" : "var(--grey7)",
                    text: label,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                />
              )}
              {hint && <Hint hint={hint} link={hintLink} />}
            </Row>

            {description && (
              <Text
                data={{
                  text: description,
                  fontStyle: "bodyMd",
                  margin: "5px 0 0 0",
                  color: "var(--grey8)",
                }}
              />
            )}
          </HeaderRow>
        )}
        <Body padding={padding}>{children}</Body>
      </Container>
    </Menu>
  );
};

export default MenuComponent;
