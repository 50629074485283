import { Button, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rApp, rConfirmationModalData, rSubscription } from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminForm from "../components/AdminForm";
import SettingsCard from "./components/SettingsCard";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Domains = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [customDomain, setCustomDomain] = useState("");

  const [isCustomDomainSaving, setIsCustomDomainSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const customDomainData = get(app, "custom_domain");

  const subscription = useRecoilValue(rSubscription);

  const navigate = useNavigate();

  const saveCustomDomain = () => {
    setIsCustomDomainSaving(true);
    apiRequest
      .post(`/domains/`, {
        domain: customDomain,
        mode: "domain",
      })
      .then((response) => {
        setCustomDomain("");
        successNotification("Saved");
        setApp({
          custom_domain: get(response, ["data", "domain"]),
        });
      })
      .catch((error) => {
        errorNotification("Something went wrong.");
      })
      .finally(() => {
        setIsCustomDomainSaving(false);
      });
  };

  const customDomainFields = [
    {
      id: "custom_domain",
      label: "Custom Domain Name",
      placeholder: "www.yourwebsite.com",
      componentId: "Input",
      autocomplete: "domain",
      hint: "To add a custom domain, you must have already purchased it from a domain registrar like GoDaddy, etc.",
      value: customDomain,
    },
  ];

  return (
    <>
      <SettingsSection
        title="Custom Domain"
        description="Manage the custom domain associated with your app"
        settingsCategory={"App Settings"}
        hideCard
        width="600px"
      >
        <SettingsCard
          label="Custom Domain"
          description="Add a custom domain name from an external provider like GoDaddy"
        >
          {subscription ? (
            <>
              <Text
                data={{
                  text: "Click to learn how to setup a custom domain",
                  onClick: () =>
                    window.open(
                      "https://help.frontly.ai/en/articles/8282996-custom-domain-setup-cname"
                    ),
                  fontStyle: "headingSm",
                  color: "var(--primary-admin)",
                  margin: "0 0 15px 0",
                }}
              />
              {customDomainData ? (
                <div>
                  <Text
                    data={{
                      text: "Domain",
                      fontStyle: "headingSm",
                    }}
                  />
                  <Text
                    data={{
                      text: customDomainData.custom_domain,
                      fontStyle: "bodySm",
                      allowSelect: true,
                    }}
                  />
                  <Text
                    data={{
                      text: "DNS CNAME Address",
                      fontStyle: "headingSm",
                      margin: "20px 0 0 0",
                    }}
                  />
                  <Text
                    data={{
                      text: customDomainData.cname,
                      fontStyle: "bodySm",
                      margin: "5px 0 0 0",
                      allowSelect: true,
                    }}
                  />
                  <Button
                    data={{
                      text: "Remove Domain",
                      type: "basic",
                      size: "small",
                      isFetching: isDeleting,
                      margin: "15px 0 0 0",
                      onClick: () =>
                        setConfirmationModalData({
                          title: "Remove Domain?",
                          text: "Are you sure you want to remove this domain from your app? This is irreversible.",
                          confirm: () => {
                            setIsDeleting(true);
                            apiRequest.delete(`/domains/`).then(() => {
                              successNotification("Domain Removed");
                              setApp({
                                custom_domain: null,
                              });
                              setIsDeleting(false);
                            });
                          },
                        }),
                    }}
                  />
                </div>
              ) : (
                <AdminForm
                  fields={customDomainFields}
                  sectionPadding="0px"
                  submitText={"Save Changes"}
                  submit={saveCustomDomain}
                  isFetching={isCustomDomainSaving}
                  onChange={(k, v) => setCustomDomain(v)}
                  disableSubmit={customDomain.length === 0}
                />
              )}
            </>
          ) : (
            <Button
              data={{
                text: "Upgrade to access this feature",
                icon: "FiArrowUpCircle",
                onClick: () => navigate("/settings/billing"),
              }}
            />
          )}
        </SettingsCard>
      </SettingsSection>
    </>
  );
};

export default Domains;
