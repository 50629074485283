import { Breadcrumb, Button, Row, Text } from "app/components";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SettingsSection = ({
  settingsCategory,
  title,
  description,
  children,
  articleLink,
  width,
  buttons = [],
  hideCard = false,
}) => {
  const navigate = useNavigate();
  return (
    <SettingsSectionContainer width={width}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        gap="30px"
        margin="0 0 15px 0"
      >
        <div style={{ width: "100%" }}>
          <Breadcrumb
            fontStyle="headingXl"
            items={[
              {
                text: settingsCategory || "Settings",
                onClick: () => navigate("/settings"),
              },
              { text: title, onClick: null },
            ]}
          />
          {description && (
            <Text
              data={{
                text: description,
                fontSize: 18,
                fontWeight: 400,
                color: "var(--grey6)",
                margin: "15px 0 0 0",
              }}
            />
          )}
        </div>
        <Row alignItems="center" gap="10px">
          {articleLink && (
            <Button
              data={{
                text: "View Help Article",
                type: "basic",
                icon: "FiExternalLink",
                onClick: () => window.open(articleLink),
              }}
            />
          )}
          {buttons.map((button) => (
            <Button data={button} />
          ))}
        </Row>
      </Row>
      <CardSection hideCard={hideCard}>{children}</CardSection>
    </SettingsSectionContainer>
  );
};

export default SettingsSection;

const SettingsSectionContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: ${(p) => p.width || "500px"};
`;

export const CardSection = styled.div`
  ${(p) =>
    p.hideCard
      ? "gap: 30px;"
      : `
    background: white;
    box-shadow: var(--card-shadow);
    border-radius: 12px;
    flex: 1;
    padding: 20px;
  `}
`;
