import { rApp, rSubscription } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import SettingsCard from "./components/SettingsCard";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Branding = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState(false);

  const subscription = useRecoilValue(rSubscription);

  const save = () => {
    setChanges(false);
    successNotification("Saved");
    apiRequest.post("/app_settings/", {
      primary_color: get(app, "primary_color"),
      logo: get(app, "logo"),
      navigation_logo: get(app, "navigation_logo"),
      favicon: get(app, "favicon"),
      logo_font: get(app, "logo_font"),
      hide_branding: get(app, "hide_branding"),
    });
  };

  const navigate = useNavigate();

  const buttonProps = {
    text: "Upgrade",
    size: "small",
    width: "100px",
    icon: "FiArrowUpCircle",
    componentId: "Button",
    onClick: () => navigate("/settings/billing"),
  };

  const paidFeatures = subscription
    ? [
        {
          id: "logo",
          label: "Logo",
          componentId: "ImageUpload",
          hint: "Upload a logo to be used in your app navigation and login / signup pages",
          value: get(app, "logo"),
          disabled: !subscription,
          paidFeature: !subscription,
        },
        {
          id: "navigation_logo",
          label: "Navigation Logo",
          componentId: "ImageUpload",
          hint: "Upload a logo to be used in your app navigation. If left blank, your primary logo will be used.",
          value: get(app, "navigation_logo"),
          disabled: !subscription,
          paidFeature: !subscription,
        },
        {
          id: "favicon",
          label: "Favicon",
          hint: "Upload an image to be displayed in the browser tab",
          componentId: "ImageUpload",
          value: get(app, "favicon"),
          disabled: !subscription,
          paidFeature: !subscription,
        },
        {
          id: "hide_branding",
          label: "Hide Frontly Badge",
          componentId: "Switch",
          hint: "On published free apps, there is a Made With Frontly badge in the bottom corner. On paid plans, you can disable this badge.",
          disabled: !subscription,
          paidFeature: !subscription,
          value: get(app, "hide_branding"),
        },
      ]
    : [
        {
          id: "logo",
          label: "Logo",
          hint: "Upload a logo to be used in your app navigation and login / signup pages",
          ...buttonProps,
        },
        {
          id: "navigation_logo",
          label: "Navigation Logo",
          hint: "Upload a logo to be used in your app navigation. If left blank, your primary logo will be used.",
          ...buttonProps,
        },
        {
          id: "favicon",
          label: "Favicon",
          hint: "Upload an image to be displayed in the browser tab",
          ...buttonProps,
        },
        {
          id: "hide_branding",
          label: "Hide Frontly Badge",
          hint: "On published free apps, there is a Made With Frontly badge in the bottom corner. On paid plans, you can disable this badge.",
          ...buttonProps,
        },
      ];

  let fields = [
    {
      id: "primary_color",
      label: "Primary Color",
      componentId: "ColorPicker",
      value: get(app, "primary_color"),
    },
    ...paidFeatures,
  ];

  return (
    <SettingsCard
      label="Branding"
      description="Add your own brand including colors, logo and other settings"
    >
      <AdminForm
        labelStyle="headingSm"
        sectionPadding="5px"
        fields={fields}
        submitText={"Save Changes"}
        submit={changes && save}
        width="220px"
        orientation={"horizontal"}
        onChange={(k, v) => {
          setChanges(true);
          setApp({
            [k]: v,
          });
        }}
      />
    </SettingsCard>
  );
};

export default Branding;
