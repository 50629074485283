import { Breadcrumb, Button, Row, Spinner, Text } from "app/components";
import { get, startCase } from "lodash";
import {
  rActions,
  rApps,
  rConfirmationModalData,
  rPages,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { AdminForm } from "app/adminApp/components";
import AdminWrapper from "app/adminApp/home/AdminWrapper";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

export const statusColorMap = {
  draft: "#F0F0F0",
  pending: "#fff3ad",
  published: "#b8e7d2",
  changes_requested: "#e7bbb8",
  rejected: "#e7bbb8",
};

const ListingDetails = () => {
  const [listing, setListing] = useState(null);

  const { id } = useParams();

  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [changes, setChanges] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  // Fetch listing
  useEffect(() => {
    setIsFetching(true);
    apiRequest.get(`/marketplace_listings/${id}/`).then((r) => {
      const listingData = get(r, "data");
      setIsFetching(false);
      setListing(listingData);
    });
  }, []);

  const apps = useRecoilValue(rApps);
  const pages = useRecoilValue(rPages);
  const [actions, setActions] = useRecoilState(rActions);

  const [isFetchingActions, setIsFetchingActions] = useState(false);

  const instanceId = parseInt(get(listing, "instance_id"));
  const listingType = get(listing, "type");

  const navigate = useNavigate();

  const getInstance = () => {
    if (listingType === "app") {
      return apps.find((a) => a.id === instanceId);
    } else if (listingType === "page") {
      return pages.find((p) => p.id === instanceId);
    } else if (listingType === "action") {
      return actions.find((a) => a.id === instanceId);
    }
  };

  const instance = getInstance();

  // Fetch actions if not already fetched
  useEffect(() => {
    if (actions.length === 0) {
      setIsFetchingActions(true);
      apiRequest.get("/actions/").then((r) => {
        const newActions = get(r, "data", []);
        setActions(newActions);
        setIsFetchingActions(false);
      });
    }
  }, []);

  const saveListing = () => {
    if (status === "published") {
      setConfirmationModalData({
        title: "Confirm Editing Listing",
        text: "Are you sure you want to edit your listing? This is live, so these changes will be visible to everyone immediately in the marketplace.",
        confirm: () => {
          setIsSaving(true);
          apiRequest
            .put(`/marketplace_listings/${listing.id}/`, listing)
            .then(() => {
              setIsSaving(false);
              setChanges(false);
              successNotification("Listing Saved");
            });
        },
      });
    } else {
      setIsSaving(true);
      apiRequest
        .put(`/marketplace_listings/${listing.id}/`, listing)
        .then(() => {
          setIsSaving(false);
          setChanges(false);
          successNotification("Listing Saved");
        });
    }
  };

  const status = get(listing, "status", "draft");
  const agreed = get(listing, "agreed_to_terms", false);

  const fields = [
    // {
    //   id: "status",
    //   label: "Status",
    //   componentId: "Select",
    //   value: status,
    //   hideEmptyItem: true,
    //   options: [
    //     {
    //       label: "Draft",
    //       value: "draft",
    //     },
    //     {
    //       label: "Published",
    //       value: "published",
    //     },
    //   ],
    //   columnSpan: 3,
    // },
    {
      id: "name",
      label: "Name",
      componentId: "Input",
      value: get(listing, "name"),
      columnSpan: 3,
    },
    {
      id: "short_description",
      label: "Short Description",
      hint: "A one-line description of your app",
      componentId: "Input",
      value: get(listing, "short_description"),
      columnSpan: 3,
    },
    {
      id: "industry",
      label: "Industry",
      componentId: "Input",
      hint: "If relevant, enter the industry this listing relates to",
      value: get(listing, "industry"),
      columnSpan: 3,
    },
    {
      id: "tags",
      label: "Tags",
      componentId: "Tags",
      value: get(listing, "tags"),
      hint: "Add tags to help users find your content, such as use-case, features, etc.",
      columnSpan: 3,
    },
    {
      id: "long_description",
      label: "Long Description",
      hint: "A detailed description of your app",
      componentId: "TextArea",
      minHeight: "150px",
      value: get(listing, "long_description"),
      columnSpan: 3,
    },
    {
      componentId: "Button",
      type: "secondary",
      width: "fit-content",
      icon: "FiExternalLink",
      text: "Click to read our image guidelines",
      fontColor: colors.frontlyBlue,
      padding: "0px",
      fontWeight: 600,
      columnSpan: 3,
      fontSize: 16,
      cursor: "pointer",
      onClick: () => {
        window.open(
          "https://help.frontly.ai/en/articles/10598112-frontly-marketplace-image-guidelines",
          "_blank"
        );
      },
    },
    {
      id: "primary_image",
      label: "Primary Image",
      componentId: "ImageUpload",
      value: get(listing, "primary_image"),
      imageHeight: 180,
      imageWidth: 300,
      columnSpan: 1,
      customBackground: "#f0f0f3",
      imageFramePadding: "12px",
      displayCondition: () => listingType !== "action",
    },
    {
      id: "secondary_image",
      label: "Secondary Image",
      componentId: "ImageUpload",
      value: get(listing, "secondary_image"),
      imageHeight: 180,
      imageWidth: 300,
      columnSpan: 1,
      customBackground: "#f0f0f3",
      imageFramePadding: "12px",
      displayCondition: () => listingType !== "action",
    },
    {
      id: "tertiary_image",
      label: "Tertiary Image",
      componentId: "ImageUpload",
      value: get(listing, "tertiary_image"),
      imageHeight: 180,
      imageWidth: 300,
      columnSpan: 1,
      customBackground: "#f0f0f3",
      imageFramePadding: "12px",
      displayCondition: () => listingType !== "action",
    },
    // {
    //   id: "instance_id",
    //   label: `Associated ${startCase(listingType)}`,
    //   componentId: "Input",
    //   value: get(instance, "name"),
    //   columnSpan: 3,
    //   disabled: true,
    //   hint: "This is just for your reference. This cannot be changed. If this is blank, it means you deleted the original resource this listing was associated with.",
    // },
    {
      id: "agreed_to_terms",
      label: "Frontly Marketplace Terms",
      checkboxDescription:
        "I agree to all the terms of the Frontly Marketplace",
      componentId: "Checkbox",
      value: agreed,
      columnSpan: 3,
    },
  ].filter((f) => !f.displayCondition || f.displayCondition());

  const statusMessageMap = {
    draft: "Your listing is in draft mode. You can edit it below.",
    pending:
      "Your listing is pending review by the Frontly team. You will be notified when it's been reviewed.",
    published:
      "Your listing has been approved by the Frontly team, and is published in the marketplace.",
    changes_requested:
      "Your submission was reviewed and changes have been requested in order to complete your listing.",
    rejected:
      "Your listing was rejected by the Frontly team. You should have already received feedback.",
  };

  // Submit for approval
  const submitForApproval = () => {
    apiRequest.post(`/marketplace/submit/${listing.id}/`);
    setListing({ ...listing, status: "pending" });
    successNotification("Listing Submitted For Approval");
  };

  return (
    <AdminWrapper isFetching={isFetching || isFetchingActions}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        margin="0 0 20px 0"
      >
        <Breadcrumb
          fontStyle="headingXl"
          items={[
            {
              text: "Listings",
              onClick: () => navigate("/marketplace_admin/listings"),
            },
            { text: get(listing, "name"), onClick: null },
          ]}
        />

        <Row alignItems="center" gap="10px">
          <Button
            data={
              agreed
                ? {
                    text: changes ? "Save Changes" : "Changes Saved",
                    onClick: saveListing,
                    isFetching: isSaving,
                    disabled: !changes,
                  }
                : {
                    text: "You must agree to the terms before saving",
                    onClick: () => {},
                    isFetching: false,
                    disabled: true,
                  }
            }
          />
          {agreed && status === "draft" && (
            <Button
              data={{
                text: "Submit for Approval",
                onClick: submitForApproval,
                disabled: changes || !agreed,
              }}
            />
          )}
        </Row>
      </Row>

      <Card>
        <Row alignItems="center" gap="10px" margin="0 0 25px 0">
          <StatusBadge color={statusColorMap[status]}>
            {startCase(status)}
          </StatusBadge>
          <Text
            data={{
              text: statusMessageMap[status],
              fontSize: 16,
              color: "var(--grey5)",
            }}
          />
        </Row>
        <AdminForm
          sectionPadding={"0px"}
          gridLayout
          gridLayoutSize="33%"
          labelStyle="headingSm"
          fields={fields}
          onChange={(k, v) => {
            setChanges(true);
            setListing({
              ...listing,
              [k]: v,
            });
          }}
        />
        <Text
          data={{
            text: "View Frontly Marketplace Terms",
            color: colors.frontlyBlue,
            margin: "15px 0 0 0",
            fontWeight: 600,
            fontSize: 16,
            cursor: "pointer",
            onClick: () => {
              window.open(
                "https://help.frontly.ai/en/articles/10496307-frontly-app-marketplace-terms",
                "_blank"
              );
            },
          }}
        />
      </Card>
    </AdminWrapper>
  );
};

export default ListingDetails;

const Card = styled.div`
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  background: white;
  overflow: hidden;
  padding: 25px;
`;

const StatusBadge = styled.div`
  padding: 6px 12px 6px 12px;
  border-radius: 5px;
  background: ${(p) => p.color};
  color: var(--text);
  font-size: 15px;
  font-weight: 500;
  width: fit-content;
`;
