import { Icon, Row } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { rDarkMode, rTranslations } from "app/utils/recoil";

import { get } from "lodash";
import moment from "moment";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const Container = styled.div`
  width: fit-content;
  background: ${(p) => (p.darkMode ? colors.darkModeLightBackground : "white")};
  padding: 10px 15px 15px 15px;
  width: 290px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.s2};
`;

const Month = styled.div`
  color: ${(p) => (p.darkMode ? colors.darkModeLightText : "#111827")};
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.primaryColor};
  }
`;

const DaysOfWeek = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-top: 0.5rem;
  color: ${(p) => (p.darkMode ? colors.darkModeLightText : "#6b7280")};
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
`;

const DaysOfMonth = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: 100%;
`;

const Day = styled.div`
  display: flex;
  font-size: 13px;
  margin: 0 auto;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: ${(p) => (p.active ? p.color : "transparent")};
  color: ${(p) => p.fontColor};
  ${(p) =>
    p.disabled && `background: var(--grey21); color: black; opacity: 0.6;`};
  ${(p) =>
    p.isBetween && `background: ${p.color}; color: white; opacity: 0.6;`};
`;

const SimpleCalendar = ({
  finalDate,
  onChange,
  showScreen,
  isRange,
  startDate,
  endDate,
  preventPastDates = false,
  primaryColor,
}) => {
  const startDateFormatted = startDate.format("YYYY-MM-DD");
  const endDateFormatted = endDate.format("YYYY-MM-DD");

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const day = finalDate.format("DD");
  const month = finalDate.format("MM");
  const year = finalDate.format("YYYY");

  const today = moment();

  const date = moment(`${year}-${month}-${day}`, "YYYY-MM-DD");

  // State to track the current month being viewed
  const [currentMonth, setCurrentMonth] = useState(date.startOf("month"));

  // Function to move to previous month
  const previousMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth.clone().subtract(1, "months"));
  };

  // Function to move to next month
  const nextMonth = () => {
    setCurrentMonth((prevMonth) => prevMonth.clone().add(1, "months"));
  };

  // Generating the days for the calendar
  const startWeek = currentMonth.clone().startOf("week");
  const endWeek = currentMonth.clone().endOf("month").endOf("week");

  let days = [];
  let x = startWeek;
  while (x <= endWeek) {
    days.push(x.clone());
    x.add(1, "days");
  }

  // Displaying month and year
  let displayMonth = currentMonth.format("MMMM");
  displayMonth = get(
    translations,
    `calendarMonth${displayMonth}`,
    displayMonth
  );

  const displayYear = currentMonth.format("YYYY");

  return (
    <Container darkMode={darkMode}>
      <Header darkMode={darkMode}>
        <Icon
          data={{
            hover: true,
            color: "var(--grey7)",
            size: 20,
            onClick: previousMonth,
            icon: "FiChevronLeft",
          }}
        />
        <Row gap="5px">
          <Month
            darkMode={darkMode}
            primaryColor={primaryColor}
            onClick={() => showScreen("months")}
          >
            {displayMonth}
          </Month>
          <Month
            darkMode={darkMode}
            primaryColor={primaryColor}
            onClick={() => showScreen("years")}
          >
            {displayYear}
          </Month>
        </Row>
        <Icon
          data={{
            hover: true,
            color: "var(--grey7)",
            size: 20,
            onClick: nextMonth,
            icon: "FiChevronRight",
          }}
        />
      </Header>
      <DaysOfWeek darkMode={darkMode}>
        <div>{get(translations, "calendarSundayLetter", "S")}</div>
        <div>{get(translations, "calendarMondayLetter", "M")}</div>
        <div>{get(translations, "calendarTuesdayLetter", "T")}</div>
        <div>{get(translations, "calendarWednesdayLetter", "W")}</div>
        <div>{get(translations, "calendarThursdayLetter", "T")}</div>
        <div>{get(translations, "calendarFridayLetter", "F")}</div>
        <div>{get(translations, "calendarSaturdayLetter", "S")}</div>
      </DaysOfWeek>
      <DaysOfMonth darkMode={darkMode}>
        {days.map((d, index) => {
          const thisDay = d.format("YYYY-MM-DD");

          let isSelected = thisDay === `${year}-${month}-${day}`;

          if (isRange) {
            isSelected =
              startDateFormatted === thisDay || endDateFormatted === thisDay;
          }

          const isBetween = d.isBetween(startDate, endDate, "day");
          const isPreviousDay = d.isBefore(today, "day");

          return (
            <Day
              disabled={isPreviousDay && preventPastDates}
              darkMode={darkMode}
              key={index}
              color={primaryColor}
              active={isSelected}
              isBetween={isBetween}
              fontColor={
                isSelected
                  ? "white"
                  : darkMode
                  ? colors.darkModeLightText
                  : null
              }
              onClick={() => {
                if (!isPreviousDay || !preventPastDates) onChange(d);
              }}
            >
              {d.date()}
            </Day>
          );
        })}
      </DaysOfMonth>
    </Container>
  );
};

export default SimpleCalendar;
