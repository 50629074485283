import {
  findDefaultPage,
  getPixels,
  isFrontlyAdmin,
  resizeImage,
} from "app/utils/utils";
import { rApp, rPages, rSubscription, rUser } from "app/utils/recoil";

import { errorNotification } from "app/utils/Notification";
import { get } from "lodash";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useUtils from "app/renderingApp/useUtils";

// Frontly icons
const iconWhite =
  "https://res.cloudinary.com/frontly/image/upload/v1738126874/frontly_icon_wht_youzkw.svg";
const iconBlack =
  "https://res.cloudinary.com/frontly/image/upload/v1738126836/frontly_icon_black_ks8um4.svg";

const LogoSection = () => {
  const app = useRecoilValue(rApp);

  const appName = get(app, "name");

  const firstCharacter = appName.slice(0, 1);

  const navigation = get(app, "navigation", {});
  const lightMode = get(navigation, "textColor") === "light";

  const navigationType = get(navigation, "type", "sidebar");
  const isSidebarNarrow =
    navigationType === "sidebar" &&
    get(navigation, "sidebarStyle") === "minimal";

  const logoWidth =
    get(navigation, "logoWidth", 150) < 50
      ? 50
      : get(navigation, "logoWidth", 150);
  const logoHeight =
    get(navigation, "logoHeight", 40) < 20
      ? 20
      : get(navigation, "logoHeight", 40);

  const { passesDisplayConditions } = useUtils();

  const user = useRecoilValue(rUser);

  const navigate = useNavigate();

  const subscription = useRecoilValue(rSubscription);
  const isPaid = get(subscription, "plan");

  const customLogo = get(app, "navigation_logo") || get(app, "logo");

  const pages = useRecoilValue(rPages);

  const navigateToDefaultPage = () => {
    if (isFrontlyAdmin) {
      errorNotification("Links are disabled in admin preview");
      return;
    }

    const fallback = get(pages, 0, {});
    const defaultPage =
      pages.find((p) => p.id === get(app, "default_page")) || fallback;
    const userGroups = get(app, "user_groups");

    const defPageRoute = findDefaultPage({
      user,
      userGroups,
      pages,
      defaultPage,
      app,
      passesDisplayConditions,
    });

    if (defPageRoute) {
      navigate(defPageRoute);
    }
  };

  // Is paid and has a logo
  if (isPaid) {
    // Show logo
    if (customLogo) {
      return (
        <Logo
          logoWidth={logoWidth}
          logoHeight={logoHeight}
          isSidebarNarrow={isSidebarNarrow}
          onClick={isFrontlyAdmin ? null : navigateToDefaultPage}
          src={resizeImage({
            url: customLogo,
            height: 100,
          })}
        />
      );
    }

    // Show generic icon
    return (
      <NameIconRow
        isSidebarNarrow={isSidebarNarrow}
        isHeader={navigationType === "header"}
        onClick={isFrontlyAdmin ? null : navigateToDefaultPage}
      >
        <RoundIconWrapper isSidebarNarrow={isSidebarNarrow}>
          <RoundIconText isSidebarNarrow={isSidebarNarrow}>
            {firstCharacter.toUpperCase()}
          </RoundIconText>
        </RoundIconWrapper>
        {!isSidebarNarrow && (
          <AppName color={"var(--nav-font-color)"}>{appName}</AppName>
        )}
      </NameIconRow>
    );
  }

  // Free users
  return (
    <NameIconRow
      isSidebarNarrow={isSidebarNarrow}
      isHeader={navigationType === "header"}
      onClick={isFrontlyAdmin ? null : navigateToDefaultPage}
    >
      <FrontlyIcon
        src={lightMode ? iconWhite : iconBlack}
        isSidebarNarrow={isSidebarNarrow}
      />
      {!isSidebarNarrow && (
        <AppName color={"var(--nav-font-color)"}>{appName}</AppName>
      )}
    </NameIconRow>
  );
};

export default LogoSection;

const RoundIconWrapper = styled.div`
  min-height: ${(p) => (p.isSidebarNarrow ? "46px" : "36px")};
  min-width: ${(p) => (p.isSidebarNarrow ? "46px" : "36px")};
  max-height: ${(p) => (p.isSidebarNarrow ? "46px" : "36px")};
  max-width: ${(p) => (p.isSidebarNarrow ? "46px" : "36px")};
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
  background: var(--nav-round-icon);
  @media screen and (max-width: 800px) {
    height: 28px;
    min-width: 28px;
    border-radius: 14px;
  }
`;

const FrontlyIcon = styled.img`
  width: ${(p) => (p.isSidebarNarrow ? "32px" : "24px")};
  height: ${(p) => (p.isSidebarNarrow ? "32px" : "24px")};
  margin-right: ${(p) => (p.isSidebarNarrow ? "0px" : "5px")};
`;

const NameIconRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${(p) => p.isSidebarNarrow && "justify-content: center;"}

  ${(p) => p.isHeader && "min-width: 150px;"}
  @media screen and (max-width: 800px) {
    min-width: auto;
  }
`;

const Logo = styled.img`
  max-width: ${(p) => getPixels(p.logoWidth)};
  height: ${(p) => getPixels(p.logoHeight)};
  object-fit: contain;
  object-position: left;
  @media screen and (max-width: 800px) {
    margin: 5px 0 0 0;
    max-width: 150px;
  }
  ${(p) =>
    p.isSidebarNarrow &&
    `
    max-width: 60px;
    height: 60px;
  `}
`;

const AppName = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${(p) => p.color};
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

const RoundIconText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: var(--nav-font-color);
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
