import { Button, Row, Text } from "app/components";

import { NewBlock } from "app/adminApp/components/BlockManager/NewBlock";
import { rDarkMode } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

export const NoBlocks = ({ newBlockSlotNumber, setNewBlockSlotNumber }) => {
  const darkMode = useRecoilValue(rDarkMode);

  return (
    <EmptyStateContainer>
      {newBlockSlotNumber && (
        <NewBlock hide={() => setNewBlockSlotNumber(false)} />
      )}
      <EmptyStateImage
        src={
          "https://res.cloudinary.com/frontly/image/upload/v1685054010/undraw_responsive_re_e1nn_hn7mrx.svg"
        }
      />
      <Text
        data={{
          text: "Your page needs some content!",
          fontStyle: "headingXl",
          color: darkMode && "white",
        }}
      />
      <Text
        data={{
          text: "Add blocks to your page to bring it to life.",
          fontStyle: "bodyLg",
          color: darkMode && "var(--grey5)",
        }}
      />
      <Button
        data={{
          text: "Browse Blocks",
          size: "large",
          onClick: () => setNewBlockSlotNumber(1),
        }}
      />
    </EmptyStateContainer>
  );
};

export const EmptyStateDashboard = ({
  title,
  subtitle,
  graphic,
  buttonText,
  secondaryButton = null,
  onClick,
  margin,
}) => {
  return (
    <CardSection margin={margin}>
      <Graphic src={graphic} />
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Row gap="10px">
          {buttonText && (
            <Button
              data={{
                text: buttonText,
                size: "large",
                icon: "FiPlus",
                onClick,
              }}
            />
          )}
          {secondaryButton && (
            <Button
              data={{
                ...secondaryButton,
                type: "basic",
                size: "large",
              }}
            />
          )}
        </Row>
      </div>
    </CardSection>
  );
};

const Graphic = styled.img`
  width: 280px;
  height: 240px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 15px 0;
  max-width: 450px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`;

const EmptyStateImage = styled.img`
  height: 100px;
`;

const CardSection = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey3);
  overflow: auto;
  margin: ${(p) => p.margin || "0"};
`;
