import Item from "./Item";
import { SimpleDropdown } from "app/components";
import styled from "styled-components";
import { useState } from "react";

// Navigation section
const Section = ({
  section,
  orientation = "horizontal",
  isNarrow = false,
  sectionIndex,
  accentType,
  accentBorderRadius,
}) => {
  const { defaultExpanded = false, icon, label, items = [] } = section;

  const [expanded, setExpanded] = useState(defaultExpanded);

  // No items, hide section
  if (items.length === 0) {
    return <></>;
  }

  // If label, it's a section dropdown
  if (label) {
    return (
      <SimpleDropdown
        data={{
          options: items.map((i) => ({
            label: i.label,
            onClick: i.onClick,
            icon: i.icon,
          })),
          optionFontStyle: "bodyMdMedium",
          component: (
            <Item
              label={label}
              icon={icon}
              active={false}
              onClick={() => setExpanded(!expanded)}
              expanded={expanded}
              isDropdown={true}
              isNarrow={isNarrow}
              sectionIndex={sectionIndex}
              itemIndex={"1"}
              accentType={accentType}
              accentBorderRadius={accentBorderRadius}
            />
          ),
        }}
      />
    );
  }

  // Render non-section items?
  return (
    <ItemsContainer orientation={orientation} isNarrow={isNarrow}>
      {items.map((i, ik) => (
        <Item
          key={ik}
          label={i.label}
          icon={i.icon}
          active={i.active}
          onClick={i.onClick}
          isNarrow={isNarrow}
          sectionIndex={sectionIndex}
          itemIndex={ik}
          accentType={accentType}
          accentBorderRadius={accentBorderRadius}
        />
      ))}
    </ItemsContainer>
  );
};

export default Section;

const ItemsContainer = styled.div`
  display: flex;
  align-items: ${({ orientation }) =>
    orientation === "horizontal" ? "center" : "flex-start"};
  gap: ${(p) => (p.isNarrow ? "20px" : "10px")};
  flex-direction: ${({ orientation }) =>
    orientation === "horizontal" ? "row" : "column"};
`;
