import { Button } from "app/components";
import IconModal from "./IconModal";
import { useState } from "react";

const IconBrowser = ({ data }) => {
  const [showBrowser, setShowBrowser] = useState(false);
  return (
    <>
      <Button
        data={{
          ...data,
          text: data.value ? null : "Select Icon",
          icon: data.value,
          width: data.value ? "40px" : "80px",
          size: "small",
          type: "basic",
          onClick: () => setShowBrowser(true),
        }}
      />
      {showBrowser && (
        <IconModal data={data} hide={() => setShowBrowser(false)} />
      )}
    </>
  );
};

export default IconBrowser;
