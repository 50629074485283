import BlockHeader from "./BlockHeader";
import { colors } from "app/utils/theme";
import styled from "styled-components";

const Calendar = ({ page, hue, large }) => {
  const eventDates = [2, 6, 25];

  return (
    <>
      <BlockHeader name={page.name} hue={hue} large={large} />
      <CalendarGrid>
        {Array.from({ length: 28 }, (_, i) => (
          <CalendarCell
            key={i}
            highlighted={eventDates.includes(i + 1)}
            hue={hue}
          >
            {i + 1}
          </CalendarCell>
        ))}
      </CalendarGrid>
    </>
  );
};

export default Calendar;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-left: 1px solid ${colors.grey2};
  border-bottom: 1px solid ${colors.grey2};
  background: white;
`;

const CalendarCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 5px;
  background: ${(p) =>
    p.highlighted ? `hsl(${p.hue}, 93%, 20%, 0.1)` : "white"};
  border-right: 1px solid ${colors.grey2};
  border-top: 1px solid ${colors.grey2};
`;
