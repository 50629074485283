import { CardContent, Icon, Row, SkeletonLoader, Text } from "app/components";
import { boxShadow, colors, spacing } from "app/utils/theme";
import { get, isNil } from "lodash";
import {
  getPixels,
  isFrontlyAdmin,
  resizeImage,
  truncateText,
} from "app/utils/utils";
import { rApp, rDarkMode } from "app/utils/recoil";

import { GridImage } from "./Grid";
import { getBadgeColorMap } from "./Table";
import { getUniqueBadgeColors } from "app/components/Badge";
import styled from "styled-components";
import { useEffect } from "react";
import useListData from "app/useListData";
import useProcessObjects from "app/useProcessObjects";
import { useRecoilValue } from "recoil";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import useUtils from "app/renderingApp/useUtils";

const getUniqueItemId = (index = 0) => {
  const rand = Math.random().toString(36);
  return `${rand}-${index}`;
};

const KanbanAdmin = ({ block, page }) => {
  const { processObjects } = useProcessObjects();

  const { getListData } = useListData();

  const { processDynamicText, addModalToStack } = useUtils();

  const { populateKanbanColumns } = useSpreadsheetRequests();

  const darkMode = useRecoilValue(rDarkMode);

  const data = getListData(block);

  const fields = get(block, "fields", []);
  const finalFields = [
    {
      mapKey: "image", // This tells processObjects to convert the mapped key to 'image'
      key: get(block, "image"),
    },
    ...fields,
  ];

  const items = processObjects(
    page,
    block,
    finalFields,
    data,
    processDynamicText
  )
    .map((item, i) => ({
      index: i,
      ...item,
      uniqueItemId: getUniqueItemId(i),
    }))
    .filter((item, index) => index < 150);

  const columnKey = get(block, "columnKey", null);

  const columns = get(block, "columns", []).map((c) => ({
    ...c,
    columnItems: items.filter((item) => get(item, columnKey) === c.value),
  }));

  const itemCount = get(items, "length", 0);

  // Watch for data being populated and no columns being set, then run this function to set the columns based on the column key
  useEffect(() => {
    if (
      block.spreadsheet &&
      itemCount > 0 &&
      columnKey &&
      columns.length === 0
    ) {
      populateKanbanColumns({ sheetId: block.spreadsheet, columnKey });
    }
  }, [itemCount, columnKey]);

  const initialColumnColors = [
    "#df516e",
    "#f79e44",
    "#f7c744",
    "#74c98f",
    "#429ef0",
    "#6945e1",
  ];

  const columnColors = getUniqueBadgeColors(columns.map((c) => c.value));

  const recordLabel = get(block, "labelSingular") || "Record";

  const createModeLabel = get(block, "createModeLabel") || `New ${recordLabel}`;

  const showCreate = (colValue) =>
    !isFrontlyAdmin &&
    addModalToStack({
      label: createModeLabel,
      blockId: block.id,
      type: "create",
      defaultValues: { [get(block, "columnKey")]: colValue },
    });

  // Get user's custom defined badge colors
  const badgeColorMap = getBadgeColorMap(get(block, "badgeColors", []));

  // Find height of available container for grid layout
  const inGridLayout = get(block, "inGridLayout");

  const activeApp = useRecoilValue(rApp);

  const styling = get(activeApp, "styling");

  const blockBorder = get(styling, "blockBorder");
  const blockBorderRadius = get(styling, "blockBorderRadius");
  const blockBorderColor = get(styling, "blockBorderColor");
  const blockBoxShadow = get(styling, "blockBoxShadow");
  const finalBorder = blockBorder ? "1px solid " + blockBorderColor : "none";
  const backgroundColor = get(block, "backgroundColor", "white");

  const imageHeight = get(block, "imageHeight", "150px");
  const customBadgeColors = get(block, "customBadgeColors", []);

  const gridColumns = get(block, "gridColumns", 2);

  let height = get(block, "height") ? getPixels(get(block, "height")) : "auto";
  if (inGridLayout) {
    height = getPixels(get(block, "gridHeight"));
  }

  const fetching = block.isFetching;

  return (
    <ColumnsContainer height={height}>
      {columns.map((c, i) => {
        const { columnItems } = c;

        const columnColor =
          c.color ||
          get(initialColumnColors, i) ||
          get(columnColors, ["columns", c.value]);

        return (
          <Column key={i}>
            <KanbanColumnHeader
              darkMode={darkMode}
              color={columnColor}
              border={finalBorder}
              boxShadow={blockBoxShadow}
              borderRadius={blockBorderRadius}
              backgroundColor={backgroundColor}
            >
              <Row alignItems="center" gap="10px">
                <Text
                  data={{
                    text: truncateText(c.label, 20),
                    fontStyle: "headingMd",
                    color: darkMode ? "white" : null,
                  }}
                />

                {!fetching && (
                  <CountWrapper darkMode={darkMode}>
                    <Text
                      data={{
                        text: columnItems.length,
                        fontStyle: "headingMd",
                        color: "var(--grey7)",
                      }}
                    />
                  </CountWrapper>
                )}
              </Row>
              {block?.showCreate && (
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: "var(--grey7)",
                    hover: true,
                    onClick: () => showCreate(c.value),
                  }}
                />
              )}
            </KanbanColumnHeader>

            <CardsWrapper>
              {fetching &&
                [1, 2, 3, 4, 5].map((r, index) => {
                  return (
                    <Card
                      imageHeight={imageHeight}
                      blockId={block.id}
                      key={index}
                      item={{}}
                      fields={fields}
                      onClick={null}
                      skeleton
                      backgroundColor={backgroundColor}
                      customBadgeColors={customBadgeColors}
                      gridColumns={gridColumns}
                    />
                  );
                })}

              {!fetching &&
                columnItems.map((item, index) => (
                  <Card
                    imageHeight={imageHeight}
                    blockId={block.id}
                    key={index}
                    item={{ ...item, badgeColorMap }}
                    fields={fields}
                    onClick={null}
                    backgroundColor={backgroundColor}
                    customBadgeColors={customBadgeColors}
                    gridColumns={gridColumns}
                  />
                ))}
            </CardsWrapper>
          </Column>
        );
      })}
    </ColumnsContainer>
  );
};

export default KanbanAdmin;

const Card = ({
  gridColumns,
  item,
  fields,
  onClick,
  blockId,
  imageHeight,
  skeleton,
  backgroundColor,
  customBadgeColors,
  ...rest
}) => {
  const activeApp = useRecoilValue(rApp);

  const darkMode = useRecoilValue(rDarkMode);

  const styling = get(activeApp, "styling");
  const blockBorder = get(styling, "blockBorder");
  const blockBorderRadius = get(styling, "blockBorderRadius");
  const blockBorderColor = get(styling, "blockBorderColor");
  const blockBoxShadow = get(styling, "blockBoxShadow");
  const finalBorder = blockBorder ? "1px solid " + blockBorderColor : "none";

  return (
    <KanbanItemContainer
      darkMode={darkMode}
      onClick={onClick}
      {...rest}
      border={finalBorder}
      boxShadow={blockBoxShadow}
      borderRadius={blockBorderRadius}
      backgroundColor={backgroundColor}
    >
      {skeleton && (
        <SkeletonLoader
          height={imageHeight}
          width={"100%"}
          margin="0 0 15px 0"
        />
      )}

      {!skeleton && item.image && (
        <GridImage
          uniqueId={item.frontly_id}
          imageHeight={imageHeight}
          src={resizeImage({
            url: item.image,
            height: 300,
            quality: 60,
            forceJpg: true,
          })}
        />
      )}
      <CardContent
        gridColumns={gridColumns}
        fields={fields}
        item={item}
        blockId={blockId}
        skeleton={skeleton}
        customBadgeColors={customBadgeColors}
      />
    </KanbanItemContainer>
  );
};

const ColumnsContainer = styled.div`
  display: flex;
  gap: ${spacing.s6};
  overflow-x: scroll;
  height: ${(p) => p.height};
  overflow-y: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 280px;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const KanbanColumnHeader = styled.div`
  background: ${(p) =>
    p.darkMode ? colors.darkModeLightBackground : p.backgroundColor || "white"};
  padding: ${spacing.s3};
  border-radius: ${(p) =>
    getPixels(isNil(p.borderRadius) ? 8 : p.borderRadius)};
  box-shadow: ${(p) => (p.boxShadow === false ? null : boxShadow.card)};
  border: ${(p) => p.border};
  border-top: 3px solid ${(p) => p.color || "var(--primary)"};
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin: 0 0 15px 0;
  width: 100%;
`;

const CountWrapper = styled.div`
  padding: 5px;
  border: 1px solid
    ${(p) => (p.darkMode ? colors.darkModeLightBorder : "var(--grey21)")};
  border-radius: 13px;
  width: 34px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KanbanItemContainer = styled.div`
  background: ${(p) =>
    p.darkMode ? colors.darkModeLightBackground : p.backgroundColor || "white"};
  padding: ${spacing.s4};
  border-radius: ${(p) =>
    getPixels(isNil(p.borderRadius) ? 8 : p.borderRadius)};
  box-shadow: ${(p) => (p.boxShadow === false ? null : boxShadow.card)};
  border: ${(p) => p.border};
  width: 100%;
`;
