import { Button, Icon, Input, Row, SelectToggle, Text } from "app/components";
import {
  rActiveActionObject,
  rActiveBlockId,
  rActiveVersionMap,
  rPageChanges,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import ActionBrowser from "app/adminApp/actionBrowser";
import ActionBubble from "./ActionBubble";
import CreateActionModal from "./CreateActionModal";
import NewActionStep from "./NewActionStep";
import { StepContainer } from "./ActionStep";
import { get } from "lodash";
import { getHighest } from "app/utils/utils";
import { migrateActionSteps } from "./utils";
import styled from "styled-components";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import useBlocks from "app/utils/useBlocks";
import usePageActions from "app/utils/usePageActions";

const Action = ({
  data,
  multiResourceId,
  customAction = null,
  customOnChange = null,
  disabledTypes = [],
}) => {
  const titleIsEditable = get(data, "titleIsEditable", false);

  const [showActionBrowser, setShowActionBrowser] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);

  const [editAction, setEditAction] = useState(null);

  const referenceLabelIsEditable = get(data, "referenceLabelIsEditable");

  const [showEditor, setShowEditor] = useState(false);

  const { activeCustomBlock } = useActiveCustomBlock();

  const setPageChanges = useSetRecoilState(rPageChanges);

  const { actions, setActions } = usePageActions();

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const setActiveActionObject = useSetRecoilState(rActiveActionObject);

  const activeVersionMap = useRecoilValue(rActiveVersionMap);

  const { blocks: pageBlocks, setBlocks: setPageBlocks } = useBlocks();

  const actionId = get(data, "value");
  const action =
    customAction ||
    get(data, "customAction") ||
    actions.find((a) => a.id === actionId);

  useEffect(() => {
    if (showEditor && !action) {
      createNewAction();
    }
  }, [showEditor]);

  const steps = migrateActionSteps(get(action, "steps", []));

  const multiFormItemId = get(data, "multiFormItemId");

  const createNewAction = () => {
    const newId = getHighest(actions, "id") + 1;
    const newAction = {
      id: newId,
      label: `Action ${newId}`,
      steps: [
        {
          id: 1,
          type: null,
        },
      ],
    };

    const updatedBlocks = pageBlocks.map((b) => {
      if (b.id === activeBlockId) {
        if (data.isMenuBlock) {
          const items = get(b, "items", []).map((m) => {
            if (m.id === multiFormItemId) {
              return { ...m, clickAction: newId };
            }
            return m;
          });

          return { ...b, items };
        } else {
          const activeVersionId = get(activeVersionMap, activeBlockId);

          // If active block version, add action to version data
          if (activeVersionId) {
            return {
              ...b,
              versions: get(b, "versions", []).map((v) => {
                if (get(v, "id") === activeVersionId) {
                  return {
                    ...v,
                    data: { ...get(v, "data", {}), [data.id]: newId },
                  };
                }
                return v;
              }),
            };
          }

          return { ...b, [data.id]: newId };
        }
      }
      return b;
    });

    // Update actions
    setActions([...actions, newAction]);

    // Update blocks
    setPageBlocks(updatedBlocks);
  };

  const updateData = (data, k, v) =>
    data.map((a) => (a.id === actionId ? { ...a, [k]: v } : a));

  const updateAction = (k, v) => {
    const customChange = customOnChange || get(data, "customOnChange");

    if (customChange) {
      customChange(v);
    } else {
      // Update actions
      setPageChanges(true);
      setActions(updateData(actions, k, v));
    }
  };

  const addStep = (data) => {
    const { parent, child } = data;

    const newId = getHighest(steps, "id") + 1;

    if (child) {
      // Add new intermediate step above the given 'child' step

      let matchingStep = steps.find((s) => s.id === child);

      let modifiedSteps = steps.map((s) => {
        if (s.parent === matchingStep.parent) {
          return { ...s, parent: newId };
        }
        return s;
      });

      let newSteps = [
        ...modifiedSteps,
        { id: newId, type: null, parent: matchingStep.parent },
      ];

      updateAction("steps", newSteps);
    } else {
      // Add new child to a parent
      updateAction("steps", [...steps, { id: newId, type: null, parent }]);
    }
  };

  const deleteStep = (sId) => {
    const matchingStep = steps.find((s) => s.id === sId);

    const newSteps = steps
      .filter((st) => st.id !== sId)
      .map((s) => {
        if (s.parent === sId) {
          return { ...s, parent: matchingStep.parent };
        }
        return s;
      });

    updateAction("steps", newSteps);
    setActiveActionObject(null);
  };

  let actionLabel = data.label;
  if (multiResourceId) {
    actionLabel = get(action, "label");
  } else if (referenceLabelIsEditable) {
    actionLabel = get(action, "referenceLabel");
  }

  const rootSteps = steps.filter((s) => !s.parent);

  const getStepData = (s) => {
    return {
      disabledTypes,
      actionId,
      data: s,
      dynamicSources: get(data, "dynamicSources", []),
      excludedSources: get(data, "excludedSources", []),
      deleteStep: () => deleteStep(s.id),
      onChange: (newStepData) => {
        updateAction(
          "steps",
          steps.map((st) => {
            if (st.id === s.id) {
              return newStepData;
            }
            return st;
          })
        );
      },
      addStep: (v) => addStep(v),
    };
  };

  const rootStepsWithoutConditions = rootSteps.filter(
    (s) => !get(s, "conditions")
  );
  const rootConditionsWarning =
    rootSteps.length > 1 && rootStepsWithoutConditions.length > 0;

  return (
    <div className="drag-item">
      {(showCreateAction || editAction) && (
        <CreateActionModal
          hide={() => {
            setShowCreateAction(false);
            setEditAction(null);
          }}
          showAppCreated={() => setShowCreateAction(false)}
          config={{ steps }}
          action={editAction}
        />
      )}
      {showActionBrowser && (
        <ActionBrowser
          hide={() => setShowActionBrowser(false)}
          onEdit={(editedAction) => {
            setShowActionBrowser(false);
            setEditAction(editedAction);
          }}
          onSelect={(selectedAction) => {
            setShowActionBrowser(false);
            setActions(
              actions.map((a) => {
                if (a.id === actionId) {
                  return { ...selectedAction, id: actionId };
                }
                return a;
              })
            );
          }}
        />
      )}
      {showEditor && (
        <FullScreenEditor>
          <Header>
            {titleIsEditable || referenceLabelIsEditable ? (
              <Row alignItems={"center"}>
                <Icon
                  data={{
                    icon: "FiEdit",
                    color: "var(--grey7)",
                    size: 18,
                  }}
                />
                <Input
                  data={{
                    value: actionLabel,
                    minimal: true,
                    border: "0px",
                    placeholder: "Action Label",
                    padding: "5px",
                    fontStyle: "headingLg",
                    onChange: (v) =>
                      updateAction(
                        referenceLabelIsEditable ? "referenceLabel" : "label",
                        v
                      ),
                  }}
                />
              </Row>
            ) : (
              <Text
                data={{
                  text: actionLabel || "Action Label",
                  fontStyle: "headingLg",
                }}
              />
            )}

            <Row gap="15px" alignItems="center">
              {activeCustomBlock && get(activeCustomBlock, "reusable") && (
                <SelectToggle
                  data={{
                    width: "280px",
                    margin: "0 10px 0 0",
                    onChange: (v) => {
                      // Update actions
                      setActions(updateData(actions, "editableByUser", v));
                    },
                    tabs: [
                      {
                        label: "Not Editable",
                        value: false,
                        active: !get(action, "editableByUser"),
                      },
                      {
                        label: "Editable By User",
                        value: true,
                        active: get(action, "editableByUser"),
                      },
                    ],
                  }}
                />
              )}

              <Button
                data={{
                  text: "Save Action",
                  type: "basic",
                  icon: "FiSave",
                  onClick: () => setShowCreateAction(true),
                }}
              />

              <Button
                data={{
                  text: "Browse Saved Actions",
                  type: "basic",
                  icon: "FiGrid",
                  onClick: () => setShowActionBrowser(true),
                }}
              />

              <Button
                data={{
                  text: "Add Root Step",
                  type: "basic",
                  icon: "FiPlus",
                  onClick: () => addStep({ parent: null }),
                }}
              />

              <Icon
                data={{
                  icon: "FiX",
                  size: 26,
                  onClick: () => setShowEditor(false),
                  hover: true,
                }}
              />
            </Row>
          </Header>

          <StepsContainer>
            {get(action, "editableByUser") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  maxWidth: "600px",
                  background: "white",
                  border: `1px solid var(--grey3)`,
                  borderRadius: "12px",
                  padding: "20px",
                }}
              >
                <Text
                  data={{
                    text: "This action is set to 'Editable By User'",
                    fontStyle: "heading2xl",
                    textAlign: "center",
                  }}
                />
                <Text
                  data={{
                    text: "This action is configurable by the user when they use this block, so it's not editable here. This allows more customization because the action is not pre-defined.",
                    fontStyle: "bodyXl",
                    textAlign: "center",
                  }}
                />
              </div>
            )}

            {!get(action, "editableByUser") && (
              <>
                <Row>
                  {rootSteps.map((r) => (
                    <NewActionStep
                      stepId={r.id}
                      steps={steps}
                      getStepData={getStepData}
                      conditionWarning={rootConditionsWarning}
                    />
                  ))}
                </Row>

                {steps.length === 0 && (
                  <StepContainer
                    onClick={() => addStep({ parent: null })}
                    style={{ width: "fit-content" }}
                  >
                    <Row
                      justifyContent="space-between"
                      alignItems="center"
                      gap="10px"
                    >
                      <Icon
                        data={{
                          icon: "FiPlus",
                          color: "var(--grey7)",
                          hover: true,
                        }}
                      />
                      <Text
                        data={{
                          text: "Add Step",
                          fontStyle: "headingMd",
                          cursor: "pointer",
                        }}
                      />
                    </Row>
                  </StepContainer>
                )}
              </>
            )}
          </StepsContainer>
        </FullScreenEditor>
      )}

      <ActionBubble
        multiResourceId={multiResourceId}
        resourceId={data.label || data.id}
        action={action}
        onClick={() => setShowEditor(true)}
      />
    </div>
  );
};

export default Action;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  min-height: 60px;
  max-height: 60px;
  border-bottom: 1px solid var(--grey21);
  width: 100%;
`;

const FullScreenEditor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 9999;
  background: white;
  padding: 0px;
  overflow: scroll;
`;

const StepsContainer = styled.div`
  padding: 30px;
  background: var(--grey1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
