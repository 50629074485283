import {
  rActiveBlockId,
  rApp,
  rHoverBlockId,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import RenderBlock from "./RenderBlock";
import { allBlocks } from "app/utils/utils";
import { get } from "lodash";
import useAddBlock from "app/utils/useAddBlock";
import useBlockVersion from "app/utils/useBlockVersion";
import useBlocks from "app/utils/useBlocks";
import { useDragAndDropHandlers } from "./dragAndDropHandlers";
import useIsCustomBlock from "app/utils/useIsCustomBlock";

const RenderBlockSetupMode = ({ data }) => {
  const {
    page,
    customBlockOnClick,
    isPreview = false,
    index,
    parentType = null,
  } = data;

  const isCustomBlock = useIsCustomBlock();

  let originalBlock = get(data, "block", {});

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion: block } = getBlockWithVersion(originalBlock);

  const blockId = get(block, "id");
  const componentId = get(block, "componentId");

  const addBlock = useAddBlock();

  const app = useRecoilValue(rApp);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const [hoverBlockId, setHoverBlockId] = useRecoilState(rHoverBlockId);

  const isHovering = useMemo(
    () => hoverBlockId === blockId,
    [hoverBlockId, blockId]
  );

  const [activeBlockId, setActiveBlockId] = useRecoilState(rActiveBlockId);

  const isActiveBlock = activeBlockId === blockId;

  const blockSpreadsheet = savedSpreadsheets.find(
    (s) => s.id === block.spreadsheet
  );
  const sheetAccessAllowed = get(blockSpreadsheet, "allow_public_access");

  const showPublicAccessError =
    !isPreview &&
    !isCustomBlock &&
    !get(page, "requireLogin") &&
    blockSpreadsheet &&
    !sheetAccessAllowed;

  const { blocks: pageBlocks, setBlocks: setPageBlocks } = useBlocks();

  const adminPreview = !isPreview;

  const disableAdminAnimations = get(app, "disable_admin_animations", false);

  const containerRef = useRef();

  // Only add mouse events in setup mode
  const mouseEvents = useMemo(() => {
    let mEvents = {};
    if (adminPreview && !block.reusableBlockId) {
      mEvents = {
        active: isActiveBlock,
        onClick: (e) => {
          e.stopPropagation();
          setActiveBlockId(blockId);
        },
      };

      // If not disabling admin animations, add hover and mouseover things
      if (!disableAdminAnimations && !block.reusableBlockId) {
        mEvents = {
          ...mEvents,
          hovering: isHovering,
          onMouseOver: (e) => {
            e.stopPropagation();
            if (!isHovering) {
              setHoverBlockId(blockId);
            }
          },
          onMouseLeave: (e) => {
            setHoverBlockId(null);
          },
        };
      }
    }

    return mEvents;
  }, [
    adminPreview,
    block,
    activeBlockId,
    customBlockOnClick,
    disableAdminAnimations,
    isHovering,
  ]);

  const isStatBlockInManualMode =
    get(block, "valueType") === "manual" && componentId === "Stat";

  const blockConfig = allBlocks.find((b) => b.type === componentId);

  const showSheetSetup =
    !block.spreadsheet &&
    get(blockConfig, "requiresSheet") &&
    !isStatBlockInManualMode;

  let blockBorder = null;

  // If row or column, add dashed border
  if (
    ["Row", "Column"].includes(componentId) &&
    !block.reusableBlockId &&
    get(page, "showLayoutGridLines")
  ) {
    blockBorder = {
      borderWidth: "1px",
      borderColor: "rgba(0,0,0,0.15)",
      borderStyle: "dashed",
      forceBorder: true,
    };
  }

  const {
    drag,
    drop,
    isDragging,
    isDropTarget,
    position,
    dragPreviewFunction,
  } = useDragAndDropHandlers({
    containerRef,
    block,
    index,
    parentType,
    setActiveBlockId,
    setBlocks: setPageBlocks,
    blocks: pageBlocks,
    addBlock,
  });

  // Function to handle merging refs
  const handleRef = (node) => {
    containerRef.current = node;
    drag(drop(node));
  };

  const dragParams = {
    ref: handleRef,
    index: index,
    isDragging,
    isDropTarget,
  };

  const setupModeData = {
    ...data,
    dragPreviewFunction,
    isActiveBlock,
    showPublicAccessError,
    dragParams,
    mouseEvents,
    showSheetSetup,
    position,
    block,
    adminBorderOverride: blockBorder,
  };

  return <RenderBlock data={setupModeData} />;
};

export default RenderBlockSetupMode;
