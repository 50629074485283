import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getHighest } from "app/utils/utils";
import { rActiveBlockId, rPageActions } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import Action from "./Action/Action";
import { Button } from "app/components";
import { get } from "lodash";
import { spacing } from "app/utils/theme";
import useBlocks from "app/utils/useBlocks";
import useSetPage from "app/utils/useSetPage";

const MultiActions = ({ data }) => {
  const { setPage } = useSetPage();

  const { blocks, setBlocks } = useBlocks();

  const [actions, setActions] = useRecoilState(rPageActions);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const actionObjects = get(data, "value", []).map((a) => ({
    ...a,
    dynamicSources: get(data, "dynamicSources", []),
    excludedSources: get(data, "excludedSources", []),
  }));

  const addAction = () => {
    const newId = getHighest(actions, "id") + 1;

    // Update blocks
    setBlocks(
      blocks.map((b) => {
        if (b.id === activeBlockId) {
          return {
            ...b,
            [data.id]: [
              ...actionObjects,
              {
                value: newId,
                label: "New Action",
              },
            ],
          };
        }
        return b;
      })
    );

    // Update actions
    setActions([
      ...actions,
      {
        id: newId,
        label: `Action ${newId}`,
        steps: [
          {
            id: 1,
            type: null,
          },
        ],
      },
    ]);
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: spacing.s4,
      }}
      dragHandleSelector=".drag-item"
      lockAxis="y"
      onDrop={(e) => {
        const { addedIndex, removedIndex } = e;
        const movedActions = arrayMove(actionObjects, removedIndex, addedIndex);
        setPage({
          blocks: blocks.map((b) => {
            if (b.id === activeBlockId) {
              return {
                ...b,
                [data.id]: movedActions,
              };
            }
            return b;
          }),
        });
      }}
    >
      {actionObjects.map((a) => (
        <Draggable key={a.id}>
          <Action
            data={{ ...a, titleIsEditable: true }}
            multiResourceId={data.id}
          />
        </Draggable>
      ))}
      <Button
        data={{
          text: "Add Action",
          onClick: addAction,
          size: "small",
          type: "basic",
          icon: "FiPlus",
        }}
      />
    </Container>
  );
};

export default MultiActions;
