import { Column, Modal, Row, Switch, Text } from "app/components";

import { Tile } from ".";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { useState } from "react";

const EnabledFeaturesEditor = ({ data }) => {
  const [edit, setEdit] = useState();
  const features = [
    {
      name: "Billing",
      id: "billing",
    },
    {
      name: "Pages",
      id: "pages",
    },
    {
      name: "Forms",
      id: "forms",
    },
    {
      name: "Spreadsheets",
      id: "spreadsheets",
    },
    {
      name: "Users",
      id: "users",
    },
    {
      name: "Settings",
      id: "settings",
    },
    {
      name: "Experts",
      id: "experts",
    },
    {
      name: "Resources",
      id: "resources",
    },
  ];

  const enabledFeatures = get(data, "value", []) || [];

  const updateFeatures = (featureId) => {
    let newEnabledFeatures = [...enabledFeatures];

    if (enabledFeatures.includes(featureId)) {
      newEnabledFeatures = enabledFeatures.filter((a) => a !== featureId);
    } else {
      newEnabledFeatures = [...enabledFeatures, featureId];
    }

    data.onChange(newEnabledFeatures);
  };

  return (
    <>
      {edit && (
        <Modal
          header={{ title: "Enabled Features" }}
          hide={() => setEdit(false)}
        >
          {enabledFeatures.length === 0 && (
            <Text
              data={{
                text: "Note: This user has access to all features. Enable specific features if you want to limit their access.",
                margin: "0 0 30px 0",
                color: "var(--primary-admin)",
                fontStyle: "headingSm",
              }}
            />
          )}
          {enabledFeatures.length > 0 && (
            <Text
              data={{
                text: `This user has admin access to ${
                  enabledFeatures.length
                } ${enabledFeatures.length === 1 ? "feature" : "features"}`,
                margin: "0 0 30px 0",
                color: "var(--primary-admin)",
                fontStyle: "headingSm",
              }}
            />
          )}
          <Column gap="20px">
            {features.map((a) => (
              <Row>
                <Text data={{ text: a.name, width: "120px" }} />
                <Switch
                  data={{
                    disabled: data.disabled,
                    value: enabledFeatures.includes(a.id),
                    onChange: () => updateFeatures(a.id),
                  }}
                />
              </Row>
            ))}
          </Column>
        </Modal>
      )}
      <Tile
        icon="FiEdit"
        label={`Enabled Features (${
          enabledFeatures.length === 0 ? "All" : enabledFeatures.length
        })`}
        onClick={() => setEdit(true)}
      />
    </>
  );
};

export default EnabledFeaturesEditor;
