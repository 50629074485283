import { Button, EditableText, Icon, Row } from "app/components";
import styled, { keyframes } from "styled-components";

import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { rUser } from "app/utils/recoil";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const samplePrompt =
  "A portal for my marketing agency clients to review project statuses and request revisions.";

const AiBuilder = () => {
  const [prompt, setPrompt] = useState(samplePrompt);

  const [step, setStep] = useState("prompt");

  const [pages, setPages] = useState([]);
  const [appName, setAppName] = useState("");

  const [isGeneratingPages, setIsGeneratingPages] = useState(false);

  const finalizeApp = () => {
    setStep("generating");
    // setIsGeneratingPages(true);
    apiRequest.post("/generate_tables_with_ai/", {
      prompt,
      pages,
      name: appName,
    });
    // .then((res) => {
    //   const tables = get(res, "data.tables", []);
    //   setTables(tables);
    //   setStep("tables");
    //   setIsGeneratingPages(false);
    // })
    // .catch((err) => {
    //   console.log(err);
    //   // setIsGeneratingPages(false);
    // });
  };

  const generatePages = () => {
    setIsGeneratingPages(true);

    apiRequest
      .post("/generate_pages_with_ai/", {
        prompt,
      })
      .then((res) => {
        const pages = get(res, "data.pages", []);
        const appName = get(res, "data.app_name", "");
        setAppName(appName);
        setPages(pages);
        setStep("pages");
        setIsGeneratingPages(false);
      })
      .catch((err) => {
        console.log(err);
        setIsGeneratingPages(false);
      });
  };

  if (step === "pages") {
    return (
      <Pages pages={pages} setPages={setPages} finalizeApp={finalizeApp} />
    );
  }

  // TODO - Generate a style step where you can select your color theme, upload a logo, etc.
  if (step === "generating") {
    return <GeneratingScreen />;
  }

  return (
    <Background>
      <Logo
        src={
          "https://res.cloudinary.com/frontly/image/upload/v1738126836/frontly_icon_black_ks8um4.svg"
        }
      />
      <Content>
        <Title>What do you want to build?</Title>
        <Subtitle>
          We'll create a starter app for you based on your description.
        </Subtitle>
        <PromptInput
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="I want to build an app that..."
        />
        <Button
          data={{
            text: "Generate App",
            onClick: generatePages,
            padding: "16px 20px 16px 20px",
            size: "extraLarge",
            icon: "FiArrowRight",
            flippedIcon: true,
            isFetching: isGeneratingPages,
          }}
        />
        {isGeneratingPages && (
          <Subtitle>This process can take up to 20 seconds.</Subtitle>
        )}
      </Content>
    </Background>
  );
};

export default AiBuilder;

const Pages = ({ pages, setPages, finalizeApp }) => {
  const colorOptions = [
    "#c3e2f7",
    "#c1f2cd",
    "#eecdfa",
    "#fad0df",
    "#c0f0f0",
    "#ffc5b2",
  ];

  return (
    <Background>
      <Logo
        src={
          "https://res.cloudinary.com/frontly/image/upload/v1738126836/frontly_icon_black_ks8um4.svg"
        }
      />
      <Content style={{ maxWidth: "1200px" }}>
        <Title>Let's define the pages for your app</Title>
        <Subtitle>
          Add, remove or revise pages below by typing. Login and signup are
          included by default.
        </Subtitle>
        <PagesGrid count={pages.length}>
          {pages.map((p, index) => (
            <Page
              key={index}
              page={p}
              index={index}
              setPages={setPages}
              pages={pages}
              colorOptions={colorOptions}
              isFinalIndex={index === pages.length - 1}
            />
          ))}
        </PagesGrid>
        <Button
          data={{
            text: "Generate Starter App",
            onClick: finalizeApp,
            padding: "16px 20px 16px 20px",
            size: "extraLarge",
            icon: "FiArrowRight",
            flippedIcon: true,
          }}
        />
      </Content>
    </Background>
  );
};

const Page = ({ page, index, setPages, pages, colorOptions, isFinalIndex }) => {
  const [isHovered, setIsHovered] = useState(false);
  const windowWidth = window.innerWidth;

  return (
    <PageContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <div style={{ position: "absolute", right: "8px", top: "9px" }}>
          <Icon
            data={{
              icon: "BsXCircle",
              size: 24,
              color: "rgba(0, 0, 0, 0.3)",
              hover: true,
              onClick: () => {
                setPages(pages.filter((_, i) => i !== index));
              },
            }}
          />
        </div>
      )}
      {isFinalIndex && (
        <div style={{ position: "absolute", right: "-45px", top: "0px" }}>
          <Icon
            data={{
              icon: "BsPlusCircle",
              size: 24,
              color: "rgba(0, 0, 0, 0.3)",
              hover: true,
              margin: "10px 0 0 10px",
              onClick: () => {
                setPages([
                  ...pages,
                  {
                    name: "",
                    description: "",
                    icon: "FiFile",
                  },
                ]);
              },
            }}
          />
        </div>
      )}
      <Row alignItems="center" gap="10px">
        <IconWrapper color={colorOptions[index % colorOptions.length]}>
          <Icon
            data={{
              icon: page.icon,
              size: windowWidth > 768 ? 20 : 16,
            }}
          />
        </IconWrapper>
        <EditableText
          data={{
            fontSize: windowWidth > 768 ? 24 : 18,
            editable: true,
            fontWeight: 700,
            placeholder: "Page Name",
            value: page.name,
            onChange: (value) => {
              setPages(
                pages.map((p, i) => (i === index ? { ...p, name: value } : p))
              );
            },
          }}
        />
      </Row>
      <EditableText
        data={{
          fontSize: windowWidth > 768 ? 20 : 16,
          editable: true,
          multiline: true,
          fontWeight: 300,
          value: page.description,
          placeholder: "Type a description for this page...",
          onChange: (value) => {
            setPages(
              pages.map((p, i) =>
                i === index ? { ...p, description: value } : p
              )
            );
          },
        }}
      />
    </PageContainer>
  );
};

const GeneratingScreen = () => {
  const user = useRecoilValue(rUser);
  const navigate = useNavigate();

  return (
    <Background>
      <Logo
        src={
          "https://res.cloudinary.com/frontly/image/upload/v1738126836/frontly_icon_black_ks8um4.svg"
        }
      />
      <Content>
        <Title>Your App Is Being Finalized</Title>
        <Subtitle style={{ margin: "20px 0 20px 0" }}>
          You will receive an email at <strong>{user.email}</strong> once it is
          ready. This process generally takes 1-2 minutes.
        </Subtitle>
        <Button
          data={{
            text: "Go To Frontly Dashboard",
            onClick: () => navigate("/home"),
            padding: "16px 20px 16px 20px",
            size: "extraLarge",
            icon: "FiArrowRight",
            flippedIcon: true,
          }}
        />
      </Content>
    </Background>
  );
};

const Logo = styled.img`
  height: 40px;
  position: absolute;
  top: 20px;
  left: 20px;
`;

const IconWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 12px;
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }
`;

// Define the keyframes for the gradient animation
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--grey1);
  background: radial-gradient(
    circle,
    rgba(195, 226, 247, 0.15) 0%,
    rgba(193, 242, 205, 0.15) 20%,
    rgba(238, 205, 250, 0.15) 40%,
    rgba(250, 208, 223, 0.15) 60%,
    rgba(192, 240, 240, 0.15) 80%,
    rgba(255, 197, 178, 0.15) 100%
  );
  background-size: 500% 500%;
  animation: ${gradientAnimation} 30s ease infinite;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 44px;
  font-weight: 600;
  color: var(--text);
  text-align: center;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: var(--text);
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const PromptInput = styled.textarea`
  width: 100%;
  padding: 20px;
  border: 0px;
  box-shadow: var(--card-shadow);
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  resize: none;
  font-size: 20px;
  min-height: 150px;
  background: white;
  outline: none;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const PageContainer = styled.div`
  width: 100%;
  padding: 20px;
  box-shadow: var(--card-shadow);
  border-radius: 15px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  width: 400px;
  min-height: 180px;
  align-self: stretch;
  @media (max-width: 1200px) {
    padding: 12px;
  }
`;

const PagesGrid = styled.div`
  gap: 20px;
  max-height: 600px;
  overflow-y: auto;
  padding: 25px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;
