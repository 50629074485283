import "react-tagsinput/react-tagsinput.css";
import "./Tags.css";

import TagsInput from "react-tagsinput";
import { get } from "lodash";
import { safeArray } from "app/utils/utils";

const Tags = ({ data }) => {
  const value = get(data, "value", []);

  const onChange = get(data, "onChange");

  return (
    <TagsInput
      value={safeArray(value)}
      onChange={(newTags) => onChange(newTags)}
    />
  );
};

export default Tags;
