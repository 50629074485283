import { Button, Menu } from "app/components";
import { get, startCase } from "lodash";

import AdminForm from "./AdminForm";
import useActiveBlock from "app/utils/useActiveBlock";
import useBlockVersion from "app/utils/useBlockVersion";
import useSetBlock from "app/utils/useSetBlock";
import { useState } from "react";

const FormValidation = ({ data }) => {
  const editField = get(data, "editField");

  const setBlock = useSetBlock();

  const originalActiveBlock = useActiveBlock();

  const { getBlockWithVersion } = useBlockVersion();

  const { blockWithVersion: activeBlock } =
    getBlockWithVersion(originalActiveBlock);

  const block = get(data, "block") || activeBlock;

  const fieldData = get(block, "fieldData", {});
  const config = get(fieldData, "config", {});

  const matchingField = data.activeField || get(config, editField, {});

  const updateField = (key, value) => {
    if (data.customOnChange) {
      data.customOnChange(key, value);
    } else {
      let newField = { ...matchingField, [key]: value };
      setBlock({
        data: {
          fieldData: {
            ...fieldData,
            config: { ...config, [editField]: newField },
          },
        },
        customBlockId: block.id,
      });
    }
  };

  const activeField = {
    ...matchingField,
    active: get(matchingField, "active") === false ? false : true,
  };

  const [anchorElement, setAnchorElement] = useState(null);

  const validationFields = [
    {
      id: "required",
      label: "Required",
      componentId: "Switch",
      section: "Validation",
      defaultValue: true,
      orientation: "horizontal",
      hint: "Is this field required to submit form?",
      value: get(activeField, "required") === false ? false : true,
    },
    {
      id: "isEmail",
      label: "Is Email",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains a valid email address",
      value: get(activeField, "isEmail"),
    },
    {
      id: "isPrice",
      label: "Is Price",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains a valid price. Allows $ but will be removed on save.",
      value: get(activeField, "isPrice"),
    },
    {
      id: "isNumber",
      label: "Is Number",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains only valid numbers",
      value: get(activeField, "isNumber"),
    },
    {
      id: "isTrue",
      label: "Is True",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value is true, generally used to confirm a checkbox or switch are turned 'on'.",
      value: get(activeField, "isTrue"),
    },
    {
      id: "isAlphabetical",
      label: "Is Alphabetical",
      componentId: "Switch",
      section: "Validation",
      defaultValue: false,
      orientation: "horizontal",
      hint: "Ensure this value contains only letters",
      value: get(activeField, "isAlphabetical"),
    },
    {
      id: "minOffset",
      label: "Min Chars/Value",
      hint: "Enter minimum characters required or minimum number for the input to be",
      componentId: "Input",
      section: "Validation",
      required: true,
      defaultValue: "",
      value: get(activeField, "minOffset"),
    },
    {
      id: "maxOffset",
      label: "Max Chars/Value",
      hint: "Enter maximum characters required or maximum number for the input to be",
      componentId: "Input",
      section: "Validation",
      required: true,
      defaultValue: "",
      value: get(activeField, "maxOffset"),
    },
    {
      id: "customRegex",
      label: "Custom Regex",
      hint: "A custom regex to validate the input value. This is an advanced feature for highly technical users.",
      componentId: "Input",
      section: "Validation",
      required: false,
      defaultValue: "",
      value: get(activeField, "customRegex"),
    },
    {
      id: "customRegexError",
      label: "Custom Regex Error",
      hint: "An error to be displayed when the user's input doesn't meet the the custom regex requirements",
      componentId: "Input",
      section: "Validation",
      required: false,
      defaultValue: "",
      value: get(activeField, "customRegexError"),
    },
  ];

  const validations = validationFields
    .map((field) => {
      if (field.value) {
        return field.id;
      }
    })
    .filter((field) => field !== undefined)
    .map((field) => startCase(field));

  let validationString = validations.join(", ");

  let validationText = "No Validation Rules";
  if (validations.length > 0) {
    if (validations.length > 3) {
      validationString =
        validations.slice(0, 3).join(", ") +
        ` and ${validations.length - 3} more`;
    }

    validationText = `Rules: ${validationString}`;
  }

  return (
    <div>
      <Menu
        anchorElement={anchorElement}
        hide={() => {
          setAnchorElement(null);
        }}
        label="Field Validation"
      >
        <AdminForm
          sectionPadding="0px"
          fields={validationFields}
          onChange={(k, v) => updateField(k, v)}
        />
      </Menu>
      <Button
        data={{
          text: validationText,
          type: "basic",
          onClick: (e) => setAnchorElement(e.currentTarget),
        }}
      />
    </div>
  );
};

export default FormValidation;
