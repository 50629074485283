import { colors } from "app/utils/theme";
import styled from "styled-components";

const Progress = ({ percent }) => {
  return (
    <Container>
      <Bar style={{ width: `${percent}%` }}>&nbsp;</Bar>
    </Container>
  );
};

export default Progress;

const Bar = styled.div`
  background: var(--primary);
`;

const Container = styled.div`
  background: var(--grey21);
  height: 10px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
`;
