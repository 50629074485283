import { Modal, Row, Switch, Text } from "app/components";

import { Tile } from ".";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rApps } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const AdminAppsEditor = ({ data }) => {
  const [edit, setEdit] = useState();
  const apps = useRecoilValue(rApps);

  const enabledApps = get(data, "value", []) || [];

  const updateApps = (appId) => {
    let newEnabledApps = [...enabledApps];

    if (enabledApps.includes(appId)) {
      newEnabledApps = enabledApps.filter((a) => a !== appId);
    } else {
      newEnabledApps = [...enabledApps, appId];
    }

    data.onChange(newEnabledApps);
  };

  return (
    <>
      {edit && (
        <Modal header={{ title: "Enabled Apps" }} hide={() => setEdit(false)}>
          {enabledApps.length === 0 && (
            <Text
              data={{
                text: "Note: This user has access to all apps. Enable specific apps if you want to limit their access.",
                margin: "0 0 30px 0",
                color: "var(--primary-admin)",
                fontStyle: "headingSm",
              }}
            />
          )}
          {enabledApps.length > 0 && (
            <Text
              data={{
                text: `This user has admin access to ${enabledApps.length} ${
                  enabledApps.length === 1 ? "app" : "apps"
                }`,
                margin: "0 0 30px 0",
                color: "var(--primary-admin)",
                fontStyle: "headingSm",
              }}
            />
          )}
          <AppsGrid>
            {apps.map((a) => (
              <Row>
                <Text data={{ text: a.name, width: "200px" }} />
                <Switch
                  data={{
                    disabled: data.disabled,
                    value: enabledApps.includes(a.id),
                    onChange: () => updateApps(a.id),
                  }}
                />
              </Row>
            ))}
          </AppsGrid>
        </Modal>
      )}
      <Tile
        icon="FiEdit"
        label={`Enabled Apps (${
          enabledApps.length === 0 ? "All" : enabledApps.length
        })`}
        onClick={() => setEdit(true)}
      />
    </>
  );
};

export default AdminAppsEditor;

const AppsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 40px;
  width: 640px;
`;
