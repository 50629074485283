import { Text } from "app/components";
import { get } from "lodash";
import styled from "styled-components";

const DataSourcesDisplay = ({ dataSources, type, margin }) => {
  return (
    <DataSourcesContainer style={{ margin }}>
      <Text
        data={{
          text: `The following data sources used in this ${type} will be copied:`,
          fontSize: 16,
          fontWeight: 300,
          margin: "0 0 8px 0",
        }}
      />
      {dataSources.map((ds) => (
        <DataSourceContainer>
          <Text
            data={{
              text: get(ds, "name") || get(ds, "title"),
              fontWeight: 600,
              fontSize: 16,
            }}
          />
          <Text
            data={{
              text: get(ds, "headers", []).join(", "),
              fontWeight: 300,
              color: "var(--grey7)",
            }}
          />
        </DataSourceContainer>
      ))}
    </DataSourcesContainer>
  );
};

export default DataSourcesDisplay;

const DataSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const DataSourceContainer = styled.div`
  border: 1px solid var(--grey4);
  border-radius: 10px;
  padding: 12px;
  gap: 3px;
  display: flex;
  flex-direction: column;
`;
