import Analytics from "./mock_blocks/Analytics";
import Calendar from "./mock_blocks/Calendar";
import Form from "./mock_blocks/Form";
import Kanban from "./mock_blocks/Kanban";
import React from "react";
import Table from "./mock_blocks/Table";
import { get } from "lodash";

const RenderComponent = ({ page, dataSources, multi, hue, large }) => {
  const dataSource = get(page, "data_source");
  const dataSourceMatch = dataSources.find((ds) => ds.key === dataSource) || [];
  const data = get(dataSourceMatch, "data", []);

  const type = get(page, "type");

  const props = {
    page,
    data,
    multi,
    hue,
    large,
  };

  switch (type) {
    case "table":
      return <Table {...props} />;
    case "form":
      return <Form {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "kanban":
      return <Kanban {...props} />;
    case "analytics":
      return <Analytics {...props} />;
  }
};

export default RenderComponent;
