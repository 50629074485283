import { Icon, Row, Text } from "app/components";
import { badges, spacing } from "app/utils/theme";

import { Tooltip } from "react-tooltip";
import { get } from "lodash";
import { rActiveActionObject } from "app/utils/recoil";
import styled from "styled-components";
import useActions from "app/utils/useActions";
import { useSetRecoilState } from "recoil";
import { useState } from "react";

const ActionStep = ({
  actionId,
  data,
  onChange,
  conditionWarning,
  addStep,
  previousSteps,
  deleteStep,
  activeStepId,
  dynamicSources,
  excludedSources,
  layerColor,
  childCount,
  disabledTypes = [],
  mock = false,
  mockSize = "small",
  showLabels = false,
}) => {
  const [showButtons, setShowButtons] = useState(false);

  const setActiveActionObject = useSetRecoilState(rActiveActionObject);
  const { getStepTypes } = useActions();

  const stepTypes = getStepTypes({
    step: data,
    onChange,
    disabledTypes,
    actionId,
    stepId: activeStepId,
  });

  // The current step 'type' config
  const config = stepTypes.find((st) => st.type === get(data, "type"));

  const stepLabel = data.type ? get(config, "label") : "Select Step Type";

  const showConditionWarning = conditionWarning && !get(data, "conditions");

  const editActionModalObject = {
    actionId,
    currentStep: data,
    disabledTypes,
    stepTypes,
    childCount,
    deleteStep,
    onChange,
    previousSteps,
    dynamicSources,
    excludedSources,
  };

  const stepContainerProps = mock
    ? { mock: true, showLabels }
    : {
        onClick: () => setActiveActionObject(editActionModalObject),
        onMouseEnter: () => setShowButtons(true),
        onMouseLeave: () => setShowButtons(false),
      };

  // Icon size
  const getIconSize = () => {
    if (mock) {
      if (mockSize === "small") {
        return 21;
      }
      if (mockSize === "medium") {
        return 28;
      }
      if (mockSize === "large") {
        return 34;
      }
    }
    return 24;
  };

  return (
    <>
      <StepWrapper>
        <StepContainer {...stepContainerProps}>
          <Row justifyContent="space-between" alignItems="center" gap="10px">
            <Icon
              data={{
                icon: get(config, "icon", "FiEdit"),
                color: layerColor,
                hover: true,
                size: getIconSize(),
              }}
            />
            {(!mock || showLabels) && (
              <Text
                data={{
                  text: stepLabel,
                  fontSize: mock ? 12 : 16,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
              />
            )}
            {showConditionWarning && (
              <div className={`step-${data.id}`}>
                <Tooltip
                  anchorSelect={`.step-${data.id}`}
                  place="left"
                  style={{ zIndex: 9999, width: "220px" }}
                >
                  This step does not have any conditions set. This will cause
                  issues with your conditional logic.
                </Tooltip>
                <Warning>
                  <Icon
                    data={{ icon: "FiAlertTriangle", color: "white", size: 15 }}
                  />
                </Warning>
              </div>
            )}
          </Row>
          {showButtons && (
            <>
              <AddStepIconWrapper
                style={{
                  top: "-10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: "var(--grey21)",
                    onClick: (e) => {
                      e.stopPropagation();
                      addStep({ child: data.id });
                    },
                    hover: true,
                    size: 16,
                  }}
                />
              </AddStepIconWrapper>
              <AddStepIconWrapper
                style={{
                  bottom: "-10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Icon
                  data={{
                    icon: "FiPlus",
                    color: "var(--grey21)",
                    onClick: (e) => {
                      e.stopPropagation();
                      addStep({ parent: data.id });
                    },
                    hover: true,
                    size: 16,
                  }}
                />
              </AddStepIconWrapper>
            </>
          )}
        </StepContainer>
      </StepWrapper>
    </>
  );
};

export default ActionStep;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--grey21);
  width: 100%;
  background: white;
  position: relative;
  cursor: pointer;

  ${({ mock, showLabels }) =>
    mock
      ? `
      gap: 10px;
      padding:  ${showLabels ? "8px" : "6px"};
      border-radius: 8px;
    `
      : `
    &:hover {
      filter: brightness(99%);
    }
    `}
`;

const AddStepIconWrapper = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--grey21);
  border-radius: 50%;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(90%);
  }
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.s3};
  cursor: pointer;
`;

const Warning = styled.div`
  background: ${badges.red};
  padding: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
`;
