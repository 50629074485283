import { get, startCase } from "lodash";

import BlockHeader from "./BlockHeader";
import { colors } from "app/utils/theme";
import styled from "styled-components";

const Form = ({ page, data, hue, multi, large }) => {
  const firstObject = get(data, 0, {});

  const formFields = get(page, "fields", []);

  let fields = Object.keys(firstObject);

  if (!large) {
    fields = fields.slice(0, 2);
  }

  if (formFields.length > 0) {
    fields = fields.filter((field) => formFields.includes(field));
  }

  return (
    <FormContainer>
      <BlockHeader name={page.name} hue={hue} hideButton large={large} />
      {fields.map((field) => {
        const value = get(firstObject, field);
        const placeholder = `Enter ${startCase(field).toLowerCase()}`;

        return (
          <FormField key={field}>
            <FormLabel>{startCase(field)}</FormLabel>
            <FormInput hasValue={!!value}>{value || placeholder}</FormInput>
          </FormField>
        );
      })}
      <FormButton hue={hue}>Submit</FormButton>
    </FormContainer>
  );
};

export default Form;

const FormButton = styled.div`
  font-size: 8px;
  font-weight: 600;
  background: ${colors.primary};
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  width: fit-content;
  background: hsl(${(p) => p.hue}, 93%, 25%, 0.8);
`;

// Styled components for the Form
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormLabel = styled.label`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #484848;
  cursor: pointer;
`;

const FormInput = styled.div`
  font-size: 10px;
  padding: 3px;
  border: 1px solid ${colors.grey2};
  border-radius: 5px;
  background: #f9f9f9;
  min-height: 20px;
  color: ${(p) => (p.hasValue ? "#000" : "#999")};
`;
