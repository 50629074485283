import { Boolean, Button, Hint, Row, Text } from "app/components";
import { get, startCase, upperCase } from "lodash";

import { rSubscription } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const starter = {
  plan: "starter",
  frequency: "monthly",
  amount: 0,
  operations_limit: 1000,
  api_access: false,
  team_support: false,
  custom_blocks: false,
  app_limit: 0, // branded apps
  ai_requests_limit: 50,
};

const basic = {
  plan: "basic",
  amount: 30,
  operations_limit: 10000,
  app_limit: 1,
  ai_requests_limit: 500,
  team_support: false,
  api_access: false,
  custom_blocks: true,
  description: "For businesses looking to try Frontly",
};

const professional = {
  plan: "professional",
  amount: 100,
  operations_limit: 50000,
  app_limit: 3,
  ai_requests_limit: 1000,
  team_support: true,
  api_access: true,
  custom_blocks: true,
  description: "For teams with growing needs",
};

const enterprise = {
  plan: "enterprise",
  description: "Contact support@frontlyapp.com for custom plans",
  price: "Custom",
  operations_limit: "Custom",
  app_limit: "Custom",
  ai_requests_limit: "Custom",
  team_support: true,
  api_access: true,
  custom_blocks: true,
};

const PlansGrid = ({ showNewMessage }) => {
  const subscription = useRecoilValue(rSubscription);

  const planName = get(subscription, "plan");

  const hasCustomPlan =
    subscription && !["basic", "professional"].includes(planName);

  const currentPlan = planName || "starter";

  let plans = [basic, professional, enterprise];

  const isAppSumo = currentPlan.includes("appsumo");

  if (hasCustomPlan) {
    plans.unshift({
      ...subscription,
      plan: isAppSumo ? startCase(planName) : "custom",
      buttonText: "Current Plan",
      isCurrentPlan: true,
      // Only if they have a custom plan, show app limit 0 as unlimited
      app_limit:
        get(subscription, "app_limit") === 0 && get(subscription, "amount") > 0
          ? "Unlimited"
          : get(subscription, "app_limit") || 0,
    });
  }

  if (!subscription) {
    plans.unshift(starter);
  }

  const features = [
    {
      label: hasCustomPlan ? "Apps" : "Branded Apps",
      key: "app_limit",
      is_unlimited: true,
      hint: hasCustomPlan
        ? "An App in Frontly is a distinct project with its own pages, users, navigation and domain name. Multiple apps can be used if you need to launch multiple separate projects under one company account."
        : "Branded apps allow adding a logo, custom domain, favicon and removing the 'Made With Frontly' badge.",
    },
    {
      label: "Users",
      key: "admin_user_limit",
      is_unlimited: true,
      hint: "Users are clients, customers, or partners who can login to your portal with an email and password. This is an end-user, so they do not have access to your admin or any editing capabilities other than what you've built into your portal.",
    },
    {
      label: hasCustomPlan ? "Frontly DB Operations" : "Operations",
      key: "operations_limit",
      is_number: true,
      hint: `Operations are the number of ${
        hasCustomPlan ? "Frontly" : ""
      } database actions that can be performed in a month. This includes viewing, creating, updating and deleting records.`,
    },
    {
      label: "AI Tokens",
      key: "ai_requests_limit",
      is_number: true,
      hint: "AI Tokens are used for the OpenAI action as well as the AI app generator and other AI features. Each individual usage of AI Generation consumes some amount of tokens. Tokens replenish each month. You can increase your token limit by adding your OpenAI API key.",
    },
    {
      label: "Custom Blocks",
      key: "custom_blocks",
      is_boolean: true,
      hint: "Create custom reusable combinations of text, buttons, icons and row/columns that can be used in your app as custom components.",
    },
    {
      label: "API Access",
      key: "api_access",
      is_boolean: true,
      hint: "API Access allows you to access your app's data via the API. This is useful for integrating with other services or building custom integrations.",
    },
    {
      label: "Team Support",
      key: "team_support",
      is_boolean: true,
      hint: "Team Support allows you to get help from the Frontly team via email and chat.",
    },
  ];

  return (
    <Container>
      <div>
        <PlanHeader></PlanHeader>
        <FeatureList>
          {features.map((f) => (
            <FeatureName>
              <Text
                data={{
                  text: upperCase(f.label),
                  fontStyle: "headingSm",
                  color: "var(--grey7)",
                  margin: "0 0 5px 0",
                }}
              />
              <Hint hint={f.hint} margin="" />
            </FeatureName>
          ))}
        </FeatureList>
      </div>

      {plans.map((p) => {
        // Get button text
        const buttonText = () => {
          if (p.buttonText) {
            return p.buttonText;
          }

          if (p.plan === currentPlan || p.isCurrentPlan) {
            return "Current Plan";
          }

          if (p.plan === "enterprise") {
            return "Contact Sales";
          }

          return "Choose Plan";
        };

        // Get plan price
        const planPrice = () => {
          if (p.plan === currentPlan || p.isCurrentPlan) {
            if (p.frequency === "one_time") {
              return "Lifetime deal";
            }

            if (p.amount === 0) {
              return "Free";
            }

            return `$${p.amount}`;
          }

          if (p.plan === "starter") {
            return "Free";
          } else if (p.plan === "enterprise") {
            return "Custom";
          }
          return `$${get(p, "amount")}`;
        };

        const getFrequencyText = () => {
          if (p.frequency === "one_time") {
            return "Lifetime deal";
          }

          if (p.frequency === "1_year") {
            return "/yr";
          }

          if (p.frequency === "2_year") {
            return "/2yr";
          }

          if (["basic", "professional"].includes(p.plan)) {
            return "/m";
          }

          return null;
        };

        const frequencyText = getFrequencyText();

        return (
          <Plan>
            <PlanHeader>
              <Text
                data={{
                  text: startCase(p.plan),
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Row gap="7px" alignItems="center" margin="10px 0 5px 0">
                <Text
                  data={{ text: planPrice(), fontSize: 30, fontWeight: 400 }}
                />

                {frequencyText && (
                  <Text
                    data={{
                      text: frequencyText,
                      fontSize: 16,
                      fontWeight: 300,
                      margin: "6px 0 0 0",
                    }}
                  />
                )}
              </Row>
              <Button
                data={{
                  text: buttonText(),
                  disabled: currentPlan === p.plan || p.isCurrentPlan,
                  onClick: () => {
                    // setNewPlan(p.key);
                    if (p.plan === "enterprise") {
                      showNewMessage(
                        "Hey Frontly team, I'd like to discuss a custom plan."
                      );
                    } else if (p.plan === "basic") {
                      window.open("https://buy.stripe.com/14k8xq84l05m1iw7u7");
                    } else if (p.plan === "professional") {
                      window.open("https://buy.stripe.com/5kA5le3O5cS86CQ9Cg");
                    }
                  },
                  margin: "10px 0 10px 0",
                }}
              />
            </PlanHeader>

            {features.map((f) => {
              let value = get(p, f.key);

              if (f.is_unlimited) {
                if (!value || value === 0) {
                  value = "Unlimited";
                } else {
                  value = value;
                }
              }

              if (f.is_boolean) {
                value = <Boolean value={value} />;
              }

              if (f.is_number) {
                value = value.toLocaleString("en-US");
              }

              return <FeatureValue>{value}</FeatureValue>;
            })}
          </Plan>
        );
      })}
    </Container>
  );
};

export default PlansGrid;

const PlanHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  flex: 1;
`;

const FeatureName = styled.div`
  font-size: 16px;
  font-weight: 300;
  height: 44px;
  border-top: 1px solid var(--grey3);
  padding: 10px 15px 10px 15px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FeatureValue = styled.div`
  font-size: 18px;
  font-weight: 300;
  height: 44px;
  border-top: 1px solid var(--grey3);
  padding: 10px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  box-shadow: var(--card-shadow);
  border-radius: 8px;
  background: white;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--grey3);
  flex: 1;
`;
