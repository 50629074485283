import { Hint, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import { isFrontlyAdmin } from "app/utils/utils";
import styled from "styled-components";

const FormInputWrapper = ({
  id,
  label,
  labelColor,
  children,
  hint,
  error,
  description,
  margin,
  link,
  orientation = "vertical",
  paidFeature,
  labelStyle,
  labelWidth = "auto",
  columnSpan = 1,
  handleFieldClick,
}) => {
  return (
    <Container
      columnSpan={columnSpan}
      onClick={(e) => {
        if (isFrontlyAdmin && handleFieldClick) {
          handleFieldClick({ id, target: e.currentTarget });
        }
      }}
      handleFieldClick={isFrontlyAdmin && handleFieldClick}
      margin={margin}
    >
      <Wrapper flexDirection={orientation === "vertical" ? "column" : "row"}>
        {label && (
          <Row alignItems="center" gap="5px" width={labelWidth}>
            <Text
              data={{
                text: label,
                margin: "0 0 5px 0",
                fontStyle: labelStyle || "bodySm",
                color: labelColor,
              }}
            />
            {hint && <Hint hint={hint} link={link} />}
          </Row>
        )}
        {description && (
          <Text
            data={{
              text: description,
              margin: "0 0 5px 0",
              fontStyle: "bodySm",
              color: "var(--grey7)",
            }}
          />
        )}
        {children}
      </Wrapper>
      {error && (
        <Text
          data={{
            text: error,
            margin: "2px 0 0 0",
            color: "red",
            fontStyle: "bodySm",
          }}
        />
      )}
    </Container>
  );
};

export default FormInputWrapper;

const Container = styled.div`
  margin: ${(p) => p.margin};
  grid-column: span ${(p) => p.columnSpan};

  @media screen and (max-width: 800px) {
    grid-column: span 1;
  }

  ${(p) =>
    p.handleFieldClick &&
    `
    &:hover{
      outline: 1px solid ${colors.frontlyBlue};
      outline-offset: -2px;
      cursor: pointer;
      border-radius: 4px;
    }
`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flexDirection || "column"};
  gap: 0px;
  ${(p) =>
    p.flexDirection === "row" &&
    "justify-content: space-between; align-items: center; gap: 20px;"}
`;
