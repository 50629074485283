import { Icon, Modal } from "app/components";
import { get, startCase } from "lodash";

import { AdminForm } from ".";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { getReusableVariables } from "app/renderingApp/fetchSpreadsheets";
import styled from "styled-components";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import { useState } from "react";

export const getEndUserCustomFields = ({
  matchingBlock,
  activeBlock,
  setBlock,
  savedSpreadsheets,
}) => {
  const customBlockInputs = getCustomBlockInputs(matchingBlock, activeBlock);

  const matchingBlockInputs = get(matchingBlock, "inputs", {});
  const activeBlockInputs = get(activeBlock, "inputs", {});

  let editableActions = [];

  const blocks = get(matchingBlock, "blocks", []);
  const actions = get(matchingBlock, "actions", []);

  blocks.forEach((b) => {
    const c = get(blockConfigs, [get(b, "componentId"), "resources"], []);
    c.filter((r) => r.isAction).forEach((r) => {
      const actionValue = get(b, r.id);
      const matchingAction = actions.find((a) => a.id === actionValue);
      const editableByUser = get(matchingAction, "editableByUser", false);

      if (editableByUser) {
        const editableActionId = `action-${b.id}-${r.id}`;

        editableActions.push({
          id: editableActionId,
          key: editableActionId,
          label: get(matchingAction, "referenceLabel"),
          componentId: "Action",
          section: "actionInputs",
        });
      }
    });
  });

  let customInputs = editableActions;

  customBlockInputs.forEach((f) => {
    get(f, "items", []).forEach((item) => {
      const fieldConfig = get(matchingBlockInputs, item.id, {});
      const splitKey = item.key.split("||")[0].trim().split("-");
      const action = splitKey[1];
      const step = splitKey[2];
      const fieldId = splitKey[3];

      let componentId = get(fieldConfig, "componentId", "DynamicString");

      let skip = false;
      let options = [];
      if (f.id === "actionInputs") {
        if (fieldId === "spreadsheet") {
          componentId = "SpreadsheetSelect";
        } else if (fieldId === "page") {
          componentId = "PageSelect";
        } else if (fieldId === "rowIdColumn") {
          const concatId = `action-${action}-${step}-spreadsheet`;
          const spreadsheetDependency = get(activeBlockInputs, concatId);

          options = get(
            savedSpreadsheets.find((s) => s.id === spreadsheetDependency),
            "headers",
            []
          ).map((h) => ({
            label: h,
            value: h,
          }));

          componentId = "Select";
          if (!spreadsheetDependency) {
            skip = true;
          }
        }
      }

      if (componentId === "Select") {
        options = get(fieldConfig, "options", []);
      }

      if (!skip) {
        customInputs.push({
          ...item,
          id: item.id, // was splitKey
          key: item.id, // was splitKey
          componentId,
          preventExtras: true, // Just for the spreadsheetselect
          options,
          hideEmptyItem: true,
          isCustomInput: true,
          label: get(fieldConfig, "label") || item.label,
          description: get(fieldConfig, "description"),
          value: get(activeBlockInputs, item.id), // was splitKey
          onChange: (k, v) =>
            setBlock({ data: { inputs: { ...activeBlockInputs, [k]: v } } }),
        });
      }
    });
  });

  return customInputs;
};

export const getCustomBlockInputs = (activeCustomBlock, activeBlock) => {
  let actionInputs = [];

  const actions = get(activeCustomBlock, "actions", []);
  const blocks = get(activeCustomBlock, "blocks", []);
  const inputs = get(activeCustomBlock, "inputs", {});

  blocks.forEach((b) => {
    const c = get(blockConfigs, [get(b, "componentId"), "resources"], []);
    c.filter((r) => r.isAction).forEach((r) => {
      const actionValue = get(b, r.id);
      const matchingAction = actions.find((a) => a.id === actionValue);
      const steps = get(matchingAction, "steps", []);

      const stepFieldMap = {
        GOOGLE: ["spreadsheet", "rowIdColumn"],
        NAVIGATE: ["page"],
      };

      if (steps.length > 0) {
        const reusableActionVariables = getReusableVariables(
          JSON.stringify(matchingAction)
        );

        reusableActionVariables.forEach((variableKey) => {
          actionInputs.push({
            id: variableKey,
            key: variableKey,
            label:
              get(inputs, [variableKey, "label"]) || startCase(variableKey),
            value: get(activeBlock, variableKey),
            componentId: "DynamicString",
            section: "actionInputs",
          });
        });

        steps.forEach((s) => {
          const fields = get(stepFieldMap, s.type, []);
          fields.forEach((f) => {
            const concatId = `action-${actionValue}-${s.id}-${f}`;
            actionInputs.push({
              id: concatId,
              key: concatId,
              label:
                get(inputs, [concatId, "label"]) ||
                `Action ${actionValue} - Step ${s.id} - ${startCase(f)}`,
              value: get(activeBlock, concatId),
              section: "actionInputs",
            });
          });
        });
      }
    });
  });

  // Reduce to unique items
  actionInputs = actionInputs.reduce(
    (acc, item) => {
      if (!acc.seen[item.id]) {
        acc.seen[item.id] = true;
        acc.result.push(item);
      }
      return acc;
    },
    { seen: {}, result: [] }
  ).result;

  const reusableBlockVariables = getReusableVariables(JSON.stringify(blocks));

  const blockInputs = reusableBlockVariables
    .map((variableKey) => {
      const matchingInput = get(inputs, variableKey);

      return {
        id: variableKey,
        key: variableKey,
        label: get(matchingInput, "label") || startCase(variableKey),
        value: get(activeBlock, variableKey),
        componentId: get(matchingInput, "componentId", "DynamicString"),
        options: get(matchingInput, "options", []),
        section: "blockInputs",
      };
    })
    // Reduce to unique items
    .reduce(
      (acc, item) => {
        if (!acc.seen[item.id]) {
          acc.seen[item.id] = true;
          acc.result.push(item);
        }
        return acc;
      },
      { seen: {}, result: [] }
    ).result;

  let finalArray = [];

  if (blockInputs.length > 0) {
    finalArray.push({
      id: "blockInputs",
      section: "blockInputs",
      componentId: "CustomBlockFieldEditor",
      items: blockInputs,
      sectionHint: "Auto-detected inputs based on detected 'input' variables",
    });
  }

  if (actionInputs.length > 0) {
    let finalActionInputs = actionInputs.filter((a) => {
      return !blockInputs.find((b) => b.key === a.key); // Corrected the filter function
    });

    if (finalActionInputs.length > 0) {
      finalArray.push({
        id: "actionInputs",
        section: "actionInputs",
        componentId: "CustomBlockFieldEditor",
        items: finalActionInputs,
        sectionHint: "Auto-detected actions found in your custom block",
      });
    }
  }

  return finalArray;
};

const CustomBlockFieldEditor = ({ data }) => {
  const { activeCustomBlock, setActiveCustomBlock } = useActiveCustomBlock();

  const [activeItemId, setActiveItemId] = useState(false);

  const items = get(data, "items", []);

  const inputs = get(activeCustomBlock, "inputs", {});
  const activeItem = get(inputs, activeItemId);

  const fields = [
    {
      key: "label",
      label: "Label",
      componentId: "Input",
      value: get(activeItem, "label"),
    },
    {
      key: "description",
      label: "Description",
      componentId: "TextArea",
      value: get(activeItem, "description"),
    },
    {
      key: "componentId",
      label: "Type",
      componentId: "Select",
      defaultValue: "Input",
      hideEmptyItem: true,
      value: get(activeItem, "componentId"),
      options: [
        { label: "Input", value: "Input" },
        { label: "Select", value: "Select" },
        { label: "Color", value: "ColorPicker" },
        { label: "Image", value: "ImageUpload" },
        { label: "TextArea", value: "TextArea" },
      ],
    },
    {
      id: "options",
      label: "Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown.",
      requiresSheet: true,
      newObject: { label: "New Item", value: "New Value" },
      orientation: "vertical",
      value: get(activeItem, "options", []),
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
      displayCondition: () => get(activeItem, "componentId") === "Select",
    },
  ].filter((f) => (f.displayCondition ? f.displayCondition() : true));

  // TODO - Add field types when it's a regular block field but too lazy right now
  // {
  //   key: "type",
  //   label: "Type",
  //   componentId: "Select",
  //   options: [
  //     { label: "Text", value: "DynamicString" },
  //     { label: "Spreadsheet", value: "SpreadsheetSelect" },
  //     { label: "Spreadsheet Column", value: "SpreadsheetColumnSelect" },
  //     { label: "Page", value: "PageSelect" },
  //   ],
  //   hint: "The type of input, depending on the use-case",
  // },

  return (
    <>
      {activeItemId && (
        <Modal
          header={{ title: "Edit Field" }}
          hide={() => setActiveItemId(null)}
          minWidth="400px"
        >
          <AdminForm
            sectionPadding="0px"
            onChange={(k, v) =>
              setActiveCustomBlock({
                inputs: {
                  ...inputs,
                  [activeItemId]: { ...activeItem, [k]: v },
                },
              })
            }
            orientation={"vertical"}
            fields={fields}
          />
        </Modal>
      )}
      <FieldsContainer>
        {items.map((item) => (
          <FieldContainer onClick={() => setActiveItemId(item.id)}>
            <Icon data={{ icon: "FiEdit", color: "var(--grey7)", size: 18 }} />
            {item.label}
          </FieldContainer>
        ))}
      </FieldsContainer>
    </>
  );
};

export default CustomBlockFieldEditor;

const FieldsContainer = styled.div`
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
`;

const FieldContainer = styled.div`
  padding: 8px 0px 8px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
`;
