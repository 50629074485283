import { Icon } from "app/components";
import { Tooltip } from "react-tooltip";
import { colors } from "app/utils/theme";
import styled from "styled-components";

const TooltipIcon = ({ anchorTag, expanded, tooltip, icon }) => {
  return (
    <>
      <Tooltip anchorSelect={anchorTag} place="left" style={{ zIndex: 9999 }}>
        {tooltip}
      </Tooltip>
      <StylesIcon expanded={expanded}>
        <Icon
          data={{
            icon,
            hover: true,
            size: 20,
            color: "var(--grey8)",
          }}
        />
      </StylesIcon>
    </>
  );
};

export default TooltipIcon;

const StylesIcon = styled.div`
  opacity: ${(p) => (p.expanded ? 0 : 1)};
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;
