import RenderBlock from "./RenderBlock";
import { get } from "lodash";
import { getValidActions } from "app/utils/utils";
import { rPageActions } from "app/utils/recoil";
import useActionResolver from "../useActionResolver";
import useBlockVersion from "app/utils/useBlockVersion";
import { useRecoilValue } from "recoil";
import { useRef } from "react";

const RenderBlockLiveMode = ({ data }) => {
  // Any live mode logic goes here

  const { customBlockOnClick, page } = data;

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion: block } = getBlockWithVersion(
    get(data, "block", {})
  );

  const pageActions = useRecoilValue(rPageActions);

  // Filter for actions with at least one step
  const actions = getValidActions(pageActions);

  const { actionResolver } = useActionResolver(page);

  const blockId = get(block, "id");
  const componentId = get(block, "componentId");

  // Handle Click Action For Row and Column only
  let handleClick = null;
  let hasValidAction = false;

  const blocksWithClickActions = [
    "Button",
    "Icon",
    "Text",
    "Image",
    "Stat",
    "Column",
    "Row",
  ];

  if (blocksWithClickActions.includes(componentId)) {
    const clickAction = get(block, "clickAction");

    // Find the actionMap in the block data
    const actionMap = get(block, "actionMap", {});

    // See if there is a raw action in the actionMap
    const rawAction = get(actionMap, "clickAction");

    // See if there is a repeating record in the block
    const repeatingRecord = get(block, "repeatingRecord");

    // If no raw action, see if there is a matching page action
    const matchingPageAction = rawAction
      ? null
      : actions.find((action) => action.id === clickAction);

    // If we found a raw action or a matching page action, we can handle the click
    if (rawAction || matchingPageAction) {
      hasValidAction = true;
      handleClick = (event) => {
        event.stopPropagation();
        actionResolver({
          rawAction: get(block, ["actionMap", "clickAction"]),
          reusableBlockId: block.reusableBlockId,
          actionId: get(matchingPageAction, "id"),
          blockId,
          context: { record: repeatingRecord },
        });
      };
    }
  }

  let mouseEvents = {};

  // Handle the default block record Click action (detail view, custom detail view, etc)
  if (customBlockOnClick) {
    mouseEvents = {
      onClick: (e) => {
        e.stopPropagation();
        customBlockOnClick();
      },
    };
  }
  // Handle a custom click action
  else if (handleClick) {
    mouseEvents = {
      onClick: handleClick,
    };
  }

  const containerRef = useRef();

  // This is the container ref, not actually related to drag
  const dragParams = {
    ref: containerRef,
  };

  const liveModeData = {
    ...data,
    block,
    dragParams,
    mouseEvents,
    hasValidAction,
  };

  return <RenderBlock data={liveModeData} />;
};

export default RenderBlockLiveMode;
