import { Select, Text } from "app/components";

const OpenAiModelSelect = ({ data }) => {
  let options = [
    {
      label: "OpenAI GPT-4o Mini",
      value: "gpt-4o-mini",
    },
    {
      label: "OpenAI GPT-4o",
      value: "gpt-4o",
    },
    {
      label: "OpenAI o3 Mini",
      value: "o3-mini",
    },
    {
      label: "Google Gemini Flash 1.5 8B",
      value: "google/gemini-flash-1.5-8b",
    },
    {
      label: "DeepSeek R1",
      value: "deepseek/deepseek-r1",
    },
    {
      label: "Anthropic Claude 3.5 Sonnet",
      value: "anthropic/claude-3.5-sonnet",
    },
  ];

  return (
    <>
      <Text
        data={{
          text: "Note: Add your own AI API Keys on the Integrations settings to increase your usage limits.",
          fontStyle: "headingXs",
          margin: "0 0 5px 0",
        }}
      />
      <Select
        data={{
          label: "AI Model",
          hideEmptyItem: true,
          options,
          onChange: (v) => data.onChange(v),
          defaultValue: "gpt-4o-mini",
          value: data.value,
        }}
      />
    </>
  );
};

export default OpenAiModelSelect;
