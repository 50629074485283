export const migrateActionSteps = (data) => {
  // Check if any item in the data has a 'parent' property
  if (data.some((item) => "parent" in item)) {
    // Some data is already in the new format, return as is
    return data;
  } else {
    // Convert data to the new format
    let new_data = [];
    let previous_id = null;
    for (let item of data) {
      let new_item = { ...item, parent: previous_id };
      new_data.push(new_item);
      previous_id = item.id;
    }
    return new_data;
  }
};
