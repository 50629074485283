import { Breadcrumb, Button, Row } from "app/components";

import { startCase } from "lodash";
import styled from "styled-components";

const TemplateBanner = ({ template }) => {
  return (
    <TemplateBannerContainer>
      <Logo
        src={
          "https://res.cloudinary.com/frontly/image/upload/v1737557733/frontly_black_abjsh6_hn3daa.svg"
        }
      />

      <TemplateText>
        Install this app template into your account to get started.
      </TemplateText>

      <ButtonsRow>
        <Button
          data={{
            text: window.innerWidth > 800 ? "View All Templates" : "View All",
            fontStyle: "headingMd",
            type: "secondary",
            flippedIcon: true,
            onClick: () =>
              (window.location.href = "https://app.frontly.ai/templates"),
          }}
        />
        <Button
          data={{
            text:
              window.innerWidth > 800 ? "Use This Template" : "Use Template",
            icon: "FiArrowRight",
            fontStyle: "headingMd",
            flippedIcon: true,
            onClick: () =>
              window.open(`https://app.frontly.ai/signup?template=${template}`),
          }}
        />
      </ButtonsRow>
    </TemplateBannerContainer>
  );
};

export default TemplateBanner;

const TemplateText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--text);
  margin-bottom: 8px;
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  height: 36px;
`;

const TemplateBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: white;
  box-shadow: var(--card-shadow-hover);
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  gap: 15px;
  z-index: 9999;
`;
