import { apiRequest, frontlyBaseApi, webUrl } from "app/utils/apiRequests";
import { errorNotification, successNotification } from "app/utils/Notification";
import { rApp, rSavedSpreadsheets } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Spinner } from "app/components";
import { boxShadow } from "app/utils/theme";
import { get } from "lodash";
import { getUrlParameter } from "app/utils/utils";
import googleSheetsLogo from "../assets/google-sheets-logo.png";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const GoogleIntegrationNew = () => {
  const [app, setApp] = useRecoilState(rApp);

  const [isFetching, setIsFetching] = useState(false);

  const location = useLocation();

  const googleSheetsCode = getUrlParameter("code", location);

  const navigate = useNavigate();

  const [showExamples, setShowExamples] = useState(false);

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  useEffect(() => {
    if (!get(app, "google_sheets_connected") && googleSheetsCode) {
      // SWAP CODE FOR TOKENS
      authorizeSheets(googleSheetsCode);
    }
  }, []);

  const authorizeSheets = (code) => {
    setIsFetching(true);
    apiRequest
      .post(`${frontlyBaseApi}/google_sheets/auth/`, {
        auth_code: code,
      })
      .then((response) => {
        const success = get(response, ["data", "success"]);

        if (success) {
          const spreadsheets = get(response, ["data", "spreadsheets"], []);
          const mergedSpreadsheets = [...savedSpreadsheets, ...spreadsheets];

          mixpanel.track("Connect Google Sheets");

          // If the user has previously connected Google before this
          const hasConnectedGoogle = get(app, "has_connected_google");

          // Show generated example sheets
          if (!hasConnectedGoogle) {
            setShowExamples(true);
            setSavedSpreadsheets(mergedSpreadsheets);
          } else {
            navigate("/home");
            setSavedSpreadsheets(mergedSpreadsheets);
            setApp({
              google_sheets_connected: true,
            });
          }

          successNotification("Google Sheets Connected");
        } else {
          errorNotification("Error connecting Google Sheets");
        }

        setIsFetching(false);
      });
  };

  return (
    <div>
      {isFetching ? (
        <ConnectButton>
          <Spinner color="#0F9D58" size={38} />
          <ButtonText>Connecting Google Sheets</ButtonText>
        </ConnectButton>
      ) : (
        <ConnectButton
          onClick={() => {
            setIsFetching(true);
            const googleSheetsAuthLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/spreadsheets&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&redirect_uri=${webUrl}/spreadsheets&client_id=${process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID}`;
            window.location.href = googleSheetsAuthLink;
          }}
        >
          <GoogleLogo src={googleSheetsLogo} />
          <ButtonText>Connect Google Sheets</ButtonText>
        </ConnectButton>
      )}

      <CustomLink
        onClick={() =>
          window.open(
            "https://help.frontly.ai/en/articles/8049487-google-sheet-setup"
          )
        }
      >
        Learn more about how Frontly uses Google Sheets
      </CustomLink>
    </div>
  );
};
export default GoogleIntegrationNew;

const CustomLink = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--grey21);
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
  color: var(--primary-admin);
`;

const GoogleLogo = styled.img`
  height: 35px;
  @media screen and (max-width: 800px) {
    height: 30px;
  }
`;

const ButtonText = styled.div`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const ConnectButton = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: white;
  border: 1px solid var(--grey3);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: ${boxShadow.deep};
  }
  @media screen and (max-width: 800px) {
    padding: 18px;
    width: 90%;
  }
`;
