import { Button, Row, Select, SimpleDropdown } from "app/components";
import {
  isObjectEqualFromKeys,
  openPageInNewTab,
  safeArray,
} from "app/utils/utils";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rCustomBlockChanges,
  rCustomBlocks,
  rPageActions,
  rPageBlocks,
  rPageChanges,
  rPages,
  rScreenPreviewSize,
} from "app/utils/recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import BackSection from "../../configPanel/components/BackSection";
import BlocksSection from "../../header/BlocksSection";
import Cookies from "js-cookie";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import useAddBlock from "app/utils/useAddBlock";
import useIsCustomBlock from "app/utils/useIsCustomBlock";
import usePage from "app/utils/usePage";
import { useState } from "react";

// -------------------------
// The memoized inner component
// -------------------------
const RenderContentInner = ({
  isCustomBlock,
  pageId,
  pages,
  showBlockSelect,
  activeCustomBlockId,
  activeDetailViewId,
  customBlocks,
  saveCustomBlockChanges,
  pageChanges,
  customBlockChanges,
  isSaving,
  isRepeatingCustomBlock,
  navigate,
  setActiveBlockId,
  setActiveEditField,
  setActiveDetailViewId,
  addBlock,
  savePage,
}) => {
  return (
    <Container>
      <Row style={{ height: "100%" }} alignItems="center">
        <BackSection />
        {!isCustomBlock && (
          <Select
            data={{
              showSearch: true,
              hideEmptyItem: true,
              value: pageId,
              width: "180px",
              onChange: (newPageId) => {
                setActiveBlockId(null);
                setActiveEditField(null);
                setActiveDetailViewId(null);
                navigate(`/page/${newPageId}`);
              },
              options: pages.map((p) => ({
                label: p.name,
                value: p.id,
              })),
            }}
          />
        )}
        {isCustomBlock && (
          <Select
            data={{
              showSearch: true,
              hideEmptyItem: true,
              value: activeCustomBlockId,
              width: "180px",
              onChange: (customBlockId) => {
                setActiveBlockId(null);
                setActiveEditField(null);
                setActiveDetailViewId(null);
                navigate(`/custom-block/${customBlockId}`);
              },
              options: safeArray(customBlocks).map((p) => ({
                label: p.name,
                value: p.id,
              })),
            }}
          />
        )}
      </Row>

      {showBlockSelect ? (
        <BlocksSection
          addBlock={addBlock}
          isRepeatingCustomBlock={isRepeatingCustomBlock}
        />
      ) : (
        <div>&nbsp;</div>
      )}

      <Row
        alignItems="center"
        justifyContent="flex-end"
        gap="10px"
        margin="0 20px 0 0"
      >
        <ScreenSizeDropdown />

        {!isCustomBlock && (
          <>
            <Button
              data={{
                onClick: () => savePage(true),
                text: "Preview",
                isFetching: isSaving,
                type: "basic",
                icon: "FiExternalLink",
                height: "38px",
              }}
            />
            <Button
              data={{
                disabled: !pageChanges,
                onClick: () => savePage(false),
                text: pageChanges ? "Save Page" : "Changes Saved",
                isFetching: isSaving,
                height: "38px",
              }}
            />
          </>
        )}
        {isCustomBlock && (
          <Button
            data={{
              disabled: !customBlockChanges,
              onClick: saveCustomBlockChanges,
              text: customBlockChanges ? "Save Changes" : "Changes Saved",
              isFetching: isSaving,
              height: "38px",
            }}
          />
        )}
      </Row>
    </Container>
  );
};

// -------------------------
// Custom comparison function
// -------------------------
const areEqual = (prevProps, nextProps) => {
  // List the keys from props that should trigger a re-render when they change.
  // (You can adjust this list as needed.)
  const keysToCompare = [
    "pageId",
    "pages",
    "showBlockSelect",
    "isCustomBlock",
    "activeCustomBlockId",
    "activeDetailViewId",
    "customBlocks",
    "actions",
    "pageChanges",
    "customBlockChanges",
    "isSaving",
    "isRepeatingCustomBlock",
  ];
  return isObjectEqualFromKeys(prevProps, nextProps, keysToCompare);
};

// Memoize the inner component with the custom comparator.
// const MemoizedRenderContentInner = memo(RenderContentInner, areEqual);

// -------------------------
// The container component
// -------------------------
const Header = () => {
  const navigate = useNavigate();
  const activeBlock = useActiveBlock();

  const app = useRecoilValue(rApp);
  const page = usePage();
  const [pages, setPages] = useRecoilState(rPages);
  const isCustomBlock = useIsCustomBlock();
  const { id } = useParams();

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const [activeDetailViewId, setActiveDetailViewId] =
    useRecoilState(rActiveDetailViewId);
  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const [isSaving, setIsSaving] = useState(false);
  const [pageChanges, setPageChanges] = useRecoilState(rPageChanges);
  const [customBlockChanges, setCustomBlockChanges] =
    useRecoilState(rCustomBlockChanges);
  const [customBlocks, setCustomBlocks] = useRecoilState(rCustomBlocks);

  // Find the custom block based on the URL param
  const activeCustomBlock = safeArray(customBlocks).find((b) => b.id == id);
  const isRepeatingCustomBlock =
    activeCustomBlock && activeCustomBlock.repeating;

  const addBlock = useAddBlock();
  const blocks = useRecoilValue(rPageBlocks);
  const actions = useRecoilValue(rPageActions);

  // Save page to API
  const savePage = (preview) => {
    setIsSaving(true);
    const newPage = { ...page, blocks, actions };
    apiRequest.post("/page/", newPage).then(() => {
      setPageChanges(false);
      setIsSaving(false);
      successNotification("Saved");

      // Update the pages state with the new page data
      setPages(
        pages.map((p) => {
          if (p.id === page.id) {
            return newPage;
          }
          return p;
        })
      );

      if (preview) {
        mixpanel.track("Preview Page");
        openPageInNewTab({
          page,
          app,
          token: Cookies.get("accessToken"),
        });
      } else {
        mixpanel.track("Save Page");
      }
    });
  };

  const hideBlocksHeader =
    activeCustomBlock &&
    !["Column", "Row"].includes(get(activeBlock, "componentId"));

  const showBlockSelect = !hideBlocksHeader && (!isCustomBlock || activeBlock);

  const saveCustomBlockChanges = () => {
    // Update the custom block
    apiRequest.patch(`/custom_blocks/${get(activeCustomBlock, "id")}/`, {
      ...activeCustomBlock,
      blocks,
      actions,
    });

    setCustomBlocks((prev) =>
      safeArray(prev).map((cb) => {
        if (cb.id === get(activeCustomBlock, "id")) {
          return { ...cb, blocks, actions };
        }
        return cb;
      })
    );

    successNotification("Saved");
    setCustomBlockChanges(false);
  };

  return (
    <RenderContentInner
      showBlockSelect={showBlockSelect}
      isCustomBlock={isCustomBlock}
      pageId={get(page, "id")}
      pages={pages}
      activeDetailViewId={activeDetailViewId}
      activeCustomBlockId={get(activeCustomBlock, "id")}
      customBlocks={customBlocks}
      pageChanges={pageChanges}
      customBlockChanges={customBlockChanges}
      isSaving={isSaving}
      isRepeatingCustomBlock={isRepeatingCustomBlock}
      navigate={navigate}
      setActiveBlockId={setActiveBlockId}
      setActiveEditField={setActiveEditField}
      setActiveDetailViewId={setActiveDetailViewId}
      addBlock={addBlock}
      savePage={savePage}
      saveCustomBlockChanges={saveCustomBlockChanges}
    />
  );
};

export default Header;

const Container = styled.div`
  background: white;
  border-bottom: 1px solid var(--grey21);
  height: 60px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ScreenSizeDropdown = () => {
  const [screenPreviewSize, setScreenPreviewSize] =
    useRecoilState(rScreenPreviewSize);

  return (
    <SimpleDropdown
      data={{
        options: [
          {
            label: "Desktop",
            value: "desktop",
            icon: "FiMonitor",
            onClick: () => setScreenPreviewSize("desktop"),
          },
          {
            label: "Tablet",
            value: "tablet",
            icon: "FiTablet",
            onClick: () => setScreenPreviewSize("tablet"),
          },
          {
            label: "Phone",
            value: "phone",
            icon: "FiSmartphone",
            onClick: () => setScreenPreviewSize("mobile"),
          },
        ],
        button: {
          type: "basic",
          height: "38px",
          icon: get(
            {
              desktop: "FiMonitor",
              tablet: "FiTablet",
              mobile: "FiSmartphone",
            },
            screenPreviewSize
          ),
        },
      }}
    />
  );
};
