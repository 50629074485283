import { Button, Text } from "app/components";
import SettingsCard, { ColumnsRow } from "../settings/components/SettingsCard";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";

import { AdminForm } from "../components";
import SettingsSection from "../settings/SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { rApp } from "app/utils/recoil";
import { useRecoilState } from "recoil";
import { useState } from "react";

const CustomFields = () => {
  const [app, setApp] = useRecoilState(rApp);

  const userSchema = get(app, "user_schema", []) || [];

  const [changes, setChanges] = useState(false);
  const [usersTableChanged, setUsersTableChanged] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const protectedFields = [
    "id",
    "first_name",
    "last_name",
    "email",
    "is_owner",
    "role",
    "user_groups",
    "user_group",
    "app_id",
    "user_schema",
    "enabled_apps",
    "custom_data",
    "can_create_apps",
    "password",
    "organization_id",
    "organization",
  ];

  const save = () => {
    const emptyFields = userSchema.filter(
      (field) => !field || isEmpty(field) || field.name === ""
    );

    if (emptyFields.length > 0) {
      errorNotification(
        "You cannot save with incomplete fields. Please correct this to save."
      );
    } else {
      const usedProtectedFields = userSchema.filter((s) =>
        protectedFields.includes(s.name)
      );
      if (usedProtectedFields.length > 0) {
        errorNotification(
          "Core User Fields are protected and cannot be used in custom fields. The following fields are protected: " +
            usedProtectedFields.map((f) => f.name).join(", ")
        );
      } else {
        setChanges(false);
        setIsSaving(true);

        apiRequest
          .post("/app_settings/", {
            user_schema: userSchema,
          })
          .then(() => {
            setIsSaving(false);
          });
        successNotification("Custom User Fields Saved");
      }
    }
  };

  const usersSheetConfig = get(app, "users_sheet_config", {});

  const saveUsersTable = () => {
    setIsSaving(true);
    apiRequest
      .post("/app_settings/", {
        users_sheet_config: usersSheetConfig,
      })
      .then(() => {
        successNotification("Users Table Saved");
        setIsSaving(false);
      });
  };

  const fieldKeys = [
    {
      key: "name",
      label: "Name",
      placeholder: "Team",
      componentId: "Input",
      width: "100%",
    },
    {
      key: "type",
      componentId: "Select",
      label: "Type",
      width: "100%",
      hint: "This determines the type of input field that will be displayed if these fields are shown to the user.",
      defaultValue: "Input",
      options: [
        { label: "Plain Text", value: "Input" },
        { label: "Select", value: "Select" },
        { label: "Date", value: "DateTimePicker" },
        { label: "Boolean (true/false)", value: "Switch" },
      ],
    },
    {
      key: "options",
      label: "Select Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown.",
      requiresSheet: true,
      newObject: { label: "New Item", value: "New Value" },
      orientation: "vertical",
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
      displayCondition: (f) => f.type === "Select",
    },
    {
      key: "defaultValue",
      label: "Default Value",
      placeholder: "None",
      width: "100%",
      componentId: "Input",
    },
    {
      key: "includeInSignup",
      label: "Include in Signup Form",
      width: "100%",
      componentId: "Switch",
      defaultValue: false,
    },
    {
      key: "editableByUser",
      label: "Editable By User",
      hint: "This field will be visible and editable by the user in the 'Edit Profile' page.",
      width: "100%",
      componentId: "Switch",
      defaultValue: false,
    },
  ];

  return (
    <SettingsSection
      settingsCategory="User Settings"
      title="Custom Fields"
      description="Define the custom fields stored for the users of your app"
      width="100%"
      hideCard
    >
      <ColumnsRow>
        <SettingsCard
          label="Custom User Fields"
          description="Add custom fields to your user profiles, stored in Frontly's database"
        >
          <AdminForm
            labelStyle="headingSm"
            sectionPadding="0px"
            fields={[
              {
                id: "user_schema",
                componentId: "MultiForm",
                labelSingular: "Field",
                width: "300px",
                getItemLabel: (item) => get(item, "name", "New Field"),
                value: userSchema,
                fields: fieldKeys,
              },
            ]}
            submitText={"Save Changes"}
            submit={changes && save}
            width="220px"
            isFetching={isSaving}
            onChange={(k, v) => {
              setChanges(true);
              setApp({
                user_schema: v,
              });
            }}
          />
        </SettingsCard>

        <SettingsCard
          label="Custom Users Table"
          description="Sync a specific data source containing user information with the active user's session automatically to access as variables"
        >
          <AdminForm
            labelStyle={"headingXs"}
            onChange={(k, v) => {
              setUsersTableChanged(true);
              setApp({
                users_sheet_config: { ...usersSheetConfig, [k]: v },
              });
            }}
            isFetching={isSaving}
            submitText="Save Changes"
            submit={usersTableChanged && saveUsersTable}
            sectionPadding="0px"
            fields={[
              {
                id: "spreadsheet_id",
                componentId: "SpreadsheetSelect",
                allowEmpty: true,
                value: get(usersSheetConfig, "spreadsheet_id"),
              },
            ]}
          />
          <Text
            data={{
              text: "Click to learn more about the Custom Users Table setting",
              onClick: () =>
                window.open(
                  "https://help.frontly.ai/en/articles/8768132-users-spreadsheet"
                ),
              color: "var(--primary-admin)",
              margin: "15px 0 0 0",
            }}
          />
        </SettingsCard>
      </ColumnsRow>
    </SettingsSection>
  );
};

export default CustomFields;
