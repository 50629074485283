import { Button, Row, Text } from "app/components";

import { colors } from "app/utils/theme";
import styled from "styled-components";

const SettingsCard = ({ label, description, children, articleLink }) => {
  return (
    <CardSection>
      <Row
        alignItems="center"
        justifyContent="space-between"
        gap="30px"
        margin="5px 0 20px 0"
      >
        <div>
          <Text
            data={{
              text: label,
              fontSize: 18,
              fontWeight: 600,
            }}
          />
          {description && (
            <Text
              data={{
                text: description,
                fontStyle: "bodyMd",
                margin: "5px 0 0 0",
                color: "var(--grey7)",
              }}
            />
          )}
        </div>
        {articleLink && (
          <Button
            data={{
              text: "View Help Article",
              type: "secondary",
              icon: "FiExternalLink",
              onClick: () => window.open(articleLink),
            }}
          />
        )}
      </Row>
      <Content>{children}</Content>
    </CardSection>
  );
};

export default SettingsCard;

const CardSection = styled.div`
  background: white;
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  flex: 1;
  padding: 20px;
`;

const Content = styled.div`
  margin-top: 20px;
`;

export const ColumnsRow = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;
