import { Button, Column, Menu } from "app/components";
import { rDarkMode, rPageFilters, rTranslations } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { useRef, useState } from "react";

import NewFilter from "app/components/NewFilter";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { getDisplayValue } from "app/components/CustomFilterButton";
import { isFrontlyAdmin } from "app/utils/utils";
import styled from "styled-components";
import useContainerDimensions from "app/utils/useContainerDimensions";
import useDynamicText from "app/renderingApp/useDynamicText";
import usePage from "app/utils/usePage";

const PageFilters = () => {
  const page = usePage();

  const darkMode = useRecoilValue(rDarkMode);
  const translations = useRecoilValue(rTranslations);

  const [anchor, setAnchor] = useState(null);

  const { processDynamicText } = useDynamicText();

  const [filterState, setFilterState] = useRecoilState(rPageFilters);

  const filterSettings = get(page, "filterSettings", {});
  const filters = get(filterSettings, "filters", []);

  const theme = get(filterSettings, "theme");

  const containerRef = useRef();

  // available screen width
  const { width } = useContainerDimensions(containerRef, null);

  if (!filters.length) return <></>;

  let totalWidth = 50;

  let finalFilters = [];
  filters.map((f) => {
    const componentId = get(f, "componentId", "Input");
    const value = get(filterState, f.id);
    const operator = get(f, "operator", "equals");

    const displayValue = getDisplayValue({
      label: get(f, "label"),
      value,
      componentId,
      operator,
      translations,
      options: get(f, "options", []),
      processDynamicText,
    });

    totalWidth += displayValue.length * 8;

    finalFilters.push({
      ...f,
      displayValue,
      theme,
      componentId,
      value,
      operator,
      onChange: (v) =>
        setFilterState({
          ...filterState,
          [f.id]: v,
        }),
    });
  });

  // account for extra space / padding / gap
  totalWidth += filters.length * 50;

  const collapse = width > 0 && totalWidth > width;

  return (
    <Container ref={containerRef}>
      {collapse && (
        <>
          <Menu
            label={get(translations, "filtersText", "Filters")}
            anchorElement={anchor}
            hide={() => setAnchor(null)}
            background={darkMode && colors.darkModeLightBackground}
          >
            <Column gap="15px">
              {finalFilters.map((f) => (
                <NewFilter data={f} />
              ))}
            </Column>
          </Menu>
          <Button
            data={{
              backgroundColor: darkMode ? colors.darkModeBackground : "white",
              color: darkMode && "white",
              border: darkMode && "1px solid #2d2d2d",
              text: get(translations, "filtersText", "Filters"),
              icon: "FiFilter",
              type: "basic",
              onClick: (e) => setAnchor(e.currentTarget),
            }}
          />
        </>
      )}
      {!collapse && finalFilters.map((f) => <NewFilter data={f} />)}
    </Container>
  );
};

export default PageFilters;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: ${isFrontlyAdmin ? "32px 32px 0 32px" : "0px"};
`;
