import * as React from "react";

import { Icon } from "app/components";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import useUtils from "../useUtils";

const Progress = ({ page, block }) => {
  const steps = get(block, "steps", []);
  const { passesDisplayConditions, processDynamicText } = useUtils();

  const orientation = get(block, "orientation", "vertical");

  const finalOrientation =
    orientation === "responsive"
      ? window.innerWidth < 768
        ? "horizontal"
        : "vertical"
      : orientation;

  return (
    <Container orientation={finalOrientation}>
      {steps.map((step, index) => {
        const conditions = get(step, "conditions", []);
        const active = passesDisplayConditions({ conditions });

        return (
          <>
            <Step
              key={index}
              isLast={index === steps.length - 1}
              active={active}
              data={step}
              orientation={finalOrientation}
              hideTextHorizontal={
                finalOrientation === "horizontal"
                  ? get(block, "hideTextHorizontal", false)
                  : false
              }
              processDynamicText={processDynamicText}
            />
          </>
        );
      })}
    </Container>
  );
};

export default Progress;

const Step = ({
  data,
  active,
  isLast,
  orientation,
  hideTextHorizontal,
  processDynamicText,
}) => {
  const { label, description } = data;

  const app = useRecoilValue(rApp);

  const primaryColor = "var(--primary)";

  const icon = active ? "FaDotCircle" : "FaRegDotCircle";

  return (
    <StepContainer orientation={orientation}>
      {!isLast && (
        <Line
          color={active ? primaryColor : "var(--grey21)"}
          orientation={orientation}
        />
      )}
      <div style={{ minWidth: "20px", zIndex: 100 }}>
        <Icon
          data={{
            icon,
            color: active ? primaryColor : "var(--grey7)",
            size: 24,
          }}
        />
      </div>
      {!hideTextHorizontal && (
        <div>
          <Label color={active ? primaryColor : "var(--grey8)"}>
            {processDynamicText({ text: label })}
          </Label>
          {description && (
            <Description color={active ? primaryColor : "var(--grey8)"}>
              {processDynamicText({ text: description })}
            </Description>
          )}
        </div>
      )}
    </StepContainer>
  );
};

const Line = styled.div`
  position: absolute;
  background-color: ${(p) => p.color};
  z-index: 90;
  ${(p) =>
    p.orientation === "vertical"
      ? `
      width: 2px;
      top: 16px;
      bottom: 0px;
      left: 11px;
    `
      : `
      height: 2px;
      left: calc(50% + 11px);
      right: calc(-50% + 11px);
      bottom: 14px;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.orientation === "vertical" ? "column" : "row")};
`;

const Label = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${(p) => p.color};
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${(p) => p.color};
`;

const StepContainer = styled.div`
  display: flex;
  position: relative;
  ${(p) =>
    p.orientation === "vertical"
      ? `
      padding-bottom: 25px;
      gap: 15px;
    `
      : `
      flex-direction: column-reverse;
      align-items: center;
      gap: 5px;
      flex: 1;
      text-align: center;
    `}
`;
