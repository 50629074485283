import { Button, Text } from ".";
import { useRef, useState } from "react";

import { colors } from "app/utils/theme";
import styled from "styled-components";

const RelativeContainer = styled.div`
  position: relative;
`;

const DocumentUpload = ({ data }) => {
  const [state, stateSet] = useState({
    isFetching: false,
    fileName: null,
  });

  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    data.onChange(file);
  };

  const handleRemoveFile = () => {
    stateSet({ ...state, fileName: null });
    if (data.onChange) {
      data.onChange("");
    }
  };

  return (
    <RelativeContainer>
      {!data.value ? (
        <Button
          data={{
            text: data.text || "Select File",
            size: data.size || "small",
            type: data.buttonType || "basic",
            icon: data.icon || "FiUploadCloud",
            onClick: () => document.getElementById("file-input").click(),
          }}
        />
      ) : (
        <>
          <Text
            data={{
              text: data.value,
              margin: "0 0 5px 0",
              color: data.color || "var(--grey5)",
              fontStyle: "bodySm",
            }}
          />
          <Button
            data={{
              text: "Remove",
              size: "small",
              type: "basic",
              icon: "FiX",
              onClick: handleRemoveFile,
            }}
          />
        </>
      )}
      <input
        id="file-input"
        type="file"
        accept=".pdf, .docx, .txt"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </RelativeContainer>
  );
};

export default DocumentUpload;
