import Pagination from "@mui/material/Pagination";

const CustomPagination = ({
  currentPage,
  pageCount,
  onChange,
  margin = "0px",
}) => {
  const paginationProps = {
    className: "mui-pagination",
    // showFirstButton: showFirstButton,
    // showLastButton: showLastButton,
    variant: "outlined",
    shape: "rounded",
    color: "primary",
  };

  return (
    <div style={{ margin }}>
      <Pagination
        {...paginationProps}
        page={currentPage}
        count={pageCount}
        onChange={(e, page) => onChange(page)}
      />
    </div>
  );
};

export default CustomPagination;
