import {
  Breadcrumb,
  Button,
  Icon,
  Row,
  SearchBar,
  Spinner,
  Text,
} from "app/components";
import { boxShadow, colors, spacing } from "app/utils/theme";
import { debounce, get, startCase } from "lodash";
import { getUrlParameter, resizeImage } from "app/utils/utils";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AdminWrapper from "app/adminApp/home/AdminWrapper";
import BuilderProfile from "./BuilderProfile";
import NewActionStep from "app/adminApp/components/Action/NewActionStep";
import { apiRequest } from "app/utils/apiRequests";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const Home = () => {
  const [actions, setActions] = useState([]);
  const [pages, setPages] = useState([]);
  const [savedListings, setSavedListings] = useState([]);
  const [builderProfile, setBuilderProfile] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    mixpanel.track("Marketplace View");
  }, []);

  const params = useParams();

  const { builderId } = params;

  const location = useLocation();

  const type = getUrlParameter("type", location);
  const status = getUrlParameter("status", location);

  const [search, setSearch] = useState("");

  const debouncedSearch = useCallback(
    debounce(async (v) => {
      if (v.length > 1 || v === "") {
        fetchListings(v);
      }
    }, 300), // Adjust the delay time as needed
    []
  );

  const fetchListings = (searchTerm = null) => {
    let endpoint = `/marketplace/`;
    const queryParams = new URLSearchParams();

    if (builderId) {
      queryParams.append("builder_id", builderId);
    }

    if (type) {
      queryParams.append("type", type);
    }

    if (searchTerm && searchTerm.length > 1) {
      queryParams.append("search", searchTerm);
    }

    if (status) {
      queryParams.append("status", status);
    }

    if (queryParams.toString()) {
      endpoint += `?${queryParams.toString()}`;
    }

    setIsFetching(true);
    apiRequest.get(endpoint).then((r) => {
      const actions = get(r, "data.actions", []);
      const pages = get(r, "data.pages", []);
      setActions(actions);
      setPages(pages);
      setSavedListings(get(r, "data.saved_listings", []));
      setBuilderProfile(get(r, "data.builder_profile", null));
      setIsFetching(false);
    });
  };

  useEffect(() => {
    fetchListings();
  }, [builderId, type]);

  const onFavorite = (id) => {
    if (savedListings.includes(id)) {
      setSavedListings(savedListings.filter((l) => l !== id));
      successNotification("Removed from favorites");
    } else {
      setSavedListings([...savedListings, id]);
      successNotification("Added to favorites");
    }

    // Add or remove to saved listings
    apiRequest.post(`/marketplace/favorite/${id}/`);
  };

  const getHeaderText = () => {
    let contentName = "content";
    if (type === "actions") {
      contentName = "actions";
    } else if (type === "pages") {
      contentName = "pages";
    }

    if (builderProfile) {
      return `Browse ${contentName} created by ${builderProfile.name}`;
    }

    return `Browse ${contentName} created by the Frontly community`;
  };

  const navigate = useNavigate();

  return (
    <AdminWrapper contentWidth="100%" isFetching={isFetching}>
      {!type && builderProfile && (
        <div>
          <Breadcrumb
            fontStyle="headingXl"
            margin="0 0 30px 0"
            items={[
              {
                text: "Marketplace",
                onClick: () => {
                  navigate("/marketplace");
                  setBuilderProfile(null);
                },
              },
              { text: "Builder Profile", onClick: null },
            ]}
          />
          <BuilderProfile data={builderProfile} />
        </div>
      )}

      {type && (
        <Row
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 30px 0"
        >
          <Breadcrumb
            fontStyle="headingXl"
            items={[
              {
                text: "Marketplace",
                onClick: () => {
                  navigate("/marketplace");
                  setBuilderProfile(null);
                },
              },
              { text: `Browse ${type}`, onClick: null },
            ]}
          />
          <SearchBar
            data={{
              value: search,
              placeholder: "Search listings",
              onChange: (v) => {
                setSearch(v);
                debouncedSearch(v);
              },
            }}
          />
        </Row>
      )}

      {!type && (
        <Row
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 30px 0"
        >
          <PageHeader>{getHeaderText()}</PageHeader>
          <SearchBar
            data={{
              value: search,
              placeholder: "Search listings",
              onChange: (v) => {
                setSearch(v);
                debouncedSearch(v);
              },
            }}
          />
        </Row>
      )}
      <Sections>
        {(!type || type === "actions") && (actions.length > 0 || search) && (
          <Section
            isFetching={isFetching}
            type="actions"
            hideHeader={type === "actions"}
            onFavorite={onFavorite}
            listings={actions.map((a) => ({
              ...a,
              saved: savedListings.includes(a.id),
            }))}
          />
        )}
        {(!type || type === "pages") && (pages.length > 0 || search) && (
          <Section
            isFetching={isFetching}
            type="pages"
            hideHeader={type === "pages"}
            onFavorite={onFavorite}
            listings={pages.map((p) => ({
              ...p,
              saved: savedListings.includes(p.id),
            }))}
          />
        )}
      </Sections>
    </AdminWrapper>
  );
};

export default Home;

const Section = ({ type, listings, onFavorite, isFetching, hideHeader }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const status = getUrlParameter("status", location);

  return (
    <SectionContainer>
      {!hideHeader && (
        <Row justifyContent="space-between" alignItems="center">
          <SectionLabel>{startCase(type)}</SectionLabel>
          <Button
            data={{
              text: `View All ${startCase(type)}`,
              onClick: () => navigate(`/marketplace/?type=${type}`),
              icon: "FiArrowRight",
              flippedIcon: true,
            }}
          />
        </Row>
      )}

      <ListingsContainer count={listings.length}>
        {isFetching && <Spinner />}
        {!isFetching &&
          listings.map((listing) => (
            <ListingCard
              key={listing.id}
              listing={listing}
              saved={listing.saved}
              onClick={() => {
                let url = `/marketplace/listing/${listing.id}`;
                if (status) {
                  url += `?status=${status}`;
                }

                navigate(url);
              }}
              onFavorite={() => onFavorite(listing.id)}
            />
          ))}
        {!isFetching && listings.length === 0 && (
          <Text
            data={{
              text: `No matching ${startCase(type)} found`,
              fontSize: 18,
              color: "var(--grey7)",
            }}
          />
        )}
      </ListingsContainer>
    </SectionContainer>
  );
};

const ListingCard = ({ listing, onClick, onFavorite }) => {
  const listingType = get(listing, "type");

  const active = listing.saved;

  const profileName = get(listing, "organization__expert_profile__name");

  return (
    <ListingContainer onClick={onClick}>
      <Row justifyContent="space-between">
        <div>
          <ListingName>{listing.name}</ListingName>
          <ListingDescription>
            {listing.short_description || "No description available"}
          </ListingDescription>
          <ProfileName>By {profileName || "Frontly Community"}</ProfileName>
        </div>
        <div style={{ width: "22px" }}>
          <Icon
            data={{
              onClick: (e) => {
                e.stopPropagation();
                onFavorite(listing.id);
              },
              icon: active ? "BsStarFill" : "BsStar",
              size: 22,
              color: active ? "#f7c744" : "var(--grey7)",
              hover: true,
            }}
          />
        </div>
      </Row>

      {["page", "app"].includes(listingType) && (
        <ListingImage
          src={resizeImage({
            url: listing.primary_image,
            width: 380,
            height: 250,
          })}
        />
      )}
      {listingType === "action" && (
        <ActionImage steps={get(listing, "config__steps")} />
      )}
    </ListingContainer>
  );
};

export const ActionImage = ({ steps, width, height, showLabels = false }) => {
  const depth = getTotalHierarchyDepth(steps);

  let mockSize = "large";
  if (depth > 2) {
    mockSize = "medium";
  }
  if (depth > 3) {
    mockSize = "small";
  }

  const getStepData = (s) => {
    return {
      data: s,
      mock: true,
      maxLayers: 4,
      deleteStep: () => null,
      onChange: () => null,
      addStep: () => null,
      mockSize,
      showLabels,
    };
  };
  const rootSteps = steps.filter((s) => !s.parent);

  return (
    <ListingActionBackground width={width || "100%"} height={height || "200px"}>
      {rootSteps.map((r) => (
        <NewActionStep stepId={r.id} steps={steps} getStepData={getStepData} />
      ))}
    </ListingActionBackground>
  );
};

function getTotalHierarchyDepth(steps) {
  // Helper function to recursively find the depth of a step
  function findDepth(stepId, currentDepth) {
    // Find children of the current step
    const children = steps.filter((step) => step.parent === stepId);

    // If no children, return the current depth
    if (children.length === 0) {
      return currentDepth;
    }

    // Recursively find the maximum depth among all children
    return Math.max(
      ...children.map((child) => findDepth(child.id, currentDepth + 1))
    );
  }

  // Find all root steps (steps without a parent)
  const rootSteps = steps.filter((step) => !step.parent);

  // Calculate the maximum depth starting from each root step
  const maxDepth = Math.max(
    ...rootSteps.map((rootStep) => findDepth(rootStep.id, 1))
  );

  return maxDepth;
}

const ProfileImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const ProfileName = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${colors.frontlyBlue};
  margin-bottom: 10px;
`;

const PageHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: var(--grey7);
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const SectionLabel = styled.div`
  font-size: 26px;
  font-weight: 600;
`;

const ListingsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-gap: ${spacing.s4};
  ${(p) => p.count && p.count < 3 && "grid-template-columns: 350px 350px;"}
  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  padding: ${spacing.s4};
  box-shadow: ${boxShadow.card};
  cursor: pointer;
`;

const ListingName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const ListingDescription = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin: 5px 0 10px 0;
`;

const ListingImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  background: #f0f0f3;
  border: 1px solid var(--grey3);
  padding: 10px;
`;

const ListingActionBackground = styled.div`
  width: ${(p) => p.width || "100%"};
  height: ${(p) => p.height || "200px"};
  object-fit: contain;
  border-radius: 8px;
  cursor: pointer;
  background: #f0f0f3;
  border: 1px solid var(--grey3);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
