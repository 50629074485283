import { get, startCase } from "lodash";
import {
  rPage,
  rPageActions,
  rPageBlocks,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";

import { Modal } from "app/components";
import { actionLabelMap } from "app/utils/useActions";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { getVariablesFromValue } from "app/utils/utils";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

// What this covers so far:

// - Blocks referencing invalid data sources
// - Actions referencing invalid data sources
// - Actions referencing invalid data source fields
// - Blocks referencing invalid Spreadsheet columns

// A list of all the valid variable keys in the entire platform
const validVariableKeys = [
  "form",
  "detail",
  "record",
  "input",
  "session",
  "localState",
  "pagination",
  "spreadsheets",
  "step",
  "actionSteps", // old
  "params",
  "cookies",
  "user",
  "content",
  "detail",
  "detailParent",
  "grandparent",
  "time",
  "windowWidth",
  "custom",
  "env",
  "block",
];

const PageWizard = () => {
  const blocks = useRecoilValue(rPageBlocks);
  const actions = useRecoilValue(rPageActions);
  const dataSources = useRecoilValue(rSavedSpreadsheets);

  const [errorsList, setErrorsList] = useState([]);

  const page = useRecoilValue(rPage);

  useEffect(() => {
    let errors = [];

    const dataSourceIds = dataSources.map((ds) => ds.id);

    const blockDataSourceIds = blocks
      .filter((b) => get(b, "spreadsheet"))
      .map((b) => get(b, "spreadsheet"));

    const inputBlockFields = blocks
      .filter((b) =>
        ["Input", "Select", "Switch", "TextArea"].includes(b.componentId)
      )
      .filter((b) => b.key)
      .map((b) => b.key);

    // Find actions that reference data sources
    let actionDataSourceIds = [];
    actions.forEach((action) => {
      const dataSourceActionSteps = get(action, "steps", []).filter((s) =>
        ["GOOGLE", "AI_GENERATE_RECORDS"].includes(s.type)
      );
      dataSourceActionSteps.forEach((step) => {
        if (step.type === "GOOGLE") {
          if (step.spreadsheet) {
            if (!dataSourceIds.includes(step.spreadsheet)) {
              errors.push({
                type: "action",
                label: `${get(actionLabelMap, step.type, step.type)} Action`,
                message: `Data source '${step.spreadsheet}' does not exist.`,
                description:
                  "To resolve this issue, select a valid data source for this action.",
              });
            } else {
              actionDataSourceIds.push(step.spreadsheet);
            }
          }
        } else if (step.type === "AI_GENERATE_RECORDS") {
          if (step.dataSource) {
            if (!dataSourceIds.includes(step.dataSource)) {
              errors.push({
                type: "action",
                label: `${get(actionLabelMap, step.type, step.type)} Action`,
                message: `The selected data source for this action step (${step.dataSource}) does not exist.`,
                description:
                  "To resolve this issue, select a valid data source for this action",
              });
            } else {
              actionDataSourceIds.push(step.dataSource);
            }
          }
        }
      });
    });

    // Find active data sources
    const activeDataSources = dataSources.filter((ds) =>
      [...blockDataSourceIds, ...actionDataSourceIds].includes(ds.id)
    );

    let validDataSourceFields = [];

    activeDataSources.forEach((ds) => {
      const fields = get(ds, "headers", []);
      validDataSourceFields = [...validDataSourceFields, ...fields];
    });

    let actionMap = {};

    blocks.forEach((block) => {
      const blockLabel = get(block, "label");
      const componentId = get(block, "componentId");

      const detectedVariables = getVariablesFromValue(block);

      // Show errors for any invalid variables
      detectedVariables
        .filter((v) => !validVariableKeys.includes(v[0]))
        .forEach((v) => {
          errors.push({
            type: "block",
            label: `${componentId} Block (${blockLabel})`,
            blockId: block.id,
            message: (
              <span>
                The variable <strong>{v[0]}</strong> is not a valid variable.
              </span>
            ),
          });
        });

      const dsFieldVariables = detectedVariables.filter((v) =>
        ["form", "detail", "record"].includes(v[0])
      );

      dsFieldVariables.forEach((v) => {
        const variableKey = get(v, 0, null);
        const variableValue = get(v, 1, null);

        if (variableValue.includes("__")) {
          // Skip validating __ variables for now (relational)
        } else {
          const isForm = variableKey === "form";

          const isInputBlock = inputBlockFields.includes(variableValue);

          const hasError =
            !validDataSourceFields.includes(variableValue) &&
            (!isForm || (isForm && !isInputBlock));

          if (hasError) {
            errors.push({
              type: "block",
              label: `${componentId} Block (${blockLabel})`,
              blockId: block.id,
              message: (
                <span>
                  The field <strong>{variableValue}</strong> in your{" "}
                  <strong>{`{{ ${variableKey}.${variableValue} }}`}</strong>{" "}
                  variable doesn't exist in any of your data sources.
                </span>
              ),
              description:
                "To resolve this issue, find the variable and enter a valid field.",
            });
          }
        }
      });

      const blockConfig = get(blockConfigs, get(block, "componentId"));

      let wizardResources = get(blockConfig, "resources", []).filter(
        (f) =>
          !f.displayCondition ||
          (f.displayCondition && f.displayCondition(block))
      );

      wizardResources.forEach((r) => {
        // If actions are set, add them to the action map
        if (r.componentId === "Action") {
          const v = get(block, r.id);
          if (v) {
            actionMap[v] = r.id;
          }
        }

        if (r.componentId === "SpreadsheetColumnSelect") {
          const selectedColumn = get(block, r.id);

          const resourceLabel = get(r, "label");

          // Right now we are only checking this if there IS a value
          if (
            selectedColumn &&
            !selectedColumn.includes("__") &&
            !validDataSourceFields.includes(selectedColumn)
          ) {
            errors.push({
              type: "block",
              label: `${componentId} Block (${blockLabel})`,
              blockId: block.id,
              message: (
                <span>
                  The column <strong>{selectedColumn}</strong> selected for the{" "}
                  <strong>{resourceLabel}</strong> resource doesn't exist in any
                  of your data sources.
                </span>
              ),
            });
          }
        }

        if (r.id === "spreadsheet") {
          const sheetId = get(block, r.id);
          if (sheetId && !dataSourceIds.includes(sheetId)) {
            errors.push({
              type: "block",
              label: `${componentId} Block (${blockLabel})`,
              blockId: block.id,
              message: (
                <span>
                  The selected data source for this block (
                  <strong>{sheetId}</strong>) does not exist.
                </span>
              ),
              description:
                "To resolve this issue, select a valid data source for this block",
            });
          }
        }
      });
    });

    actions.forEach((action) => {
      const actionMatch = get(actionMap, action.id) || "Action";

      const actionSteps = get(action, "steps", []);
      actionSteps.forEach((step) => {
        const detectedVariables = getVariablesFromValue(step);

        // Show errors for any invalid variables
        detectedVariables
          .filter((v) => !validVariableKeys.includes(v[0]))
          .forEach((v) => {
            errors.push({
              type: "action",
              label: startCase(actionMatch),
              message: (
                <span>
                  The variable <strong>{v[0]}</strong> is not a valid variable.
                </span>
              ),
            });
          });

        const dsFieldVariables = detectedVariables.filter((v) =>
          ["form", "detail", "record"].includes(v[0])
        );
        dsFieldVariables.forEach((v) => {
          const variableKey = get(v, 0, null);
          const variableValue = get(v, 1, null);

          if (variableValue.includes("__")) {
            // Skip validating __ variables for now (relational)
          } else {
            const isForm = variableKey === "form";

            const isInputBlock = inputBlockFields.includes(variableValue);

            const hasError =
              !validDataSourceFields.includes(variableValue) &&
              (!isForm || (isForm && !isInputBlock));

            const labelText = `${startCase(actionMatch)} - ${get(
              actionLabelMap,
              step.type,
              startCase(step.type)
            )}`;

            if (hasError) {
              errors.push({
                type: "action",
                label: labelText,
                message: (
                  <span>
                    The field <strong>{variableValue}</strong> in your{" "}
                    <strong>{`{{ ${variableKey}.${variableValue} }}`}</strong>{" "}
                    variable does not exist in any of your data sources.
                  </span>
                ),
                description:
                  "To resolve this issue, find the variable and enter a valid field.",
              });
            }
          }
        });
      });
    });

    setErrorsList(errors);
  }, [get(page, "id")]);

  if (errorsList.length > 0) {
    return (
      <Modal
        hide={() => setErrorsList([])}
        background="var(--grey1)"
        minWidth="650px"
        header={{
          title: `This page has ${errorsList.length} issue${
            errorsList.length === 1 ? "" : "s"
          }`,
          description:
            "Review the warnings below and make the appropriate changes to resolve them",
        }}
      >
        <ErrorList>
          {errorsList.map((e) => (
            <Error>
              <ErrorType type={e.type}>{e.label}</ErrorType>
              <ErrorMessage>{e.message}</ErrorMessage>
              <ErrorDescription>{e.description}</ErrorDescription>
            </Error>
          ))}
        </ErrorList>
      </Modal>
    );
  }

  return <></>;
};

export default PageWizard;

const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Error = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--grey3);
  padding: 10px;
  border-radius: 10px;
`;

const ErrorType = styled.div`
  font-weight: 600;
  font-size: 12px;
  background: ${({ type }) => (type === "block" ? "#b8e7d2" : "#AFE6F1")};
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const ErrorDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey7);
`;
