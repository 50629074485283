import { Icon, Menu, Row, SearchBar } from ".";
import { get, startCase } from "lodash";
import {
  getFieldStyles,
  safeArray,
  safeLower,
  safeString,
} from "app/utils/utils";

import { colors } from "app/utils/theme";
import { rRelationFormChanges } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useState } from "react";

const Select = ({ data }) => {
  const { onChange } = data;

  const relationName = get(data, "key");
  const dataSourceId = get(data, "dataSourceId");
  const activeItemId = get(data, "activeItemId");

  const [relationFormChanges, setRelationFormChanges] =
    useRecoilState(rRelationFormChanges);

  const concatId = `${dataSourceId}__${activeItemId}`;

  const relationFormChange = get(relationFormChanges, concatId);

  const isRelation = get(data, "isRelation", false);

  const options = safeArray(get(data, "options", []));
  const validTypes = ["text", "number", "password", "email"];

  // Single or Multi
  const isMulti = get(data, "multi", false);
  // How we store multi-values (csv or array)
  const multiType = get(data, "multiType", "csv");

  // Original incoming value
  let rawValue = get(data, "value") || get(data, "defaultValue") || "";

  /**
   * Convert the incoming value to a normalized form.
   * - If single-select, return as-is (string or value).
   * - If multiType === "csv", parse the CSV into a trimmed array.
   * - Otherwise (multiType === "array"), ensure we always have an array.
   */
  const parseValue = (val) => {
    if (!isMulti) return val; // single-select, no transformation

    if (multiType === "csv") {
      return safeString(val)
        .split(",")
        .map((item) => item.trim()) // trim spaces
        .filter(Boolean); // remove empty
    } else {
      // array mode
      return Array.isArray(val) ? val : safeArray(val);
    }
  };

  /**
   * Convert our internal array back into the final format
   * before calling onChange.
   * - If single-select, return as-is.
   * - If multiType === "csv", join array with commas (a,b,c).
   * - Otherwise (multiType === "array"), pass an array.
   */
  const formatValue = (val) => {
    if (!isMulti) return val;

    if (multiType === "csv") {
      return safeArray(val).filter(Boolean).join(",");
    } else {
      // array mode
      return safeArray(val);
    }
  };

  // Internal normalized value
  const value = parseValue(rawValue);

  const selectText =
    get(data, "selectText") ||
    data.placeholder ||
    data.noneText ||
    `Select ${safeLower(startCase(get(data, "key")))}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");

  const showSearch = get(data, "showSearch", false);

  // Filter options by search text
  const filteredOptions = options.filter(
    (o) =>
      safeString(get(o, "label", ""))
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      safeString(get(o, "value", ""))
        .toLowerCase()
        .includes(search.toLowerCase())
  );

  // Which options are selected based on `value` (array if multi)
  const selectedOptions = options.filter((o) =>
    isMulti ? value.includes(safeString(o.value)) : o.value == value
  );

  const getDisplayValue = () => {
    let displayValue = selectText;

    if (isMulti) {
      if (selectedOptions.length) {
        const totalChars = selectedOptions.reduce(
          (sum, o) => sum + o.label.length,
          0
        );
        if (selectedOptions.length > 1 && totalChars > 20) {
          return `${selectedOptions.length} selected`;
        }

        displayValue = selectedOptions.map((o) => o.label).join(", ");
      }
    } else {
      displayValue = get(selectedOptions, [0, "label"]) || selectText;
    }

    return displayValue;
  };

  const displayValue = getDisplayValue();

  // If single-select with exactly one selection that has an icon, display that icon
  const icon =
    selectedOptions.length === 1 ? get(selectedOptions[0], "icon") : null;

  // Handle user toggling an option
  const handleOptionClick = (optionValue) => {
    let newValue;

    if (isMulti) {
      const optionString = safeString(optionValue);

      // If the option already exists in array, remove it; otherwise, add it
      if (value.includes(optionString)) {
        // Remove it
        newValue = value.filter((v) => v !== optionString);
      } else {
        // Add it (ensuring no duplicates)
        newValue = [...new Set([...value, optionString])];
      }
    } else {
      // Single select
      newValue = optionValue;
    }

    // Pass the correctly formatted value to onChange
    onChange(formatValue(newValue));

    // Handle real-time relational data change
    if (isRelation) {
      const match = options.find((o) => o.value === optionValue);
      setRelationFormChanges({
        ...relationFormChanges,
        [concatId]: {
          ...relationFormChange,
          [relationName]: match,
        },
      });
    }

    // Close menu after choosing an item in single-select mode
    if (!isMulti) {
      setAnchorEl(null);
    }
  };

  const isDarkMode = false; // data.darkMode;

  const isDisabled = get(data, "disabled", false);

  return (
    <>
      <Menu
        anchorElement={anchorEl}
        hide={() => setAnchorEl(null)}
        padding="0px"
        minWidth="200px"
      >
        {showSearch && (
          <SearchBar
            data={{
              value: search,
              margin: "10px",
              placeholder: data.placeholder || "Search",
              onChange: (v) => setSearch(v),
              setupMode: false,
            }}
          />
        )}
        <MenuItems isDarkMode={isDarkMode}>
          {!data.hideEmptyItem && !search && (
            <MenuItem
              disabled
              onClick={() => handleOptionClick(null)}
              isDarkMode={isDarkMode}
            >
              {data.noneText || "None"}
            </MenuItem>
          )}
          {filteredOptions.map((o) => (
            <MenuItem
              key={o.value}
              active={isMulti ? value.includes(o.value) : o.value === value}
              onClick={() => handleOptionClick(o.value)}
              isDarkMode={isDarkMode}
            >
              {o.icon && (
                <Icon
                  data={{
                    icon: o.icon,
                    size: 18,
                    color: "var(--grey7)",
                    margin: "0 0 1px 0",
                    hover: true,
                  }}
                />
              )}
              {o.label}
            </MenuItem>
          ))}
          {filteredOptions.length === 0 && (
            <MenuItem>No results found</MenuItem>
          )}
        </MenuItems>
      </Menu>

      {/* Main "select" display container */}
      <Container
        {...data}
        type={validTypes.includes(data.type) ? data.type : "text"}
        width={data.width || "100%"}
        onClick={(e) => {
          if (isDisabled) return;
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Row $alignitems="center" $gap="7px">
          {icon && (
            <Icon
              data={{
                icon,
                size: 16,
                color: "var(--grey7)",
                margin: "1px 6px 0 0",
                hover: true,
              }}
            />
          )}
          <ValueText
            value={value}
            fontSize={data.fontSize}
            fontWeight={data.fontWeight}
            color={data.color}
          >
            {displayValue}
          </ValueText>
        </Row>
        {!isDisabled && (
          <Icon
            data={{
              icon: anchorEl ? "FiChevronUp" : "FiChevronDown",
              size: 20,
              color: "var(--light-grey)",
              margin: "3px 0 0 0",
              hover: true,
            }}
          />
        )}
      </Container>
    </>
  );
};

export default Select;

/* ------------------------------ STYLES ------------------------------ */

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  padding: 0px;
  background: ${(p) =>
    p.isDarkMode ? colors.darkModeLightBackground : "transparent"};
`;

const MenuItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;

  opacity: ${(p) => (p.disabled ? 0.7 : 1)};

  background: ${(p) => (p.active ? colors.grey15 : "transparent")};

  ${(p) =>
    p.isDarkMode &&
    `
    color: white;
    background: ${p.active ? colors.darkModeButtonBorder : "transparent"};
  `}

  &:hover {
    background: ${(p) =>
      p.isDarkMode ? colors.darkModeInputBackground : colors.grey15};
  }
`;

const ValueText = styled.div`
  font-size: ${(p) => p.fontSize || "14px"};
  font-weight: ${(p) => p.fontWeight};
  /* If no selections, show placeholder color; if selected, show user-defined color */
  color: ${(p) => (p.value ? p.color : "var(--light-grey)")};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(p) => getFieldStyles(p)};

  text-align: left;
  min-width: 50px;
  cursor: pointer;

  ${(p) => p.disabled && `cursor: not-allowed;`}

  &:focus {
    outline: none;
  }
`;
