import ActionStep from "./ActionStep";
import { get } from "lodash";
import { getPreviousSteps } from "app/utils/utils";
import styled from "styled-components";

const NewActionStep = ({
  stepId,
  steps,
  layer = 0,
  getStepData,
  conditionWarning,
}) => {
  const step = steps.find((step) => step.id === stepId);

  const layerColors = ["#4597f8", "#50ab64", "#df5534", "#8d4cf6"];

  const ancestorIds = getPreviousSteps(steps, stepId);

  const previousSteps = steps.filter((s, i) => ancestorIds.includes(s.id));

  const children = steps.filter(
    (step) => step.parent && step.parent === stepId
  );

  // Calculate layer color based on index and then repeat when index exceeds color array length
  const layerColor = layerColors[layer % layerColors.length];
  const childCount = children.length;

  const childrenWithoutConditions = children.filter(
    (s) => !get(s, "conditions")
  );
  const childConditionsWarning =
    children.length > 1 && childrenWithoutConditions.length > 0;

  const stepData = getStepData(step);

  const maxLayers = get(stepData, "maxLayers");
  const mockSize = get(stepData, "mockSize", "small");

  return (
    <NewStepContainer>
      <StepActionContainer color={layerColor}>
        {stepData && (
          <ActionStep
            {...stepData}
            layerColor={layerColor}
            childCount={childCount}
            previousSteps={previousSteps}
            conditionWarning={conditionWarning}
          />
        )}
      </StepActionContainer>

      {childCount > 0 && (!maxLayers || maxLayers > layer) && (
        <>
          <VerticalLine
            mock={get(stepData, "mock")}
            mockSize={mockSize}
            showLabels={get(stepData, "showLabels")}
          ></VerticalLine>
          <StepChildren>
            {children.map((child, i) => {
              // if smallest index, right border only
              const isSmallestIndex = i === 0;

              // if largest index, left border only
              const isLargestIndex = i === childCount - 1;

              // if middle index, left and right border
              const isMiddleIndex = !isSmallestIndex && !isLargestIndex;

              return (
                <StepChildContainer>
                  {childCount > 1 && (
                    <>
                      {isSmallestIndex && (
                        <HorizontalLine
                          style={{ position: "absolute", right: 0 }}
                        />
                      )}
                      {isMiddleIndex && (
                        <HorizontalLine style={{ width: "100%" }} />
                      )}
                      {isLargestIndex && (
                        <HorizontalLine
                          style={{ position: "absolute", left: 0 }}
                        />
                      )}
                    </>
                  )}

                  <VerticalLine
                    mock={get(stepData, "mock")}
                    mockSize={mockSize}
                    showLabels={get(stepData, "showLabels")}
                  />
                  <NewActionStep
                    stepId={child.id}
                    steps={steps}
                    layer={layer + 1}
                    getStepData={getStepData}
                    conditionWarning={childConditionsWarning}
                  />
                </StepChildContainer>
              );
            })}
          </StepChildren>
        </>
      )}
    </NewStepContainer>
  );
};

export default NewActionStep;

const NewStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
`;

const StepActionContainer = styled.div`
  border-radius: 10px;
  width: fit-content;
  color: white;
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  font-size: 12px;
`;

const StepChildren = styled.div`
  display: flex;
`;

const StepChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const VerticalLine = styled.div`
  border-left: 1px solid grey;
  height: 20px;
  width: 1px;

  ${({ mock, showLabels }) =>
    mock &&
    `
    height: ${showLabels ? "10px" : "6px"};
  `}
`;

const HorizontalLine = styled.div`
  border-top: 1px solid grey;
  height: 1px;
  width: calc(100% / 2);
`;
