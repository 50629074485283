import UserSection, {
  DropdownHeader,
  UserText,
} from "../components/UserSection";
import { rApp, rUser } from "app/utils/recoil";

import LogoSection from "../components/LogoSection";
import Section from "../components/Section";
import { SimpleDropdown } from "app/components";
import { boxShadow } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";
import useDropdownLinks from "../utils/useDropdownLinks";
import { useNavigationSections } from "../utils";
import { useRecoilValue } from "recoil";

const Sidebar = ({ isPreview }) => {
  // -- RECOIL / DATA -- //
  const sections = useNavigationSections(isPreview);
  const user = useRecoilValue(rUser);
  const app = useRecoilValue(rApp);

  const navigation = get(app, "navigation", {});

  const dropdownLinks = useDropdownLinks();

  // Narrow sidebar mode
  const isNarrow = get(navigation, "sidebarStyle") === "minimal";

  const backgroundColor = get(
    navigation,
    "backgroundColor",
    "var(--nav-background)"
  );

  const showBorder = get(navigation, "showBorder", true);
  const borderColor = get(navigation, "borderColor", "var(--nav-round-icon)");
  const showBoxShadow = get(navigation, "showBoxShadow", false);
  const accentType = get(navigation, "accentType", "badge"); // or color
  const accentBorderRadius = get(navigation, "accentBorderRadius", 24);

  return (
    <SidebarContainer
      isNarrow={isNarrow}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      showBorder={showBorder}
      showBoxShadow={showBoxShadow}
    >
      {/* Logo at the top */}
      <LogoWrapper isNarrow={isNarrow}>
        <LogoSection />
      </LogoWrapper>

      {/* Main scrollable area for sections */}
      <SectionsWrapper isNarrow={isNarrow}>
        {sections.map((section, idx) => (
          <Section
            key={idx}
            section={section}
            orientation="vertical"
            isNarrow={isNarrow}
            sectionIndex={idx}
            accentType={accentType}
            accentBorderRadius={accentBorderRadius}
          />
        ))}
      </SectionsWrapper>

      {/* User dropdown at the bottom */}
      {dropdownLinks.length > 0 && (
        <UserDropdownWrapper isNarrow={isNarrow}>
          <SimpleDropdown
            data={{
              headerComponent: isNarrow ? (
                <DropdownHeader>
                  <UserText user={user} />
                </DropdownHeader>
              ) : null,
              component: (
                <UserSection user={user} text={isNarrow ? false : true} />
              ),
              options: dropdownLinks,
            }}
          />
        </UserDropdownWrapper>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;

/* ------------------------------ STYLES ------------------------------ */

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background: ${(p) => p.backgroundColor};
  overflow-x: hidden;
  ${(p) =>
    p.showBoxShadow &&
    `
    box-shadow: ${boxShadow.card};
  `}

  ${(p) =>
    p.showBorder &&
    `
    border-right: 1px solid ${p.borderColor};
  `}

  ${(p) =>
    p.isNarrow &&
    `
    width: 100px;
  `}
`;

const LogoWrapper = styled.div`
  padding: ${(p) => (p.isNarrow ? "25px 20px" : "20px 25px")};
  flex-shrink: 0;
  border-bottom: 1px solid var(--nav-round-icon);
`;

const SectionsWrapper = styled.div`
  flex: 1;
  overflow-y: auto;

  padding: 20px 13px 10px 16px;
  ${(p) => p.isNarrow && `padding: 15px 6px 10px 6px;`}

  display: flex;
  flex-direction: column;

  gap: ${(p) => (p.isNarrow ? "10px" : "8px")};

  align-items: ${(p) => (p.isNarrow ? "center" : "flex-start")};
`;

const UserDropdownWrapper = styled.div`
  padding: 20px 20px;
  flex-shrink: 0;
  border-top: 1px solid var(--nav-round-icon);
  ${(p) =>
    p.isNarrow &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
