import { Row } from "app/components";
import styled from "styled-components";

const BlockHeader = ({ name, hue, hideButton, large }) => {
  return (
    <Row
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: "5px" }}
    >
      <TableLabel large={large}>{name}</TableLabel>
      {!hideButton && <AddButton hue={hue}>Add +</AddButton>}
    </Row>
  );
};

export default BlockHeader;

const TableLabel = styled.div`
  font-size: ${(p) => (p.large ? "12px" : "10px")};
  font-weight: ${(p) => (p.large ? "700" : "600")};
`;

const AddButton = styled.div`
  font-size: 8px;
  font-weight: 600;
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  background: hsl(${(p) => p.hue}, 93%, 25%, 0.8);
`;
