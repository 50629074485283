import {
  filterBasedOnUserGroups,
  getDetailViewMode,
  getLayoutSizes,
  getPixels,
} from "app/utils/utils";
import { get, isEmpty } from "lodash";
import {
  rApp,
  rFetchingBlockIds,
  rFetchingVariables,
  rFilters,
  rFormState,
  rPage,
  rPageBlocks,
  rSubscription,
  rUser,
  refreshBlockIdsSelector,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import ClientNavigationWrapper from "app/renderingApp/ClientNavigationWrapper";
import Grid from "app/adminApp/GridLayout";
import MadeWithFrontly from "app/adminApp/components/MadeWithFrontly";
import PageFilters from "./PageFilters";
import RenderBlockRouter from "app/renderingApp/RenderBlock/RenderBlockRouter";
import RenderDetailView from "app/renderingApp/RenderDetailView";
import TemplateBanner from "./TemplateBanner";
import { demoSubdomains } from "app/adminApp/new_templates/templates";
import mixpanel from "mixpanel-browser";
import useActionResolver from "app/renderingApp/useActionResolver";
import useBlockVersion from "app/utils/useBlockVersion";
import useDynamicText from "app/renderingApp/useDynamicText";
import { useFetchSpreadsheets } from "app/renderingApp/fetchSpreadsheets";
import { useInitializePage } from "app/adminApp/pageBuilder/v2/utils/useInitializePage";
import { useLocation } from "react-router-dom";
import useModalStateData from "app/useModalStateData";
import useUtils from "app/renderingApp/useUtils";

const RenderPage = ({ page, availableWidth, isPublicRoute = false }) => {
  useInitializePage(page);

  const user = useRecoilValue(rUser);
  const app = useRecoilValue(rApp);

  const refreshBlockIds = useRecoilValue(refreshBlockIdsSelector);

  const setFilterState = useSetRecoilState(rFilters);

  const { passesDisplayConditions } = useUtils();

  const { processDynamicText } = useDynamicText();

  const subscription = useRecoilValue(rSubscription);

  const currentPlan = get(subscription, "plan");
  const isDemoApp = demoSubdomains.includes(get(app, "subdomain"));
  const isUnpaid = !isDemoApp && !currentPlan;

  const location = useLocation();

  const [initialFetchedBlocks, setInitialFetchedBlocks] = useState({});
  const [fetchedBlocks, setFetchedBlocks] = useState({});

  const setFormState = useSetRecoilState(rFormState);

  const localInitialFetchedBlocks = get(initialFetchedBlocks, page.id, []);
  const localFetchedBlocks = get(fetchedBlocks, page.id, []);

  const { handleCustomAction } = useActionResolver();

  const path = get(location, "pathname");

  const { getBlockWithVersion } = useBlockVersion();

  const recoilPage = useRecoilValue(rPage);

  const pageBlocks = useRecoilValue(rPageBlocks);

  const blocksWithVersions = pageBlocks.map((b) => {
    const { blockWithVersion: blockVersion } = getBlockWithVersion(b);
    return blockVersion;
  });

  const initialBlocks = filterBasedOnUserGroups({
    items: blocksWithVersions,
    app,
    user,
    passesDisplayConditions,
  }).map((b) => ({ ...b, availableWidth }));

  const blockCount = pageBlocks.length;

  // Handle setting default visible filters to the state
  useEffect(() => {
    if (blockCount > 0) {
      const defaultVisibleFilters = getDefaultVisibleFilters(
        initialBlocks,
        processDynamicText
      );
      setFilterState(defaultVisibleFilters);
    }
  }, [path, blockCount]);

  const blocksToFetch = initialBlocks.filter(
    (b) =>
      passesDisplayConditions({
        conditions: get(b, "displayConditions", []),
      }) || get(b, "fetchIfHidden")
  );

  const blocks = initialBlocks.filter((b) =>
    passesDisplayConditions({ conditions: get(b, "displayConditions", []) })
  );

  const isFetchingVariables = useRecoilValue(rFetchingVariables);

  const rootBlocks = blocks.filter((b) => !b.parent && !b.layoutParent);
  const rootBlocksAndLayouts = blocksToFetch.filter((b) => !b.parent);

  // DETAIL VIEW STUFF
  const { modalStack } = useModalStateData();
  const lastItem = get(modalStack, modalStack.length - 1);
  const lastItemBlockId = get(lastItem, "blockId");

  let activeDetailBlock = pageBlocks.find((b) => b.id === lastItemBlockId);

  const { blockWithVersion } = getBlockWithVersion(activeDetailBlock);
  activeDetailBlock = blockWithVersion;

  const detailViewMode = getDetailViewMode(activeDetailBlock, blocks);

  const { fetchSpreadsheets } = useFetchSpreadsheets();

  const rootAndLayoutIds = rootBlocksAndLayouts.map((b) => b.id);

  const newVisibleBlockIds = rootAndLayoutIds.filter(
    (id) =>
      !localFetchedBlocks.includes(id) &&
      !localInitialFetchedBlocks.includes(id)
  );

  const newVisibleBlocks = blocks.filter(
    (b) => newVisibleBlockIds.includes(b.id) && b.spreadsheet
  );

  const hasUnFetchedBlocks =
    localInitialFetchedBlocks.length > 0 && newVisibleBlocks.length > 0;

  useEffect(() => {
    const pageLoadAction = get(page, "pageLoadAction");
    if (pageLoadAction) {
      handleCustomAction({
        rawAction: pageLoadAction,
        action: pageLoadAction,
      });
    }
  }, [path]);

  useEffect(() => {
    if (hasUnFetchedBlocks) {
      fetchSpreadsheetData(newVisibleBlocks);
    }
  }, [hasUnFetchedBlocks]);

  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const [defaultFormStateSet, setDefaultFormStateSet] = useState(false);

  // Reset when the page changes?
  useEffect(() => {
    setDefaultFormStateSet(false);
  }, [path]);

  // Set default form state
  useEffect(() => {
    if (!defaultFormStateSet) {
      if (blockCount > 0) {
        if (fetchingBlockIds.length === 0) {
          const inputBlocks = blocks
            .filter((b) =>
              ["Input", "Select", "Switch", "TextArea"].includes(b.componentId)
            )
            .filter((b) => b.key && b.defaultValue);

          let defFormState = {};
          inputBlocks.forEach((b) => {
            defFormState[`${page.id}-${b.key}`] = processDynamicText({
              text: b.defaultValue,
            });
          });

          if (!isEmpty(defFormState)) {
            setDefaultFormStateSet(true);
            setFormState(defFormState);
          }
        }
      }
    }
  }, [
    blockCount,
    path,
    isFetchingVariables,
    fetchingBlockIds,
    defaultFormStateSet,
  ]);

  const fetchSpreadsheetData = (blocksToFetch, isInitial = false) => {
    fetchSpreadsheets(blocksToFetch, isInitial);
    const newFetchedBlocks = [
      ...localFetchedBlocks,
      ...blocksToFetch.map((b) => b.id),
    ];
    setFetchedBlocks({ [page.id]: newFetchedBlocks });
    if (isInitial) {
      setInitialFetchedBlocks({ [page.id]: newFetchedBlocks });
    }
  };

  const recoilPageId = get(recoilPage, "id");
  const pageId = get(page, "id");

  useEffect(() => {
    if (blockCount > 0 && recoilPageId === pageId) {
      fetchSpreadsheetData(rootBlocksAndLayouts, true);
      const element = document.getElementById("renderBlocksContainer");
      if (element) {
        // Scroll to top of container
        element.scrollIntoView({ behavior: "instant" });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, blockCount, recoilPageId]);

  const refreshBlockIdsCount = get(refreshBlockIds, "count", 0);
  const refreshBlockIdsArray = get(refreshBlockIds, "ids", []);

  // For refreshing blocks via action only
  useEffect(() => {
    if (refreshBlockIdsArray.length > 0) {
      const refreshBlocks = blocksToFetch.filter((b) =>
        refreshBlockIdsArray.includes(b.id)
      );
      fetchSpreadsheetData(refreshBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshBlockIdsArray, refreshBlockIdsCount]);

  // TRACK ACTIVE USED BLOCKS IN MIXPANEL
  useEffect(() => {
    const isDemoApp = demoSubdomains.includes(get(app, "subdomain"));

    if (isDemoApp) {
      mixpanel.track("Demo App Page View", {
        subdomain: get(app, "subdomain"),
      });
    } else {
      let blocksObject = {};
      blocks
        .map((b) => b.componentId)
        .forEach((b) => {
          blocksObject[b] = true;
        });

      mixpanel.track("Client App Page View", {
        subdomain: get(app, "subdomain"),
        blocks: blocks.map((b) => b.componentId),
        ...blocksObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layoutType = get(page, "layoutType", "default");

  // TODO - support dynamic resizing
  const width = window.innerWidth;
  const screenPreviewSize = () => {
    if (width < 800) {
      return "mobile";
    }

    if (width < 1200) {
      return "tablet";
    }

    return "desktop";
  };

  const layoutSizes = getLayoutSizes(rootBlocks);
  const screenSize = screenPreviewSize();
  const finalScreenSize = layoutSizes.includes(screenSize)
    ? screenSize
    : "desktop";

  const gridSize = get({ desktop: 25, tablet: 20, mobile: 15 }, screenSize, 25);

  const primaryColor = get(app, "primary_color");

  // Eventually we'll put this on, but we'll want to backfill I think
  // const hideBranding = get(app, "hide_branding");
  // const showMadeWithFrontly = isUnpaid || !hideBranding;
  const showMadeWithFrontly = isUnpaid;

  return (
    <div
      style={{
        "--primary": primaryColor,
      }}
    >
      {isDemoApp && <TemplateBanner template={get(app, "subdomain")} />}
      <ClientNavigationWrapper page={page} isPublicRoute={isPublicRoute}>
        {showMadeWithFrontly && <MadeWithFrontly />}

        {!activeDetailBlock && <PageFilters />}

        {layoutType === "default" &&
          (!activeDetailBlock || detailViewMode === "modal") &&
          rootBlocks.map((block) => (
            <RenderBlockRouter
              key={`${page.id}-${block.id}`}
              data={{
                page,
                block: { ...block, parentComponentId: null },
              }}
            />
          ))}

        {layoutType === "grid" && (
          <Grid
            screenPreviewSize={finalScreenSize}
            items={rootBlocks.map((block) => {
              const blockPosition = get(block, [
                "gridPosition",
                finalScreenSize,
              ]);
              const blockHeight = getPixels(
                get(blockPosition, "height", 10) * gridSize
              );

              return {
                id: block.id,
                gridPosition: get(block, "gridPosition", {}),
                content: (
                  <RenderBlockRouter
                    key={block.id}
                    data={{
                      page,
                      inLayout: true,
                      block: {
                        ...block,
                        height: blockHeight,
                        inGridLayout: true,
                      },
                    }}
                  />
                ),
              };
            })}
          />
        )}

        {activeDetailBlock && (
          <RenderDetailView activeBlock={activeDetailBlock} page={page} />
        )}
      </ClientNavigationWrapper>
    </div>
  );
};

export default RenderPage;

// Get default visible filters
export const getDefaultVisibleFilters = (blocks, processDynamicText) => {
  let defaultVisibleFilters = {};

  blocks.forEach((b) => {
    let blockFilters = {};

    get(b, "visibleFilters", [])
      .filter((f) => f.defaultValue)
      .forEach((f) => {
        blockFilters[f.key] = processDynamicText({
          text: f.defaultValue,
        });
      });

    defaultVisibleFilters[b.id] = blockFilters;
  });

  return defaultVisibleFilters;
};
