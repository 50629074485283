import {
  badgeColors,
  fetchIfHidden,
  label,
  labelColor,
  spreadsheetSelect,
} from "./shared";

import { get } from "lodash";

export const InfoList = {
  componentId: "InfoList",
  resources: [
    label,
    labelColor,
    {
      ...spreadsheetSelect,
      hint: "Select the data source to display a record from",
    },
    {
      id: "rowId",
      label: "Row ID",
      hint: "The frontly_id of the row you're displaying. Most of the time this is a dynamic variable.",
      componentId: "DynamicString",
      section: "listData",
      requiresSheet: true,
      width: "150px",
      orientation: "horizontal",
    },
    {
      id: "rowIdColumn",
      label: "Row Column",
      section: "listData",
      orientation: "horizontal",
      width: "150px",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      defaultValue: "frontly_id",
      hint: "The column to use in the row finding condition. Defaults to frontly_id.",
    },
    {
      id: "fieldData",
      label: "Fields",
      componentId: "StaticFields",
      section: "listData",
      requiresSheet: true,
      showRelationFields: true,
      keys: [
        {
          id: "label",
          label: "Label",
          hint: "Override the default label",
          componentId: "Input",
          required: true,
          defaultValue: "",
        },
        {
          id: "type",
          label: "Field Type",
          hint: "Define how the column should be displayed visually in forms and other blocks",
          componentId: "Select",
          required: true,
          defaultValue: "text",
          options: [
            { label: "Text", value: "text" },
            { label: "Link", value: "link" },
            { label: "Badge", value: "badge" },
            { label: "Image", value: "image" },
            { label: "Date", value: "date" },
            { label: "Number", value: "number" },
            { label: "Boolean (true/false)", value: "boolean" },
          ],
        },
        {
          displayCondition: (f) => get(f, "type") === "badge",
          ...badgeColors,
        },
        {
          id: "customColors",
          label: "Custom Colors",
          componentId: "MultiForm",
          labelSingular: "Custom Color",
          passIdToChild: true,
          width: "300px",
          displayCondition: (f) => get(f, "type") === "badge",
          fields: [
            {
              key: "label",
              label: "Label",
              placeholder: "Label",
              componentId: "Input",
              width: "100%",
            },
            {
              key: "color",
              label: "Color",
              componentId: "ColorPicker",
              width: "100%",
            },
            {
              id: "displayConditions",
              label: "Display Conditions",
              componentId: "DisplayConditions",
              orientation: "vertical",
            },
          ],
        },
        {
          displayCondition: (f) => get(f, "type") === "date",
          id: "dateFormat",
          label: "Date Format",
          componentId: "DateFormatConfig",
          required: true,
          enabledFields: ["outputDate", "outputTime", "showAmPm"],
        },
        {
          displayCondition: (f) => get(f, "type") === "badge",
          id: "splitBadge",
          label: "Split Badges",
          componentId: "Switch",
          orientation: "horizontal",
          defaultValue: false,
          hint: "Should split the badges by `,`?",
        },
        {
          displayCondition: (f) => get(f, "type", "text") === "link",
          key: "linkText",
          label: "Link Text",
          hint: "Override the default link text",
          componentId: "Input",
          required: false,
        },
        {
          displayCondition: (f) =>
            ["text", "link"].includes(get(f, "type", "text")),
          key: "truncate",
          label: "Character Limit",
          componentId: "Input",
          required: false,
          type: "number",
          defaultValue: 35,
        },
        {
          id: "conditions",
          label: "Display Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
          allowSpreadsheet: true,
        },
      ],
    },
    {
      id: "gridLayout",
      label: "Grid Layout",
      hint: "Display the form fields in a responsive grid instead of vertically stacking",
      componentId: "Switch",
      defaultValue: false,
      orientation: "horizontal",
      section: "layout",
      isStyle: true,
    },
    fetchIfHidden,
  ],
};
