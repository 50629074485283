import { Breadcrumb, Icon, Text } from "app/components";
import { rDarkMode, rPageBlocks, rShowStylesPanel } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "app/adminApp/components/AdminForm";
import { Tooltip } from "react-tooltip";
import { colors } from "app/utils/theme";
import { get } from "lodash";
// import { isObjectEqualFromKeys } from "app/utils/utils";
import { memo } from "react";
import styled from "styled-components";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";

// ─────────────────────────────────────────────────────────────
// 1. Create the inner (presentational) component that takes all
//    data as props. This component contains the bulk of your UI logic.
// ─────────────────────────────────────────────────────────────

const PageStylesInner = ({
  page,
  blocks,
  darkMode,
  showStylesPanel,
  setShowStylesPanel,
  setPage,
}) => {
  // Determine if any blocks are layout blocks.
  const hasLayoutBlocks =
    blocks.filter((b) => ["Row", "Column"].includes(get(b, "componentId")))
      .length > 0;

  const pageFields = [
    {
      id: "backgroundColor",
      label: "Background Color",
      componentId: "ColorPicker",
      value: get(page, "backgroundColor"),
      section: "background",
      width: "150px",
      orientation: "horizontal",
      displayCondition: () => !darkMode,
    },
    {
      id: "contentWidth",
      label: "Max Width",
      componentId: "Select",
      hint: "Set a max width for your page content.",
      value: get(page, "contentWidth"),
      defaultValue: "100%",
      orientation: "horizontal",
      width: "90px",
      options: [
        { label: "100%", value: "100%" },
        { label: "1200px", value: 1200 },
        { label: "1000px", value: 1000 },
        { label: "800px", value: 800 },
        { label: "600px", value: 600 },
      ],
      section: "width",
    },
    {
      id: "icon",
      label: "Navigation Icon",
      componentId: "IconBrowser",
      value: get(page, "icon"),
      orientation: "horizontal",
      hint: "An icon that displays in the app navigation for this page",
      section: "navigation",
    },
    {
      id: "hideNavigation",
      label: "Hide Navigation",
      componentId: "Switch",
      value: get(page, "hideNavigation"),
      defaultValue: false,
      section: "navigation",
      hint: "Completely hide the app navigation when viewing this page.",
    },
    {
      label: "Padding",
      id: "padding",
      value: get(page, "padding", {}),
      section: "spacing",
      orientation: "vertical",
      componentId: "PaddingConfig",
    },
    {
      id: "rootBlockGap",
      label: "Block Spacing",
      componentId: "Input",
      hint: "The distance between the root blocks on your page, in px",
      value: get(page, "rootBlockGap"),
      defaultValue: "30",
      placeholder: "30",
      orientation: "horizontal",
      section: "spacing",
      width: "100px",
    },
    {
      id: "showLayoutGridLines",
      label: "Show Layout Grid Lines",
      componentId: "Switch",
      value: get(page, "showLayoutGridLines"),
      orientation: "horizontal",
      section: "designMode",
      tab: "more",
      displayCondition: () => hasLayoutBlocks,
    },
    {
      id: "addVisiblePadding",
      label: "Add Visible Padding",
      componentId: "Switch",
      value: get(page, "addVisiblePadding"),
      orientation: "horizontal",
      section: "designMode",
      tab: "more",
      displayCondition: () => get(page, "showLayoutGridLines"),
    },
  ];

  return (
    <div>
      <PanelHeader
        label="Page Styles"
        expanded={showStylesPanel}
        setExpanded={setShowStylesPanel}
      />
      <AdminForm
        fields={pageFields.filter(
          (f) => !f.displayCondition || f.displayCondition()
        )}
        orientation="horizontal"
        borderBottom={true}
        onChange={(k, v) => setPage({ [k]: v })}
      />
    </div>
  );
};

// ─────────────────────────────────────────────────────────────
// 2. Create a custom comparison function using your helper.
//    (List here the keys whose equality matters.)
//
//    Note: Your utility `isObjectEqualFromKeys` will use lodash's isEqual
//    to deeply compare each key's value.
// ─────────────────────────────────────────────────────────────

// const areEqual = (prevProps, nextProps) => {
//   const usedPageKeys = [
//     "backgroundColor",
//     "contentWidth",
//     "icon",
//     "hideNavigation",
//     "padding",
//     "rootBlockGap",
//     "showLayoutGridLines",
//     "addVisiblePadding",
//   ];

//   // Check if the relevant keys in the 'page' object have changed
//   const isPageEqual = isObjectEqualFromKeys(
//     prevProps.page,
//     nextProps.page,
//     usedPageKeys
//   );

//   // Check if other props have changed
//   const isOtherPropsEqual = isObjectEqualFromKeys(prevProps, nextProps, [
//     "blocks",
//     "darkMode",
//     "showStylesPanel",
//   ]);

//   return isPageEqual && isOtherPropsEqual;
// };

// Wrap the inner component in React.memo with your custom comparator.
// const MemoizedPageStylesInner = memo(PageStylesInner, areEqual);

// ─────────────────────────────────────────────────────────────
// 3. Create the container component that extracts the data
//    (using your recoil hooks and custom hooks) and passes it
//    to the memoized inner component as props.
// ─────────────────────────────────────────────────────────────

const PageStyles = () => {
  const { setPage } = useSetPage();
  const page = usePage();
  const blocks = useRecoilValue(rPageBlocks);
  const darkMode = useRecoilValue(rDarkMode);
  const [showStylesPanel, setShowStylesPanel] =
    useRecoilState(rShowStylesPanel);

  return (
    <PageStylesInner
      page={page}
      blocks={blocks}
      darkMode={darkMode}
      showStylesPanel={showStylesPanel}
      setShowStylesPanel={setShowStylesPanel}
      setPage={setPage}
    />
  );
};

const Header = styled.div`
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid ${colors.pitchBorder};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelHeader = ({
  label,
  expanded,
  setExpanded,
  direction,
  breadcrumbs,
  deleteBlock,
}) => {
  return (
    <Header>
      {breadcrumbs ? (
        <Breadcrumb fontStyle="headingMd" items={breadcrumbs} />
      ) : (
        <Text data={{ text: label, fontStyle: "headingMd" }} />
      )}

      {deleteBlock ? (
        <CloseButtonContainer onClick={deleteBlock}>
          <Icon
            data={{
              icon: "FiTrash",
              size: 17,
              hover: true,
              color: "var(--grey7)",
            }}
          />
        </CloseButtonContainer>
      ) : (
        <CloseButton
          expanded={expanded}
          setExpanded={setExpanded}
          direction={direction}
        />
      )}
    </Header>
  );
};

const CloseButton = ({ expanded, setExpanded, direction = "right" }) => {
  const icon = direction === "right" ? "FiChevronRight" : "FiChevronLeft";

  return (
    <>
      <Tooltip
        anchorSelect={`.close-styles-button`}
        place="left"
        style={{ zIndex: 9999 }}
      >
        Hide
      </Tooltip>
      <CloseButtonContainer
        onClick={() => setExpanded(!expanded)}
        className="close-styles-button"
      >
        <Icon
          data={{
            icon,
            size: 22,
            hover: true,
            color: "var(--grey7)",
          }}
        />
      </CloseButtonContainer>
    </>
  );
};

const CloseButtonContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  padding: 2px;
  border-radius: 10px;
  border: 1px solid var(--grey21);
  &:hover {
    background: var(--grey1);
  }
`;

export default PageStyles;
