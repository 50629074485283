import * as AdminComponents from "app/adminApp/components";
import * as Components from "app/components";

import { Button, Icon, Text } from "app/components";
import { get, startCase } from "lodash";

import { colors } from "app/utils/theme";
import styled from "styled-components";

const MultiRecordEditor = ({ data }) => {
  const columns = get(data, "columns", []);
  const rows = get(data, "value", []);

  const addRow = () => {
    data.onChange([...rows, get(data, "newObject", {})]);
  };

  const updateRow = (index, k, v) => {
    data.onChange(
      rows.map((r, i) => {
        if (i === index) {
          return { ...r, [k]: v };
        }
        return r;
      })
    );
  };

  return (
    <>
      <TableBorderContainer>
        <TableContainer>
          <Header>
            {columns.map((column) => (
              <HeaderItem>
                <Text
                  data={{
                    text: startCase(column.key),
                    fontStyle: "headingXs",
                    cursor: "pointer",
                  }}
                />
              </HeaderItem>
            ))}
            <div style={{ width: "30px" }}></div>
          </Header>

          {rows.map((row, rowIndex) => (
            <Row>
              {columns.map((column) => {
                const FormInput = get(
                  { ...Components, ...AdminComponents },
                  column.componentId
                );
                return (
                  <TableCell>
                    <FormInput
                      data={{
                        ...column,
                        width: "100%",
                        value: get(row, column.key),
                        onChange: (v) => updateRow(rowIndex, column.key, v),
                      }}
                    />
                  </TableCell>
                );
              })}
              <div style={{ width: "30px" }}>
                <Icon
                  data={{
                    icon: "FiTrash",
                    hover: true,
                    onClick: () =>
                      data.onChange(rows.filter((r, ri) => rowIndex !== ri)),
                    color: "var(--grey7)",
                    size: 18,
                  }}
                />
              </div>
            </Row>
          ))}

          {rows.length === 0 && (
            <Row>
              <Text
                data={{
                  text: "No Data",
                  fontStyle: "bodySm",
                  cursor: "pointer",
                }}
              />
            </Row>
          )}
        </TableContainer>
      </TableBorderContainer>
      <Button
        data={{
          text: data.buttonText || "Add",
          onClick: addRow,
          icon: "FiPlus",
          size: "small",
          type: "basic",
          margin: "15px 0 0 0 ",
        }}
      />
    </>
  );
};

export default MultiRecordEditor;

const TableBorderContainer = styled.div`
  border: 1px solid var(--grey21);
  border-radius: 10px;
`;

const TableContainer = styled.div``;

const Row = styled.div`
  display: flex;
  gap: 15px;
  padding: 10px 15px 10px 15px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  gap: 15px;
  padding: 10px 15px 10px 15px;
  flex: 1;
  background: #f6f6f7;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid var(--grey21);
`;

const HeaderItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex: 1;
`;

const TableCell = styled.div`
  flex: 1;
`;
