import { Column, Modal, Row, Spinner, Text } from "app/components";
import SettingsSection, { CardSection } from "./SettingsSection";
import { get, startCase } from "lodash";
import { useEffect, useState } from "react";

import { TableComponent } from "app/renderingApp/blocks/Table";
import { apiRequest } from "app/utils/apiRequests";
import moment from "moment";

const ErrorLog = () => {
  const [editId, setEditId] = useState(null);

  const [errors, setErrors] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    apiRequest.get("/errors/").then((res) => {
      setErrors(get(res, "data", []));
      setIsFetching(false);
    });
  }, []);

  const processedErrors = errors.map((p) => ({
    ...p,
    type: startCase(get(p, "type") || "Unknown"),
    system: startCase(get(p, "system") || "Unknown"),
    message: getMessage(p),
    date: `${moment(get(p, "date", "")).format("MMM D YYYY, h:mm a")} (${moment(
      get(p, "date", "")
    ).fromNow()})`,
    onClick: () => setEditId(p.id),
  }));

  const activeError = processedErrors.find((e) => e.id === editId);

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="App Errors"
      description="View a log of recent errors in your app"
      settingsCategory={"Advanced App Settings"}
    >
      <CardSection>
        {isFetching && <Spinner />}

        {!isFetching && (
          <TableComponent
            data={{
              preventDarkMode: true,
              rows: processedErrors,
              columns: [
                {
                  key: "system",
                  columnType: "badge",
                },
                {
                  key: "type",
                  columnType: "badge",
                },
                {
                  label: "Message",
                  key: "message",
                  truncate: 50,
                },
                {
                  key: "date",
                  truncate: 50,
                },
              ],
            }}
          />
        )}
      </CardSection>

      {editId && (
        <Modal
          header={{
            title: `Error ID: ${editId}`,
          }}
          hide={() => setEditId(null)}
        >
          <Column gap="20px">
            <Row alignItems="center" gap="10px">
              <Text
                data={{
                  text: "Date:",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Text
                data={{
                  text: get(activeError, "date", ""),
                  fontSize: 20,
                  fontWeight: 300,
                }}
              />
            </Row>
            <Row alignItems="center" gap="10px">
              <Text
                data={{
                  text: "System:",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Text
                data={{
                  text: get(activeError, "system", ""),
                  fontSize: 20,
                  fontWeight: 300,
                }}
              />
            </Row>
            <Row alignItems="center" gap="10px">
              <Text
                data={{
                  text: "Type:",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Text
                data={{
                  text: get(activeError, "type", ""),
                  fontSize: 20,
                  fontWeight: 300,
                }}
              />
            </Row>
            <div>
              <Text
                data={{
                  text: "Message:",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              />
              <Text
                data={{
                  text: get(activeError, "message", ""),
                  fontSize: 20,
                  fontWeight: 300,
                }}
              />
            </div>
          </Column>
        </Modal>
      )}
    </SettingsSection>
  );
};

export default ErrorLog;

const getMessage = (error) => {
  // Try getting 'error' key
  if (get(error, "message")) {
    return get(error, "message");
  }

  if (get(error, ["data", "message"])) {
    return get(error, ["data", "message"]);
  }

  return "Unknown";
};
