import {
  addSlash,
  filterBasedOnUserGroups,
  isFrontlyAdmin,
} from "app/utils/utils";
import { get, isEmpty, isNil } from "lodash";
import { rApp, rPages, rUser } from "app/utils/recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { errorNotification } from "app/utils/Notification";
import useModalStateData from "app/useModalStateData";
import useUtils from "../useUtils";

// Get the navigation items for the current user
export const useNavigationSections = () => {
  const app = useRecoilValue(rApp);
  const navigation = get(app, "navigation", {});

  const { setModalStack } = useModalStateData();

  const user = useRecoilValue(rUser);

  const pages = useRecoilValue(rPages);

  const location = useLocation();
  const path = get(location, "pathname");

  const { passesDisplayConditions } = useUtils();

  const navigate = useNavigate();

  const navigationPageOrder = get(navigation, "pageOrder", []);
  const navigationSections = get(navigation, "sections", []);

  const customLinks = get(navigation, "customLinks", []);

  let combinedItems = [
    ...pages,
    ...customLinks.map((l) => ({
      ...l,
      isCustomLink: true,
      name: l.label,
      published: true,
    })),
  ];

  // Filter based on user groups
  combinedItems = filterBasedOnUserGroups({
    items: combinedItems,
    app,
    user,
    passesDisplayConditions,
  });

  const loggedInUser = !isEmpty(user);

  const getIsActive = (p, pi) => {
    if (p.isCustomLink) return false;

    // Fake it for the admin
    if (isFrontlyAdmin && pi === 0) return true;

    return addSlash(path) === addSlash(p.route);
  };

  const finalPages = combinedItems
    .filter((p) => loggedInUser || !p.requireLogin) // If user is not logged in, only show pages that don't require login
    .filter(
      (p) => p.published || ["owner", "admin"].includes(get(user, "role"))
    )
    .filter((p) =>
      passesDisplayConditions({ conditions: get(p, "displayConditions", []) })
    )
    .map((p, pi) => {
      const matchingConfig = navigationPageOrder.find((v) => v.value === p.id);

      return {
        icon: p.icon,
        label: p.name,
        section: get(matchingConfig, "section"),
        onClick: () => {
          if (isFrontlyAdmin) {
            errorNotification("Links are disabled in admin preview");
            return;
          }

          if (p.isCustomLink) {
            if (p.link.includes("http") || p.link.includes("www.")) {
              window.open(p.link);
            } else {
              navigate(addSlash(p.link));
            }
          } else {
            setModalStack([]);
            navigate(addSlash(p.route));
          }
        },
        pageId: p.id,
        active: getIsActive(p, pi),
      };
    });

  const sortedPages = sortPages(finalPages, navigationPageOrder);

  let sections = navigationSections.map((s) => ({
    ...s,
    items: sortedPages.filter((p) => p.section === s.id),
  }));

  const noSectionPages = sortedPages.filter(
    (p) => p.section === "" || !p.section || isNil(p.section)
  );

  const noSection =
    noSectionPages.length > 0
      ? [
          {
            label: null,
            items: noSectionPages,
          },
        ]
      : [];

  const finalSections = [...noSection, ...sections];

  return finalSections;
};

// Sort pages based on the page order defined by the admin
export const sortPages = (pages, pageOrder) => {
  const hiddenPageIds = pageOrder.filter((p) => p.hidePage).map((p) => p.value);

  // Use a Set to keep track of page ids that are in pageOrder
  const pageOrderIds = new Set(pageOrder.map((p) => p.value));

  // Add pages that are in the pageOrder to sortedPages in the given order
  const sortedPages = pageOrder
    .map((po) => {
      return pages.find((p) => p.pageId === po.value) || null;
    })
    .filter((p) => p !== null); // Filter out any null entries

  // Add pages that are NOT in the pageOrder to sortedPages
  pages.forEach((page) => {
    if (page.isCustomLink) {
      if (!pageOrderIds.has(page.id)) {
        sortedPages.push(page);
      }
    } else {
      if (!pageOrderIds.has(page.pageId)) {
        sortedPages.push(page);
      }
    }
  });

  return sortedPages.filter((p) => !hiddenPageIds.includes(p.pageId));
};
