import { Button, Row, SearchBar, Tabs, Text } from "app/components";
import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "../settings/components/SettingsCard";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, startCase } from "lodash";
import { getDisplayHeaders, getUrlParameter, safeLower } from "app/utils/utils";
import {
  rApp,
  rConfirmationModalData,
  rNewDataSourceModalData,
  rRefreshSheetData,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import { AdminForm } from "../components";
import AdminWrapper from "../home/AdminWrapper";
import AirtableAuth from "../settings/AirtableAuth";
import CardGrid from "../components/CardGrid";
import DateFieldsModal from "./DateFieldsModal";
import { EmptyStateDashboard } from "app/renderPage/EmptyStates";
import GoogleIntegrationNew from "./GoogleIntegrationNew";
import { InfoBox } from "../settings/InfoBox";
import { Label } from "app/adminApp/home/Pages";
import NewDataSourceModal from "./NewDataSourceModal/NewDataSourceModal";
import RelationalData from "./RelationalData";
import { apiRequest } from "app/utils/apiRequests";
import { colors } from "app/utils/theme";
import { dataSourceIconMap } from "./utils";
import mixpanel from "mixpanel-browser";

const Spreadsheets = () => {
  const [app, setApp] = useRecoilState(rApp);

  const navigate = useNavigate();

  const location = useLocation();

  const tab = getUrlParameter("tab", location) || "dataSources";

  const code = getUrlParameter("code", location);

  const setNewDataSource = useSetRecoilState(rNewDataSourceModalData);

  const setRefreshSheetData = useSetRecoilState(rRefreshSheetData);

  const supabaseProjectId = get(app, "supabase_project_id");

  useEffect(() => {
    if (code && tab !== "accountConnections") {
      const search = get(location, "search");
      const newRoute = `/spreadsheets${search}&tab=accountConnections`;
      navigate(newRoute);
    }
  }, []);

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const [search, setSearch] = useState("");

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const filteredSheets = savedSpreadsheets.filter(
    (sheet) =>
      search === "" ||
      safeLower(sheet.title).includes(safeLower(search)) ||
      safeLower(sheet.tab_name).includes(safeLower(search))
  );

  const disconnect = () => {
    setConfirmationModalData({
      title: "Disconnect Google Sheets",
      text: "Are you sure you want to disconnect Google Sheets? This will break any existing pages using your spreadsheets.",
      hide: null,
      confirm: () => {
        // CONFIRMED DELETE
        setIsDisconnecting(true);

        apiRequest
          .post(`/app_settings/`, {
            google_sheets_access_token: "",
            google_sheets_refresh_token: "",
          })
          .then(() => {
            setApp({
              google_sheets_connected: false,
            });

            mixpanel.track("Disconnected Google Sheet");

            successNotification("Google Sheets Disconnected");
          })
          .catch((error) => {
            errorNotification("Could not disconnect your Google Sheets");
          })
          .finally(() => {
            setIsDisconnecting(false);
          });
      },
    });
  };

  const unlinkSheet = (sheetId) => {
    apiRequest
      .delete("/sheet_management/", {
        data: { id: sheetId },
      })
      .then(() => {
        setSavedSpreadsheets(savedSpreadsheets.filter((s) => s.id !== sheetId));
        successNotification("Data Source Removed");
        mixpanel.track("Unlink Google Sheet");
      });
  };

  const dataSourcesContent = (
    <div>
      {savedSpreadsheets.length === 0 && (
        <EmptyStateDashboard
          title="You don't have any data sources"
          subtitle="To build a live app, you will need to add your own data. Get started by clicking the button below."
          graphic={
            "https://res.cloudinary.com/frontly/image/upload/v1716245131/Graphs_y2esoe.svg"
          }
          buttonText="Add Data Source"
          onClick={() => setNewDataSource({ type: null })}
        />
      )}

      {savedSpreadsheets.length > 0 && (
        <CardGrid
          noResultsName={"data sources"}
          items={filteredSheets.map((s) => {
            const service = get(s, "service", "google");

            let dropdownOptions = [
              {
                label: "Remove Data Source",
                icon: "FiTrash",
                onClick: () =>
                  setConfirmationModalData({
                    title: "Remove Data Source",
                    text: "Are you sure you want to remove this data source? This is irreversible and it will break any pages using it in your app.",
                    confirm: () => unlinkSheet(s.id),
                  }),
              },
            ];

            if (!s.deleted && service !== "frontly_db") {
              dropdownOptions.push({
                icon: "FiRefreshCcw",
                label: "Refresh Data & Headers",
                onClick: () => setRefreshSheetData({ sheetId: s.id }),
              });
            }

            if (s.service === "supabase") {
              dropdownOptions.push({
                icon: "FiExternalLink",
                label: "Open In Supabase",
                onClick: () =>
                  window.open(
                    `https://supabase.com/dashboard/project/${supabaseProjectId}`
                  ),
              });
            } else {
              dropdownOptions.push({
                icon: "FiExternalLink",
                label: "Open In Google",
                onClick: () =>
                  window.open(
                    `https://docs.google.com/spreadsheets/d/${s.spreadsheet_id}/edit#gid=${s.tab_id}`
                  ),
              });
            }

            let badges = [
              {
                color: "#F0F0F0",
                text: startCase(service),
              },
              {
                color: s.allow_public_access ? "#AFE6F1" : "#F0F0F0",
                text: s.allow_public_access ? "Public" : "Private",
              },
            ];

            if (s.deleted) {
              badges.push({
                color: "#f1afaf",
                text: "Not Found In Google",
              });
            }

            return {
              title: s.title,
              icon: get(dataSourceIconMap, service),
              description: `${
                getDisplayHeaders(get(s, "headers", [])).length
              } columns`,
              onClick: () => {
                s.deleted
                  ? errorNotification(
                      "This sheet tab can no longer be found in Google. Did you delete it?"
                    )
                  : navigate(`/spreadsheet/${s.id}`);
              },
              badges,
              options: dropdownOptions,
            };
          })}
          itemsPerPage={12}
        />
      )}
    </div>
  );

  const [supabaseChanges, setSupabaseChanges] = useState(false);

  const saveSupabase = () => {
    apiRequest.post("/app_settings/", {
      supabase_project_id: supabaseProjectId,
      supabase_key: get(app, "supabase_key"),
      supabase_host: get(app, "supabase_host"),
      supabase_database_password: get(app, "supabase_database_password"),
    });
    setSupabaseChanges(false);
    successNotification("Supabase settings saved");
  };

  const googleConnected = get(app, "google_sheets_connected");

  const databaseSettingsUrl = `https://supabase.com/dashboard/project/${supabaseProjectId}/settings/database`;

  return (
    <AdminWrapper padding="40px">
      <NewDataSourceModal />
      <DateFieldsModal />

      <Label>Data Sources</Label>
      <Row
        margin="15px 0 30px 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          data={{
            margin: "15px 0 0 0",
            fontSize: 20,
            fontWeight: 600,
            tabs: [
              {
                label: "Data Sources",
                active: tab === "dataSources",
                onClick: () => navigate("/spreadsheets?tab=dataSources"),
              },
              {
                label: "Relational Data",
                active: tab === "dataRelations",
                onClick: () => navigate("/spreadsheets?tab=dataRelations"),
                hidden: savedSpreadsheets.length === 0,
              },
              {
                label: "Account Connections",
                active: tab === "accountConnections",
                onClick: () => navigate("/spreadsheets?tab=accountConnections"),
              },
            ],
          }}
        />
        {tab === "dataSources" && (
          <Row gap="15px">
            <SearchBar
              data={{
                value: search,
                placeholder: "Search data sources",
                onChange: (v) => setSearch(v),
              }}
            />
            <Button
              data={{
                text: "Add Data Source",
                icon: "FiPlus",
                onClick: () => setNewDataSource({ type: null }),
              }}
            />
          </Row>
        )}
      </Row>

      {tab === "dataSources" && dataSourcesContent}
      {tab === "accountConnections" && (
        <ColumnsRow>
          <CardsColumn>
            <SettingsCard
              label="Google Sheets"
              description="Connect your app to your Google spreadsheets to use as a database"
              articleLink=""
            >
              {!googleConnected && <GoogleIntegrationNew />}

              {googleConnected && (
                <Button
                  data={{
                    isFetching: isDisconnecting,
                    text: "Google Sheets Connected - Click To Disconnect",
                    icon: "FiAlertTriangle",
                    type: "basic",
                    onClick: disconnect,
                  }}
                />
              )}
            </SettingsCard>
            <SettingsCard
              label="Airtable"
              description="Connect an Airtable base to use as a Frontly app database"
              articleLink=""
            >
              <AirtableAuth />
            </SettingsCard>
          </CardsColumn>
          <CardsColumn>
            <SettingsCard
              label="Supabase"
              description="Connect your app to your Supabase database"
              articleLink=""
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={[
                  {
                    id: "supabase_project_id",
                    label: "Project ID",
                    hint: "Your Supabase Project ID (found in your Supabase dashboard).",
                    value: supabaseProjectId,
                  },
                  {
                    id: "supabase_key",
                    label: "Public Key",
                    hint: "A secure API key used by Frontly to make requests to your Supabase account.",
                    type: "password",
                    value: get(app, "supabase_key"),
                  },
                  {
                    id: "supabase_host",
                    label: "Host",
                    hint: "The host value for your database, found in your Supabase database settings.",
                    value: get(app, "supabase_host"),
                  },
                  {
                    id: "supabase_database_password",
                    label: "Database Password",
                    hint: "The password to your database, used when creating the project. Found in your Supabase database settings. Can be reset if you don't know it, but this could have consequences if you have other connections to your database.",
                    type: "password",
                    value: get(app, "supabase_database_password"),
                  },
                ]}
                submitText={"Save Changes"}
                submit={supabaseChanges && saveSupabase}
                orientation={"vertical"}
                onChange={(k, v) => {
                  setSupabaseChanges(true);
                  setApp({
                    ...app,
                    [k]: v,
                  });
                }}
              />
              {supabaseProjectId && (
                <Text
                  data={{
                    margin: "10px 0 0 0",
                    text: "Open Supabase Database Settings (For Host and Password)",
                    onClick: () => window.open(databaseSettingsUrl),
                    cursor: "pointer",
                    color: "var(--primary-admin)",
                  }}
                />
              )}
              <InfoBox margin="15px 5px 5px 5px">
                Supabase integration is in beta mode, which means it's under
                development and is subject to change and potential bugs.
              </InfoBox>
            </SettingsCard>
          </CardsColumn>
        </ColumnsRow>
      )}

      {tab === "dataRelations" && <RelationalData />}
    </AdminWrapper>
  );
};

export default Spreadsheets;
