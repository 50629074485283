import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { Icon, Menu, Modal, Row, Text } from "app/components";
import { colors, spacing } from "app/utils/theme";
import { get, startCase } from "lodash";

import AdminForm from "./AdminForm";
import { arrayMove } from "app/utils/utils";
import styled from "styled-components";
import { useState } from "react";

const GoogleSheetCellPicker = ({ data }) => {
  const [editField, setEditField] = useState(null);
  const { onChange } = data;
  const allCells = get(data, "value", []);

  const onAddCellClick = () => {
    onChange([...allCells, { label: "Label" }]);
  };

  const onChangeCellData = (k, v) => {
    let allCellsCpy = JSON.parse(JSON.stringify(allCells));
    allCellsCpy[editField][k] = v;
    onChange(allCellsCpy);
  };

  const activeField = get(allCells, editField);

  const fields =
    data.id === "inputs"
      ? [
          {
            id: "label",
            componentId: "Input",
            preventExtras: true,
            label: "Label",
            value: get(activeField, "label"),
            section: "Basics",
          },
          {
            id: "section",
            componentId: "Input",
            preventExtras: true,
            label: "Section",
            value: get(activeField, "section"),
            section: "Basics",
            hint: "Create a section to organize fields into groups",
          },
          {
            id: "cell",
            label: "Google Sheet Cell",
            placeholder: "C5",
            hint: "Enter a cell name using Google Sheet notation, like A2 or F21, etc.",
            value: get(activeField, "cell"),
            section: "Basics",
          },
          {
            id: "checkboxDescription",
            label: "Description",
            hint: "Description to display for your checkbox",
            componentId: "Input",
            required: true,
            defaultValue: "",
            displayCondition: (f) => get(f, "componentId") === "Checkbox",
          },
          {
            id: "componentId",
            label: "Field Type",
            hint: "Define the input for users to use in the form.",
            componentId: "Select",
            required: true,
            defaultValue: "Input",
            section: "Basics",
            value: get(activeField, "componentId"),
            options: [
              { label: "Input", value: "Input" },
              { label: "Select", value: "Select" },
              { label: "Switch", value: "Switch" },
              { label: "Checkbox", value: "Checkbox" },
              { label: "Date", value: "DateTimePicker" },
            ],
          },
          {
            id: "options",
            label: "Options",
            componentId: "DataGrid",
            orientation: "vertical",
            section: "Basics",
            columnSpan: 2,
            hint: "Define the options to appear in your select dropdown",
            value: get(activeField, "options", []),
            columns: [
              {
                key: "label",
                componentId: "Input",
              },
              {
                key: "value",
                componentId: "Input",
              },
            ],
            displayCondition: () =>
              get(activeField, "componentId") === "Select",
          },
          {
            id: "description",
            label: "Description",
            hint: "Display a description for this field",
            componentId: "Input",
            required: false,
            defaultValue: "",
            columnSpan: 2,
            section: "Additional Options",
            value: get(activeField, "description"),
          },
          {
            id: "hint",
            label: "Hint",
            hint: "Display a hint for this field",
            componentId: "Input",
            required: false,
            defaultValue: "",
            section: "Additional Options",
            value: get(activeField, "hint"),
          },
          {
            id: "placeholder",
            label: "Placeholder",
            hint: "Display a placeholder for this field",
            componentId: "Input",
            required: false,
            defaultValue: "",
            section: "Additional Options",
            value: get(activeField, "placeholder"),
          },
          {
            id: "defaultValue",
            label: "Default Value",
            hint: "Enter a default value for this field",
            componentId: "Input",
            required: false,
            defaultValue: "",
            section: "Additional Options",
            value: get(activeField, "defaultValue"),
          },
          {
            id: "conditions",
            label: "Display Conditions",
            componentId: "DisplayConditions",
            orientation: "vertical",
            allowCalculatorInput: true,
            value: get(activeField, "conditions"),
          },
          {
            id: "validation",
            label: "Validation",
            componentId: "FormValidation",
            activeField,
            customOnChange: (k, v) => onChangeCellData(k, v),
          },
        ]
      : [
          {
            id: "label",
            componentId: "DynamicString",
            preventExtras: true,
            label: "Label",
            value: get(activeField, "label"),
          },
          {
            id: "cell",
            label: "Google Sheet Cell",
            placeholder: "C5",
            hint: "Enter a cell name using Google Sheet notation, like A2 or F21, etc.",
            value: get(activeField, "cell"),
          },
        ];

  return (
    <div>
      {editField !== null && (
        <Modal
          header={{
            title: data.id === "inputs" ? "Edit Input Field" : "Edit Result",
          }}
          minWidth="500px"
          hide={() => setEditField(null)}
          buttons={[
            {
              id: "delete",
              componentId: "Button",
              label: "Delete Field",
              text: "Delete",
              type: "basic",
              onClick: () => {
                onChange(allCells.filter((c, ci) => ci !== editField));
                setEditField(null);
              },
            },
          ]}
        >
          <AdminForm
            onChange={onChangeCellData}
            gridLayout
            gridLayoutSize={"200px"}
            sectionPadding={"15px 0px 0px 0px"}
            firstSectionPadding={"0px"}
            borderBottom={false}
            submitText={"Save"}
            fields={fields.filter(
              (f) =>
                !f.displayCondition ||
                (f.displayCondition && f.displayCondition(f))
            )}
          />
        </Modal>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Text data={{ text: startCase(data.id), fontStyle: "bodyMd" }} />
        <Icon
          data={{
            icon: "FiPlus",
            color: "var(--grey7)",
            size: 14,
            hover: true,
            onClick: onAddCellClick,
          }}
        />
      </div>

      {allCells.length > 0 && (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: `1px solid var(--grey21)`,
            background: "white",
          }}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          onDrop={(e) => {
            const { addedIndex, removedIndex } = e;
            const movedItems = arrayMove(allCells, removedIndex, addedIndex);
            data.onChange(movedItems);
          }}
        >
          {allCells.map((f, idx) => (
            <Draggable key={f.key}>
              <Field {...{ field: f, setEditField, idx }} />
            </Draggable>
          ))}
        </Container>
      )}

      {allCells.length === 0 && <Text data={{ text: "No Cells" }} />}
    </div>
  );
};

export default GoogleSheetCellPicker;

const Field = ({ field, setEditField, idx }) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <FieldContainer
      onMouseEnter={() => {
        if (!showEdit) {
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => {
        if (showEdit) {
          setShowEdit(false);
        }
      }}
      className="drag-item"
      onClick={() => setEditField(idx)}
    >
      <Row alignItems="center">
        <Icon
          data={{
            icon: "RxDragHandleDots2",
            color: "var(--grey21)",
            size: 18,
          }}
        />
        <Text
          data={{
            text: startCase(field.label),
            cursor: "pointer",
            margin: "0 3px 0 3px",
          }}
        />
        {showEdit && (
          <Icon
            data={{
              icon: "FiEdit",
              color: "var(--grey7)",
              size: 14,
            }}
          />
        )}
      </Row>
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  padding: ${spacing.s2} 0px ${spacing.s2} 0px;
  border-bottom: 1px solid var(--grey21);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: var(--grey1);
  }
  :not(:last-child) {
    border-bottom: 1px solid var(--grey1);
  }
`;
