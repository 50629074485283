import { Button, Column, Modal } from "app/components";
import { fixBlocks, safeArray } from "app/utils/utils";
import { rCustomBlocks, rPageActions, rPageBlocks } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AdminForm } from ".";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { getBlockBundle } from "../getBlockBundle";
import { successNotification } from "app/utils/Notification";
import useActiveBlock from "app/utils/useActiveBlock";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CustomBlock = ({ isNew }) => {
  const activeBlock = useActiveBlock();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const setCustomBlocks = useSetRecoilState(rCustomBlocks);

  const pageBlocks = useRecoilValue(rPageBlocks);
  const pageActions = useRecoilValue(rPageActions);

  const formType = get(formData, "type", "standard");

  const saveAsCustomBlock = async () => {
    setIsSaving(true);

    const blocks = fixBlocks(pageBlocks);

    // If new, use this
    let newData = {
      blocks: [
        {
          id: 1,
          componentId: "Column",
        },
      ],
    };

    // If not new, use this
    if (!isNew) {
      newData = getBlockBundle({
        block: activeBlock,
        actions: pageActions,
        blocks,
        blocksToDuplicate: blocks,
      });
    }

    const newBlock = {
      ...newData,
      name: get(formData, "name", ""),
      reusable: formType === "reusable" || formType === "repeating",
      repeating: formType === "repeating",
      description: get(formData, "description", ""),
    };

    const newBlockRes = await apiRequest.post("/custom_blocks/", {
      config: newBlock,
    });

    const newBlockObj = get(newBlockRes, "data", {});

    // Add new block to recoil
    setCustomBlocks((prev) => [...safeArray(prev), newBlockObj]);

    setFormData({});

    successNotification("Saved as custom block");

    setShowModal(false);

    setIsSaving(false);

    if (isNew) {
      navigate(`/custom-block/${get(newBlockObj, "id", "")}`);
    }
  };

  let fields = [
    {
      id: "name",
      label: "Name",
      description: "Give your custom block a memorable name",
      value: get(formData, "name", ""),
    },
    {
      id: "description",
      label: "Description",
      description: "Describe what this custom block does",
      value: get(formData, "description", ""),
      displayCondition: () => !isNew,
    },
    {
      id: "type",
      label: "Custom Block Type",
      componentId: "SelectToggle",
      description: "Warning: This cannot be changed after creation",
      value: formType,
      tabs: [
        {
          label: "Standard",
          value: "standard",
        },
        {
          label: "Reusable",
          value: "reusable",
        },
        {
          label: "Repeating",
          value: "repeating",
        },
      ],
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition())
  );

  return (
    <>
      {showModal && (
        <Modal
          minWidth="400px"
          hide={() => setShowModal(false)}
          header={{
            title: isNew ? "New Custom Block" : "Save Custom Block",
          }}
        >
          <Column gap="15px">
            <AdminForm
              sectionPadding={"0px"}
              onChange={(k, v) =>
                setFormData({
                  ...formData,
                  [k]: v,
                })
              }
              fields={fields}
            />
            <Button
              data={{
                text: isNew ? "Create Custom Block" : "Save Custom Block",
                onClick: saveAsCustomBlock,
                margin: "15px 0 0 0",
                isFetching: isSaving,
              }}
            />
          </Column>
        </Modal>
      )}
      <Button
        data={{
          text: isNew ? "New Custom Block" : "Save As Custom Block",
          onClick: () => setShowModal(true),
          icon: isNew ? "FiPlus" : "FiCopy",
          type: isNew ? "primary" : "basic",
          size: isNew ? "medium" : "small",
        }}
      />
    </>
  );
};

export default CustomBlock;
