import { Button, Text } from "app/components";

import { colors } from "app/utils/theme";
import { resizeImage } from "app/utils/utils";
import { startCase } from "lodash";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ImageColorTop = styled.div`
  background-color: hsl(${(p) => p.hue}, 75%, 40%, 0.5);
  height: ${(p) => (p.small ? "3px" : "7px")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.small ? "3px" : "4px")};
  border-radius: 5px 5px 0 0;
`;

const ImageColorBar = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  height: ${(p) => (p.small ? "2px" : "3px")};
  width: 33%;
  border-radius: 4px;
`;

const PreviewContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: hsl(${(p) => p.hue}, 63%, 30%, 0.3);
  opacity: ${(p) => (p.showPreview ? "1.0" : "0.0")};
  transition: 300ms;
  cursor: pointer;
  border-radius: 8px;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.isDashboard &&
    `
  min-width: 380px;
  max-height: 330px;
  min-height: 330px;
  `}
`;

const ImageContainer = styled.div`
  background-color: hsl(${(p) => p.hue}, 63%, 50%, 0.2);
  overflow: hidden;
  padding: ${(p) => (p.small ? "15px 30px 15px 30px" : "25px 50px 25px 50px")};
  border-radius: 10px;
  transition: 300ms;
  position: relative;
  max-height: 320px;
  min-height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(14, 13, 20, 0.1) 18px 18px 18px,
    rgba(14, 13, 20, 0.05) 12px 18px 48px;
`;

export const Template = ({ data, isPublic, onClick, small, isDashboard }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const navigate = useNavigate();

  return (
    <TemplateContainer index={data.key} isDashboard={isDashboard}>
      <ImageContainer
        small={small}
        hue={data.hue}
        onMouseEnter={() => setShowPreview(true)}
        onMouseLeave={() => setShowPreview(false)}
      >
        <PreviewContainer showPreview={showPreview} hue={data.hue}>
          {isPublic ? (
            <Button
              data={{
                text: "Use Template",
                width: "140px",
                padding: "13px",
                isFetching,
                onClick: () => navigate(`/app_templates/${data.key}`),
              }}
            />
          ) : (
            <Button
              data={{
                text: "View details",
                width: "140px",
                padding: "13px",
                isFetching,
                onClick,
              }}
            />
          )}

          <Button
            data={{
              text: "See live demo",
              type: "basic",
              width: "140px",
              padding: "13px",
              onClick: () => window.open(`https://${data.key}.frontly.ai`),
            }}
          />
        </PreviewContainer>

        <div>
          <ImageColorTop hue={data.hue} small={small}>
            <ImageColorBar small={small} />
          </ImageColorTop>
          <Image
            src={resizeImage({
              url: data.screenshot,
              height: 350,
              forceJpg: true,
            })}
          />
        </div>
      </ImageContainer>

      <Text
        data={{
          text: data.name || startCase(data.key),
          fontStyle: "headingMd",
          margin: "15px 0 3px 0",
        }}
      />
      <Text
        data={{
          text: data.description,
          fontStyle: "bodyMd",
          color: colors.grey3,
        }}
      />
    </TemplateContainer>
  );
};
