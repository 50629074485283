import { Row, Text } from "app/components";
import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "./components/SettingsCard";
import { rApp, rPages, rSubscription } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import { InfoBox } from "./InfoBox";
import { default as NavigationPreview } from "app/renderingApp/navigation/Navigation";
import SettingsSection from "./SettingsSection";
import UserGroupPermissions from "../components/UserGroupPermissions";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Navigation = () => {
  const pages = useRecoilValue(rPages);

  const [app, setApp] = useRecoilState(rApp);

  const [changes, setChanges] = useState({});

  const [permissionsAnchor, setPermissionsAnchor] = useState(null);

  const subscription = useRecoilValue(rSubscription);

  const navigation = get(app, "navigation", {});
  const [selectedCustomLink, setSelectedCustomLink] = useState(null);

  const navigationType = get(navigation, "type", "sidebar");
  const textColor = get(navigation, "textColor", "dark");
  const pageOrder = get(navigation, "pageOrder", []);

  const newNavigation = get(app, "new_navigation", false);

  const [navigationLogoChanged, setNavigationLogoChanged] = useState(false);

  const save = (type) => {
    setChanges({
      ...changes,
      [type]: null,
    });
    successNotification("Saved");

    let updateObj = {
      navigation,
    };

    if (navigationLogoChanged) {
      updateObj.navigation_logo = get(app, "navigation_logo");
    }

    if (type === "new_navigation") {
      updateObj.new_navigation = get(app, "new_navigation");
    }

    apiRequest.post("/app_settings/", updateObj);
    setNavigationLogoChanged(false);
  };

  const links = get(navigation, "customLinks", []);

  // This is copied because it's different than the one needed for end navigation
  const sortPages = (pages, pageOrder) => {
    // Use a Set to keep track of page ids that are in pageOrder
    const pageOrderIds = new Set(pageOrder.map((p) => p.value));

    // Add pages that are in the pageOrder to sortedPages in the given order
    const sortedPages = pageOrder
      .map((po) => {
        const test =
          pages.find((p) => {
            if (p.isCustomLink) {
              return p.id === po.value;
            }

            return p.pageId === po.value;
          }) || null;

        return test;
      })
      .filter((p) => p !== null); // Filter out any null entries

    // Add pages that are NOT in the pageOrder to sortedPages
    pages.forEach((page) => {
      if (page.isCustomLink) {
        if (!pageOrderIds.has(page.id)) {
          sortedPages.push(page);
        }
      } else {
        if (!pageOrderIds.has(page.pageId)) {
          sortedPages.push(page);
        }
      }
    });

    return sortedPages;
  };

  let combinedItems = [
    ...pages.map((p) => ({ ...p, pageId: p.id })),
    ...links.map((l) => ({ ...l, isCustomLink: true, name: l.label })),
  ];

  const pageOptions = sortPages(combinedItems, pageOrder).map((page) => {
    const matchingConfig = pageOrder.find((v) => v.value === page.id);
    return {
      value: page.id,
      label: page.name,
      section: get(matchingConfig, "section"),
      hidePage: get(matchingConfig, "hidePage", false),
    };
  });

  const linkStyles = [
    {
      id: "accentType",
      label: "Accent Type",
      hint: "The type of accent to use for the navigation items",
      value: get(navigation, "accentType", "badge"),
      defaultValue: "badge",
      orientation: "horizontal",
      componentId: "SelectToggle",
      width: "250px",
      tabs: [
        {
          label: "Badge",
          value: "badge",
        },
        {
          label: "Color",
          value: "color",
        },
      ],
      displayCondition: () => newNavigation,
    },
    {
      id: "textColor",
      label: "Text Color",
      componentId: "SelectToggle",
      hideEmptyItem: true,
      value: textColor,
      width: "250px",
      tabs: [
        {
          label: "Light",
          value: "light",
        },
        {
          label: "Dark",
          value: "dark",
        },
      ],
    },
    {
      id: "linksPosition",
      label: "Links Position",
      componentId: "SelectToggle",
      value: get(navigation, "linksPosition", "center"),
      defaultValue: "center",
      hint: "Adjust the location of the links section in the header.",
      width: "250px",
      hideEmptyItem: true,
      tabs: [
        { label: "Left", value: "flex-start" },
        { label: "Center", value: "center" },
        { label: "Right", value: "flex-end" },
      ],
      displayCondition: () => navigationType === "header",
    },
    {
      id: "accentBorderRadius",
      label: "Accent Border Radius",
      componentId: "Input",
      hint: "The border radius of the navigation items",
      value: get(navigation, "accentBorderRadius"),
      defaultValue: 24,
      orientation: "horizontal",
      displayCondition: () =>
        get(navigation, "accentType", "badge") === "badge",
    },
  ];

  const containerStyles = [
    {
      id: "type",
      label: "Navigation Type",
      value: navigationType,
      hideEmptyItem: true,
      componentId: "SelectToggle",
      width: "250px",
      tabs: [
        {
          label: "Sidebar",
          value: "sidebar",
        },
        {
          label: "Header",
          value: "header",
        },
      ],
    },
    {
      id: "sidebarStyle",
      label: "Sidebar Style",
      hideEmptyItem: true,
      defaultValue: "default",
      value: get(navigation, "sidebarStyle"),
      componentId: "SelectToggle",
      width: "250px",
      tabs: [
        {
          label: "Default",
          value: "default",
        },
        {
          label: "Minimal",
          value: "minimal",
        },
      ],
      displayCondition: () => navigationType === "sidebar",
    },
    {
      id: "backgroundColor",
      label: "Background Color",
      componentId: "ColorPicker",
      value: get(navigation, "backgroundColor"),
    },
    {
      id: "showBorder",
      label: "Border",
      componentId: "Switch",
      defaultValue: false,
      width: "180px",
      value: get(navigation, "showBorder"),
    },
    {
      id: "borderColor",
      label: "Border Color",
      componentId: "ColorPicker",
      width: "180px",
      value: get(navigation, "borderColor"),
      displayCondition: () => get(navigation, "showBorder"),
    },
    {
      id: "showBoxShadow",
      label: "Box Shadow",
      componentId: "Switch",
      defaultValue: false,
      width: "180px",
      value: get(navigation, "showBoxShadow"),
      displayCondition: () => newNavigation,
    },
  ];

  const styling = [
    {
      id: "navigation_logo",
      label: "Navigation Logo",
      componentId: "ImageUpload",
      value: get(app, "navigation_logo"),
      displayCondition: () => subscription,
    },
    {
      id: "logoWidth",
      label: "Logo Width",
      componentId: "Input",
      hint: "Adjust the maximum width of the logo in the navigation bar to fit your logo better",
      value: get(navigation, "logoWidth", 150),
      defaultValue: 150,
      width: "120px",
      displayCondition: () => subscription,
    },
    {
      id: "logoHeight",
      label: "Logo Height",
      componentId: "Input",
      hint: "Adjust the height of the logo in the navigation bar to fit your logo better",
      value: get(navigation, "logoHeight", 40),
      defaultValue: 40,
      width: "120px",
      displayCondition: () => subscription,
    },
    {
      id: "menuPreference",
      label: "Menu Preference",
      componentId: "SelectToggle",
      value: get(navigation, "menuPreference", "dynamic"),
      defaultValue: "dynamic",
      hint: "Determines whether the menu should use the user's first initial, or the menu icon, or dynamic based on whether or not there are pages overflowing into the dropdown menu.",
      width: "250px",
      hideEmptyItem: true,
      tabs: [
        { label: "Dynamic", value: "dynamic" },
        { label: "User", value: "user" },
        { label: "Menu", value: "menu" },
      ],
      displayCondition: () => navigationType === "header",
    },
  ];

  const showOtherPreferences = navigationType === "header" || subscription;

  const sorting = [
    {
      id: "pageOrder",
      componentId: "ReorderItems",
      value: get(navigation, "pageOptions", []),
      options: pageOptions,
      orientation: "vertical",
    },
  ];

  const customLinks = [
    {
      id: "customLinks",
      componentId: "MultiForm",
      width: "300px",
      orientation: "vertical",
      labelSingular: "Custom Link",
      value: get(navigation, "customLinks", []),
      fields: [
        {
          key: "label",
          label: "Label",
          componentId: "Input",
        },
        {
          key: "link",
          label: "Link",
          componentId: "Input",
          hint: "Enter a full link with https for external links or enter a page or form URL",
        },
        {
          key: "icon",
          label: "Icon",
          orientation: "horizontal",
          componentId: "IconBrowser",
        },
        {
          id: "userGroupPermissions",
          label: "User Groups",
          componentId: "FormButton",
          hint: "Limit Block access to certain users",
          link: "https://help.frontly.ai/en/articles/7974437-user-groups",
          icon: "FiKey",
          width: "36px",
          section: "visibility",
          orientation: "horizontal",
          onClick: (e, data) => {
            setSelectedCustomLink(data.activeField);
            setPermissionsAnchor(e.currentTarget);
          },
          advanced: true,
        },
        {
          id: "displayConditions",
          label: "Display Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
          section: "visibility",
          hint: "Define custom conditions for when this Block should be visible",
          advanced: true,
        },
        // {
        //   key: "openNewWindow",
        //   label: "Open In New Window",
        //   orientation: "horizontal",
        //   componentId: "Switch",
        // },
      ],
    },
  ];

  const sections = [
    {
      id: "sections",
      componentId: "MultiForm",
      width: "300px",
      orientation: "vertical",
      labelSingular: "Section",
      value: get(navigation, "sections", []),
      fields: [
        {
          key: "label",
          label: "Label",
          componentId: "Input",
        },
        {
          key: "icon",
          label: "Icon",
          orientation: "horizontal",
          componentId: "IconBrowser",
        },
        {
          key: "expandable",
          componentId: "Switch",
          label: "Expandable",
          orientation: "horizontal",
          hint: "Allow the section to be expanded or contracted to show/hide items",
          defaultValue: true,
        },
        {
          key: "defaultExpanded",
          componentId: "Switch",
          label: "Default Expanded",
          orientation: "horizontal",
          hint: "Whether the expandable section should be defaulted to open or closed",
          defaultValue: true,
          displayCondition: (f) => f.expandable,
        },
      ],
    },
  ];

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="App Navigation"
      description="Configure the navigation bar for users to move between pages in your app"
      articleLink="https://help.frontly.ai/en/articles/8008379-app-navigation-sidebar-header"
      settingsCategory={"App Settings"}
    >
      {permissionsAnchor && (
        <UserGroupPermissions
          isUserPage
          noGroupsText="To determine which pages this user can access, define User Groups in Settings"
          anchorElement={permissionsAnchor}
          onClose={() => {
            setPermissionsAnchor(null);
          }}
          values={selectedCustomLink?.user_groups || []}
          onChange={(v) => {
            setChanges({ ...changes, customLinks: true });
            setApp({
              navigation: {
                ...navigation,
                customLinks: navigation.customLinks.map((item) =>
                  item.id === selectedCustomLink.id
                    ? { ...item, user_groups: v }
                    : item
                ),
              },
            });
          }}
        />
      )}
      {newNavigation && navigationType === "header" && (
        <HeaderPreview>
          <Text
            data={{
              text: "Preview",
              fontSize: 18,
              fontWeight: 600,
              margin: "0 0 15px 0",
            }}
          />
          <NavigationPreview />
        </HeaderPreview>
      )}
      <Row gap="30px">
        <ColumnsRow>
          {newNavigation && navigationType === "sidebar" && (
            <SidebarPreview>
              <Text
                data={{
                  text: "Preview",
                  fontSize: 18,
                  fontWeight: 600,
                  margin: "0 0 15px 0",
                }}
              />
              <NavigationPreview />
            </SidebarPreview>
          )}
          <CardsColumn>
            {get(app, "id") < 31593 && (
              <SettingsCard
                label="New Navigation System"
                description="Opt in to the new improved navigation system for this app"
              >
                <AdminForm
                  labelStyle="bodyMd"
                  sectionPadding="5px"
                  fields={[
                    {
                      key: "new_navigation",
                      label: "Enable New Navigation System",
                      componentId: "Switch",
                      value: newNavigation,
                    },
                  ]}
                  submitText={"Save Changes"}
                  submit={
                    get(changes, "new_navigation")
                      ? () => save("new_navigation")
                      : null
                  }
                  orientation={"horizontal"}
                  width="180px"
                  onChange={(k, v) => {
                    setChanges({ ...changes, new_navigation: true });
                    setApp({
                      new_navigation: v,
                    });
                  }}
                />
              </SettingsCard>
            )}
            <SettingsCard
              label="Container Styles"
              description="Adjust the navigation container styles"
            >
              <AdminForm
                labelStyle="bodyMd"
                sectionPadding="5px"
                fields={containerStyles.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Changes"}
                submit={
                  get(changes, "containerStyles")
                    ? () => save("containerStyles")
                    : null
                }
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, containerStyles: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>

            {showOtherPreferences && (
              <SettingsCard
                label="Other Preferences"
                description="Adjust other styling preferences"
              >
                <AdminForm
                  labelStyle="bodyMd"
                  sectionPadding="5px"
                  fields={styling.filter(
                    (f) =>
                      !f.displayCondition ||
                      (f.displayCondition && f.displayCondition())
                  )}
                  submitText={"Save Changes"}
                  submit={
                    get(changes, "otherPreferences")
                      ? () => save("otherPreferences")
                      : null
                  }
                  orientation={"horizontal"}
                  width="180px"
                  onChange={(k, v) => {
                    setChanges({ ...changes, otherPreferences: true });

                    // Handle awkwardly placed navigation logo field
                    if (k === "navigation_logo") {
                      setNavigationLogoChanged(true);
                      setApp({
                        navigation_logo: v,
                        navigation: {
                          ...navigation,
                          [k]: v,
                        },
                      });
                    } else {
                      setApp({
                        navigation: {
                          ...navigation,
                          [k]: v,
                        },
                      });
                    }
                  }}
                />
              </SettingsCard>
            )}

            <SettingsCard
              label="Custom Links"
              description="Add custom links to your navigation, such as external links"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={customLinks.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Custom Links"}
                submit={
                  get(changes, "customLinks") ? () => save("customLinks") : null
                }
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, customLinks: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>
          <CardsColumn>
            <SettingsCard
              label="Link Styles"
              description="Adjust the navigation link styles"
            >
              <AdminForm
                labelStyle="bodyMd"
                sectionPadding="5px"
                fields={linkStyles.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Changes"}
                submit={
                  get(changes, "linkStyles") ? () => save("linkStyles") : null
                }
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, linkStyles: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
            <SettingsCard
              label="Sorting"
              description="Drag items to adjust the order of how they appear in the navigation"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={sorting.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Sorting"}
                submit={get(changes, "sorting") ? () => save("sorting") : null}
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, sorting: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
              <InfoBox margin="15px 5px 5px 5px">
                Pages and Custom Links will appear in this list.
              </InfoBox>
            </SettingsCard>
            <SettingsCard
              label="Navigation Sections"
              description="Group navigation items in expandable sections"
            >
              <AdminForm
                labelStyle="headingSm"
                sectionPadding="5px"
                fields={sections.filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
                submitText={"Save Changes"}
                submit={
                  get(changes, "sections") ? () => save("sections") : null
                }
                orientation={"horizontal"}
                width="180px"
                onChange={(k, v) => {
                  setChanges({ ...changes, sections: true });
                  setApp({
                    navigation: {
                      ...navigation,
                      [k]: v,
                    },
                  });
                }}
              />
            </SettingsCard>
          </CardsColumn>
        </ColumnsRow>
      </Row>
    </SettingsSection>
  );
};

export default Navigation;

const HeaderPreview = styled.div`
  background: white;
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  flex: 1;
  padding: 20px;
  margin-bottom: 30px;
`;

const SidebarPreview = styled.div`
  background: white;
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  flex: 1;
  padding: 20px;
`;
