import { ConfigContainer, PanelContent } from "./styles";
import {
  rActiveBlockId,
  rActiveDetailViewId,
  rActiveEditField,
  rApp,
  rShowSettingsPanel,
} from "app/utils/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import ActiveBlockConfig from "../../configPanel/ActiveBlockConfig";
import CustomBlockConfig from "../../configPanel/CustomBlockConfig";
import DetailViewSettings from "../../configPanel/DetailViewSettings";
import PageConfig from "../../configPanel/PageConfig";
import { PanelHeader } from "../../configPanel/PageStyles";
import TooltipIcon from "../../configPanel/components/TooltipIcon";
import { get } from "lodash";
import { truncateText } from "app/utils/utils";
import useActiveBlock from "app/utils/useActiveBlock";
import useDeleteBlock from "../../useDeleteBlock";
import useIsCustomBlock from "app/utils/useIsCustomBlock";

const ConfigPanel = () => {
  const { deleteBlock } = useDeleteBlock();

  const setActiveBlockId = useSetRecoilState(rActiveBlockId);

  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const setActiveDetailViewId = useSetRecoilState(rActiveDetailViewId);

  const isCustomBlock = useIsCustomBlock();

  const activeBlock = useActiveBlock();

  const [showSettingsPanel, setShowSettingsPanel] =
    useRecoilState(rShowSettingsPanel);

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const activeApp = useRecoilValue(rApp);

  // -------------------------

  // PATRICK PASTED THIS HERE FEB 4, 2025. It was in the DetailViewBanner which I removed. need to incorporate this to make the nested detail view work?

  // const blocks = useRecoilValue(rPageBlocks);

  // const activeDetailBlock = blocks.find((b) => b.id === activeDetailViewId);

  // const parentBlock = blocks.find(
  //   (b) => b.id === get(activeDetailBlock, "parent")
  // );

  // // TODO - This works for 2 layers deep, but then dies - fix this later
  // const detailViewCrumbs = parentBlock
  //   ? [
  //       {
  //         text: get(parentBlock, "label") || get(parentBlock, "componentId"),
  //         onClick: () => {
  //           setActiveDetailViewId(null);
  //           setActiveBlockId(get(parentBlock, "id"));
  //           setActiveEditField(null);
  //         },
  //       },
  //       {
  //         text: `${
  //           get(activeDetailBlock, "label") ||
  //           get(activeDetailBlock, "componentId")
  //         } Detail View`,
  //         onClick: () => {
  //           setActiveDetailViewId(get(parentBlock, "id"));
  //           setActiveBlockId(null);
  //           setActiveEditField(null);
  //         },
  //       },
  //       {
  //         text: "Detail View",
  //       },
  //     ]
  //   : [
  //       {
  //         text:
  //           get(activeDetailBlock, "label") ||
  //           get(activeDetailBlock, "componentId"),
  //         onClick: () => {
  //           setActiveDetailViewId(null);
  //           setActiveBlockId(null);
  //           setActiveEditField(null);
  //         },
  //       },
  //       {
  //         text: "Detail View",
  //       },
  //     ];

  // -------------------------

  const getBreadcrumbs = () => {
    if (activeBlock) {
      let initialCrumbText = null;

      if (activeDetailViewId) {
        initialCrumbText = "Detail View";
      } else if (isCustomBlock) {
        initialCrumbText = "Custom Block";
      } else {
        initialCrumbText = "Page";
      }

      return [
        {
          text: initialCrumbText,
          onClick: () => {
            setActiveBlockId(null);
            setActiveEditField(null);
          },
        },
        {
          text: truncateText(
            get(activeBlock, "label") || get(activeBlock, "componentId"),
            16
          ),
        },
      ];
    } else {
      if (isCustomBlock) {
        return [
          {
            text: "Edit Custom Block",
          },
        ];
      }

      if (activeDetailViewId) {
        return [
          {
            text: "Page",
            onClick: () => {
              setActiveDetailViewId(null);
            },
          },
          {
            text: "Detail View",
          },
        ];
      }

      return [
        {
          text: "Page Settings",
          onClick: null,
        },
      ];
    }
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <ConfigContainer
      className="open-settings-button"
      expanded={showSettingsPanel}
      onClick={!showSettingsPanel ? () => setShowSettingsPanel(true) : null}
    >
      <PanelContent expanded={showSettingsPanel}>
        <PanelHeader
          label={
            activeBlock
              ? "Block Settings"
              : activeDetailViewId
              ? "Detail View Settings"
              : "Page Settings"
          }
          expanded={showSettingsPanel}
          setExpanded={setShowSettingsPanel}
          direction="left"
          breadcrumbs={breadcrumbs}
          deleteBlock={
            get(activeApp, "show_active_block_delete_button") && activeBlock
              ? deleteBlock
              : null
          }
        />
        <RenderPanelContent
          isCustomBlock={isCustomBlock}
          activeBlock={activeBlock}
          activeDetailViewId={activeDetailViewId}
          deleteBlock={deleteBlock}
        />
      </PanelContent>
      {!showSettingsPanel && (
        <TooltipIcon
          anchorTag=".open-settings-button"
          expanded={showSettingsPanel}
          tooltip="Show Settings"
          icon="BsFillGearFill"
        />
      )}
    </ConfigContainer>
  );
};

export default ConfigPanel;

const RenderPanelContent = ({
  isCustomBlock,
  activeBlock,
  activeDetailViewId,
  deleteBlock,
}) => {
  // Active Block
  if (activeBlock) {
    return <ActiveBlockConfig deleteBlock={deleteBlock} />;
  }

  // Detail View
  if (activeDetailViewId) {
    return <DetailViewSettings />;
  }

  // Custom Block Config
  if (isCustomBlock && !activeDetailViewId) {
    return <CustomBlockConfig />;
  }

  // If nothing else, show page config
  return <PageConfig />;
};
