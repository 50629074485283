import { Button, Column, Text } from "app/components";
import { rConfirmationModalData, rSubscription, rUser } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import SettingsCard from "./components/SettingsCard";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ApiSettings = () => {
  const user = useRecoilValue(rUser);

  const [apiKey, setApiKey] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const subscription = useRecoilValue(rSubscription);

  const navigate = useNavigate();

  const refreshApiKey = () => {
    setConfirmationModalData({
      title: "Request New API Key (Account-Wide)",
      text: "WARNING - This will affect ALL your apps. This will immediately invalidate your current API key (if you have one) and replace it with a new one. Are you sure?",
      confirm: () => {
        setIsRefreshing(true);
        apiRequest
          .post("/refresh_api_key/", {
            is_refreshing: true,
          })
          .then((res) => {
            const newApiKey = get(res, ["data", "api_key"]);
            setApiKey(newApiKey);
            setIsRefreshing(false);
          });
      },
    });
  };

  if (get(user, "role") !== "owner") {
    return <></>;
  }

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="API Settings"
      description="Configure API settings for your organization"
      settingsCategory={"Organization Settings"}
    >
      <Column style={{ width: "500px" }} gap="30px">
        <SettingsCard
          label={"API Key"}
          description="For authenticating requests from third-party services. Warning - These are organization-wide settings! Create users via API through a webhook"
        >
          {subscription ? (
            <>
              {apiKey && (
                <div>
                  <Text
                    data={{
                      text: "Your API Key (click to copy):",
                      fontSize: 18,
                      fontWeight: 500,
                      color: "var(--grey7)",
                      margin: "0 0 10px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: apiKey,
                      fontSize: 20,
                      fontWeight: 500,
                      fontColor: "var(--primary-admin)",
                      onClick: () => {
                        navigator.clipboard.writeText(apiKey);
                        successNotification("Copied to clipboard");
                      },
                    }}
                  />
                </div>
              )}

              {apiKey && (
                <Button
                  data={{
                    text: "Hide API Key",
                    componentId: "Button",
                    width: "180px",
                    icon: "FiKey",
                    type: "basic",
                    margin: "20px 0 0 0",
                    onClick: () => setApiKey(null),
                  }}
                />
              )}

              {!apiKey && (
                <Button
                  data={{
                    text: "Request New API Key",
                    componentId: "Button",
                    isFetching: isRefreshing,
                    width: "180px",
                    icon: "FiKey",
                    onClick: refreshApiKey,
                    displayCondition: () => !apiKey,
                  }}
                />
              )}
            </>
          ) : (
            <Button
              data={{
                text: "Upgrade to access this feature",
                icon: "FiArrowUpCircle",
                onClick: () => navigate("/settings/billing"),
              }}
            />
          )}
        </SettingsCard>
      </Column>
    </SettingsSection>
  );
};

export default ApiSettings;
