import SettingsCard, {
  CardsColumn,
  ColumnsRow,
} from "./components/SettingsCard";
import { rApp, rOrganization } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import AdminForm from "../components/AdminForm";
import DataPolicy from "./DataPolicy";
import SettingsSection from "./SettingsSection";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { successNotification } from "app/utils/Notification";
import { useState } from "react";

const Advanced = () => {
  const [app, setApp] = useRecoilState(rApp);

  const organization = useRecoilValue(rOrganization);

  const [changes, setChanges] = useState({});

  const save = (sectionKey) => {
    successNotification("Saved");

    const sectionChanges = get(changes, sectionKey);
    apiRequest.post("/app_settings/", sectionChanges);

    setChanges({
      ...changes,
      [sectionKey]: null,
    });
  };

  const allowCustomJavascript = get(organization, "allow_custom_javascript");

  const fields = [
    {
      id: "enable_block_versions",
      label: "Enable Block Versions",
      hint: "Enable the 'block versions' feature which allows conditional settings for a specific block.",
      componentId: "Switch",
      orientation: "horizontal",
      value: get(app, "enable_block_versions"),
    },
    {
      id: "disable_admin_animations",
      label: "Increase Admin Performance",
      hint: "Disable animations in the admin panel and reduce the number of displayed rows to increase performance. This will not affect the user-facing app. This is only useful if you have a lot of data or blocks, or are experiencing unresponsive behavior in the admin panel.",
      componentId: "Switch",
      orientation: "horizontal",
      value: get(app, "disable_admin_animations"),
    },
    {
      id: "include_full_relation_records",
      label: "Include Full Relation Records",
      hint: "This is an advanced setting which is only rarely needed. If you are not sure, leave it off. By default, when data relations are loaded, only the minimal data is used.",
      componentId: "Switch",
      orientation: "horizontal",
      value: get(app, "include_full_relation_records"),
    },
    {
      id: "allow_create_users_webhook",
      label: "Allow Create User Webhook",
      hint: "Enable a webhook that allows users to be created via API - This is an advanced feature",
      componentId: "Switch",
      orientation: "horizontal",
      value: get(app, "allow_create_users_webhook"),
    },
    {
      id: "allow_edit_user_webhook",
      label: "Allow Edit User Webhook",
      hint: "Enable a webhook that allows users to be edited via API - This is an advanced feature",
      componentId: "Switch",
      orientation: "horizontal",
      value: get(app, "allow_edit_user_webhook"),
    },
    {
      id: "show_active_block_delete_button",
      label: "Show Active Block Delete Button",
      hint: "Show a delete button on the active block in the admin panel instead of the collapse sidebar button.",
      componentId: "Switch",
      orientation: "horizontal",
      value: get(app, "show_active_block_delete_button"),
    },
    {
      id: "number_format",
      label: "Number Format",
      componentId: "Select",
      value: get(app, "number_format"),
      options: [
        {
          label: "Euro",
          value: "euro",
        },
      ],
    },
    {
      id: "custom_javascript",
      label: "Custom Javascript",
      hint: "Add custom Javascript to your app. This will be added to the head of your app. Enter valid javascript functions only",
      componentId: "TextArea",
      minHeight: "100px",
      orientation: "vertical",
      monospace: true,
      value: get(app, "custom_javascript"),
      displayCondition: () => allowCustomJavascript,
    },
  ];

  let sections = [
    {
      key: "other",
      title: "Assorted Advanced App Settings",
      subtitle: "Manage advanced settings for your app",
      fields: fields,
    },
    {
      key: "environmentVariables",
      title: "Environment Variables",
      subtitle: "Configure secure environment variables for your app",
      fields: [
        {
          id: "environment_variables",
          componentId: "MultiForm",
          labelSingular: "Environment Variable",
          passIdToChild: true,
          value: get(app, "environment_variables", []),
          width: "300px",
          getItemLabel: (f) => get(f, "key"),
          newObject: {
            key: "new_variable",
            value: "",
          },
          fields: [
            {
              key: "key",
              label: "Key",
              placeholder: "Key",
              componentId: "Input",
              width: "100%",
            },
            {
              key: "value",
              label: "Value",
              componentId: "Input",
              width: "100%",
              type: "password",
            },
          ],
        },
      ],
    },
  ];

  return (
    <SettingsSection
      hideCard
      width="100%"
      title="Advanced"
      description="Configure advanced settings for your app"
      settingsCategory={"App Settings"}
    >
      <ColumnsRow>
        <CardsColumn>
          {sections.map((section, i) => {
            const sectionChanges = get(changes, section.key);
            return (
              <SettingsCard
                label={section.title}
                description={section.subtitle}
              >
                <AdminForm
                  labelStyle="headingSm"
                  sectionPadding="0px"
                  fields={section.fields.filter(
                    (f) =>
                      !f.displayCondition ||
                      (f.displayCondition && f.displayCondition(f))
                  )}
                  submitText={"Save Changes"}
                  submit={sectionChanges ? () => save(section.key) : null}
                  onChange={(k, v) => {
                    setChanges({
                      ...changes,
                      [section.key]: {
                        ...get(changes, section.key),
                        [k]: v,
                      },
                    });
                    setApp({
                      [k]: v,
                    });
                  }}
                />
              </SettingsCard>
            );
          })}
        </CardsColumn>
        <CardsColumn>
          <DataPolicy />
        </CardsColumn>
      </ColumnsRow>
    </SettingsSection>
  );
};

export default Advanced;
