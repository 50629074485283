import { Button, Modal, Row } from "app/components";
import { get, startCase } from "lodash";

import DataSourcesDisplay from "./DataSourcesDisplay";
import { dataSourceIconMap } from "app/adminApp/spreadsheets/utils";
import { rApp } from "app/utils/recoil";
import { safeArray } from "app/utils/utils";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { useState } from "react";

export const InstallListingModal = ({ hide, confirm, listing }) => {
  const type = get(listing, "type");

  return (
    <Modal
      hide={hide}
      minWidth="550px"
      header={{
        title: `Install ${startCase(type)}`,
      }}
    >
      <Content listing={listing} confirm={confirm} />
    </Modal>
  );
};

const Content = ({ listing, confirm }) => {
  const [dataService, setDataService] = useState(null);

  const app = useRecoilValue(rApp);
  const googleConnected = get(app, "google_sheets_connected");

  const dataSources = safeArray(listing, "data_sources");

  const type = get(listing, "type");

  const labelMap = {
    frontly_db: "Frontly Database",
    google: "Google Sheets",
  };

  const hasDs = dataSources.length > 0;

  // Has data sources - Show Data Source Service Select
  if (hasDs && !dataService) {
    return (
      <div>
        <DataSourcesDisplay dataSources={dataSources} type={type} />

        <MessageText style={{ marginBottom: "20px" }}>
          Select your preferred data source:
        </MessageText>

        <Row gap={"15px"} margin="10px 0 0 0">
          <ServiceOption onClick={() => setDataService("frontly_db")}>
            <ServiceIcon src={get(dataSourceIconMap, "frontly_db")} />
            <ServiceText>Frontly Database</ServiceText>
          </ServiceOption>

          {googleConnected && (
            <ServiceOption onClick={() => setDataService("google")}>
              <ServiceIcon src={get(dataSourceIconMap, "google")} />
              <ServiceText>Google Sheets</ServiceText>
            </ServiceOption>
          )}
        </Row>
      </div>
    );
  }

  // Data service selected OR has no data sources
  if (dataService || !hasDs) {
    return (
      <div>
        {hasDs && (
          <MessageText style={{ marginBottom: "20px" }}>
            Using <strong>{labelMap[dataService]}</strong> as your data source.
          </MessageText>
        )}

        <MessageText style={{ marginBottom: "20px" }}>
          Confirm installing {type} {hasDs && "and data sources"} into your
          current app?
        </MessageText>

        <Row gap={"15px"} margin="10px 0 0 0">
          <Button
            data={{
              text: "Confirm Installation",
              onClick: () => confirm(dataService),
              size: "large",
            }}
          />
        </Row>
      </div>
    );
  }
};

const ServiceOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--grey3);
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: var(--primary);
  }
`;

const ServiceIcon = styled.img`
  height: 25px;
`;

const ServiceText = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const MessageText = styled.div`
  font-size: 18px;
  font-weight: 300;
`;
