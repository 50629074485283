import frontlyIconBlack from "app/assets/images/frontly-icon-black.png";
import googleSheetsLogo from "app/adminApp/assets/google-sheets-logo.png";

export const dataSourceIconMap = {
  supabase:
    "https://res.cloudinary.com/frontly/image/upload/v1716142973/supabase-icon_akbjay.png",
  airtable:
    "https://res.cloudinary.com/frontly/image/upload/v1716143095/airtable-icon-512x428-olxouyvv_yu6idx.png",
  google: googleSheetsLogo,
  frontly_db: frontlyIconBlack,
};
