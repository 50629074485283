import UserSection, {
  DropdownHeader,
  UserText,
} from "../components/UserSection";
import { get, isEmpty, isNil } from "lodash";
import { rApp, rUser } from "app/utils/recoil";

import LogoSection from "../components/LogoSection";
import Section from "../components/Section";
import { SimpleDropdown } from "app/components";
import { boxShadow } from "app/utils/theme";
import { isFrontlyAdmin } from "app/utils/utils";
import styled from "styled-components";
import useDropdownLinks from "../utils/useDropdownLinks";
import { useNavigationSections } from "../utils";
import { useRecoilValue } from "recoil";

const Header = ({ screenWidth, isPreview }) => {
  // -- RECOIL / DATA -- //
  const sections = useNavigationSections(isPreview);
  const user = useRecoilValue(rUser);

  let dropdownLinks = useDropdownLinks();

  const app = useRecoilValue(rApp);

  const navigation = get(app, "navigation", {});

  const menuPreference = get(navigation, "menuPreference", "dynamic"); // "user", "menu"

  const showBorder = get(navigation, "showBorder", true);
  const borderColor = get(navigation, "borderColor", "var(--nav-round-icon)");
  const showBoxShadow = get(navigation, "showBoxShadow", false);
  const accentType = get(navigation, "accentType", "badge"); // or color
  const accentBorderRadius = get(navigation, "accentBorderRadius", 24);

  let linksPosition = get(navigation, "linksPosition", "center");

  // -- BUILD A FLAT LIST OF "SLOTS" -- //
  // For labeled sections: single slot containing multiple items
  // For unlabeled sections: each item is its own slot
  const flattenedSlots = [];
  sections.forEach((section) => {
    if (section.label) {
      // Count the entire labeled section as 1 slot
      flattenedSlots.push({
        type: "section",
        label: section.label,
        icon: section.icon,
        items: section.items || [],
      });
    } else {
      // Each item is its own slot
      (section.items || []).forEach((it) => {
        flattenedSlots.push({
          type: "item",
          label: it.label,
          icon: it.icon,
          onClick: it.onClick,
          active: it.active,
        });
      });
    }
  });

  // -- FIGURE OUT HOW MANY SLOTS WE CAN FIT -- //
  // For simplicity, adjust these constants as needed
  const LOGO_WIDTH = 200;
  const USER_DROPDOWN_WIDTH = 150;
  const EXTRA_PADDING = isFrontlyAdmin ? 280 : 80; // includes left/right margins, etc.
  const SLOT_WIDTH = 150; // approximate width per slot

  const availableWidth = Math.max(
    screenWidth - LOGO_WIDTH - USER_DROPDOWN_WIDTH - EXTRA_PADDING,
    0
  );

  const maxVisibleSlots = Math.floor(availableWidth / SLOT_WIDTH);

  // -- SPLIT INTO "VISIBLE" VS "OVERFLOW" -- //
  const visibleSlots = [];
  const overflowSlots = [];
  let countUsed = 0;

  flattenedSlots.forEach((slot) => {
    if (countUsed < maxVisibleSlots) {
      visibleSlots.push(slot);
      countUsed++;
    } else {
      overflowSlots.push(slot);
    }
  });

  // -- RECONSTRUCT THE "sections" FOR DISPLAY -- //
  // We'll group consecutive 'item' slots together as an unlabeled section
  // We'll keep each 'section' slot separate
  const displayedSections = [];
  let tempUnlabeledGroup = [];

  function pushUnlabeledGroup() {
    if (tempUnlabeledGroup.length > 0) {
      displayedSections.push({
        label: null, // unlabeled section
        items: [...tempUnlabeledGroup],
      });
      tempUnlabeledGroup = [];
    }
  }

  visibleSlots.forEach((slot) => {
    if (slot.type === "section") {
      // push any pending unlabeled items first
      pushUnlabeledGroup();
      // then push this entire labeled section as one
      displayedSections.push({
        label: slot.label,
        icon: slot.icon,
        items: slot.items,
      });
    } else {
      // it's a simple item slot
      tempUnlabeledGroup.push({
        label: slot.label,
        icon: slot.icon,
        onClick: slot.onClick,
        active: slot.active,
      });
    }
  });
  // push any trailing unlabeled items
  pushUnlabeledGroup();

  // -- ADD OVERFLOW SLOTS INTO THE USER DROPDOWN LINKS -- //
  // If it's a labeled section, we add each item to the dropdown
  // If it's a single item, we add just that item
  overflowSlots.forEach((slot) => {
    if (slot.type === "section") {
      // add each item from the labeled section
      slot.items.forEach((it) => {
        dropdownLinks.push({
          label: it.label,
          icon: it.icon,
          onClick: it.onClick,
        });
      });
    } else {
      // single item
      dropdownLinks.push({
        label: slot.label,
        icon: slot.icon,
        onClick: slot.onClick,
      });
    }
  });

  const linkCount = dropdownLinks.length;

  const spacerMinWidth = linksPosition === "center" ? "150px" : "0px";

  const showSpacer = linkCount > 0 || linksPosition === "center";

  const hasUser = !isEmpty(user) && !isNil(user);

  // -- RENDER -- //
  return (
    <Container
      borderColor={borderColor}
      showBorder={showBorder}
      showBoxShadow={showBoxShadow}
    >
      <LogoSection />
      <SectionsRow linksPosition={linksPosition}>
        {displayedSections.map((section, idx) => (
          <Section
            key={idx}
            section={section}
            accentType={accentType}
            accentBorderRadius={accentBorderRadius}
          />
        ))}
      </SectionsRow>

      {showSpacer && (
        <DropdownSpacer linksPosition={linksPosition} minWidth={spacerMinWidth}>
          {linkCount > 0 && (
            <SimpleDropdown
              data={{
                headerComponent: hasUser ? (
                  <DropdownHeader>
                    <UserText user={user} />
                  </DropdownHeader>
                ) : null,
                component: (
                  <UserSection
                    user={user}
                    text={false}
                    showMenu={overflowSlots.length > 0}
                    menuPreference={menuPreference}
                  />
                ),
                options: dropdownLinks,
              }}
            />
          )}
        </DropdownSpacer>
      )}
    </Container>
  );
};

export default Header;

/* ------------------------------ STYLES ------------------------------ */

const DropdownSpacer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: ${(p) => p.minWidth};
  @media screen and (max-width: 800px) {
    min-width: auto;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--nav-background);
  padding: 15px 30px;

  gap: 50px;

  ${(p) =>
    p.showBoxShadow &&
    `
    box-shadow: ${boxShadow.card};
  `}

  ${(p) =>
    p.showBorder &&
    `
    border-bottom: 1px solid ${p.borderColor};
  `}

  @media screen and (max-width: 800px) {
    padding: 10px 15px;
    gap: 20px;
  }
`;

const SectionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.linksPosition || "center"};
  gap: 20px;
  flex: 1;
`;
