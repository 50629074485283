import {
  Modal,
  Row,
  SearchBar,
  SimpleDropdown,
  Spinner,
  Text,
} from "app/components";
import { rActions, rConfirmationModalData } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { ActionImage } from "../settings/marketplace/Home";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";

const ActionBrowser = ({ hide, onSelect, onEdit }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [templates, setTemplates] = useRecoilState(rActions);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  useEffect(() => {
    if (templates.length === 0) {
      setIsFetching(true);
      apiRequest.get("/actions/").then((r) => {
        const newTemplates = get(r, "data", []);
        setTemplates(newTemplates);
        setIsFetching(false);
      });
    }
  }, []);

  const deleteAction = (id, isListing) => {
    setConfirmationModalData({
      title: "Delete Action",
      text: "Are you sure you want to delete this action? This is irreversible.",
      confirm: () => {
        if (isListing) {
          apiRequest.post(`/marketplace/favorite/${id}/`);
        } else {
          apiRequest.delete(`/actions/${id}`);
        }
        setTemplates(templates.filter((t) => t.id !== id));
        successNotification("Action deleted");
      },
    });
  };

  const [search, setSearch] = useState("");

  const filteredTemplates = templates.filter((t) =>
    get(t, "name").toLowerCase().includes(search.toLowerCase())
  );

  const confirmAndSelectAction = (action) => {
    setConfirmationModalData({
      title: "Select Action",
      text: "Are you sure you want to overwrite your existing action with this one? This is irreversible.",
      confirm: () => {
        onSelect({ ...get(action, "config", {}) });
        successNotification("Action added");
      },
    });
  };

  return (
    <Modal
      hide={hide}
      minWidth="1200px"
      header={{
        title: "Browse Saved Actions",
      }}
    >
      {/* Find useful actions pre-made by other Frontly users and the Frontly team */}
      {isFetching && <Spinner />}
      {!isFetching && (
        <>
          <SearchBar
            data={{
              placeholder: "Search saved actions",
              value: search,
              onChange: (v) => setSearch(v),
            }}
          />
          <ActionsGrid>
            {filteredTemplates.map((action) => (
              <ActionCard onClick={() => confirmAndSelectAction(action)}>
                <Row justifyContent="space-between">
                  <div>
                    <Text
                      data={{
                        text: get(action, "name"),
                        fontSize: 20,
                        fontWeight: 600,
                        cursor: "pointer",
                        margin: "0 0 5px 0",
                      }}
                    />
                    <Text
                      data={{
                        text: get(action, "description"),
                        truncate: 50,
                        fontSize: 16,
                        fontWeight: 300,
                        cursor: "pointer",
                        margin: "0 0 15px 0",
                      }}
                    />
                  </div>
                  <SimpleDropdown
                    data={{
                      options: [
                        {
                          label: "Edit",
                          icon: "FiEdit",
                          onClick: () => onEdit(action),
                        },
                        {
                          label: "Delete",
                          icon: "FiTrash",
                          onClick: () =>
                            deleteAction(action.id, action.is_listing),
                        },
                      ],
                      icon: {
                        icon: "FiMoreHorizontal",
                        size: 25,
                        hover: true,
                        color: "var(--grey7)",
                      },
                    }}
                  />
                </Row>

                <ActionImage steps={get(action, ["config", "steps"], [])} />
              </ActionCard>
            ))}
          </ActionsGrid>
        </>
      )}
    </Modal>
  );
};

export default ActionBrowser;

const ActionCard = styled.div`
  border: 1px solid var(--grey3);
  box-shadow: var(--card-shadow);
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: var(--card-shadow-hover);
  }
`;

const ActionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 25px;
  margin-top: 25px;
`;
