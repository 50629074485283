import { Button, Hint, Row, Text } from "app/components";
import { get, startCase } from "lodash";
import {
  getInputBackgroundColor,
  getPixels,
  getUniqueValues,
  isFrontlyAdmin,
  sortSections,
} from "app/utils/utils";
import {
  gridColumnGapObject,
  gridRowGapObject,
  labelStyleObject,
  paddingObject,
  sizeObject,
} from "app/renderingApp/blocks/Form/utils";
import { rActiveEditField, rApp, rDarkMode } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import FormField from "app/renderingApp/blocks/Form/FormField";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import useListData from "app/useListData";
import useUtils from "app/renderingApp/useUtils";

const AdminForm = ({
  activeBlock,
  formContext,
  previousSteps = [],
  excludedSources = [],
  dynamicSources = [],
  allowFieldClick = false,
  fields,
  labelStyle,
  labelColor,
  labelWidth = "auto",
  onChange,
  editField, // for staticfields only
  submit,
  orientation,
  width,
  submitText,
  sectionPadding,
  firstSectionPadding, // for overriding the first section only - this is hacky
  isFetching,
  sectionOrder = [],
  errors,
  buttonColor,
  buttonFullWidth,
  borderBottom = null,
  margin = "0px",
  disableSubmit,
  gridLayout = false,
  gridLayoutSize = null,
  hideSectionLabel = false,
  buttons = [],
  otherContent = null,
  isLiveApp = false,
  skeleton = false,
  submitOnEnter = false,
  dataSourceId = null,
  activeItemId = null,
  sectionLabelColor = null,
}) => {
  const darkMode = useRecoilValue(rDarkMode);

  const activeApp = useRecoilValue(rApp);

  const buttonBorderRadius = isFrontlyAdmin
    ? null
    : get(activeApp, ["styling", "buttonBorderRadius"]);

  const sections = sortSections(
    getUniqueValues(fields, "section"),
    sectionOrder
  );

  const hideBorder = sections.length === 1;

  const { getListData } = useListData();

  const rows = getListData(activeBlock);

  const setActiveEditField = useSetRecoilState(rActiveEditField);

  const getComponent = (componentId, disabled) => {
    if (
      !disabled ||
      ["Switch", "Checkbox", "ImageUpload", "TextArea"].includes(componentId)
    ) {
      return componentId;
    }

    if (!["Switch", "Checkbox", "ImageUpload"].includes(componentId)) {
      return "Input";
    }
  };

  const getDynamicOptions = (f) => [
    // { label: "None", value: null },
    ...getUniqueValues(rows, f.key, f.componentId)
      .filter((v) => v && v)
      .map((d) => ({
        label: d,
        value: d,
      })),
  ];

  const inputSize = isFrontlyAdmin
    ? "medium"
    : get(activeApp, ["styling", "formInputSize"], "medium");

  const size = get(sizeObject, inputSize);

  fields = fields
    .map((f) => {
      if (["Select", "MultiSelect"].includes(f.componentId)) {
        return {
          ...f,
          options: f.options || getDynamicOptions(f),
          padding: get(
            {
              medium: "7px 10px 7px 10px",
              large: "12px 15px 12px 15px",
            },
            inputSize
          ),
        };
      }
      if (
        ["SpreadsheetColumnSelect", "SpreadsheetSelect"].includes(f.componentId)
      ) {
        return {
          ...f,
          padding: get(
            {
              medium: "7px 10px 7px 10px",
              large: "12px 15px 12px 15px",
            },
            inputSize
          ),
        };
      } else {
        return {
          ...f,
          padding: get(paddingObject, inputSize),
        };
      }
    })
    .filter((f) => f.type !== "Hidden")
    .filter((f) => f.componentId !== "Hidden")
    .map((f) => ({
      ...f,
      componentId: getComponent(f.componentId, f.disabled),
    }));

  const { processDynamicText } = useUtils();

  let processedSubmitText = processDynamicText({ text: submitText });

  const inputRadius = isFrontlyAdmin
    ? null
    : get(activeApp, ["styling", "formInputRadius"]);

  const gridRowGap = get(gridRowGapObject, inputSize);
  const gridColumnGap = get(gridColumnGapObject, inputSize);
  const isDarkMode = darkMode && isLiveApp;

  return (
    <div style={{ margin, width: "100%" }}>
      {sections.map((s, index) => {
        const sectionFields = fields.filter((f) => f.section === s);

        const formSectionPadding =
          index === 0 ? firstSectionPadding || sectionPadding : sectionPadding;

        const sectionHint = sectionFields.find((f) => f.sectionHint);

        return (
          <FormSection
            key={index}
            label={startCase(s)}
            hint={sectionHint && sectionHint.sectionHint}
            borderBottom={borderBottom && !hideBorder}
            sectionPadding={formSectionPadding}
            hideSectionLabel={hideSectionLabel}
            gridLayout={gridLayout}
            gridLayoutSize={gridLayoutSize}
            gridRowGap={getPixels(gridRowGap)}
            gridColumnGap={getPixels(gridColumnGap)}
            sectionLabelColor={sectionLabelColor}
          >
            {sectionFields.map((field, i) => {
              let fieldOnChange = get(field, "onChange") || onChange;

              return (
                <FormField
                  key={field.id}
                  data={{
                    ...field,
                    skeleton,
                    editField,
                    labelWidth,
                    fontStyle: size,
                    previousSteps,
                    dataSourceId,
                    activeItemId,
                    excludedSources: field.excludedSources || excludedSources,
                    dynamicSources: field.dynamicSources || dynamicSources,
                    darkMode,
                    labelColor:
                      labelColor || (isDarkMode && colors.darkModeLightText2),
                    background: getInputBackgroundColor({
                      darkMode: isDarkMode,
                      disabled: field.disabled,
                    }),
                    borderRadius: getPixels(inputRadius),
                    border:
                      isDarkMode && `1px solid ${colors.darkModeButtonBorder}`,
                    color: isDarkMode && "white",
                    handleFieldClick: !allowFieldClick
                      ? null
                      : (obj) =>
                          setActiveEditField({
                            ...obj,
                            blockId: get(activeBlock, "id"),
                            formContext,
                          }),
                    width: field.width || width || "100%",
                    orientation: field.orientation || orientation,
                    labelStyle: labelStyle || get(labelStyleObject, inputSize),
                    componentId: get(field, "componentId", "Input"),
                    error: get(errors, field.id),
                    onChange: (value) =>
                      fieldOnChange(field.id || field.key, value),
                    onKeyPress: (e) => {
                      if (submitOnEnter) {
                        if (e.key === "Enter") {
                          submit();
                        }
                      }
                    },
                  }}
                />
              );
            })}
          </FormSection>
        );
      })}
      {otherContent}

      {(submit || buttons.length > 0) && (
        <Row alignItems="center" gap="15px" margin="20px 0 0 0">
          {submit && (
            <Button
              data={{
                backgroundColor: buttonColor,
                onClick: submit,
                width: buttonFullWidth && "100%",
                isFetching,
                borderRadius: buttonBorderRadius,
                text: processedSubmitText || "Submit",
                disabled: disableSubmit,
                fontStyle: "headingSm",
              }}
            />
          )}
          {buttons &&
            buttons.map((b) => (
              <Button
                data={{
                  ...b,
                  borderRadius: buttonBorderRadius
                    ? getPixels(buttonBorderRadius)
                    : null,
                }}
              />
            ))}
        </Row>
      )}
    </div>
  );
};

export default AdminForm;

const Fields = styled.div`
  border-bottom: ${(p) => (p.borderBottom ? "1px" : "0px")} solid
    ${colors.pitchBorder};
  padding: ${(p) => p.sectionPadding || "15px"};
  display: flex;
  flex-direction: column;
  gap: ${(p) => getPixels(p.gap || 15)};
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: ${(p) => p.gridRowGap};
  grid-column-gap: ${(p) => p.gridColumnGap};
  ${(p) =>
    p.gridLayout &&
    `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${
      p.gridLayoutSize || "250px"
    }, 1fr));
  `}
`;

const FormSection = ({
  label,
  hint,
  borderBottom,
  children,
  sectionPadding,
  hideSectionLabel = false,
  gridLayout = false,
  gridLayoutSize = null,
  gridColumnGap,
  gridRowGap,
  sectionLabelColor,
}) => {
  return (
    <Fields
      borderBottom={borderBottom}
      sectionPadding={sectionPadding}
      gap={hint ? 5 : 15}
    >
      {!hideSectionLabel && label && (
        <Row alignItems="center" gap="5px">
          <Text
            data={{
              text: label,
              fontStyle: "headingSmBold",
              cursor: "pointer",
              color: sectionLabelColor || "var(--grey8)",
            }}
          />
          {hint && <Hint hint={hint} margin="5px 0 0 0" />}
        </Row>
      )}
      <Items
        gridLayout={gridLayout}
        gridLayoutSize={gridLayoutSize}
        gridColumnGap={gridColumnGap}
        gridRowGap={gridRowGap}
      >
        {children}
      </Items>
    </Fields>
  );
};
