import { get } from "lodash";

export const operators = [
  {
    label: "Equals",
    description: "Row value is equal to X",
    value: "equals",
  },
  {
    label: "Does Not Equal",
    description: "Row value does not equal X",
    value: "does_not_equal",
  },
  {
    label: "Greater Than",
    description: "Row value is greater than X",
    value: "greater_than",
  },
  {
    label: "Less Than",
    description: "Row value is less than X",
    value: "less_than",
  },
  {
    label: "Contains",
    description: "Row value contains X",
    value: "contains",
  },
  {
    label: "Does Not Contain",
    description: "Row value does not contain X",
    value: "does_not_contain",
  },
  {
    label: "In",
    description: "Row value is in 'A,B,C'",
    value: "in",
  },
  {
    label: "Not In",
    description: "Row value is not in 'A,B,C'",
    value: "not_in",
  },
  {
    label: "Is True",
    description: "Row value is true (boolean)",
    value: "is_true",
  },
  {
    label: "Is False",
    description: "Row value is false (boolean)",
    value: "is_false",
  },
  {
    label: "Length Greater Than",
    description: "Row value is more than X characters",
    value: "length_greater_than",
  },
  {
    label: "Length Less Than",
    description: "Row value is less than X characters",
    value: "length_less_than",
  },
  {
    label: "Date Equals",
    description: "Row value is the same date as X",
    value: "date_equals",
  },
  {
    label: "Date After",
    description: "Row value is after date X",
    value: "date_after",
  },
  {
    label: "Date Before",
    description: "Row value is before date X",
    value: "date_before",
  },
  {
    label: "Date In Range",
    description: "Row value is between date X and date Y",
    value: "date_in_range",
  },
  {
    label: "Exists",
    description: "Row value is not null or empty",
    value: "exists",
  },
  {
    label: "Does Not Exist",
    description: "Row value is empty or null",
    value: "does_not_exist",
  },
  {
    label: "Array Contains",
    description: "Row value (array) contains X",
    value: "array_contains",
  },
  {
    label: "Array Does Not Contain",
    description: "Row value (array) does not contain X",
    value: "array_does_not_contain",
  },
  {
    label: "Array Contains All",
    description: "Row value (array) contains all of 'A,B,C' (array)",
    value: "array_contains_all",
  },
  {
    label: "Array Contains Any",
    description: "Row value (array) contains any of 'A,B,C' (array)",
    value: "array_contains_any",
  },
];

const arrayOperators = [
  "array_contains", // "[1, 2, 3] contains 1"
  "array_does_not_contain", // "[1, 2, 3] does not contain 1"
  "array_contains_all", // "[1, 2, 3] contains all [1, 2]"
  "array_contains_any", // "[1, 2, 3] contains any [1, 2]"
];

export const operatorTypesMap = {
  // These are all for when the DB value is an array like [1, 2, 3]
  db_value_is_array: {
    recommended: arrayOperators,
    other: [
      // TODO - Make these work for arrays
      // "length_greater_than",
      // "length_less_than",
      "exists",
      "does_not_exist",
    ],
  },
  boolean: {
    recommended: ["is_true", "is_false"],
    other: ["exists", "does_not_exist"],
  },
  numeric: {
    recommended: ["greater_than", "less_than", "equals", "in", "not_in"],
    other: [
      "does_not_equal",
      "contains",
      "does_not_contain",
      "exists",
      "does_not_exist",
    ],
  },
  date: {
    recommended: ["date_equals", "date_after", "date_before", "date_in_range"],
    other: [
      "equals",
      "does_not_equal",
      "contains",
      "does_not_contain",
      "exists",
      "does_not_exist",
    ],
  },
  text: {
    recommended: ["equals", "does_not_equal", "contains", "does_not_contain"],
    other: [
      "length_greater_than",
      "length_less_than",
      "exists",
      "does_not_exist",
      "in",
      "not_in",
    ],
  },
};

// The operators that are available in Airtable.
const airtableOperators = [
  "equals",
  "not_equals",
  "contains",
  "greater_than",
  "less_than",
];

// The operators that are available in Supabase.
const supabaseOperators = [
  "equals",
  "does_not_equal",
  "greater_than",
  "less_than",
  "contains",
  "does_not_contain",
  "in",
  "not_in",
  ...arrayOperators,

  // TODO - Add these operators
  "exists",
  "does_not_exist",
  "is_true",
  "is_false",
  // "length_greater_than",
  // "length_less_than",
];

const frontlyDbOperators = [
  "equals",
  "does_not_equal",
  "contains",
  "does_not_contain",
  "exists",
  "does_not_exist",
  "greater_than",
  "less_than",
  "in",
  "not_in",
  // TODO - Support other operators
  // "date_equals",
  // "date_after",
  // "date_before",
  // "date_in_range",
  // "is_true",
  // "is_false",
  // "length_greater_than",
  // "length_less_than",
];

const googleOperators = [
  "equals",
  "does_not_equal",
  "contains",
  "does_not_contain",
  "exists",
  "does_not_exist",
  "greater_than",
  "less_than",
  "in",
  "not_in",
  "date_equals",
  "date_after",
  "date_before",
  "date_in_range",
  "is_true",
  "is_false",
  "length_greater_than",
  "length_less_than",
  ...arrayOperators,
];

const fallbackOperators = {
  recommended: [
    "equals",
    "does_not_equal",
    "contains",
    "does_not_contain",
    "exists",
    "does_not_exist",
    "greater_than",
    "less_than",
    "is_true",
    "is_false",
  ],
  other: [
    "in",
    "not_in",
    "date_equals",
    "date_after",
    "date_before",
    "date_in_range",
    "length_greater_than",
    "length_less_than",
  ],
};

const serviceOperatorsMap = {
  supabase: supabaseOperators,
  airtable: airtableOperators,
  frontly_db: frontlyDbOperators,
  google: googleOperators,
};

export const getOperators = ({
  service,
  dataType = "text",
  context = null,
  isArray = false,
  allowAll = false,
}) => {
  if (allowAll) {
    const serviceOperators = [
      ...new Set([
        ...get(serviceOperatorsMap, "google", []),
        ...arrayOperators,
      ]),
    ];

    const recommended = get(fallbackOperators, "recommended", []);

    return {
      serviceOperators, // google has the most
      recommended,
      other: serviceOperators.filter((o) => !recommended.includes(o)),
    };
  }

  let finalDataType = dataType;

  if (["integer", "float"].includes(dataType)) {
    finalDataType = "numeric";
  }

  if (isArray) {
    finalDataType = "db_value_is_array";
  }

  let serviceOperators = get(serviceOperatorsMap, service, []);

  // Hide date_in_range for hidden filters
  if (context == "hiddenFilters") {
    serviceOperators = serviceOperators.filter((o) => o !== "date_in_range");
  }

  const typeMatch = get(operatorTypesMap, finalDataType, fallbackOperators);

  return {
    serviceOperators,
    ...typeMatch,
  };
};
