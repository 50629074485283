import { isFrontlyAdmin, safeArray } from "app/utils/utils";
import {
  rCustomBlockActions,
  rCustomBlockBlocks,
  rCustomBlocks,
  rPage,
  rPageActions,
  rPageBlocks,
  rPages,
} from "app/utils/recoil";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { get } from "lodash";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import { useEffect } from "react";

export const useInitializePage = (livePage) => {
  const setInitialPageBlocks = useSetRecoilState(rPageBlocks);
  const setInitialPageActions = useSetRecoilState(rPageActions);
  const setPage = useSetRecoilState(rPage);
  const pages = useRecoilValue(rPages);
  const { activeCustomBlock } = useActiveCustomBlock();
  const customBlocks = useRecoilValue(rCustomBlocks);

  const setCustomBlockBlocks = useSetRecoilState(rCustomBlockBlocks);
  const setCustomBlockActions = useSetRecoilState(rCustomBlockActions);

  const location = useLocation();

  const path = get(location, "pathname");
  const { id } = useParams();

  const changeTrigger = isFrontlyAdmin ? id : path;

  const getPage = () => {
    if (livePage) {
      return livePage;
    } else {
      if (activeCustomBlock) {
        return activeCustomBlock;
      } else {
        return pages.find((p) => p.id === parseInt(id));
      }
    }
  };

  // Note - this should happen in live mode too
  useEffect(() => {
    const page = getPage();

    if (page) {
      const initialPageBlocks = get(page, "blocks", []);

      setInitialPageBlocks(safeArray(initialPageBlocks));

      // Initialize custom block actions and blocks ----------------
      const localCustomBlocks = initialPageBlocks.filter(
        (b) => b.componentId === "Custom"
      );
      let cbActionsObj = {};
      let cbBlocksObj = {};
      localCustomBlocks.forEach((b) => {
        const matchingCustomBlock = customBlocks.find(
          (c) => c.id === b.customBlock
        );
        const cbActions = get(matchingCustomBlock, "actions", []);
        const cbBlocks = get(matchingCustomBlock, "blocks", []);
        cbActionsObj[b.id] = safeArray(cbActions);
        cbBlocksObj[b.id] = safeArray(cbBlocks);
      });
      setCustomBlockActions(cbActionsObj);
      setCustomBlockBlocks(cbBlocksObj);
      // ------------------------------------------------------------

      const initialPageActions = get(page, "actions", []);
      setInitialPageActions(safeArray(initialPageActions));

      setPage({ ...page, blocks: null, actions: null });
    }
  }, [changeTrigger]);
};
