import { AdminForm } from ".";
import { get } from "lodash";
import usePage from "app/utils/usePage";
import useSetPage from "app/utils/useSetPage";
import { useState } from "react";

const PageFiltersConfig = () => {
  const { setPage } = useSetPage();

  const page = usePage();

  const filterSettings = get(page, "filterSettings", {});
  const filters = get(filterSettings, "filters", []);

  const [activeFilterIndex, setActiveFilterIndex] = useState(null);

  const activeFilter = filters.find((f, i) => i === activeFilterIndex);

  const getOperatorDefaultValue = () => {
    // If I do this, ensure it also is handled in the data processing
    // if (get(activeFilter, "componentId") === "DateTimePicker") {
    //   return "date_equals";
    // }

    return "equals";
  };

  const fields = [
    {
      key: "label",
      label: "Label",
      hint: "A visible label",
      componentId: "Input",
    },
    {
      key: "componentId",
      label: "Component",
      hint: "The type of input field for users to filter their data with",
      componentId: "Select",
      hideEmptyItem: true,
      options: [
        { label: "Input", value: "Input" },
        { label: "True/False", value: "Boolean" },
        { label: "Select Dropdown", value: "Select" },
        { label: "Multi Select", value: "MultiSelect" },
        { label: "Date", value: "DateTimePicker" },
        { label: "Relative Date", value: "RelativeDate" },
        { label: "Date Range", value: "DateRangePicker" },
      ],
    },
    {
      key: "operator",
      label: "Operator",
      componentId: "OperatorSelect",
      hideEmptyItem: true,
      defaultValue: getOperatorDefaultValue(),
      context: "visibleFilters",
      allowAll: true,
      displayCondition: (f) =>
        get(f, "componentId") &&
        !["DateRangePicker", "Boolean"].includes(get(f, "componentId")),
    },
    {
      key: "options",
      label: "Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown.",
      newObject: { label: "Option 1", value: "option_1" },
      orientation: "vertical",
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
      displayCondition: (f) =>
        ["Select", "MultiSelect"].includes(get(f, "componentId")),
    },
    {
      key: "icon",
      label: "Icon",
      componentId: "IconBrowser",
    },
    {
      key: "sheets",
      componentId: "PageFilterSheets",
    },
  ];

  return (
    <div>
      <AdminForm
        sectionPadding={"0px"}
        margin="0 0 15px 0"
        fields={[
          {
            id: "filters",
            orientation: "vertical",
            labelSingular: "Filter",
            componentId: "MultiForm",
            value: filters,
            getItemLabel: (item) => get(item, "label", "New Filter"),
            itemClick: (i) => setActiveFilterIndex(i),
            fields,
            onChange: (k, newFilters) =>
              setPage({
                filterSettings: { ...filterSettings, filters: newFilters },
              }),
          },
          {
            key: "theme",
            label: "Color Theme",
            componentId: "Select",
            hideEmptyItem: true,
            defaultValue: "white",
            value: get(filterSettings, "theme"),
            options: [
              { label: "White", value: "white" },
              { label: "Grey", value: "grey" },
            ],
            displayCondition: () => get(filters, "length", 0) > 0,
          },
        ].filter(
          (f) =>
            !f.displayCondition || (f.displayCondition && f.displayCondition())
        )}
        onChange={(k, v) =>
          setPage({ filterSettings: { ...filterSettings, [k]: v } })
        }
      />
    </div>
  );
};

export default PageFiltersConfig;
