import { get, startCase } from "lodash";

import AdminForm from "app/adminApp/components/AdminForm";
import { getCustomBlockInputs } from "app/adminApp/components/CustomBlockFieldEditor";
import { rCustomBlockChanges } from "app/utils/recoil";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import { useSetRecoilState } from "recoil";

const CustomBlockConfig = () => {
  const activeBlock = useActiveBlock();

  const { activeCustomBlock, setActiveCustomBlock } = useActiveCustomBlock();

  const setCustomBlockChanges = useSetRecoilState(rCustomBlockChanges);

  const isReusable = get(activeCustomBlock, "reusable");

  const customBlockFields = isReusable
    ? getCustomBlockInputs(activeCustomBlock, activeBlock)
    : [];

  // Get Block Type
  let blockType = "standard";
  if (get(activeCustomBlock, "reusable")) {
    blockType = "reusable";
  }
  if (get(activeCustomBlock, "repeating")) {
    blockType = "repeating";
  }

  const fields = [
    {
      id: "name",
      label: "Name",
      componentId: "Input",
      section: "basics",
    },
    {
      id: "description",
      label: "Description",
      componentId: "Input",
      section: "basics",
    },
    {
      id: "type",
      label: "Block Type",
      hint: "This cannot be changed after creation",
      componentId: "Input",
      section: "basics",
      value: startCase(blockType),
      disabled: true,
    },
    ...customBlockFields,
    {
      id: "blockHierarchy",
      componentId: "BlockHierarchy",
      sectionHint: "Configure the blocks on this page",
      section: "blocks",
      orientation: "vertical",
      tab: "basics",
    },
  ].filter(
    (f) => !f.displayCondition || (f.displayCondition && f.displayCondition())
  );

  return (
    <AdminForm
      fields={fields.map((f) => ({
        ...f,
        value: f.value || get(activeCustomBlock, f.id),
        orientation: get(f, "orientation", "vertical"),
      }))}
      borderBottom={true}
      onChange={(k, v) => {
        setCustomBlockChanges(true);
        setActiveCustomBlock({ [k]: v });
      }}
    />
  );
};

export default CustomBlockConfig;
