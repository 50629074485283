import { Icon, Text } from "app/components";
import { filterBasedOnUserGroups, isFrontlyAdmin } from "app/utils/utils";
import { rActiveBlockId, rApp, rPageBlocks, rUser } from "app/utils/recoil";

import { NewBlock } from "app/adminApp/components/BlockManager/NewBlock";
import RenderBlockRouter from "app/renderingApp/RenderBlock/RenderBlockRouter";
import { Tooltip } from "react-tooltip";
import { get } from "lodash";
import { layouts } from "app/renderingApp/layouts";
import styled from "styled-components";
import { successNotification } from "app/utils/Notification";
import { useRecoilValue } from "recoil";
import useSetBlock from "app/utils/useSetBlock";
import { useState } from "react";
import useUtils from "app/renderingApp/useUtils";

const Layout = ({ block, page }) => {
  const activeBlockId = useRecoilValue(rActiveBlockId);

  const setBlock = useSetBlock();

  const user = useRecoilValue(rUser);
  const app = useRecoilValue(rApp);

  const selectedLayout = get(layouts, get(block, "layout", 1));
  const layoutItems = get(selectedLayout, "items", []);
  const [newBlockSlotNumber, setNewBlockSlotNumber] = useState(false);

  const availableWidth = get(block, "availableWidth", window.innerWidth);

  const { passesDisplayConditions } = useUtils();

  const pageBlocks = useRecoilValue(rPageBlocks);

  const blocks = filterBasedOnUserGroups({
    items: pageBlocks,
    app,
    user,
    passesDisplayConditions,
  }).filter((b) =>
    passesDisplayConditions({ conditions: get(b, "displayConditions", []) })
  );

  const layoutBlocks = blocks.filter((b) => b.layoutParent === block.id);

  if (!isFrontlyAdmin && layoutBlocks.length === 0) {
    return <></>;
  }

  const removeFromLayout = () => {
    setBlock({ data: { layoutParent: null, layoutPosition: null } });
    successNotification("Block Removed From Layout");
  };

  return (
    <>
      {newBlockSlotNumber && (
        <NewBlock
          layoutPosition={newBlockSlotNumber}
          layoutParent={block.id}
          hide={() => setNewBlockSlotNumber(false)}
        />
      )}
      {selectedLayout && (
        <LayoutContainer {...selectedLayout} availableWidth={availableWidth}>
          {layoutItems.map((item) => {
            const matchingBlock = layoutBlocks.find(
              (b) => b.layoutPosition === item.i
            );

            if (matchingBlock) {
              return (
                <LayoutContainer {...item} availableWidth={availableWidth}>
                  {isFrontlyAdmin && activeBlockId === matchingBlock.id && (
                    <>
                      <Tooltip
                        anchorSelect={".removeFromLayout"}
                        place="bottom"
                        style={{ zIndex: 9999 }}
                      >
                        Remove From Layout
                      </Tooltip>
                      <SlotNumber
                        className="removeFromLayout"
                        style={{ cursor: "pointer" }}
                        onClick={removeFromLayout}
                      >
                        <Icon data={{ icon: "FiX", size: 15, hover: true }} />
                      </SlotNumber>
                    </>
                  )}

                  <RenderBlockRouter
                    key={matchingBlock.id}
                    data={{
                      inLayout: true,
                      block: matchingBlock,
                      page,
                      setupMode: isFrontlyAdmin, // can probably remove this
                    }}
                  />
                </LayoutContainer>
              );
            }

            if (!matchingBlock && isFrontlyAdmin) {
              return (
                <DropZone
                  {...item}
                  onClick={() => setNewBlockSlotNumber(item.i)}
                  availableWidth={availableWidth}
                >
                  <SlotNumber>{item.i}</SlotNumber>
                  <Icon
                    data={{
                      icon: "BsPlusSquare",
                      color: "var(--grey7)",
                      size: 32,
                      margin: "0 0 15px 0",
                    }}
                  />
                  <Text
                    data={{
                      text: "Click To Add Block",
                      fontStyle: "bodyLg",
                      color: "var(--grey7)",
                    }}
                  />
                </DropZone>
              );
            }
          })}
        </LayoutContainer>
      )}
    </>
  );
};

export default Layout;

const renderSize = (width, props) => {
  const { cols12, row12, col12, cols8, row8, col8, cols4, row4, col4 } = props;

  let size = "desktop";
  if (width < 800) {
    size = "mobile";
  } else if (width < 1200) {
    size = "tablet";
  }

  const obj = {
    desktop: { cols: cols12, col: col12, row: row12 },
    tablet: { cols: cols8, col: col8, row: row8 },
    mobile: { cols: cols4, col: col4, row: row4 },
  };

  const { cols, col, row } = get(obj, size);

  let finalString = "";

  if (cols) {
    finalString += `grid-template-columns: ${cols};`;
  }
  if (row) {
    finalString += `grid-row: ${row};`;
  }
  if (col) {
    finalString += `grid-column: ${col};`;
  }

  return finalString;
};

const SlotNumber = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  height: 20px;
  width: 20px;
  background: #8aabe6;
  border-radius: 10px;
  font-size: 12px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 499;
`;

const LayoutContainer = styled.div`
  ${(p) => renderSize(p.availableWidth, p)}
  display: grid;
  grid-gap: 25px;
  position: relative;
`;

const DropZone = styled.div`
  ${(p) => renderSize(p.availableWidth, p)}
  border: 2px dashed var(--grey21);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey8);
  font-size: 18px;
  min-height: 240px;
  // height: fit-content;
  align-self: stretch;
  cursor: pointer;
  transition: 150ms;
  font-weight: 500;
  &:hover {
    border: 2px dashed var(--grey7);
  }
`;
