import { Icon, Text } from "app/components";

import { NewBlock } from "app/adminApp/components/BlockManager/NewBlock";
import { colors } from "app/utils/theme";
import { isFrontlyAdmin } from "app/utils/utils";
import styled from "styled-components";
import { useState } from "react";

const Placeholder = ({ block, page }) => {
  const [showNewBlock, setShowNewBlock] = useState(false);

  return (
    <>
      {showNewBlock && (
        <NewBlock
          hide={() => setShowNewBlock(false)}
          placeholderId={block.id}
          layoutParent={block.layoutParent}
        />
      )}
      <Container>
        {isFrontlyAdmin && (
          <Clickable
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowNewBlock(true);
            }}
          >
            <Icon
              data={{
                icon: "BsPlusSquare",
                color: "var(--grey7)",
                size: 24,
                hover: true,
              }}
            />
            <Text
              data={{
                text: "Add Block",
                fontStyle: "bodyLg",
                color: "var(--grey7)",
                cursor: "pointer",
              }}
            />
          </Clickable>
        )}
      </Container>
    </>
  );
};

export default Placeholder;

const Clickable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  gap: 10px;
  border-radius: 10px;
  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
  transition: background 0.2s;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  width: 100%;
  ${isFrontlyAdmin && `border: 2px dashed var(--grey7);`}
`;
