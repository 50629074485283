import Body from "./components/Body";
import ConfigPanel from "./components/ConfigPanel";
import { DndProvider } from "react-dnd";
import EditActionStepModal from "app/adminApp/components/Action/EditActionStepModal";
import { HTML5Backend } from "react-dnd-html5-backend";
import Header from "./components/Header";
import StylesPanel from "./components/StylesPanel";
import { rActiveActionObject } from "app/utils/recoil";
import { useInitializePage } from "./utils/useInitializePage";
import { useRecoilValue } from "recoil";

// Trying an entirely new rendering system to reduce overlap between different parts of the editor
const PageBuilderV2 = () => {
  const activeActionObject = useRecoilValue(rActiveActionObject);

  useInitializePage();

  return (
    <DndProvider backend={HTML5Backend}>
      <StylesPanel />
      {activeActionObject && <EditActionStepModal obj={activeActionObject} />}
      <Header />
      <Body />
      <ConfigPanel />
    </DndProvider>
  );
};

export default PageBuilderV2;
