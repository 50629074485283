import AppCreatedEmailModal, {
  CreateNewAppModal,
} from "../components/AppCreatedEmailModal";
import { Button, Modal, Row, SearchBar, Text } from "app/components";
import { errorNotification, successNotification } from "app/utils/Notification";
import {
  rApp,
  rApps,
  rConfirmationModalData,
  rSubscription,
  rUser,
} from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "./AdminWrapper";
import CardGrid from "../components/CardGrid";
import CreateAppModal from "../generate/CreateApp";
import CreateModal from "../components/CreateModal";
import { Label } from "./Pages";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import mixpanel from "mixpanel-browser";
import { safeLower } from "app/utils/utils";
import useFetchAppConfig from "app/utils/useFetchAppConfig";
import { useNavigate } from "react-router-dom";

export const AppsContent = ({ itemsPerPage = 12 }) => {
  const fetchAppConfig = useFetchAppConfig();

  const user = useRecoilValue(rUser);

  const app = useRecoilValue(rApp);

  const navigate = useNavigate();

  // Disabling for now (foreign app install)
  // const location = useLocation();
  // const installAppId = getUrlParameter("install_app_id", location);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const [search, setSearch] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(null);
  const [showNewAppModal, setShowNewAppModal] = useState(null);

  const [apps, setApps] = useRecoilState(rApps);

  const appId = get(app, "id");

  const subscription = useRecoilValue(rSubscription);

  const currentPlan = get(subscription, "plan");
  const appLimit = get(subscription, "app_limit");

  const hasUnlimitedApps = currentPlan && (appLimit === 0 || !appLimit);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const canCreateApp =
    hasUnlimitedApps ||
    (currentPlan && apps.length < appLimit) ||
    (!currentPlan && apps.length < 3);

  useEffect(() => {
    if (app && !appId) {
      navigate("/pages");
    }
  }, [appId]);

  useEffect(() => {
    mixpanel.track("App Management Page View");
  }, []);

  // Disabling for now (foreign app install)
  // useEffect(() => {
  //   if (installAppId) {
  //     setDuplicateAppId(installAppId);
  //   }
  // }, [installAppId]);

  const deleteApp = (appId) =>
    setConfirmationModalData({
      title: "Delete App",
      text: "Are you sure you want to delete this app? This is really serious, and is irreversible. All pages, users, spreadsheets and everything else associated with this app will be removed permanently.",
      confirm: () => {
        successNotification("App Deleted");
        setApps(apps.filter((a) => a.id !== appId));
        apiRequest.delete("/app_settings/", {
          data: { id: appId },
        });
      },
    });

  const filteredApps = apps.filter((a) => {
    if (search === "") return true;
    return (
      safeLower(a.name).includes(safeLower(search)) ||
      safeLower(a.subdomain).includes(safeLower(search))
    );
  });

  const [duplicateAppId, setDuplicateAppId] = useState(null);

  const [appCreatedEmailType, setAppCreatedEmailType] = useState(null);

  if (appCreatedEmailType) {
    return (
      <AppCreatedEmailModal
        action={appCreatedEmailType}
        hide={() => {
          setAppCreatedEmailType(null);
        }}
      />
    );
  }

  const getDropdownOptions = (isActive, app) => {
    let deleteOption = [];

    const isOwner = get(user, "role") === "owner";

    if (isActive) {
      if (isOwner) {
        // Is active, is owner
        deleteOption = [
          {
            label: "Delete (Disabled)",
            icon: "FiTrash",
            onClick: () =>
              errorNotification("Sorry, but you can't delete your active app."),
          },
        ];
      }
    } else {
      // Not active
      if (isOwner) {
        deleteOption = [
          {
            label: "Delete",
            icon: "FiTrash",
            onClick: () => deleteApp(app.id),
          },
        ];
      }
    }

    return [
      ...deleteOption,
      {
        label: "Duplicate App",
        icon: "FiCopy",
        onClick: () => setDuplicateAppId(app.id),
      },
    ];
  };

  const activeApp = apps.find((a) => a.id === duplicateAppId);
  const activeAppName = get(activeApp, "name");

  return (
    <div>
      {showUpgradeModal && (
        <UpgradeModal hide={() => setShowUpgradeModal(false)} />
      )}
      {/* {duplicateAppId && duplicateAppId === installAppId && (
        <Modal
          hide={() => setDuplicateAppId(null)}
          minWidth="600px"
          header={{
            title: `Install the ${startCase(
              installAppId
            )} app from another user?`,
            description:
              "This is an app made by another user of Frontly. If you don't know who built this app, DO NOT install this app. You should only see this if you deliberately followed an install link provided by another user who you trust. Are you sure you want to install this app?",
          }}
        >
          <Row gap={"15px"} margin="10px 0 0 0">
            <Button
              data={{
                text: "Install This App",
                onClick: () => duplicateApp(duplicateAppId, "new"),
              }}
            />
          </Row>
        </Modal>
      )} */}
      {/* && duplicateAppId !== installAppId */}
      {duplicateAppId && (
        <CreateNewAppModal
          title={`Duplicate '${activeAppName}' App`}
          hide={() => setDuplicateAppId(null)}
          onClick={(installLocation, service) => {
            setDuplicateAppId(null);
            setAppCreatedEmailType("duplicated");
            apiRequest.post("/copy_app/", {
              app_id: duplicateAppId,
              service,
              action: "duplicate",
              install_location: installLocation,
            });
          }}
        />
      )}

      {showNewAppModal && (
        <CreateAppModal
          type={showNewAppModal}
          hide={() => setShowNewAppModal(null)}
          showAppCreated={setAppCreatedEmailType}
        />
      )}
      {showCreateModal && (
        <CreateModal
          hide={() => setShowCreateModal(false)}
          showNewApp={(createType) => {
            setShowNewAppModal(createType);
            setShowCreateModal(false);
          }}
        />
      )}
      <Row justifyContent="space-between" margin="0 0 30px 0">
        <Label>Apps</Label>
        <Row alignItems="center" gap="15px">
          <SearchBar
            data={{
              value: search,
              placeholder: "Search apps",
              onChange: (v) => setSearch(v),
            }}
          />
          {get(user, "can_create_apps") && (
            <Button
              data={{
                text: "New App",
                icon: "FiPlus",
                onClick: () => {
                  if (canCreateApp) {
                    setShowCreateModal(true);
                  } else {
                    setShowUpgradeModal(true);
                  }
                },
              }}
            />
          )}
        </Row>
      </Row>

      <CardGrid
        noResultsName={"apps"}
        items={filteredApps.map((a) => {
          const isActive = a.id === appId;
          const dropdownOptions = getDropdownOptions(isActive, a);

          return {
            title: a.name,
            description: a.subdomain,
            onClick: () => {
              if (isActive) {
                navigate("/pages");
              } else {
                fetchAppConfig({
                  appId: a.id,
                  callback: () => navigate("/pages"),
                });
              }
            },
            badges: [
              {
                color: a.id === appId ? "#b8e7d2" : "#F0F0F0",
                text: a.id === appId ? "Current App" : "Click To Open",
              },
            ],
            options: dropdownOptions,
          };
        })}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

const Apps = () => {
  return (
    <AdminWrapper>
      <AppsContent />
    </AdminWrapper>
  );
};

export default Apps;

export const UpgradeModal = ({ hide }) => {
  const navigate = useNavigate();

  return (
    <Modal header={{ title: "Upgrade To Create More Apps" }} hide={hide}>
      <Text
        data={{
          fontStyle: "bodyLg",
          text: "You've reached your plan limit. To create more apps, upgrade your plan.",
        }}
      />

      <Button
        data={{
          text: "View Pricing Plans",
          onClick: () => {
            hide();
            navigate("/settings/billing");
          },
          size: "large",
          margin: "20px 0 0 0",
        }}
      />
    </Modal>
  );
};
