import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isEmpty } from "lodash";
import { rApp, rSetConfig } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { AdminForm } from "../components";
import { Modal } from "app/components";
import { apiRequest } from "app/utils/apiRequests";
import { decryptConfig } from "app/utils/encryptionUtils";
import { isValidSubdomain } from "app/utils/utils";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateAppModal = ({ type, hide, showAppCreated }) => {
  const setConfig = useSetRecoilState(rSetConfig);

  const [isFetching, setIsFetching] = useState(false);
  const [errors, errorsSet] = useState({});

  const app = useRecoilValue(rApp);

  const hasSupabase = !!get(app, "supabase_project_id");
  const hasGoogle = get(app, "google_sheets_connected");
  const hasAirtable = !!get(app, "airtable_token");

  const hasDataIntegrations = hasSupabase || hasGoogle || hasAirtable;

  const [formData, setFormData] = useState({
    name: "New App",
    subdomain: "",
    copy_data_integrations: hasDataIntegrations,
    ai_prompt: "",
    data_source_type: "frontly_db",
  });

  const navigate = useNavigate();

  const { name, subdomain, ai_prompt, data_source_type } = formData;

  const copyDataIntegrations = get(formData, "copy_data_integrations");

  const isFormValid = () => {
    let errors = {};

    if (!name) {
      errors["name"] = "Please enter a valid name.";
    } else if (name.length < 3) {
      errors["name"] = "Please enter a longer name.";
    }

    errors = { ...errors, ...isValidSubdomain(subdomain) };

    errorsSet(errors);

    return isEmpty(errors);
  };

  const createApp = () => {
    if (!isFormValid()) return;

    if (type === "ai") {
      // TODO - Figure out how to wait 3 seconds to check if there were any immediate errors, but then stop waiting
      showAppCreated("generated");
      hide();
      apiRequest.post("/create_app/", {
        name,
        subdomain,
        copy_data_integrations: copyDataIntegrations,
        ai_prompt,
      });
    } else {
      setIsFetching(true);
      apiRequest
        .post("/create_app/", {
          name,
          subdomain,
          copy_data_integrations: copyDataIntegrations,
        })
        .then((response) => {
          const responseData = get(response, "data");
          // console.log("RESPONSE DATA", responseData);

          const error = get(responseData, "error");
          // console.log("ERROR", error);

          if (error) {
            errorNotification(error);
            setIsFetching(false);
          } else {
            const data = decryptConfig(responseData);
            successNotification("App Created");
            setConfig(data);
            hide();
            mixpanel.track("App Created", { template: "blank" });
            navigate("/pages");
            localStorage.setItem("appId", get(data, ["app", "id"]));
          }
        })
        .catch((res) => {
          // console.log("RES", res);
          const error = get(res, "error");
          // console.log("ERROR", error);
          errorNotification("Something went wrong.");
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  let fields = [
    {
      id: "name",
      componentId: "Input",
      label: "Name",
      value: name,
    },
    {
      id: "subdomain",
      componentId: "Input",
      label: "Subdomain",
      value: subdomain,
      hint: "A subdomain is the web address for your frontly-branded domain, like myappname.frontly.ai. This can be changed from settings at any time.",
    },
  ];

  // Add copy data integrations field if there are data integrations
  if (hasDataIntegrations) {
    fields = [
      ...fields,
      {
        id: "copy_data_integrations",
        componentId: "Switch",
        label: "Copy Existing Data Integrations",
        hint: "If enabled, we'll copy your current app's data integration connections to the new app.",
        value: copyDataIntegrations,
      },
    ];
  }

  // Add AI Prompt field
  if (type === "ai") {
    fields = [
      ...fields,
      {
        id: "ai_prompt",
        componentId: "TextArea",
        label: "Describe the app you want to create",
        minHeight: "100px",
        value: ai_prompt,
        hint: "This prompt will be used to generate the app.",
      },
    ];

    // If google connected, give a choice as to where the data is generated
    if (hasGoogle) {
      fields = [
        ...fields,
        {
          id: "data_source_type",
          componentId: "SelectToggle",
          label: "Data Source Types",
          tabs: [
            {
              label: "Frontly Database",
              value: "frontly_db",
            },
            {
              label: "Google Sheets",
              value: "google",
            },
          ],
          value: data_source_type,
          hint: "The data source type used to store the generated app's data",
        },
      ];
    }
  }

  return (
    <Modal
      minWidth={type === "ai" ? "550px" : "400px"}
      header={{
        title:
          type === "ai" ? "Generate a starter app with AI" : "Create New App",
        description:
          type === "ai"
            ? "We'll generate some pages and data tables to get you started"
            : "",
      }}
      hide={hide}
    >
      <AdminForm
        isFetching={isFetching}
        labelStyle="headingSm"
        submit={createApp}
        errors={errors}
        submitText={type === "ai" ? "Generate Starter App" : "Create App"}
        onChange={(k, v) =>
          setFormData({
            ...formData,
            [k]: v,
          })
        }
        sectionPadding="0px"
        fields={fields}
      />
    </Modal>
  );
};

export default CreateAppModal;
