import { rPageBlocks, rPageChanges } from "./recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

const useBlocks = () => {
  const [blocks, setBlocks] = useRecoilState(rPageBlocks);
  const setPageChanges = useSetRecoilState(rPageChanges);

  // Update blocks and set page changes to true
  const setBlocksWithChanges = (newBlocks) => {
    setBlocks(newBlocks);
    setPageChanges(true);
  };

  return { blocks, setBlocks: setBlocksWithChanges };
};

export default useBlocks;
