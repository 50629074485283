import { Button, Icon, Text } from "app/components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  allBlocks,
  fixBlocks,
  fixCustomBlocks,
  getBlockLabel,
  isBlockChildOf,
} from "app/utils/utils";
import { errorNotification, successNotification } from "app/utils/Notification";
import { get, isNil } from "lodash";
import { rActiveBlockId, rActiveDetailViewId } from "app/utils/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { NewBlock } from "./BlockManager/NewBlock";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveCustomBlock from "app/utils/useActiveCustomBlock";
import useBlocks from "app/utils/useBlocks";
import { useState } from "react";

const BlockHierarchy = () => {
  const { activeCustomBlock } = useActiveCustomBlock();

  const activeBlock = useActiveBlock();

  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);

  const activeBlockId = useRecoilValue(rActiveBlockId);

  const [showNewBlock, setShowNewBlock] = useState(false);

  const { blocks: pageBlocks, setBlocks: setPageBlocks } = useBlocks();

  // Initialize blocks
  const originalBlocks = fixCustomBlocks(fixBlocks(pageBlocks));

  let blocks = [...originalBlocks];

  let rootBlock = null;
  if (activeBlockId) {
    rootBlock = activeBlockId;
  } else if (activeDetailViewId) {
    rootBlock = activeDetailViewId;
  }

  const isDetailViewRoot =
    activeDetailViewId &&
    (!activeBlockId || activeBlockId === activeDetailViewId);

  if (!activeDetailViewId) {
    // Filter out custom detail view blocks
    blocks = blocks.filter((block) => isNil(block.parent));
  }

  // Function to find children of a given block
  const findChildren = (parentId, parentField = "layoutParent") => {
    return blocks.filter((block) => {
      if (!parentId) {
        return !get(block, parentField);
      }
      return get(block, parentField) === parentId;
    });
  };

  // Function to recursively build the ordered list of blocks
  const buildBlockList = (
    parentId = null,
    list = [],
    parentField = "layoutParent"
  ) => {
    // Find children of the current parent
    const children = findChildren(parentId, parentField);

    children.forEach((child) => {
      list.push(child); // Add the child
      buildBlockList(child.id, list); // Recursively add children of this child
    });
    return list;
  };

  // If the block is the root block AND there is an activeDetailViewId, the field to check is 'parent' instead of 'layoutParent'
  const parentField = isDetailViewRoot ? "parent" : "layoutParent";

  // Initialize the block list starting with root blocks (blocks without a parent)
  const orderedBlocks = buildBlockList(rootBlock, [], parentField);

  // Function to calculate the depth of each block for indentation
  const calculateDepth = (block, depth = 0) => {
    if (!block) {
      return 0;
    }

    if (!block.layoutParent) {
      return depth;
    }
    const parentBlock = blocks.find((b) => b.id === block.layoutParent);
    return calculateDepth(parentBlock, depth + 1);
  };

  const rootBlockDepth = isDetailViewRoot
    ? 0
    : rootBlock
    ? calculateDepth(blocks.find((b) => b.id === rootBlock)) + 1
    : 0;

  // Adding depth information to each ordered block
  const blocksWithDepth = orderedBlocks.map((block) => ({
    ...block,
    depth: calculateDepth(block) - rootBlockDepth,
  }));

  const iconMap = allBlocks.reduce((acc, block) => {
    acc[block.type] = block.icon;
    return acc;
  }, {});

  // HANDLE DRAG AND DROP
  const onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    const isMovingDown = source.index < destination.index;

    if (!destination) return;

    const blockBeforeMod = isMovingDown ? 0 : -1;

    const destinationIndex = destination?.index;

    if (destinationIndex === source.index) return;

    const blockBefore = orderedBlocks.find(
      (b, bi) => bi === destinationIndex + blockBeforeMod
    );

    const blockBeforeIsRowOrColumn = ["Row", "Column"].includes(
      blockBefore?.componentId
    );

    const blockAfter = blocks.find((b, bi) => bi === destinationIndex);

    const blockAfterIsInRowOrColumn = blockAfter?.layoutParent;

    // Write a function that takes the block and `blocks` and returns the root parent
    // This involves recursively finding the parent of the parent until there is no 'layoutParent'
    const findBlockRootParent = (block) => {
      if (!block.layoutParent) {
        return null;
      }
      const parentBlock = blocks.find((b) => b.id === block.layoutParent);
      return findBlockRootParent(parentBlock);
    };

    let movedBlock = { ...blocks.find((b) => b.id === parseInt(draggableId)) };

    // HANDLE ERROR IF MOVING BLOCK INSIDE ITS OWN CHILD
    if (blockBefore) {
      const isChildOf = isBlockChildOf(blockBefore, movedBlock, blocks);
      if (isChildOf) {
        errorNotification(
          "You cannot move a block into another block that is its child."
        );
        // INVALID ACTION, ABORT
        return;
      }
    }

    if (blockBefore) {
      // HAS BLOCK BEFORE
      // console.log("BLOCK BEFORE", blockBefore);

      // BLOCK IS BEFORE A ROW OR COLUMN
      if (blockBeforeIsRowOrColumn) {
        // console.log("MOVING TO ROW OR COLUMN", blockBefore.componentId);
        movedBlock = {
          ...movedBlock,
          layoutParent: blockBefore.id,
          parent: null,
        };
      } else {
        // BLOCK BEFORE IS NOT A ROW OR COLUMN
        if (!blockBefore?.layoutParent) {
          // console.log("MOVING TO ROOT");

          if (activeDetailViewId) {
            movedBlock = {
              ...movedBlock,
              layoutParent: null,
              parent: activeDetailViewId,
            };
          } else {
            movedBlock = {
              ...movedBlock,
              layoutParent: null,
              parent: null,
            };
          }
        } else if (blockBefore?.layoutParent) {
          // MOVING TO PARENT OF BLOCK BEFORE
          // console.log("MOVING TO PARENT OF", blockBefore.layoutParent);
          movedBlock = {
            ...movedBlock,
            layoutParent: blockBefore?.layoutParent,
            parent: null,
          };
        } else if (blockAfterIsInRowOrColumn) {
          // console.log("MOVING TO ROW/COLUMN", blockBefore.id);
          movedBlock = {
            ...movedBlock,
            layoutParent: blockAfterIsInRowOrColumn, // this also actually stores the layoutparent id
            parent: null,
          };
        }
      }
    } else if (!blockBefore) {
      // THERE IS NO BLOCK BEFORE, MOVING TO ROOT
      // console.log("MOVING TO ROOT");
      if (activeDetailViewId) {
        movedBlock["parent"] = rootBlock;
        movedBlock["layoutParent"] = null;
      } else {
        movedBlock = {
          ...movedBlock,
          parent: null,
          layoutParent: rootBlock, // this rootBlock may be null or a root depending on what we're working on
        };
      }
    }

    // console.log("MOVED BLOCK", movedBlock);

    let reorderedBlocks = [...orderedBlocks].map((block) => {
      // console.log("BLOCK", block.id, parseInt(draggableId));
      if (block.id === parseInt(draggableId)) {
        return {
          ...block,
          ...movedBlock,
        };
      }
      return block;
    });

    // Find the block that was dragged and remove it from the list
    const [reorderedItem] = reorderedBlocks.splice(source.index, 1);

    // Insert it into its new position
    reorderedBlocks.splice(destination.index, 0, reorderedItem);

    // If there is a 'rootBlock' set, get the list of other blocks NOT in this list
    // Once reordered blocks is set, figure out where to insert this list within the existing list (at what index)
    // Actually, because we didn't move the root block, it would be whatever index the root block is in the existing list of all blocks

    // 1. Find the index
    // 2. Filter out all the reordered blocks from the original list
    // 3. Insert the reordered blocks at the index

    const lostBlocks = originalBlocks.filter(
      (b) => !reorderedBlocks.map((b) => b.id).includes(b.id)
    );

    const rootBlockIndex = lostBlocks.findIndex((b) => b.id === rootBlock);

    if (rootBlock) {
      // create a new array which is the lostBlocks, with reorderedBlocks inserted at rootBlockIndex
      const newBlocks = [
        ...lostBlocks.slice(0, rootBlockIndex),
        ...reorderedBlocks,
        ...lostBlocks.slice(rootBlockIndex),
      ];

      const rootBlox =
        activeCustomBlock &&
        newBlocks.filter((b) => !b.layoutParent && !b.parent).length;

      // Don't allow this action
      if (rootBlox > 1) {
        errorNotification("Custom blocks can only have one root block");
        return;
      }

      // Update the blocks with the new order
      setPageBlocks(newBlocks);
    } else {
      // Update the blocks with the new order
      const newBlocks = [...reorderedBlocks, ...lostBlocks];

      const rootBlox =
        activeCustomBlock &&
        newBlocks.filter((b) => !b.layoutParent && !b.parent).length;

      // Don't allow this action
      if (rootBlox > 1) {
        errorNotification("Custom blocks can only have one root block");
        return;
      }

      setPageBlocks(newBlocks);
    }
  };

  const remove = (blockId) => {
    const newBlocks = originalBlocks.map((b) => {
      if (b.id === blockId) {
        let newBlock = {
          ...b,
          layoutParent: null,
          parent: null,
        };

        if (activeDetailViewId) {
          newBlock["parent"] = activeDetailViewId;
        }

        return newBlock;
      }

      return b;
    });

    setPageBlocks(newBlocks);

    successNotification("Removed from layout");
  };

  return (
    <>
      {showNewBlock && (
        <NewBlock
          isRowOrColumn={true}
          layoutParent={activeBlock ? activeBlock.id : null}
          hide={() => setShowNewBlock(false)}
        />
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="root">
          {(provided) => (
            <Container {...provided.droppableProps} ref={provided.innerRef}>
              {blocksWithDepth.map((block, index) => {
                return (
                  <Draggable
                    key={block.id}
                    draggableId={block.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <Block
                        remove={remove}
                        block={block}
                        provided={provided}
                        icon={get(iconMap, block.componentId)}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        data={{
          text: "Add Block",
          onClick: () => setShowNewBlock(true),
          size: "small",
          type: "basic",
          icon: "FiPlus",
          margin: "20px 0 0 0",
        }}
      />
    </>
  );
};

export default BlockHierarchy;

const Block = ({ provided, block, icon, remove }) => {
  const { activeCustomBlock } = useActiveCustomBlock();
  const setActiveBlockId = useSetRecoilState(rActiveBlockId);
  const [hover, setHover] = useState(false);

  const mouseEvents =
    !activeCustomBlock && block.layoutParent
      ? {
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false),
        }
      : {};

  return (
    <BlockContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...mouseEvents}
      onClick={() => {
        setActiveBlockId(block.id);
      }}
      style={{
        marginLeft: `${block.depth * 25}px`,
        ...provided.draggableProps.style,
      }}
    >
      <Text
        data={{
          text: getBlockLabel(block),
          cursor: "pointer",
          fontStyle: "bodyLg",
          whiteSpace: "nowrap",
        }}
      />
      {hover ? (
        <>
          <Tooltip
            anchorSelect={`.removeFromLayout2`}
            place="bottom"
            style={{ zIndex: 9999, fontSize: "12px", padding: "8px" }}
          >
            Remove from layout
          </Tooltip>
          <div className="removeFromLayout2" style={{ height: "18px" }}>
            <Icon
              data={{
                icon: "FiArrowUpLeft",
                size: 17,
                hover: true,
                onClick: (e) => {
                  e.stopPropagation();
                  remove(block.id);
                },
              }}
            />
          </div>
        </>
      ) : (
        <div style={{ height: "18px" }}>
          <Icon
            data={{
              icon,
              size: 17,
              hover: true,
            }}
          />
        </div>
      )}
    </BlockContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 5px;
  max-height: 500px;
`;

const BlockContainer = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  border: 1px solid var(--grey21);
  &:hover {
    background-color: #f0f0f0;
  }
`;
