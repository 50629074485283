import { Button, Modal, SearchBar, Text } from "app/components";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import { metrics } from "../blockConfigs/Stat";
import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import { useState } from "react";

const MetricBrowser = ({ data }) => {
  const [search, setSearch] = useState("");
  const [showBrowser, setShowBrowser] = useState(false);

  const activeBlock = useActiveBlock();

  const selectedMetric = get(data, "value", "Count");

  const isDataBase =
    get(activeBlock, "spreadsheet") &&
    get(activeBlock, "spreadsheet").toString().includes("db__");

  const filteredMetrics = metrics.filter((m) => {
    if (isDataBase && !["Count", "Average", "Sum"].includes(m.label)) {
      return false;
    }

    if (search === "") return m;
    return m.label.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <Button
        data={{
          text: selectedMetric,
          // size: "small",
          type: "basic",
          onClick: () => setShowBrowser(true),
        }}
      />

      {showBrowser && (
        <Modal
          minWidth="800px"
          header={{
            title: "Select Metric",
            description:
              "Turn your spreadsheet data into a calculated field using one of our formulas below",
          }}
          hide={() => setShowBrowser(false)}
        >
          <SearchBar
            data={{
              value: search,
              onChange: (v) => setSearch(v),
            }}
          />
          <MetricsGrid>
            {filteredMetrics.map((m) => (
              <Metric
                active={selectedMetric === m.label}
                onClick={() => data.onChange(m.label)}
              >
                <Text
                  data={{
                    text: m.label,
                    fontStyle: "headingMd",
                    cursor: "pointer",
                  }}
                />
                <Text
                  data={{
                    text: m.description,
                    fontStyle: "bodyMd",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                />
              </Metric>
            ))}
          </MetricsGrid>
        </Modal>
      )}
    </>
  );
};

export default MetricBrowser;

const Metric = styled.div`
  border: 4px solid
    ${(p) => (p.active ? "var(--primary-admin)" : "transparent")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  gap: 5px;
  cursor: pointer;
  background: ${colors.clickUpBackground};
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  gap: 15px;
  margin-top: 20px;
`;
