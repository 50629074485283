import {
  DateTimePicker,
  FileUpload,
  Icon,
  ImageUpload,
  Input,
  Options,
  Row,
  Spinner,
  Text,
  TextArea,
} from "app/components";
import { addSlash, getUrlParameter, isFrontlyAdmin } from "app/utils/utils";
import { get, isEmpty } from "lodash";
import { rApp, rFormIsFetching } from "app/utils/recoil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import useValidateFields, { getValidationObject } from "app/utils/validation";

import styled from "styled-components";
import useActionResolver from "../useActionResolver";
import useDynamicText from "../useDynamicText";
import useUtils from "../useUtils";
import useWindowSize from "app/utils/useWindowSize";

const componentMap = {
  Input,
  Options,
  TextArea,
  DateTimePicker,
  FileUpload,
  ImageUpload,
};

const FullScreenForm = ({
  data,
  editMode,
  forceStep = undefined,
  setForceStep,
}) => {
  const { processDynamicText } = useDynamicText();
  const { passesDisplayConditions } = useUtils();
  const [formData, setFormData] = useState({});

  const fields = get(data, "fields", []).filter((f) => {
    const isPass = passesDisplayConditions({
      conditions: f.displayConditions,
      context: { form: formData },
    });
    return isPass;
  });

  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);

  const { validateFields } = useValidateFields();

  const currentStep = forceStep !== undefined ? forceStep : step;

  const [formIsFetching, setFormIsFetching] = useRecoilState(rFormIsFetching);

  const isFetching = formIsFetching === data.id;

  const navigate = useNavigate();

  const location = useLocation();

  const backRoute = getUrlParameter("back", location);

  const activeApp = useRecoilValue(rApp);

  const primaryColor = "var(--primary)";

  const { handleCustomAction } = useActionResolver();

  const { width } = useWindowSize();

  const currentField =
    currentStep > fields.length - 1 ? get(fields, 0) : get(fields, currentStep);

  const validationObject = getValidationObject(fields);

  const currentKey = get(currentField, "key");

  const currentFieldValidationObject = {
    [currentKey]: get(validationObject, currentKey),
  };

  const currentValue = get(formData, currentKey, "");

  const componentId = get(currentField, "componentId");

  const Component = get(componentMap, componentId);

  const textColor = get(data, "textColor", "light");

  let variableObj = {};

  if (componentId === "FileUpload") {
    variableObj["size"] = "large";
  }

  const logo = get(data, "logo") || get(activeApp, "navigation_logo");

  const nextStep = () => {
    if (setForceStep) {
      setForceStep(undefined);
    }

    const valResults = validateFields({
      validationSchema: currentFieldValidationObject,
      values: formData,
      hasSubmitted: true,
      setErrors: (o) => setError(get(o, currentKey)),
    });

    if (isEmpty(valResults)) {
      // NAVIGATE TO NEXT STEP
      if (step < fields.length) {
        setStep(step + 1);
      }

      if (step === fields.length - 1) {
        if (!isFrontlyAdmin) {
          const submitAction = get(data, "submitAction");
          handleCustomAction({
            action: submitAction,
            context: { form: formData },
            formId: get(data, "id"),
            formActionType: "submitAction",
          });
        }
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && componentId !== "TextArea") {
        nextStep();
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [componentId, nextStep]);

  if (fields.length === 0) {
    return (
      <Background
        color={data.backgroundColor || primaryColor}
        editMode={editMode}
      >
        <Container>
          {data.showLogo && <Logo src={logo} />}
          <Title color={textColor}>Your form is waiting to be created</Title>
          <Description color={textColor}>
            Add fields to see it come to life
          </Description>
        </Container>
      </Background>
    );
  }

  if (isFetching) {
    return (
      <Background
        color={data.backgroundColor || primaryColor}
        editMode={editMode}
      >
        <Container>
          {data.showLogo && <Logo src={logo} />}
          <Title style={{ margin: "0 0 20px 0" }} color={textColor}>
            Processing your submission
          </Title>
          <Spinner color={textColor} size={40} />
        </Container>
      </Background>
    );
  }

  const colorProperties =
    textColor === "light"
      ? {
          color: "white",
          background: "transparent",
          borderColor: "white",
          placeholderColor: "rgba(255,255,255,0.5)",
        }
      : {
          color: "var(--grey8)",
          background: "transparent",
          borderColor: "white",
          placeholderColor: "rgba(0,0,0,0.5)",
        };

  return (
    <Background
      color={data.backgroundColor || primaryColor}
      editMode={editMode}
    >
      {step < fields.length && (
        <Container>
          {data.showLogo && <Logo src={logo} />}
          <Header>
            {backRoute && (
              <BackRow onClick={() => navigate(addSlash(backRoute))}>
                <Icon
                  data={{
                    icon: "FiArrowLeft",
                    color: "white",
                    size: 25,
                    hover: true,
                  }}
                />
                <Text
                  data={{
                    text: "Back",
                    color: "white",
                    fontStyle: "bodyLg",
                    cursor: "pointer",
                  }}
                />
              </BackRow>
            )}
            <Title color={textColor}>
              {processDynamicText({
                text: get(currentField, "label"),
                context: {
                  form: formData,
                },
              })}
            </Title>
            <Description color={textColor}>
              {processDynamicText({
                text: get(currentField, "description"),
                context: {
                  form: formData,
                },
              })}
            </Description>
          </Header>
          <Body>
            <Component
              data={{
                value: currentValue,
                ...currentField,
                ...variableObj,
                ...colorProperties,
                textColor,
                borderRadius: "0px",
                borderWidth: "0px 0px 1px 0px",
                fontStyle: width > 800 ? "body2xl" : "bodyXl",
                padding: "20px 0px 30px 0px",
                minHeight: "70px",
                onChange: (v) => {
                  setFormData({
                    ...formData,
                    [currentKey]: v,
                  });
                  setError(null);
                },
              }}
            />
          </Body>
          <Footer>
            {error && (
              <Error>
                <Text
                  data={{
                    text: error,
                    color: "#df516e",
                    fontStyle: "headingSm",
                  }}
                />
              </Error>
            )}
            {!error && (
              <Row alignItems="center" gap="15px" margin="20px 0 0 0">
                <Button onClick={nextStep} color={data.buttonColor}>
                  <ButtonText color={data.buttonTextColor}>
                    {data.nextButtonText || "OK"}
                  </ButtonText>
                  <Icon
                    data={{
                      icon: "FiCheck",
                      color: data.buttonTextColor || "var(--grey5)",
                      hover: true,
                    }}
                  />
                </Button>

                {width > 800 && (
                  <Row alignItems="center" gap="3px">
                    <Text
                      data={{
                        color: textColor === "light" ? "white" : "var(--grey8)",
                        text: data.pressEnterText || "Press enter",
                        fontStyle: "bodySm",
                      }}
                    />
                    <Icon
                      data={{
                        icon: "FiCornerDownLeft",
                        color: textColor === "light" ? "white" : "var(--grey8)",
                        size: 18,
                      }}
                    />
                  </Row>
                )}
              </Row>
            )}
          </Footer>
        </Container>
      )}

      {step === fields.length && (
        <Container>
          {data.showLogo && <Logo src={logo} />}
          <Title color={textColor}>
            {data.completeMessage || "Thanks for your submission"}
          </Title>
          <Description color={textColor}>
            {data.completeDescription || "Our team will be in touch"}
          </Description>
          <Button
            onClick={() => {
              if (!isFrontlyAdmin) {
                const completeButtonAction = get(data, "completeButtonAction");
                handleCustomAction({
                  action: completeButtonAction,
                  context: { form: formData },
                  formId: get(data, "id"),
                  formActionType: "completeButtonAction",
                });
              }
            }}
            style={{ marginTop: "20px" }}
          >
            <ButtonText>{data.completeButtonText || "Return Home"}</ButtonText>
          </Button>
        </Container>
      )}

      {step < fields.length && (
        <ArrowButtons
          step={step}
          fieldsLength={fields.length}
          setStep={setStep}
          nextStep={nextStep}
        />
      )}
    </Background>
  );
};

export default FullScreenForm;

const ArrowButtons = ({ step, fieldsLength, setStep, nextStep }) => {
  return (
    <ArrowButtonsContainer>
      <ArrowButton
        disabled={step === 0}
        onClick={() => {
          if (step !== 0) {
            setStep(step - 1);
          }
        }}
        style={{ borderRight: `1px solid var(--grey21)` }}
      >
        <Icon
          data={{
            icon: "FiChevronUp",
            color: "var(--grey7)",
            size: 20,
            hover: true,
            disabled: step === 0,
          }}
        />
      </ArrowButton>
      <ArrowButton
        onClick={() => (step < fieldsLength - 1 ? nextStep() : null)}
        disabled={step === fieldsLength - 1}
      >
        <Icon
          data={{
            icon: "FiChevronDown",
            color: "var(--grey7)",
            size: 20,
            hover: true,
            disabled: step === fieldsLength - 1,
          }}
        />
      </ArrowButton>
    </ArrowButtonsContainer>
  );
};

const Header = styled.div``;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Footer = styled.div``;

const Error = styled.div`
  margin: 20px 0 0 0;
  background: white;
  border-radius: 50px;
  padding: 15px;
  width: fit-content;
`;

const Background = styled.div`
  background: ${(p) => p.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${(p) => (p.editMode ? "300px" : "0")};
  right: 0;
  bottom: 0;
`;

const ArrowButtonsContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
`;

const ArrowButton = styled.div`
  padding: 8px 10px 8px 10px;
  background: white;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    !p.disabled &&
    `
  &:hover {
    filter: brightness(90%);
  }
  `};
`;

const Container = styled.div`
  width: 800px;
  @media screen and (max-width: 1000px) {
    width: 80%;
    padding: 20px;
  }
  @media screen and (max-width: 800px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: ${(p) => (p.color === "light" ? "white" : "var(--grey8)")};
  @media screen and (max-width: 1000px) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: ${(p) => (p.color === "light" ? "white" : "var(--grey8)")};
  opacity: 0.5;
  margin-top: 8px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 20px 0 20px;
  height: 54px;
  background: ${(p) => p.color || "white"};
  border-radius: 30px;
  cursor: pointer;
  gap: 6px;
  @media screen and (max-width: 800px) {
    height: 42px;
    padding: 0 15px 0 15px;
  }
  &:hover {
    filter: brightness(90%);
  }
`;

const ButtonText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${(p) => p.color || "var(--grey5)"};
`;

const Logo = styled.img`
  height: 40px;
  position: absolute;
  top: 30px;
  left: 40px;
  @media screen and (max-width: 1000px) {
    top: 20px;
    left: 20px;
    margin-bottom: 30px;
    position: initial;
  }
`;

const BackRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 0 50px 0;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
