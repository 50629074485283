import { inputBlockResources } from "./shared";

export const Select = {
  componentId: "Select",
  resources: [
    ...inputBlockResources,
    {
      id: "options",
      label: "Options",
      componentId: "DataGrid",
      orientation: "vertical",
      hint: "Define the options to appear in your select dropdown.",
      newObject: { label: "Label", value: "Value" },
      orientation: "vertical",
      columns: [
        {
          key: "label",
          componentId: "Input",
        },
        {
          key: "value",
          componentId: "Input",
        },
      ],
    },
    {
      id: "showSearch",
      label: "Allow Search",
      hint: "Filter the options base on the input value",
      componentId: "Switch",
      required: false,
      defaultValue: true,
    },
    {
      id: "selectText",
      label: "Select Text",
      hint: "Override the default 'Select' text for when no value is selected",
      componentId: "DynamicString",
      required: false,
      defaultValue: "",
    },
    {
      id: "defaultValue",
      label: "Default Value",
      hint: "Enter a default value for this field",
      componentId: "DynamicString",
      required: false,
      defaultValue: "",
    },
    {
      id: "validation",
      label: "Validation",
      componentId: "FormValidationSimple",
    },
  ],
};
