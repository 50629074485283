import BlockHeader from "./BlockHeader";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { truncateText } from "app/utils/utils";

const Table = ({ page, data, hue, multi, large }) => {
  const tableRows = large ? data : data.slice(0, 3);

  const headers = Object.keys(data[0]);

  const truncateLength = multi ? 12 : 20;

  return (
    <>
      <BlockHeader name={page.name} hue={hue} large={large} />
      <TableContainer>
        <TableRow>
          {headers.map((header) => (
            <TableHeader large={large}>{header}</TableHeader>
          ))}
        </TableRow>
        {tableRows.map((row) => (
          <TableRow>
            {headers.map((header) => (
              <TableCell large={large}>
                <TableCellContent>
                  {truncateText(row[header], truncateLength)}
                </TableCellContent>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableContainer>
    </>
  );
};

export default Table;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  overflow: hidden;
  background: white;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
`;

const TableHeader = styled.div`
  font-weight: 600;
  font-size: 9px;
  min-width: ${(p) => (p.large ? 90 : 70)}px;
  max-width: ${(p) => (p.large ? 90 : 70)}px;
  padding: 5px;
  border-right: 1px solid ${colors.grey2};
  overflow: hidden;
  background: #f9f9f9;
`;

const TableCell = styled.div`
  font-weight: 500;
  font-size: 9px;
  padding: 5px;
  min-width: ${(p) => (p.large ? 90 : 70)}px;
  max-width: ${(p) => (p.large ? 90 : 70)}px;
  border-right: 1px solid ${colors.grey2};
  border-top: 1px solid ${colors.grey2};
  &:last-child {
    border-right: none;
  }
`;

const TableCellContent = styled.div`
  overflow: hidden;
`;
