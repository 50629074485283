import AppCreatedEmailModal, {
  CreateNewAppModal,
} from "./AppCreatedEmailModal";

import { Modal } from "app/components";
import TemplateDetails from "../new_templates/TemplateDetails";
import { apiRequest } from "app/utils/apiRequests";
import { get } from "lodash";
import { templates } from "../new_templates/templates";
import { useState } from "react";

const TemplateModal = ({ activeTemplateId, setActiveTemplateId }) => {
  const [copyTemplateId, setCopyTemplateId] = useState(null);

  const activeTemplate = templates.find((t) => t.id === activeTemplateId);

  const [showAppCreatedEmailModal, setShowAppCreatedEmailModal] =
    useState(false);

  if (showAppCreatedEmailModal) {
    return (
      <AppCreatedEmailModal
        hide={() => {
          setShowAppCreatedEmailModal(false);
          setActiveTemplateId(null);
        }}
      />
    );
  }

  const activeTemplateName = get(activeTemplate, "name");

  if (copyTemplateId) {
    return (
      <CreateNewAppModal
        title={`Install '${activeTemplateName}' Template`}
        hide={() => setCopyTemplateId(null)}
        onClick={(installLocation, service) => {
          setCopyTemplateId(null);
          setShowAppCreatedEmailModal(true);
          apiRequest.post("/copy_app/", {
            app_id: copyTemplateId,
            service,
            action: "template",
            install_location: installLocation,
          });
        }}
      />
    );
  }

  return (
    <Modal
      minWidth={"calc(100% - 100px)"}
      customVerticalPadding={50}
      header={{
        title: "Template Details",
      }}
      hide={() => setActiveTemplateId(null)}
    >
      <TemplateDetails
        inModal
        internal={true}
        templateId={activeTemplate.id}
        installTemplate={() => setCopyTemplateId(get(activeTemplate, "id"))}
      />
    </Modal>
  );
};

export default TemplateModal;
