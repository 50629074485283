import { Icon, Modal, Row, SearchBar, Tabs } from "app/components";
import { get, snakeCase, startCase } from "lodash";
import { getOperators, operators } from "app/utils/operatorUtils";

import styled from "styled-components";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";
import { useState } from "react";

const OperatorSelect = ({ data }) => {
  const activeBlock = useActiveBlock();

  const activeBlockSheet = useActiveBlockSheet();

  const [showOptions, setShowOptions] = useState(false);

  let fieldConfig = get(data, "fieldConfig", null);
  let activeColumn = get(data, ["activeField", "key"]);

  // Hack for the 'Stat' block until I can find a better way
  if (get(activeBlock, "componentId") === "Stat") {
    if (!fieldConfig && get(data, "getFieldConfigFromActiveBlock")) {
      const selectedField = get(activeBlock, "field");
      if (selectedField && activeBlockSheet) {
        activeColumn = selectedField;
        fieldConfig = get(activeBlockSheet, [
          "field_data",
          "config",
          selectedField,
        ]);
      }
    }
  }

  const changeValue = (sheetId) => {
    data.onChange(sheetId);
  };

  const value = get(data, "value", "");

  let dataType = get(fieldConfig, "type", "text");
  // Override data type if Frontly ID
  if (activeColumn === "frontly_id") {
    dataType = "integer";
  }

  const isArray = get(fieldConfig, "is_array", false);
  const service = get(data, "service"); // not defaulting to google here

  const operatorsObject = getOperators({
    dataType,
    isArray,
    service,
    context: get(data, "context"),
    allowAll: data.allowAll,
  });

  const { serviceOperators, recommended, other } = operatorsObject;

  const noServiceOperators = serviceOperators.length === 0;

  const allowedOperators = [...recommended, ...other].filter(
    (o) => noServiceOperators || serviceOperators.includes(o)
  );

  const matchingAllowedOperator = allowedOperators.find((o) => o === value);

  const matchingOperator = operators.find((o) => get(o, "value") === value);

  const invalidSelection = value && !matchingAllowedOperator;

  return (
    <>
      {showOptions && (
        <OperatorModal
          operatorsObject={operatorsObject}
          changeValue={changeValue}
          hide={() => setShowOptions(false)}
          value={value}
          dataType={dataType}
          isArray={isArray}
          service={service}
          activeColumn={activeColumn}
          invalidSelection={invalidSelection}
          matchingOperator={matchingOperator}
        />
      )}
      <DisplayRow onClick={() => setShowOptions(true)} width={data.width}>
        {invalidSelection}
        <OperatorDisplayName invalid={invalidSelection}>
          <span>
            {invalidSelection
              ? "Invalid Operator"
              : matchingOperator
              ? get(matchingOperator, "label")
              : "Select Operator"}
          </span>

          {invalidSelection ? (
            <Icon
              data={{
                icon: "FiAlertTriangle",
                size: 16,
                color: "red",
                hover: true,
              }}
            />
          ) : (
            <Icon data={{ icon: "FiEdit", size: 16, color: "var(--grey7)" }} />
          )}
        </OperatorDisplayName>
      </DisplayRow>
    </>
  );
};

export default OperatorSelect;

const DisplayRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--grey3);
  padding: 10px 12px 10px 13px;
  border-radius: 8px;
  cursor: pointer;
  width: ${({ width }) => width || "100%"};
  margin-bottom: 5px;
  background: white;
  &:hover {
    background: var(--grey2);
  }
`;

const OperatorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
  ${({ count }) => count === 1 && "grid-template-columns: 250px;"}
`;

const OperatorDisplayName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  color: ${({ invalid }) => (invalid ? "red" : "var(--grey9)")};
`;

const OperatorName = styled.div`
  font-size: 15px;
  font-weight: 600;
`;

const OperatorDescription = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: var(--grey7);
  margin-top: 4px;
`;

const OperatorCard = styled.div`
  border: 1px solid var(--grey3);
  padding: 10px 13px 10px 13px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  gap: 10px;
  ${({ active }) =>
    active && `background: var(--grey1); outline: 2px solid black;`}
  &:hover {
    background: var(--grey1);
  }
`;

const OperatorModal = ({
  operatorsObject,
  changeValue,
  hide,
  value,
  dataType,
  service,
  isArray,
  activeColumn,
  invalidSelection,
  matchingOperator,
}) => {
  const serviceNameMap = {
    supabase: "Supabase",
    airtable: "Airtable",
    google: "Google Sheets",
    frontly_db: "Frontly DB",
  };

  const { serviceOperators, recommended, other } = operatorsObject;

  const noServiceOperators = serviceOperators.length === 0;

  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("recommended");

  const currentOperatorKeys = tab === "recommended" ? recommended : other;

  // Filter operators based on the selected tab
  const filteredOperators = currentOperatorKeys.filter(
    (o) =>
      snakeCase(o).includes(snakeCase(search)) &&
      (serviceOperators.includes(o) || noServiceOperators)
  );

  const tabs = [
    { label: "Recommended", key: "recommended" },
    { label: "Other", key: "other" },
  ].map(({ label, key }) => ({
    label,
    active: tab === key,
    onClick: () => setTab(key),
  }));

  // Make operators map
  let operatorMap = {};
  operators.forEach((o) => {
    operatorMap[get(o, "value")] = o;
  });

  return (
    <Modal minWidth="800px" header={{ title: "Select Operator" }} hide={hide}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        margin="0 0 20px 0"
      >
        <Tabs
          data={{
            tabs,
          }}
        />
        {service && (
          <DataTypeSpan>
            Showing operators compatible with the{" "}
            <span style={{ fontWeight: 600, color: "black" }}>{dataType}</span>{" "}
            type in{" "}
            <span style={{ fontWeight: 600, color: "black" }}>
              {get(serviceNameMap, service, service)}
            </span>
            .
          </DataTypeSpan>
        )}
      </Row>

      <SearchBar
        data={{
          value: search,
          placeholder: "Search operators",
          onChange: (v) => setSearch(v),
        }}
      />
      <OperatorsGrid count={filteredOperators.length}>
        {filteredOperators.map((o) => {
          const operator = get(operatorMap, o, null);

          const description = get(operator, "description").replace(
            "Row value",
            `${activeColumn ? startCase(activeColumn) : "Row value"}`
          );

          return (
            <OperatorCard active={o === value} onClick={() => changeValue(o)}>
              <OperatorName>{get(operator, "label")}</OperatorName>
              <OperatorDescription>{description}</OperatorDescription>
            </OperatorCard>
          );
        })}
        {filteredOperators.length === 0 && (
          <OperatorName>No matching operators found</OperatorName>
        )}
      </OperatorsGrid>
      {isArray && (
        <PreviewText>
          Expecting the row value to be an array or comma-separated values
        </PreviewText>
      )}

      {invalidSelection && (
        <InvalidSelection>
          <Icon data={{ icon: "FiAlertTriangle", size: 16, color: "red" }} />
          <span>
            The operator{" "}
            <span style={{ fontWeight: 600 }}>
              {get(matchingOperator, "label", startCase(value))}
            </span>{" "}
            is not compatible with the{" "}
            <span style={{ fontWeight: 600 }}>{dataType}</span> type in{" "}
            <span style={{ fontWeight: 600 }}>
              {get(serviceNameMap, service, service)}
            </span>
          </span>
        </InvalidSelection>
      )}
    </Modal>
  );
};

const InvalidSelection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: red;
  margin-top: 20px;
`;

const PreviewText = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--grey7);
  margin-top: 20px;
`;

const DataTypeSpan = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: var(--grey7);
`;
