import { useNavigate, useParams } from "react-router-dom";

import AdminWrapper from "app/adminApp/home/AdminWrapper";
import EditBuilderProfile from "app/adminApp/experts/expert/EditBuilderProfile";
import Listings from "./Listings";
import { Tabs } from "app/components";

const MarketplaceAdmin = () => {
  const { tab } = useParams();

  const navigate = useNavigate();

  return (
    <AdminWrapper contentWidth="100%">
      {/* <SettingsSection
      width="100%"
      hideCard
      title="App Marketplace"
      description="List your apps, pages and actions for public distribution in Frontly's app marketplace"
      settingsCategory={"Advanced App Settings"}
    > */}
      <Tabs
        data={{
          fontSize: "20px",
          margin: "0 0 20px 0",
          tabs: [
            {
              label: "Listings",
              active: tab === "listings",
              onClick: () => navigate("/marketplace_admin/listings"),
            },
            {
              label: "Builder Profile",
              active: tab === "profile",
              onClick: () => navigate("/marketplace_admin/profile"),
            },
          ],
        }}
      />
      {tab === "listings" && <Listings />}
      {tab === "profile" && <EditBuilderProfile />}
    </AdminWrapper>
  );
};

export default MarketplaceAdmin;
