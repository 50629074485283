import AdminWrapper from "./AdminWrapper";
import { AppsContent } from "./Apps";
import DataSourcesCard from "./DataSourcesCard";
import { TemplatesSection } from "../appManagement/Templates";
import { get } from "lodash";
import { rUser } from "app/utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const Home = () => {
  const user = useRecoilValue(rUser);

  return (
    <AdminWrapper padding="0px">
      <Sections>
        <AppsContent itemsPerPage={8} />
        <DataSourcesCard />
        {get(user, "can_create_apps") && <TemplatesSection />}
      </Sections>
    </AdminWrapper>
  );
};

export default Home;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
`;
