import { Button, SearchBar } from "app/components";
import { get, isNil, truncate } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { Header } from "../landing/Header";
import RenderComponent from "./RenderComponent";
import { Template } from "../appManagement/Template";
import { getUrlParameter } from "app/utils/utils";
import styled from "styled-components";
import { templates } from "./templates";
import { useState } from "react";

const NewTemplates = () => {
  const location = useLocation();
  const urlSearch = getUrlParameter("search", location);
  const [search, setSearch] = useState(urlSearch);

  const filteredTemplates = templates.filter((t) =>
    t.name.toLowerCase().includes(search.toLowerCase())
  );

  const navigate = useNavigate();

  return (
    <Content>
      <Header />
      <HeaderText>Browse App Templates</HeaderText>
      <SubHeaderText>
        Choose an app template to jumpstart your business
      </SubHeaderText>
      <SearchBar
        data={{
          width: "300px",
          value: search,
          placeholder: "Search templates",
          onChange: (v) => setSearch(v),
        }}
      />
      <TemplatesContainer>
        {filteredTemplates.map((t, i) => (
          <Template
            small
            isDashboard
            data={t}
            onClick={() => navigate(`/templates/${t.id}`)}
          />
        ))}
      </TemplatesContainer>

      {filteredTemplates.length === 0 && (
        <>
          <NoResults>Not finding what you're looking for?</NoResults>
          <Button
            data={{
              text: "Generate with AI",
              icon: "HiSparkles",
              onClick: () => navigate("/signup"),
              margin: "20px 0 0 0",
              size: "large",
            }}
          />
        </>
      )}
    </Content>
  );
};

export default NewTemplates;

// export const Template = ({
//   data,
//   size = "small",
//   showCard = false,
//   internal = false,
//   onClick = null,
// }) => {
//   const navigate = useNavigate();

//   return (
//     <TemplateContainer
//       onClick={() => (onClick ? onClick() : navigate(`/templates/${data.key}`))}
//       showCard={showCard}
//     >
//       <TemplateContent data={data} size={size} hover={internal} />
//       <TemplateName size={size}>{data.name}</TemplateName>
//       <TemplateDescription size={size}>{data.description}</TemplateDescription>
//     </TemplateContainer>
//   );
// };

const ExtraContent = ({ page }) => {
  const extraBlocks = get(page, "extraBlocks", []);

  if (extraBlocks.length === 0) {
    return <></>;
  }

  return (
    <div style={{ margin: "10px 0 0 0" }}>
      {extraBlocks.map((b) => (
        <div>
          <TextLabel>{b.label}</TextLabel>
          <TextValue>{truncate(b.value, { length: 85 })}</TextValue>
        </div>
      ))}
    </div>
  );
};

const TextLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #484848;
  cursor: pointer;
`;

const TextValue = styled.div`
  font-size: 10px;
  font-weight: 300;
  margin-bottom: 4px;
  color: #242424;
  cursor: pointer;
`;

const GradientBackground = styled.div`
  width: 300px;
  height: 200px;
  ${(p) => p.size === "large" && "width: 600px; height: 400px;"}

  background-image: ${({
    src,
    backgroundColor,
    bottomGradient,
    rightGradient,
  }) => `
    ${
      bottomGradient
        ? `linear-gradient(to top, ${backgroundColor} 0%, transparent 20%),`
        : ""
    }
    ${
      rightGradient
        ? `linear-gradient(to left, ${backgroundColor} 0%, transparent 20%),`
        : ""
    }
    url(${src})
  `};
  background-size: cover;
  background-position: left;
  border-radius: 15px;
`;

export const TemplateContent = ({ data, size = "small", hover = false }) => {
  const [showPreview, setShowPreview] = useState(false);

  const firstPage = get(data, ["pages", 0], {});
  const secondPage = get(data, ["pages", 1]);

  const dataSources = get(data, "data_sources", []);

  const backgroundColor = get(data, "backgroundColor", "#f6f6f7");

  if (
    size === "large" &&
    data.screenshot &&
    (data.bottomGradient || data.rightGradient)
  ) {
    return (
      <GradientBackground
        src={data.screenshot}
        backgroundColor={backgroundColor}
        bottomGradient={data.bottomGradient}
        rightGradient={data.rightGradient}
        size={size}
      />
    );
  }

  if (size === "large" && data.screenshot) {
    return <Screenshot src={data.screenshot} />;
  }

  return (
    <ColorContainer
      hue={data.hue}
      size={size}
      onMouseEnter={() => setShowPreview(true)}
      onMouseLeave={() => setShowPreview(false)}
    >
      <PreviewContainer showPreview={hover && showPreview} hue={data.hue}>
        <Button
          data={{
            text: "View details",
            width: "140px",
            padding: "13px",
          }}
        />
        {data.isRealApp && (
          <Button
            data={{
              text: "See live demo",
              type: "basic",
              width: "140px",
              padding: "13px",
              onClick: () => window.open(`https://${data.key}.frontly.ai`),
            }}
          />
        )}
      </PreviewContainer>
      <Centered>
        {data.screenshot ? (
          <ColorScreenshot src={data.screenshot} />
        ) : (
          <>
            <PageContainer multi={secondPage} type={firstPage.type} size={size}>
              <RenderComponent
                page={firstPage}
                multi={!!secondPage}
                hue={data.hue}
                size={size}
                dataSources={dataSources}
              />
              <ExtraContent page={firstPage} />
            </PageContainer>
            {secondPage && (
              <PageContainer
                multi={!!secondPage}
                type={secondPage.type}
                relative
                size={size}
              >
                <RenderComponent
                  page={secondPage}
                  multi={!!secondPage}
                  hue={data.hue}
                  size={size}
                  dataSources={dataSources}
                />
                <ExtraContent page={secondPage} />
              </PageContainer>
            )}
          </>
        )}
      </Centered>
    </ColorContainer>
  );
};

const Screenshot = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  width: 600px;
  border-radius: 15px;
`;

const ColorScreenshot = styled.img`
  width: 300px;
  height: 200px;
  object-fit: contain;
  border-radius: 15px;
  padding: 10px;
`;

const NoResults = styled.div`
  font-size: 24px;
  opacity: 0.6;
  margin-top: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;

const HeaderText = styled.div`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 50px;
`;

const SubHeaderText = styled.div`
  font-size: 18px;
  opacity: 0.6;
  margin-bottom: 30px;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const typeWidthMap = {
  form: 170,
};

const PageContainer = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  padding: ${(p) => (p.size === "large" ? "13px" : "10px")};
  ${(p) =>
    p.size === "small" &&
    p.relative &&
    p.multi &&
    `
    width: 165px;
    transform: translate(-35px, 10px);
    `}
  ${(p) =>
    p.size === "small" &&
    !p.relative &&
    p.multi &&
    `
    width: 165px;
    transform: translate(35px, -10px);
    `}

      ${(p) =>
    p.size === "large" &&
    p.relative &&
    p.multi &&
    `
    width: ${get(typeWidthMap, p.type, 245)}px;
    transform: translate(-15px, 15px);
    `}
  ${(p) =>
    p.size === "large" &&
    !p.relative &&
    p.multi &&
    `
    width: ${get(typeWidthMap, p.type, 245)}px;
    transform: translate(15px, -15px);
    `}
`;

const ColorContainer = styled.div`
  background-color: hsl(${(p) => p.hue}, 63%, 50%, 0.2);
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 220px;
  min-width: 330px;

  ${(p) =>
    p.size === "large" &&
    `
    height: 350px;
    width: 550px;
  `}
`;

const TemplatesContainer = styled.div`
  display: grid;
  grid-template-columns: 370px 370px 370px;
  gap: 40px;
  justify-content: center;
  padding: 30px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    grid-template-columns: 370px 370px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 370px;
  }
`;

const TemplateContainer = styled.div`
  ${(p) =>
    p.showCard &&
    `
    padding: 20px;
    background: white;
    box-shadow: var(--card-shadow);
    &:hover {
      box-shadow: var(--card-shadow-hover);
    }
  `}
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const TemplateName = styled.div`
  font-size: ${(p) => (p.size === "small" ? "16px" : "20px")};
  font-weight: 700;
  margin-top: ${(p) => (p.size === "small" ? "10px" : "20px")};
`;

const TemplateDescription = styled.div`
  font-size: ${(p) => (p.size === "small" ? "14px" : "16px")};
  opacity: 0.6;
  margin-top: ${(p) => (p.size === "small" ? "4px" : "8px")};
`;

const PreviewContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: hsl(${(p) => p.hue}, 63%, 30%, 0.3);
  opacity: ${(p) => (p.showPreview ? "1.0" : "0.0")};
  transition: 300ms;
  cursor: pointer;
  border-radius: 8px;
  z-index: 1000;
`;
