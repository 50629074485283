import {
  listBlockResources,
  removeFromLayout,
  saveCustomBlockButton,
  sortingColumn,
  sortingDirection,
} from "app/adminApp/blockConfigs/shared";
import {
  rActiveVersionMap,
  rApp,
  rCustomBlocks,
  rFetchingBlockIds,
  rPageBlocks,
  rSavedSpreadsheets,
} from "app/utils/recoil";
import { useEffect, useState } from "react";

import AdminForm from "app/adminApp/components/AdminForm";
import BlockVersionConfig from "./BlockVersionConfig";
import BlockWizard from "./BlockWizard";
import { SelectToggle } from "app/components";
import UserGroupPermissions from "app/adminApp/components/UserGroupPermissions";
import { blockConfigs } from "app/adminApp/blockConfigs";
import { get } from "lodash";
import { getEndUserCustomFields } from "app/adminApp/components/CustomBlockFieldEditor";
import { layouts } from "app/renderingApp/layouts";
import { safeArray } from "app/utils/utils";
import useActiveBlock from "app/utils/useActiveBlock";
import useActiveBlockSheet from "app/utils/useActiveBlockSheet";
import useBlockVersion from "app/utils/useBlockVersion";
import useIsCustomBlock from "app/utils/useIsCustomBlock";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useSetBlock from "app/utils/useSetBlock";

const ActiveBlockConfig = () => {
  const fetchingBlockIds = useRecoilValue(rFetchingBlockIds);

  const isCustomBlock = useIsCustomBlock();

  const setBlock = useSetBlock();

  const page = usePage();

  const app = useRecoilValue(rApp);

  const activeBlockSheet = useActiveBlockSheet();

  const enableBlockVersions = get(app, "enable_block_versions", false);

  const customBlocks = useRecoilValue(rCustomBlocks);
  const originalActiveBlock = useActiveBlock();
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const activeBlockConfig = get(
    blockConfigs,
    get(originalActiveBlock, "componentId")
  );

  const { getBlockWithVersion } = useBlockVersion();
  const { blockWithVersion: activeBlock, versionData: activeBlockVersionData } =
    getBlockWithVersion(originalActiveBlock);

  const [permissionsAnchor, setPermissionsAnchor] = useState(null);

  const activeVersionMap = useRecoilValue(rActiveVersionMap);

  const userGroups = get(activeBlock, "userGroups");
  const pageUserGroups = get(page, "userGroups");

  let blockResources = get(activeBlockConfig, "resources", []);

  const blocks = useRecoilValue(rPageBlocks);

  const usedLayoutPositions = blocks
    .filter(
      (b) => b.layoutPosition && b.layoutPosition !== activeBlock.layoutPosition
    )
    .map((b) => b.layoutPosition);

  const activeLayout = blocks.find((b) => b.id === activeBlock.layoutParent);
  const layoutPosition = get(activeBlock, "layoutPosition");
  const activeLayoutItems = get(
    layouts,
    [get(activeLayout, "layout"), "items"],
    []
  ).filter((x) => !usedLayoutPositions.includes(x.i));

  const [tab, setTab] = useState("basics");

  useEffect(() => {
    setTab("basics");
  }, [activeBlock.id]);

  const isFetching = fetchingBlockIds.includes(activeBlock.id);

  const isSupabase = get(activeBlockSheet, "service") === "supabase";

  let otherFields = [
    {
      id: "innerJoin",
      label: "Inner Join",
      componentId: "Switch",
      section: "Supabase Inner Join",
      advanced: true,
      value: get(activeBlock, "innerJoin", false),
      hint: "Inner Join affects how data relations are handled in Supabase",
      displayCondition: () => isSupabase,
    },
  ];

  if (activeBlock.layoutParent) {
    const parentBlock = blocks.find((b) => b.id === activeBlock.layoutParent);
    const parentType = get(parentBlock, "componentId");

    // Keeping for now, but deprecate soon
    if (parentType === "Layout") {
      otherFields = [
        {
          id: "layoutPosition",
          label: "Layout Position",
          hideEmptyItem: true,
          componentId: "Select",
          hint: "Determine which Page Layout slot this Block is located in.",
          value: layoutPosition,
          width: "55px",
          options: activeLayoutItems.map((x) => ({
            label: x.i,
            value: x.i,
          })),
          orientation: "horizontal",
          displayCondition: page.layout,
          section: "content",
        },
      ];
    }
  }

  if (activeBlock.layoutParent || activeBlock.parent) {
    otherFields.push(removeFromLayout);
  }

  const matchingBlockOriginal = safeArray(customBlocks).find(
    (b) => b.original_id === get(activeBlock, "customBlock")
  );

  const matchingBlockNew = safeArray(customBlocks).find(
    (b) => b.id === get(activeBlock, "customBlock")
  );

  // If there's a match with original_id, use that - otherwise try the new one.
  const matchingBlock = matchingBlockOriginal || matchingBlockNew;

  let customInputs = getEndUserCustomFields({
    matchingBlock,
    activeBlock,
    setBlock,
    savedSpreadsheets,
  });

  const reusableCustomBlock = get(matchingBlock, "reusable");
  const customBlockResource = reusableCustomBlock
    ? []
    : [saveCustomBlockButton];

  if (reusableCustomBlock) {
    blockResources = [...blockResources, ...customInputs];

    const repeatingCustomBlock = get(matchingBlock, "repeating");
    if (repeatingCustomBlock) {
      blockResources = [
        ...blockResources,
        ...listBlockResources,
        sortingColumn,
        sortingDirection,
      ];
    }
  }

  const activeVersionId = get(activeVersionMap, get(activeBlock, "id"));

  const fields = [
    ...blockResources
      .map((r) => {
        if (r.isCustomInput) {
          return r;
        }

        if (r.isAction && activeVersionId) {
          return {
            ...r,
            value: get(activeBlockVersionData, r.id, r.defaultValue),
            block: activeBlock.id,
          };
        }
        return {
          ...r,
          value: get(activeBlock, r.id, r.defaultValue),
          block: activeBlock.id,
        };
      })
      .map((r) => ({
        ...r,
        referenceLabelIsEditable: isCustomBlock && r.isAction ? true : false,
      })),
    ...otherFields,
    {
      id: "userGroupPermissions",
      label: "User Groups",
      componentId: "FormButton",
      hint: "Limit Block access to certain users",
      link: "https://help.frontly.ai/en/articles/7974437-user-groups",
      icon: "FiKey",
      width: "36px",
      section: "visibility",
      orientation: "horizontal",
      onClick: (e) => setPermissionsAnchor(e.currentTarget),
      advanced: true,
    },
    {
      id: "displayConditions",
      label: "Display Conditions",
      componentId: "DisplayConditions",
      orientation: "vertical",
      value: get(activeBlock, "displayConditions", []),
      section: "visibility",
      hint: "Define custom conditions for when this Block should be visible",
      advanced: true,
    },
    ...customBlockResource,
  ]
    .filter(
      (f) =>
        !f.requiresSheet ||
        (f.requiresSheet && activeBlock.spreadsheet && !isFetching)
    )
    .filter((f) => {
      const isStyle = f.isStyle; // || get(f, "section") === "styles";
      // Styles
      if (isStyle) {
        return false;
      }

      // Filters
      if (get(f, "section", "").includes("Filter") && tab === "filters") {
        return true;
      }

      return f.advanced
        ? tab === "advanced"
        : tab === "basics" && !get(f, "section", "").includes("Filter");
    })
    .filter(
      (f) =>
        !f.displayCondition ||
        (f.displayCondition && f.displayCondition(activeBlock))
    );

  const sectionOrder = [
    "dataSource",
    "spreadsheet",
    "chartType",
    "setup",
    "tableColumns",
    "blockContainer",
    "content",
    "card",
    "dateSettings",
    "badge",
    "formMode",
    "formFields",
    "eventCardFields",
    "basics",
    "actions",
    "mapData",
    "listData",
    "timelineData",
    "detailView",
    "calculation",
    "display",
    "filters",
    "visibility",
    "styles",
  ];

  const filterResources = blockResources
    .filter((r) => get(r, "section", "").includes("Filter"))
    .filter(
      (f) =>
        !f.requiresSheet ||
        (f.requiresSheet && activeBlock.spreadsheet && !isFetching)
    );

  const filtersTab =
    filterResources.length > 0
      ? [
          {
            label: "Filters",
            value: "filters",
            active: tab === "filters",
          },
        ]
      : [];

  return (
    <div>
      <>
        <BlockWizard />
        {permissionsAnchor && (
          <UserGroupPermissions
            noGroupsText="To determine who can view this page, define User Groups in User Settings"
            anchorElement={permissionsAnchor}
            onClose={() => {
              setPermissionsAnchor(null);
            }}
            values={userGroups}
            allowedValues={pageUserGroups}
            onChange={(v) =>
              setBlock({
                data: {
                  userGroups: v,
                },
              })
            }
          />
        )}
      </>

      {enableBlockVersions && <BlockVersionConfig />}

      <SelectToggle
        data={{
          margin: "15px 15px 0 15px",
          onChange: (v) => setTab(v),
          tabs: [
            {
              label: "Basics",
              value: "basics",
              active: !tab || tab === "basics",
            },
            ...filtersTab,
            {
              label: "More",
              value: "advanced",
              active: tab === "advanced",
            },
          ],
        }}
      />

      <AdminForm
        key={activeBlock.id}
        sectionOrder={sectionOrder}
        fields={fields}
        borderBottom={true}
        onChange={(k, v) => setBlock({ data: { [k]: v } })}
      />
    </div>
  );
};

export default ActiveBlockConfig;
