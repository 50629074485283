import { get, startCase } from "lodash";
import { rPageBlocks, rSavedSpreadsheets } from "app/utils/recoil";

import { AdminForm } from ".";
import { useRecoilValue } from "recoil";

const PageFiltersConfig = ({ data }) => {
  const activeField = get(data, ["activeField"], {});

  const filterSheetMap = get(activeField, "sheets", {});

  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);

  const blocks = useRecoilValue(rPageBlocks);

  const spreadsheetBlocks = blocks.filter(
    (b) => b.spreadsheet && !["Form", "InfoList"].includes(b.componentId)
  );

  const uniqueSpreadsheetIds = [
    ...new Set(spreadsheetBlocks.map((b) => b.spreadsheet)),
  ];

  const sheets = savedSpreadsheets.filter((s) =>
    uniqueSpreadsheetIds.includes(s.id)
  );

  const sheetFields = sheets.map((s) => ({
    key: s.id,
    label: startCase(s.title),
    componentId: "Select",
    section: "Sheet Column Mapping",
    sectionHint:
      "Determine which spreadsheet columns are connected to each filter",
    value: get(filterSheetMap, s.id),
    options: get(s, "headers", []).map((h) => ({ label: h, value: h })),
  }));

  return (
    <div>
      <AdminForm
        sectionPadding={"0px"}
        fields={sheetFields}
        onChange={(k, v) => {
          let newMap = { ...filterSheetMap, [k]: v };
          data.onChange(newMap);
        }}
      />
    </div>
  );
};

export default PageFiltersConfig;
