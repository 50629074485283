import Header from "./header";
import Sidebar from "./sidebar";
import { get } from "lodash";
import { rApp } from "app/utils/recoil";
import usePage from "app/utils/usePage";
import { useRecoilValue } from "recoil";
import useWindowSize from "app/utils/useWindowSize";

// The actual content of the navigation
const NavigationContent = ({ navigation, isPreview }) => {
  const page = usePage();
  const hideNavigation = get(page, "hideNavigation", false);

  const { width } = useWindowSize();

  const navigationType = get(navigation, "type", "sidebar");

  if (hideNavigation) {
    return null;
  }

  // Show Header
  if (navigationType === "header" || width < 800) {
    return <Header screenWidth={width} isPreview={isPreview} />;
  }

  // Show Sidebar
  if (navigationType === "sidebar") {
    return <Sidebar isPreview={isPreview} />;
  }
};

// A wrapper that sets the color via CSS variable
const Navigation = ({ isPreview }) => {
  const app = useRecoilValue(rApp);
  const navigation = get(app, "navigation", {});
  const lightMode = get(navigation, "textColor") === "light";

  return (
    <div
      style={{
        "--nav-background": get(navigation, "backgroundColor"),
        "--nav-font-color": getColor(1, lightMode),
        "--nav-item-accent": getColor(0.08, lightMode),
        "--nav-round-icon": getColor(0.1, lightMode),
        "--nav-chevron-color": getColor(0.75, lightMode),
      }}
    >
      <NavigationContent navigation={navigation} isPreview={isPreview} />
    </div>
  );
};

export default Navigation;

// A custom function to get a color based on the light mode
const getColor = (number, lightMode) => {
  if (lightMode) {
    return `rgba(255,255,255,${number})`;
  }

  return `rgba(0,0,0,${number})`;
};
