import * as bootstrap from "react-icons/bs";
import * as feather from "react-icons/fi";
import * as hero from "react-icons/hi";
import * as md from "react-icons/md";

import {
  Button,
  FormInputWrapper,
  Icon,
  Modal,
  Row,
  SearchBar,
  Select,
  Text,
} from "app/components";

import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const IconContainer = styled("div")`
  border: 4px solid ${(p) => (p.active ? "var(--primary)" : "transparent")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
`;

const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  width: 100%;
  gap: 10px;
`;

export const packs = {
  bootstrap,
  feather,
  hero,
  md,
};

const IconModal = ({ data, hide }) => {
  const [activePack, setActivePack] = useState("bootstrap");

  const [search, setSearch] = useState("");

  // show how many
  const [amount, amountSet] = useState(49);

  const selectedPack = Object.keys(get(packs, activePack, {}));

  const options = [
    { label: "Bootstrap", value: "bootstrap" },
    { label: "Feather", value: "feather" },
    { label: "Hero", value: "hero" },
    { label: "Material Design", value: "md" },
  ];

  const filteredIcons = selectedPack.filter((i) => {
    if (search === "") return i;
    return i.toLowerCase().includes(search.toLowerCase());
  });

  const paginatedIcons = filteredIcons.slice(0, amount);

  const showButton = selectedPack.length >= paginatedIcons.length;

  return (
    <Modal header={{ title: "Select Icon" }} hide={hide}>
      <Row gap="15px" margin="0 0 30px 0">
        <FormInputWrapper label="Icon Pack">
          <Select
            data={{
              placeholder: "Icon Pack",
              hideEmptyItem: true,
              width: "200px",
              options,
              value: activePack,
              onChange: (newPack) => {
                setActivePack(newPack);
                amountSet(49);
              },
            }}
          />
        </FormInputWrapper>

        <FormInputWrapper label="Icon Name">
          <SearchBar
            data={{
              value: search,
              onChange: (v) => setSearch(v),
            }}
          />
        </FormInputWrapper>

        <FormInputWrapper label="Clear Selection">
          <Button
            data={{
              text: "Clear Selection",
              type: "basic",
              onClick: () => data.onChange(null),
            }}
          />
        </FormInputWrapper>
      </Row>

      <IconsContainer>
        {paginatedIcons.map((iconName, index) => {
          return (
            <IconContainer key={index} active={data.value === iconName}>
              <Icon
                data={{
                  icon: iconName,
                  hover: true,
                  size: 25,
                  color: "black",
                  onClick: () =>
                    data.onChange(data.value === iconName ? null : iconName),
                }}
              />
            </IconContainer>
          );
        })}
      </IconsContainer>

      {!paginatedIcons.length && (
        <Text
          data={{
            text: "No icons match this search.",
          }}
        />
      )}

      {showButton && (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            margin: "30px 0 0 0 ",
          }}
        >
          <Button
            data={{
              text: "Show more icons",
              type: "basic",
              onClick: () => amountSet((previousAmount) => previousAmount + 50),
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default IconModal;
