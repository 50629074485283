import { Column, Icon, Text } from ".";

import { colors } from "app/utils/theme";
import { get } from "lodash";
import styled from "styled-components";

const LargeIconSelect = ({ data }) => {
  const { onChange } = data;

  const value = get(data, "value", null);
  const options = get(data, "options", []);
  const orientation = get(data, "layout", "vertical"); // 'vertical', 'horizontal'
  const cardStyle = get(data, "cardStyle", "card"); // 'card', 'noCard'

  const handleClick = (optionValue) => {
    onChange(optionValue);
  };

  return (
    <Container
      orientation={orientation}
      count={options.length}
      cardStyle={cardStyle}
    >
      {options.map((option, index) => {
        const active = value === option.value;

        return (
          <Option
            key={option.value}
            orientation={orientation}
            active={active}
            cardStyle={cardStyle}
            onClick={() => handleClick(option.value)}
          >
            <Icon
              data={{
                icon: option.icon,
                color: active ? "var(--primary)" : "var(--grey7)",
                hover: true,
                size: 18,
              }}
            />
            <Text
              data={{
                text: option.label,
                fontSize: 14,
                fontWeight: 600,
                cursor: "pointer",
              }}
            />
          </Option>
        );
      })}
    </Container>
  );
};

export default LargeIconSelect;

const Container = styled.div`
  display: flex;
  width: 100%;
  ${(p) =>
    p.orientation === "horizontal"
      ? `
          flex-direction: row;
          gap: ${p.cardStyle === "card" ? "8px" : "40px"};
        `
      : `
          flex-direction: column;
          gap: 10px;
        `}
  flex-wrap: wrap;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => (p.orientation === "horizontal" ? "8px" : "20px")};
  box-sizing: border-box;
  color: var(--grey1);
  cursor: pointer;
  border-radius: 10px;
  width: ${(p) => (p.orientation === "horizontal" ? "auto" : "100%")};
  background: #fafafa;
  border: 2px solid ${(p) => (p.active ? "var(--primary)" : "#f1f1f1")};
  padding: 10px;
`;
