import { ConfigContainer, PanelContent } from "./styles";
import { rActiveDetailViewId, rShowStylesPanel } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import ActiveBlockStyles from "../../configPanel/ActiveBlockStyles";
import CustomBlockStyles from "../../configPanel/CustomBlockStyles";
import DetailViewStyles from "../../configPanel/DetailViewStyles";
import PageStyles from "../../configPanel/PageStyles";
import TooltipIcon from "../../configPanel/components/TooltipIcon";
import useActiveBlock from "app/utils/useActiveBlock";
import useIsCustomBlock from "app/utils/useIsCustomBlock";

const StylesPanel = () => {
  const isCustomBlock = useIsCustomBlock();
  const activeBlock = useActiveBlock();
  const activeDetailViewId = useRecoilValue(rActiveDetailViewId);
  const [showStylesPanel, setShowStylesPanel] =
    useRecoilState(rShowStylesPanel);

  return (
    <ConfigContainer
      rightSide
      className="open-styles-button"
      expanded={showStylesPanel}
      onClick={!showStylesPanel ? () => setShowStylesPanel(true) : null}
    >
      <PanelContent expanded={showStylesPanel}>
        <RenderStylesPanelContent
          isCustomBlock={isCustomBlock}
          activeBlock={activeBlock}
          activeDetailViewId={activeDetailViewId}
        />
      </PanelContent>
      {!showStylesPanel && (
        <TooltipIcon
          anchorTag=".open-styles-button"
          expanded={showStylesPanel}
          tooltip="Show Style Settings"
          icon="FaPaintBrush"
        />
      )}
    </ConfigContainer>
  );
};

const RenderStylesPanelContent = ({
  isCustomBlock,
  activeBlock,
  activeDetailViewId,
}) => {
  // Active Block
  if (activeBlock) {
    return <ActiveBlockStyles />;
  }

  // Detail View
  if (!isCustomBlock && activeDetailViewId) {
    return <DetailViewStyles />;
  }

  // Custom block
  if (isCustomBlock && !activeDetailViewId) {
    return <CustomBlockStyles />;
  }

  // If nothing else, show page styles
  return <PageStyles />;
};

export default StylesPanel;
